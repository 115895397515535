import React, { MutableRefObject } from 'react';
import { View } from 'react-native';

import { Store } from '../../../store';
import Config, { Log } from '../../../config';
import { ContextTheme, dimensionWidth } from '../../../styles';
import GpeButton from '../../GpeButton';
import DimensionsEventTimeout from '../../DimensionsEvent';
import {
    doDeleteBookFromUserBookshelf,
    openReadItemId,
} from '../../../middlewares/LibraryMiddleware';
import { getTranslation } from '../../../middlewares/AppMiddleware';
import { EbookInterface } from '../../../interfaces/middlewares';
import { removeFromStorage } from '../../../middlewares/commons';
import GpeText from '../../GpeText';
import { DispatchType } from '../../../interfaces/store';

function EbookInfo(
    props: {
        ebook: EbookInterface,
        bookshelfSample?: boolean,
    }
) {
    /** STORE */
    const { state, dispatch } = React.useContext(Store);

    /** PROPS */
    const translation = React.useMemo(() => getTranslation(state.app.language), [state.app.language]);
    const dispatchRef: MutableRefObject<DispatchType> = React.useRef(dispatch);
    const downloadedVersion = React.useMemo(() => (state.library.downloadedVersions[props.ebook.gpeId]
        && state.library.downloadedVersions[props.ebook.gpeId] === Config.ebook.downloadFileName
        ? state.library.downloadedVersions[props.ebook.gpeId]
        : undefined
    ), [
        state.library.downloadedVersions,
        props.ebook.gpeId
    ]);

    /** MEDIA */
    const titleMedia = React.useMemo(() => ({ xs: 32, md: 44 }), []);
    const authorsMedia = React.useMemo(() => ({ xs: 20, md: 24 }), []);

    /** LOCAL STATE */
    const [ebookInfoState, setEbookInfoState] = React.useState<{
        titleSize: any;
        authorsSize: any;
    }>({
        titleSize: dimensionWidth(titleMedia),
        authorsSize: dimensionWidth(authorsMedia),
    });

    /** RESIZE LISTENER */
    DimensionsEventTimeout(() => {
        const _titleSize = dimensionWidth(titleMedia);
        const _authorsSize = dimensionWidth(authorsMedia);
        if (_titleSize !== ebookInfoState.titleSize
            || _authorsSize !== ebookInfoState.authorsSize) {
            setEbookInfoState({
                ...ebookInfoState,
                titleSize: _titleSize,
                authorsSize: _authorsSize,
            });
        }
    });

    return React.useMemo(() => {
        Log.debug('component: EbookInfo');

        return (
            <View
                style={{
                    width: '100%',
                    maxWidth: 600,
                    margin: 'auto',
                }}>
                <GpeText
                    style={{
                        fontFamily: ContextTheme.font.semibold,
                        fontSize: ebookInfoState.titleSize,
                        textAlign: 'center',
                        marginBottom: 10,
                    }}>{props.ebook.title}</GpeText>

                {props.ebook.authors && props.ebook.authors.length ? (
                    <GpeText
                        style={{
                            fontFamily: ContextTheme.font.regular,
                            fontSize: ebookInfoState.authorsSize,
                            textAlign: 'center',
                            marginBottom: 10,
                        }}>{`de `}{props.ebook.authors.map((author: string, index: number) => {
                            let separator = props.ebook.authors && index === props.ebook.authors.length - 2
                                ? ' e '
                                : props.ebook.authors && index < props.ebook.authors.length - 1
                                    ? ', '
                                    : '';
                            return author + separator;
                        })}</GpeText>
                ) : null}

                {props.ebook.sample ? (
                    <GpeText
                        style={{
                            fontFamily: ContextTheme.font.regular,
                            fontSize: 16,
                            textAlign: 'center',
                            marginBottom: 10,
                        }}>{`Excerto da obra.`}</GpeText>
                ) : null}

                <View
                    style={{ marginTop: 50 }}>
                    <GpeButton
                        style={{ width: '100%', maxWidth: undefined, marginBottom: 10, }}
                        onPress={() => state.app.storageDir
                            && state.app.uid
                            && state.user.authToken
                            && state.user.auth
                            && openReadItemId(
                                dispatchRef.current,
                                state.app.storageDir,
                                state.app.uid,
                                state.user.authToken,
                                state.user.auth,
                                state.user.kitabooToken,
                                state.library.readingStarted,
                                props.ebook,
                                translation,
                            )}>{props.ebook.formatType === Config.ebook.format.kitabooAudio
                                ? 'OUVIR'
                                : 'LER'}</GpeButton>
                    {downloadedVersion ? (
                        <GpeButton
                            style={{ width: '100%', maxWidth: undefined, marginBottom: 10, }}
                            onPress={() => state.app.storageDir
                                && removeFromStorage(
                                    dispatchRef.current,
                                    state.app.storageDir,
                                    props.ebook
                                )}>{`REMOVER DA MEMÓRIA`}</GpeButton>
                    ) : null}

                    {props.ebook.sample
                        && props.bookshelfSample
                        && props.ebook.subArtid
                        ? (
                            <GpeButton
                                style={{ width: '100%', maxWidth: undefined, marginBottom: 10, }}
                                onPress={() => state.app.uid
                                    && state.app.storageDir
                                    && state.user.auth
                                    && state.user.authToken
                                    && doDeleteBookFromUserBookshelf(
                                        dispatchRef.current,
                                        props.ebook,
                                        state.app.uid,
                                        state.app.storageDir,
                                        state.user.auth,
                                        state.user.authToken,
                                        true
                                    )}>{`ELIMINAR`}</GpeButton>
                        ) : null}
                </View>
            </View>
        );
    }, [
        translation,
        props,
        ebookInfoState,
        state.app.storageDir,
        state.app.uid,
        state.user.auth,
        state.user.authToken,
        state.user.kitabooToken,
        state.library.readingStarted,
        downloadedVersion,
    ]);
}

export { EbookInfo as default }