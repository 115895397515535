import React from 'react';
import { Text, TextProps } from 'react-native';

function GpeText(props: TextProps & { children: any }) {
    return React.useMemo(() => {
        return (
            <Text
                allowFontScaling={false}
                {...props} />
        );
    }, [props]);
};

export { GpeText as default };