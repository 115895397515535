import React from 'react';

import Config from "../config";
import { Store } from '../store';
import WebPage from './WebPage';

/**
 * Creates Devices Management WebView
 */
function DevicesWebPage() {
    const { state } = React.useContext(Store);

    const token = React.useMemo(() => (state.user.auth ? `?token=${state.user.auth.token}` : ''
    ), [state.user.auth]);

    const contextConfig = React.useMemo(() => (
        Config.contextParams
    ), []);

    const isFromAppParam = React.useMemo(() => ('&APP=true'), []);

    const uri = React.useMemo(() => (
        `${contextConfig.devicesUrl}${token}${isFromAppParam}`
    ), [contextConfig.devicesUrl, token, isFromAppParam]);

    return React.useMemo(() => {
        return (
            <WebPage uri={uri} />
        )
    }, [uri]);
}

export { DevicesWebPage as default }