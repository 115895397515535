import React, { MutableRefObject } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';

import Config, { Log } from '../../../../../config';
import { ContextTheme } from '../../../../../styles';
import SocialButton from '../SocialButton';
import { socialLoginToken } from '../../../../../middlewares/UserMiddleware';
import { Store } from '../../../../../store';
import { DispatchType } from '../../../../../interfaces/store';

declare const window: any;

function GoogleLogin(props: { keepLoggedIn: boolean, onPress?: Function, fromRegist?: boolean }) {
    const { dispatch } = React.useContext(Store);

    const dispatchRef: MutableRefObject<DispatchType> = React.useRef(dispatch);
    const { keepLoggedIn, onPress, fromRegist } = React.useMemo(() => props, [props]);

    const injectScript = React.useCallback(() => {
        const responseHandler = (response: { credential?: string }) => {
            if (response
                && response.credential) {
                socialLoginToken(
                    dispatchRef.current,
                    Config.social.google.provider,
                    response.credential,
                    fromRegist,
                    keepLoggedIn,
                );
            }
        };

        const googleLoad = () => {
            window.google.accounts.id.initialize({
                client_id: Config.context.social.google.webId,
                ux_mode: 'popup',
                callback: responseHandler,
            });

            window.google.accounts.id.prompt((
                notification: {
                    isNotDisplayed: () => boolean,
                    isSkippedMoment: () => boolean
                }
            ) => {
                if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
                    document.cookie = `g_state=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT`;
                    window.google.accounts.id.prompt();
                }
            });
        };

        // inject script
        const scriptId = 'google-api';
        const elem = document.getElementById(scriptId);
        elem && googleLoad();
        !elem && ((document: any, src: string, id: string) => {
            return new Promise((resolve, reject) => {
                // let body = document.getElementsByTagName('body')[0];
                let tag = document.createElement('script');

                tag.id = id;
                tag.type = 'text/javascript';
                tag.async = false; // Load in order

                const handleLoad = () => { googleLoad(); resolve(src); }
                const handleReject = () => { reject(src) }

                // tag.onreadystatechange = handleCallback;
                tag.addEventListener('load', handleLoad)
                tag.addEventListener('error', handleReject);
                tag.src = src;
                // body.appendChild(tag);
                document.head.appendChild(tag);
                return tag;
            });
        })(window.document, Config.social.google.sdk, scriptId);
    }, [keepLoggedIn, fromRegist]);

    return React.useMemo(() => {
        Log.debug('component: GoogleLogin');

        return (
            <SocialButton
                onPress={() => onPress
                    ? onPress()
                    : injectScript()}>
                <FontAwesomeIcon
                    fontSize={16}
                    color={ContextTheme.palette.googleRed}
                    icon={faGoogle} />
            </SocialButton>
        )
    }, [onPress, injectScript]);
}

export { GoogleLogin as default };