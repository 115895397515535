import { AppRegistry, Platform } from 'react-native';

import Config, { initializeLogger } from './config';
import App from './app';

initializeLogger();

AppRegistry.registerComponent(Config.context.appName, () => App);

if (Platform.OS === 'web') {
    AppRegistry.runApplication(Config.context.appName, { rootTag: document.getElementById('root') });
}