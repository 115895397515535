import React from 'react';
import { TouchableOpacity } from 'react-native';
import {
    BrowserRouter,
    Route as BrowserRoute,
    Switch as BrowserSwitch,
    Link as BrowserLink,
    Redirect as BrowserRedirect,
    useLocation as BrowserLocation,
    useHistory as BrowserHistory,
} from 'react-router-dom';

export function Router(props: any) {
    return <BrowserRouter>{props.children}</BrowserRouter>;
}

export function Route(props: any) {
    return <BrowserRoute>{props.children}</BrowserRoute>;
}

export function Switch(props: any) {
    return <BrowserSwitch>{props.children}</BrowserSwitch>;
}

export function Link(props: any) {
    return (
        <BrowserLink
            style={{ textDecorationLine: 'none' }}
            to={props.to}>
            <TouchableOpacity
                style={props.style}>
                {props.children}
            </TouchableOpacity>
        </BrowserLink>
    );
}

export function Redirect(props: { to: string }) {
    /** manter as query params do url */
    const params = { ...props };
    if (params && params.to) {
        const location = BrowserLocation();
        const searchParams = new URLSearchParams(location.search);
        params.to = `${params.to}${searchParams.toString()
            ? '?' + searchParams.toString()
            : ''}`;
    }
    return <BrowserRedirect {...params} />;
}

export function BackButton(props: any) {
    return props
        && props.children
        ? props.children
        : null;
}

export const useLocation = BrowserLocation;

export function useHistory() {
    const history = BrowserHistory();
    const location = BrowserLocation();
    const searchParams = new URLSearchParams(location.search);
    const insertParams = `${searchParams.toString()
        ? '?' + searchParams.toString()
        : ''}`;
    return {
        push: (url: string, newParams: string | null = null) => {
            return history.push(`${url}${newParams === null ? insertParams : newParams}`);
        },
        replace: (url: string, newParams: string | null = null) => {
            return history.replace(`${url}${newParams === null ? insertParams : newParams}`);
        }
    }
}