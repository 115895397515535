import React from 'react';
import { View, Animated, StyleSheet } from 'react-native';
import { Store } from '../../../../store';
import { ContextTheme, RegistStyles } from '../../../../styles';
import { getTranslation } from '../../../../middlewares/AppMiddleware';
import GpeText from '../../../GpeText';

function ProgressBar(props: any) {
    /** STORE */
    const { state } = React.useContext(Store);
    /** PROPS */
    const translation = React.useMemo(() => getTranslation(state.app.language), [state.app.language]);
    /** MEDIA **/
    let animation = React.useRef(new Animated.Value(0));
    let progress = props.progress ? props.progress : 0;

    React.useEffect(() => {
        Animated.timing(animation.current, {
          toValue: progress,
          duration: 1000,
          useNativeDriver: false
        }).start();
        
    },[progress])

    const width = animation.current.interpolate({
        inputRange: [0, 100],
        outputRange: ["0%", "100%"],
        extrapolate: "clamp"
    })

    const color = animation.current.interpolate({
        inputRange: [0, 25, 50, 75, 100],
        outputRange: ['#FFFFFF','#FF4B47','#F9AE35','#37D096','#2DAF7D'],
        extrapolate: "clamp"
    })

    let description = React.useMemo(() => {
        if (props.progress === 0) return "";
        if (props.progress === 25) return "FRACA";
        if (props.progress === 50) return "MÉDIA";
        if (props.progress === 75) return "FORTE";
        if (props.progress === 100) return "MUITO FORTE";  
    },[props.progress])

    return React.useMemo(() => {
        return (
            <View key="container" style={RegistStyles.progressBarContainer}>
                <GpeText style={[RegistStyles.progressBarDesc, {color: ContextTheme.palette.lightBlack}]}>
                    {progress > 0 ? translation('password.progressbar.description') : ""}
                    <Animated.Text style={{ color: color }}>{description}</Animated.Text>
                </GpeText>
                <View key="progress-bar" style={[RegistStyles.progressBar]}>
                    <Animated.View style={[StyleSheet.absoluteFill, { backgroundColor: color, width, borderRadius: 5 }]} />
                </View>
            </View>
        )
    }, [translation, description, color, width, progress]);
}

export { ProgressBar as default };