import { Linking } from "react-native";

import { LanguageKeys } from '../interfaces/language';
import language from '../language';
import { getStorage, getStorageKeys, clearStorage } from '../store/storage';
import Config, { AppContext, Log } from '../config';
import {
    handleLanguageData,
    getCurrentTime,
    generateRandom,
    openPopup,
    services,
    loading,
    getAssetDirectory
} from './commons';
import { doLogout } from "./UserMiddleware";
import { DispatchType } from "../interfaces/store";
import { AssetsInterface, AssetsKeysInterface } from "../interfaces/components";
import { directoryExist, downloadFile, removeDirectory } from "./FilesystemMiddleware";

const onGoingRequests: {
    [key: string]: boolean
} = {};

function doClearStorage() {
    clearStorage();
}

function generateUid() {
    const uid = Config.platform !== 'android' && Config.platform !== 'ios'
        ? 123
        : String(getCurrentTime()) + String(generateRandom());
    return uid;
}

function loadStorage(_dispatch: DispatchType) {
    loading(
        _dispatch,
        "getStorageKeys",
        () => getStorageKeys()
            .then(async (keys: any) => {
                // XXX: nao usar permanentemente, apenas para resets entre versoes
                const _ignoreStorageKeys: Array<string> = [
                    // 'APP-LANGUAGE',
                    // 'USER-AUTH',
                    // 'LIBRARY-BOOKSHELF',
                    // 'APP-UID_REGISTERED',
                    // 'LIBRARY-READING_STARTED',
                    // 'LIBRARY-DOWNLOADED_COVERS',
                ];
                const _storage = [];
                for (const key of keys) {
                    if (_ignoreStorageKeys.indexOf(key) < 0) {
                        const value = await getStorage(key);
                        _storage.push({
                            type: key,
                            payload: value,
                        });
                    }
                }
                _storage.push({
                    type: 'APP-STORAGE_LOADED',
                    payload: true,
                });
                _dispatch(_storage);
            })
    );
}

function doCheckVersion(_dispatch: DispatchType) {
    return services.app(
        _dispatch,
        {
            checkVersion: {
                urlParams: {
                    "appName": Config.context.packageName,
                    "appVersion": Config.appVersion
                }
            }
        },
        (response) => {
            const manager = response.success
                && response.success.data
                && response.success.data.length
                ? response.success.data[0]
                : { mustUpdate: false };
            _dispatch({
                type: 'APP-MANAGER',
                payload: manager,
            });
        }
    );
}

function createMobileSession(
    _dispatch: DispatchType,
    _appStateUid: string,
    _userStateAuth: { token: string },
    _userStateAuthToken: string
) {
    return services.app(
        _dispatch,
        {
            createMobileSession: {
                urlParams: {
                    "applicationalContextId": Config.context.applicationContext,
                    "readerAppId": Config.context.appId[Config.platform],
                },
                headers: {
                    "x-token": _userStateAuth.token,
                    "x-st": _userStateAuthToken,
                    "x-di": _appStateUid,
                }
            }
        },
        (response) => {
            if (response.error) {
                _dispatch({
                    type: 'APP-UID_REGISTERED_ERROR',
                    payload: response.error,
                });
                openPopup(_dispatch, response.error);
                return doLogout(_dispatch);
            }

            response.success
                && _dispatch({
                    type: 'APP-UID_REGISTERED',
                    payload: _appStateUid,
                });
        }
    );
}

function getLanguage(_dispatch: DispatchType) {
    return services.app(
        _dispatch,
        {
            getValueByKey: {
                urlParams: {
                    "siteId": Config.context.siteId,
                    "redisKey": `${Config.context.name}.reader-language`,
                }
            }
        },
        (response) => {
            const cmsLanguage = response.success
                && response.success.data
                ? handleLanguageData(response.success.data)
                : {};
            _dispatch({
                type: 'APP-LANGUAGE',
                payload: {
                    ...language[Config.contextName as AppContext],
                    ...cmsLanguage
                }
            });
        }
    );
}

function getTranslation(
    appStateLanguage: LanguageKeys | null
) {
    const _appStateLanguage = appStateLanguage;
    return (
        _key: keyof LanguageKeys,
        _replace?: Array<string>,
        showKey: boolean = true
    ) => {
        if (_appStateLanguage
            && _appStateLanguage[_key]) {
            let _value: string = _appStateLanguage[_key];
            if (_replace && _replace.length) {
                _replace.forEach((element, index) => {
                    _value = _value.replace('{' + index + '}', element);
                });
            }
            return _value;
        }
        else {
            return showKey ? '[' + _key + ']' : undefined;
        }
    }
}

async function openLink(_url: string, _target = '_self') {
    if (_url) {
        switch (Config.platform) {
            case 'macos':
            case 'windows':
            case 'linux':
                window.require('electron').shell.openExternal(_url);
                break;
            case 'web':
                const win: any = window.open(_url, _target);
                win.focus();
                break;
            default:
                try {
                    Linking.openURL(_url);
                } catch (error) { }
                break;
        }
    }
}

async function openLinkByPlatform(
    _urlAndroid: string,
    _urlIos: string,
    _urlWeb: string
) {
    if (_urlAndroid && _urlIos && _urlWeb) {
        switch (Config.platform) {
            case 'ios':
                openLink(_urlIos);
                break;
            case 'android':
                openLink(_urlAndroid);
                break;
            default:
                openLink(_urlWeb);
                break;
        }
    }
}

async function downloadAssets(
    _dispatch: DispatchType,
    _appStateStorageDir: string,
    _assets: AssetsInterface,
): Promise<void> {
    const _serviceName = 'downloadAssets';

    if (onGoingRequests[_serviceName]) return;

    onGoingRequests[_serviceName] = true;

    const _mainDirectory = getAssetDirectory(_appStateStorageDir, 'keys');
    const _downloadingAssets: { [key: string]: { uri: string, file: string } } = {};
    const _promisesEvents: Array<{}> = [];

    const _dispatchCallback = (key: string) => {
        _dispatch({
            type: 'APP-ADD_DOWNLOADED_ASSETS',
            payload: { [key]: _downloadingAssets[key] },
        });
    };

    const _errorCallback = (key: string, uri: string) => {
        Log.error("ERROR ASSET DOWNLOAD:", key, uri);
        _dispatch({
            type: 'APP-REMOVE_DOWNLOADED_ASSETS',
            payload: key,
        });
        delete _downloadingAssets[key];
    };

    _dispatch({
        type: 'APP-DOWNLOADED_ASSETS',
        payload: {},
    });

    return removeDirectory(_mainDirectory)
        .then(() => {
            for (const key in _assets.keys) {
                for (const entry in _assets.keys[key as keyof AssetsKeysInterface]) {
                    const uri = _assets.keys[key as keyof AssetsKeysInterface][entry];
                    const assetKeyIndex = `${key}.${entry}`;
                    if (!_downloadingAssets[assetKeyIndex]) {
                        if (Config.platform === 'web') {
                            _downloadingAssets[assetKeyIndex] = { uri: uri, file: uri };
                            _dispatchCallback(assetKeyIndex);
                        } else {
                            const fileDirectory = `${_mainDirectory}/${assetKeyIndex}`;
                            const extension = uri.split('.');
                            const fileName = `file.${extension[extension.length - 1]}`;
                            _downloadingAssets[assetKeyIndex] = { uri: uri, file: `file://${fileDirectory}/${fileName}` };
                            _promisesEvents.push(
                                downloadFile(
                                    uri,
                                    fileName,
                                    fileDirectory,
                                ).then(() => {
                                    const _fileLocation = `${fileDirectory}/${fileName}`;
                                    directoryExist(_fileLocation)
                                        .then((fileExists: boolean) => fileExists
                                            ? _dispatchCallback(assetKeyIndex)
                                            : _errorCallback(assetKeyIndex, uri))
                                        .catch(() => _errorCallback(assetKeyIndex, uri));

                                }).catch(() => _errorCallback(assetKeyIndex, uri))
                            );
                        }
                    }
                }
            }

            return Promise.all(_promisesEvents).then(() => {
                _dispatch({
                    type: 'APP-ASSETS_VERSION',
                    payload: _assets.version,
                });

                delete onGoingRequests[_serviceName];
            });
        });
}

function getAssetImageUri(
    _key: string,
    _appDownloadedAssets: { [key: string]: { uri: string, file: string } },
): string | undefined {

    return _key
        && _appDownloadedAssets[_key]
        && _appDownloadedAssets[_key].file
        ? _appDownloadedAssets[_key].file
        : undefined;
}

function redownloadAsset(
    _dispatch: DispatchType,
    _appStateStorageDir: string,
    _appDownloadedAssets: { [key: string]: { uri: string, file: string } },
    _key: string,
) {
    if (Config.platform !== 'web'
        && _appDownloadedAssets[_key]) {
        const _asset = _appDownloadedAssets[_key];

        const _errorCallback = () => {
            Log.error("ERROR ASSET DOWNLOAD:", _key, _asset.uri);
            _dispatch({
                type: 'APP-REMOVE_DOWNLOADED_ASSETS',
                payload: _key,
            });
        };

        const _callback = (_fileLocation: string) => {
            _dispatch({
                type: 'APP-ADD_DOWNLOADED_ASSETS',
                payload: { [_key]: { uri: _asset.uri, file: `file://${_fileLocation}` } },
            });
        };

        const mainDirectory = getAssetDirectory(_appStateStorageDir, 'keys');
        const fileDirectory = `${mainDirectory}/${_key}`;
        const extension = _asset.uri.split('.');
        const fileName = `file.${extension[extension.length - 1]}`;

        downloadFile(
            _asset.uri,
            fileName,
            fileDirectory,
        ).then(() => {
            const _fileLocation = `${fileDirectory}/${fileName}`;
            directoryExist(_fileLocation)
                .then((fileExists: boolean) => fileExists
                    ? _callback(_fileLocation)
                    : _errorCallback())
                .catch(_errorCallback);

        }).catch(_errorCallback);
    }
}

export {
    loadStorage,
    doCheckVersion,
    createMobileSession,
    doClearStorage,
    getLanguage,
    generateUid,
    openLink,
    openLinkByPlatform,
    getTranslation,
    downloadAssets,
    getAssetImageUri,
    redownloadAsset,
}