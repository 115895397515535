import React from 'react';

import { Store } from '../store';
import Config, { Log } from '../config';
import { Redirect } from '../components/GpeRouter';
import Context from '../components/ByContext';

function IntroductionScreen(props: any) {
    const { state } = React.useContext(Store);

    return React.useMemo(() => {
        Log.debug('screen: INTRODUCTION');

        return state.user.auth
            ? <Context.OnBoard />
            : <Redirect to={Config.routes.firstpage} />
    }, [state.user.auth]);
}

export { IntroductionScreen as default }