import React from 'react';
import { View } from 'react-native';

import { Store } from '../../../store';
import { LibraryStyles, ContextTheme } from '../../../styles';
import Icon from '../../Icon';

function CoverDraggingIcon() {
    const { state } = React.useContext(Store);

    const draggingMode = React.useMemo(() => (
        state.library.moveToShelf || state.library.manualSorting
    ), [state.library.moveToShelf, state.library.manualSorting]);

    return React.useMemo(() => {
        return (draggingMode
            ? (
                <View
                    style={[
                        LibraryStyles.icon,
                        LibraryStyles.themeBackgroundColor,
                    ]}>
                    <Icon color={ContextTheme.palette.white} icon='d' />
                </View>
            ) : null
        )
    }, [draggingMode]);
}

export { CoverDraggingIcon as default }