import React from 'react';

import { HelpStyles } from '../../../../styles';
import Icon from '../../../Icon';
import language from '../../../../language';
import GpeText from '../../../GpeText';

const helpContent = [
    {
        title: <GpeText>Organização dos artigos na Biblioteca</GpeText>,
        text: (
            <GpeText>
                Como numa biblioteca, os seus artigos são apresentados em estantes.
                Na página inicial da Biblioteca são apresentadas sugestões de leitura na <GpeText style={HelpStyles.themeColor}>{language.bertrand['library.showcase.editor.title']}</GpeText> e na estante <GpeText style={HelpStyles.themeColor}>{language.bertrand['library.showcase.reading.title']}</GpeText> os últimos artigos abertos na sua biblioteca.
                Na estante <GpeText style={HelpStyles.themeColor}>{language.bertrand['library.ebooks.bookshelf.title']}</GpeText> encontra os artigos que foram comprados por si. Para além destas estantes automáticas, pode organizar os seus artigos em estantes criadas por si.
                Pode criar, renomear e apagar as suas estantes.
            </GpeText>
        ),
        options: [
            {
                title: <GpeText>Criar, editar e Apagar Estantes</GpeText>,
                text: (
                    <GpeText>
                        Para criar uma estante clique em <GpeText style={HelpStyles.themeColor}>+ {language.bertrand['library.bookshelf.list.new.shelf']}</GpeText>.
                        Para apagar ou editar o nome da estante, dentro da estante clique em e escolha a opção <GpeText style={HelpStyles.themeColor}>{language.bertrand['library.bookshelf.menu.option.delete']}</GpeText> ou <GpeText style={HelpStyles.themeColor}>{language.bertrand['library.bookshelf.menu.option.rename']}</GpeText>.
                    </GpeText>
                ),
            },
            {
                title: <GpeText>Mover os seus artigos dentro e entre as estantes</GpeText>,
            text: <GpeText>Para mover os artigos dentro da estante, clique em <Icon style={HelpStyles.iconText} icon='o' />, escolha a opção <GpeText style={HelpStyles.themeColor}>{language.bertrand['library.bookshelf.menu.option.order.manual']}</GpeText>, clique no botão do eBook e mova-o para a posição pretendida. Para mover os artigos para outra estante, clique em <Icon style={HelpStyles.iconText} icon='d' />, escolha a opção <GpeText style={HelpStyles.themeColor}>{language.bertrand['library.bookshelf.menu.option.move']}</GpeText>, clique no botão <Icon style={HelpStyles.iconText} icon='d' /> do eBook e mova-o para cima do nome da estante pretendida. Dentro das estantes pode ordenar os seus artigos por ordem alfabética, Autor ou Título.</GpeText>,
            }
        ],
    },
    {
        title: <GpeText>Informação sobre leitura offline e excertos</GpeText>,
        text: <GpeText>Os artigos com a indicação leitura offline estão disponíveis para ler sem ligação à internet, ou seja, estão disponíveis no seu dispositivo. Para carregar um eBook no seu dispositivo, clique na capa e aguarde que o eBook seja carregado a 100% e seja apresentada a indicação leitura offline Para remover os artigos da memória e, assim libertar espaço no seu dispositivo, clique de forma prolongada na capa e escolha a opção Remover da memória. Os excertos podem ser adicionados à Biblioteca por si ou pela Bertrand.Para remover os excertos da Biblioteca, clique de forma prolongada na capa e escolha a opção Remover da Biblioteca.</GpeText>,
    },
    {
        title: <GpeText>Marcador de Leitura sincronizado</GpeText>,
        text: <GpeText>Pode ler o seu eBook em vários dispositivos sem se preocupar com o marcador. A Bertrand sincroniza automaticamente o marcador para que nunca perca a última página. Só tem é de verificar se tem ligação à internet quando abre e fecha o eBook para atualizar o marcador. Nos casos em que não tem acesso à internet o marcador ficará guardado no dispositivo sem ser sincronizado automaticamente com os restantes.</GpeText>,
    },
    {
        title: <GpeText>Funcionalidades do leitor Biblio</GpeText>,
        text: <GpeText>Na Biblio pode ler os seus artigos como quiser. Disponibilizamos várias funcionalidades para que a sua experiência de leitura seja a melhor possível.</GpeText>,
        options: [
            {
                title: <GpeText>NAVEGAÇÃO</GpeText>,
                text: <GpeText>Navegue no eBook através do Índice <Icon style={HelpStyles.iconText} icon='i' /> ou se quiser uma navegação mais rápida utilize o cursor do menu no rodapé.</GpeText>,
            },
            {
                title: <GpeText>NOTAS</GpeText>,
                text: <GpeText>Insira, apague e edite as suas notas <Icon style={HelpStyles.iconText} icon='n' /> como e quantas vezes quiser. Estão sempre disponíveis para consulta posterior.</GpeText>,
            },
            {
                title: <GpeText>PESQUISA</GpeText>,
                text: <GpeText>Pesquise <Icon style={HelpStyles.iconText} icon='s' /> uma ou mais palavras dentro do eBook. Se encontrar, surge uma caixa com a lista dos resultados e pode navegar para as respetivas páginas.</GpeText>,
            },
            {
                title: <GpeText>CONFIGURAÇÕES</GpeText>,
                text: <GpeText>Na opção configurações <Icon style={HelpStyles.iconText} icon='a' /> defina as suas preferências de leitura. Ajuste o tamanho e fonte da letra. Escolha a melhor cor de fundo para ler: branco, sépia ou preto – leitura aconselhada para modo noturno. Estas configurações estão disponíveis sempre que o formato do eBook permita.</GpeText>,
            },
            {
                title: <GpeText>ASSISTENTE DE LEITURA</GpeText>,
                text: <GpeText>Disponibilizamos ainda o Assistente de Leitura da Língua Portuguesa Porto Editora (apenas em modo online), para que esclareça qualquer dúvida que surja. Clique de forma prolongada na palavra e veja a definição. O assistente de leitura está disponível sempre que o formato do eBook permita.</GpeText>,
            },
        ],
    }
];

export { helpContent as default }