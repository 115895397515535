import { ErrorServicesType, LanguageKeys } from '../interfaces/language';
import Config from '../config';
import wook, { errorMapping as wookErrors } from './contexts/wook';
import bertrand, { errorMapping as bertrandErrors } from './contexts/bertrand';

const errorMapping: {
    wook: ErrorServicesType,
    bertrand: ErrorServicesType,
} = {
    wook: wookErrors[Config.defaultLanguage],
    bertrand: bertrandErrors[Config.defaultLanguage],
}

const language: {
    wook: LanguageKeys,
    bertrand: LanguageKeys,
} = {
    wook: wook[Config.defaultLanguage],
    bertrand: bertrand[Config.defaultLanguage],
}

export { language as default, errorMapping };