import React, { MutableRefObject } from 'react';
import {
    TouchableOpacity,
    View,
    SafeAreaView,
    Dimensions,
    ScrollView,
    NativeSyntheticEvent,
    NativeScrollEvent
} from 'react-native';

import Config, { Log } from '../../../../../config';
import { CarouselContentInterface } from '../../../../../interfaces/components';
import { DispatchType } from '../../../../../interfaces/store';
import { setOnBoard } from '../../../../../middlewares/UserMiddleware';
import { Store } from '../../../../../store';
import { CarouselStyles, ContextTheme, LibraryStyles } from '../../../../../styles';
import { useHistory } from '../../../../GpeRouter';
import GpeScrollView from '../../../../GpeScrollView';
import Icon from '../../../../Icon';
import DimensionsEventTimeout from '../../../../DimensionsEvent';
import Slide from './components/partials/Slide';

function Onboard() {
    const { state, dispatch } = React.useContext(Store);

    const onBoardContent: Array<CarouselContentInterface> = React.useMemo(() => [
        {
            title: 'Audiolivros',
            subtitle: 'Agora, na Biblio Bertrand, já pode ouvir os audiolivros comprados na nossa livraria online.',
            assetKey: 'onboard.slideAudio0',
            footer: (
                <React.Fragment>
                    {`São disponibilizados na sua Biblioteca, identificados, na respetiva capa, com o símbolo `}
                    <Icon
                        style={[
                            LibraryStyles.iconText,
                            { backgroundColor: ContextTheme.palette.theme },
                        ]}
                        color={ContextTheme.palette.white}
                        icon={'S'} />
                    {`\nPara começar a ouvir, clique na capa.`}
                </React.Fragment>
            ),
        },
        {
            title: `Audiolivros`,
            subtitle: `Escolha quando e como quer ouvir.`,
            assetKey: 'onboard.slideAudio1',
            footer: `Selecione a velocidade de narração e programe o temporizador caso pretenda que a narração pare após um período definido por si.`
        },
        {
            title: `Audiolivros`,
            subtitle: `Adicione marcadores às suas passagens preferidas.`,
            assetKey: 'onboard.slideAudio2',
            footer: `Edite ou elimine os seus marcadores conforme desejar. Clicando em qualquer um deles, será encaminhado para o momento que destacou.`
        },
        {
            title: 'Biblioteca',
            subtitle: 'Pesquisar na Biblioteca.',
            assetKey: 'onboard.slideEbook0',
            footer: `Encontre o que procura,\npesquisando por título ou autor.`
        },
        {
            title: 'Biblioteca',
            subtitle: 'Mover artigos para outras estantes.',
            assetKey: 'onboard.slideEbook1',
            footer: (
                <React.Fragment>
                    {`Selecione essa opção clicando em `}
                    <Icon style={LibraryStyles.iconText} icon='n' />
                    {`,\ne mova os seus artigos utilizando o ícone `}
                    <Icon style={LibraryStyles.iconText} icon='d' />{`.`}
                </React.Fragment>
            )
        },
        {
            title: 'Bertrand Reader',
            subtitle: 'Ler um eBook.',
            assetKey: 'onboard.slideEbook2',
            footer: `Para começar a ler, toque na capa.`
        },
        {
            title: 'Bertrand Reader',
            subtitle: 'Ler offline.',
            assetKey: 'onboard.slideEbook3',
            footer: `Os seus eBooks ficam disponíveis no seu dispositivo e podem ser lidos sem ligação à internet.`
        },
        {
            title: 'Bertrand Reader',
            subtitle: 'Consultar definições.',
            assetKey: 'onboard.slideEbook4',
            footer: `Ao tocar prolongadamente numa palavra,\nacionará o assistente de leitura e poderá consultar a sua definição.`
        },
        {
            title: 'Bertrand Reader',
            subtitle: 'Fazer destaques e notas.',
            assetKey: 'onboard.slideEbook5',
            footer: `Organize-os por cores.`
        },
        {
            title: 'Bertrand Reader',
            subtitle: 'Escrever e desenhar.',
            assetKey: 'onboard.slideEbook6',
            footer: (
                <React.Fragment>
                    {`Se o seu eBook apresentar este ícone `}
                    <Icon style={LibraryStyles.iconText} icon='n' />
                    {`,\npode escrever e desenhar.`}
                </React.Fragment>
            )
        },
    ], []);

    /** PROPS */
    const history = useHistory();
    const dispatchRef: MutableRefObject<DispatchType> = React.useRef(dispatch);
    const scrollRef: MutableRefObject<ScrollView | null> = React.useRef(null);
    const swiperRef: MutableRefObject<SafeAreaView | null> = React.useRef(null);

    /** LOCAL STATE */
    const [sliderState, setSliderState] = React.useReducer((
        _state: {
            sliderWidth: number,
            sliderHeight: number,
            currentPage: number,
        },
        _payload: {
            sliderWidth?: number,
            sliderHeight?: number,
            currentPage?: number,
        }
    ) => ({ ..._state, ..._payload }), {
        sliderWidth: Dimensions.get('window').width,
        sliderHeight: 0,
        currentPage: 0,
    });

    const setSliderPage = React.useCallback(
        (event: NativeSyntheticEvent<NativeScrollEvent>) => {
            const { x } = event.nativeEvent.contentOffset;
            const indexOfNextScreen = Math.round(x / sliderState.sliderWidth);
            setSliderState({ currentPage: indexOfNextScreen });
        },
        [sliderState.sliderWidth]
    );

    const scrollNextPage = React.useCallback((page: 1 | -1) => {
        const currentPage = sliderState.currentPage + page;
        scrollRef.current?.scrollTo({
            x: sliderState.sliderWidth * currentPage,
            animated: true
        });
        setSliderState({ currentPage: currentPage });
    }, [sliderState.sliderWidth, sliderState.currentPage]);

    /** RESIZE LISTENER */
    DimensionsEventTimeout(() => {
        const { width, height } = Dimensions.get('window');
        if (width !== sliderState.sliderWidth
            || height !== sliderState.sliderHeight) {
            setSliderState({ sliderHeight: height, sliderWidth: width });
        }
    });

    return React.useMemo(() => {
        Log.debug('component: Onboard');

        return (
            <SafeAreaView
                ref={(refer) => swiperRef.current = refer}
                onLayout={() => swiperRef.current?.measure(
                    (x: number, y: number, width: number, height: number) => height
                        && height !== sliderState.sliderHeight
                        && setSliderState({ sliderHeight: height, sliderWidth: width })
                )}
                style={{ flex: 1 }}>
                <GpeScrollView
                    style={{ backgroundColor: ContextTheme.palette.lighestGrey }}
                    horizontal={true}
                    scrollEventThrottle={16}
                    pagingEnabled={true}
                    showsHorizontalScrollIndicator={false}
                    ref={scrollRef}
                    onMomentumScrollEnd={(event) => setSliderPage(event)}>
                    {onBoardContent.map((content, index) => (
                        <View
                            key={index}
                            style={{
                                width: sliderState.sliderWidth,
                                height: sliderState.sliderHeight,
                            }}>
                            {(sliderState.currentPage === index + 1
                                || sliderState.currentPage === index
                                || sliderState.currentPage === index - 1)
                                && <Slide
                                    showGif={sliderState.currentPage === index}
                                    height={sliderState.sliderHeight}
                                    content={content} />}
                        </View>
                    ))}
                </GpeScrollView>

                <TouchableOpacity
                    testID='close-onboard'
                    style={[CarouselStyles.exitStyle]}
                    onPress={() => state.user.auth?.id
                        && setOnBoard(dispatchRef.current, 2, history, state.user.auth.id)}>
                    <Icon icon='X' style={{ fontSize: 35 }} />
                </TouchableOpacity>

                {Config.platform === 'web'
                    && sliderState.currentPage > 0
                    ? (
                        <View
                            style={{
                                position: 'absolute',
                                left: 20,
                                top: '45%',
                                backgroundColor: '#CCCCCC',
                            }}>
                            <TouchableOpacity
                                onPress={() => scrollNextPage(-1)}>
                                <Icon
                                    style={{
                                        height: 80,
                                        lineHeight: 80,
                                    }}
                                    color={ContextTheme.palette.black}
                                    icon={'('} />
                            </TouchableOpacity>
                        </View>
                    ) : null}

                {Config.platform === 'web'
                    && sliderState.currentPage < onBoardContent.length - 1
                    ? (
                        <View
                            style={{
                                position: 'absolute',
                                right: 20,
                                top: '45%',
                                backgroundColor: '#CCCCCC',
                            }}>
                            <TouchableOpacity
                                onPress={() => scrollNextPage(1)}>
                                <Icon
                                    style={{
                                        height: 80,
                                        lineHeight: 80,
                                    }}
                                    color={ContextTheme.palette.black}
                                    icon={')'} />
                            </TouchableOpacity>
                        </View>
                    ) : null}

                <View style={CarouselStyles.paginationWrapper}>
                    {onBoardContent.map((key, index) => (
                        <View
                            key={`${index}_${sliderState.currentPage}`}
                            style={[
                                CarouselStyles.paginationDots,
                                { opacity: sliderState.currentPage === index ? 1 : 0.2 }
                            ]} />
                    ))}
                </View>
            </SafeAreaView>
        );
    }, [
        state.user.auth,
        onBoardContent,
        sliderState,
        setSliderPage,
        scrollNextPage,
        history,
        scrollRef,
    ]);
}

export { Onboard as default }