import React from 'react';
import { DimensionValue, View } from 'react-native';

import { Log } from '../../../config';
import { Store } from '../../../store';
import { getTranslation } from '../../../middlewares/AppMiddleware';
import { dimensionWidth, LibraryStyles } from '../../../styles';
import SimpleCover from './SimpleCover';
import { EbookInterface } from '../../../interfaces/middlewares';
import SearchBar from '../../Search/components/SearchBar';
import { ShelfsListDropdown } from './ShelfsListDropdown';
import GpePerfectScrollbar from '../../GpePerfectScrollbar';
import { showcaseProductToEbookFormat } from '../../../middlewares/commons';
import { ShowcaseInterface, ShowcaseProductInterface } from '../../../interfaces/store';
import TitleH1 from './TitleH1';
import TitleH3 from './TitleH3';
import DimensionsEventTimeout from '../../DimensionsEvent';

function Showcases(props: { containerStyle: DimensionValue, showTitle: boolean, showShelfsDropdown: boolean }) {
    /** STORE */
    const { state } = React.useContext(Store);

    /** MEDIA */
    const coverHeightMedia = React.useMemo(() => ({
        xs: 180,
        md: 150,
        lg: 210
    }), []);

    /** PROPS */
    const translation = React.useMemo(() => getTranslation(state.app.language), [state.app.language]);

    const _readingStarted = React.useMemo(() => (state.user.auth
        && state.user.auth.id
        && state.library.readingStarted
        && state.library.readingStarted[state.user.auth.id]
        ? state.library.readingStarted[state.user.auth.id]
        : []
    ), [state.library.readingStarted, state.user.auth]);

    const _samplesShowcases = React.useMemo(() => (state.library.bookshelf
        && state.library.bookshelf.showcases
        ? state.library.bookshelf.showcases
        : []
    ), [state.library.bookshelf]);

    const _showcases = React.useMemo(() => (state.library.showcases
    ), [state.library.showcases]);

    /** LOCAL STATE */
    const [coverHeightState, setCoverHeightState] = React.useState(dimensionWidth(coverHeightMedia));

    /** RESIZE LISTENER */
    DimensionsEventTimeout(() => {
        const _coverHeight = dimensionWidth(coverHeightMedia);
        if (_coverHeight !== coverHeightState) {
            setCoverHeightState(_coverHeight);
        }
    });

    /** RENDER */
    return React.useMemo(() => {
        Log.debug(`component: Showcases`);

        return (
            <View
                style={{
                    width: props.containerStyle,
                }}>
                <View
                    style={[
                        LibraryStyles.bookshelfHeader,
                        props.showTitle ? { marginBottom: 0 } : null
                    ]}>

                    {/** barra de pesquisa */}
                    <SearchBar
                        showcaseRender={true} />

                    {/** listagem das estantes numa dropdown */}
                    {props.showShelfsDropdown
                        && <ShelfsListDropdown showcaseRender={true} />}
                </View>

                {_readingStarted
                    && _readingStarted.length
                    ? (
                        <React.Fragment>
                            {props.showTitle
                                && (
                                    <View
                                        style={LibraryStyles.bookshelfHeader}>
                                        <TitleH1 title={translation('library.showcase.reading.title')} />
                                    </View>
                                )}

                            {/** esta a ler */}
                            <View
                                style={{ marginBottom: 20 }}>
                                {!props.showTitle
                                    && (
                                        <TitleH3 title={translation('library.showcase.reading.title')} />
                                    )}

                                <GpePerfectScrollbar
                                    horizontal={true}>
                                    <View
                                        style={{ flexDirection: 'row', paddingLeft: 30 }}>
                                        {_readingStarted.map(
                                            (value: EbookInterface, index: number) => <SimpleCover
                                                key={`${value.subArtid}_${index}`}
                                                ebook={value}
                                                height={coverHeightState}
                                                showcaseRender={true} />
                                        )}
                                    </View>
                                </GpePerfectScrollbar>
                            </View>
                        </React.Fragment>
                    ) : null}

                {_showcases
                    && _showcases.length
                    ? _showcases.map(
                        (showcase: ShowcaseInterface, index: number) => showcase.products
                            && showcase.products.length
                            ? (
                                <React.Fragment key={index}>
                                    {/** montra */}

                                    {props.showTitle
                                        && (
                                            <View
                                                style={LibraryStyles.bookshelfHeader}>
                                                <TitleH1 title={showcase.nomeSeccao} />
                                            </View>
                                        )}

                                    <View
                                        style={{ marginBottom: 20 }}>
                                        {!props.showTitle
                                            && (
                                                <TitleH3 title={showcase.nomeSeccao} />
                                            )}

                                        <GpePerfectScrollbar
                                            horizontal={true}>
                                            <View
                                                style={{ flexDirection: 'row', paddingLeft: 30 }}>
                                                {showcase.products.map(
                                                    (ebook: ShowcaseProductInterface, index: number) => <SimpleCover
                                                        key={`${ebook.subArtID}_${index}`}
                                                        ebook={showcaseProductToEbookFormat(ebook)}
                                                        height={coverHeightState}
                                                        showcaseRender={true} />
                                                )}
                                            </View>
                                        </GpePerfectScrollbar>
                                    </View>
                                </React.Fragment>
                            ) : null
                    ) : null}

                {_samplesShowcases
                    && _samplesShowcases.length
                    ? _samplesShowcases.map(
                        (
                            showcase: {
                                title: string,
                                ebooksList: Array<EbookInterface>
                            },
                            index: number
                        ) => showcase.ebooksList
                            && showcase.ebooksList.length
                                ? (
                                    <React.Fragment key={index}>
                                        {/** quero ler */}

                                        {props.showTitle
                                            && (
                                                <View
                                                    style={LibraryStyles.bookshelfHeader}>
                                                    <TitleH1 title={showcase.title} />
                                                </View>
                                            )}

                                        <View
                                            style={{ marginBottom: 20 }}>
                                            {!props.showTitle
                                                && (
                                                    <TitleH3 title={showcase.title} />
                                                )}

                                            <GpePerfectScrollbar
                                                horizontal={true}>
                                                <View
                                                    style={{ flexDirection: 'row', paddingLeft: 30 }}>
                                                    {showcase.ebooksList.map((ebook: EbookInterface, index: number) => <SimpleCover
                                                        key={`${ebook.subArtid}_${index}`}
                                                        ebook={ebook}
                                                        height={coverHeightState}
                                                        showcaseRender={true}
                                                        bookshelfSample={true} />
                                                    )}
                                                </View>
                                            </GpePerfectScrollbar>
                                        </View>
                                    </React.Fragment>
                                ) : null
                    ) : null}
            </View>
        );
    }, [
        translation,
        props,
        _samplesShowcases,
        _showcases,
        _readingStarted,
        coverHeightState,
    ]);
}

export { Showcases as default }