import React, { MutableRefObject } from 'react';
import { View } from 'react-native';

import { Store } from '../store';
import Config, { Log } from '../config';
import BaseStyles, { ContextTheme } from '../styles';
import { getTranslation } from '../middlewares/AppMiddleware';
import { useHistory } from '../components/GpeRouter';
import GpeText from '../components/GpeText';

const timeoutDelay = 3000;

function NotFoundScreen() {
    const { state } = React.useContext(Store);

    const translation = React.useMemo(() => getTranslation(state.app.language), [state.app.language]);
    const history = useHistory();
    const timeout: MutableRefObject<NodeJS.Timeout | undefined> = React.useRef();

    React.useEffect(() => {
        timeout.current
            && clearTimeout(timeout.current);
        timeout.current = setTimeout(() => {
            history.push(Config.routes.firstpage);
        }, timeoutDelay);
        return () => {
            timeout.current
                && clearTimeout(timeout.current);
        };
    }, [
        history,
        timeout,
    ]);

    return React.useMemo(() => {
        Log.debug('screen: NOTFOUND');

        return (
            <View style={BaseStyles.container}>
                <GpeText style={{ fontFamily: ContextTheme.font.regular }}>
                    {translation('app.page.not.found.text')}
                </GpeText>
            </View>
        );
    }, [
        translation
    ]);
}

export { NotFoundScreen as default };