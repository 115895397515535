import { getEbookInBookshelf, handleBookshelfData, isAudioBook, openPopup } from '../../commons';
import Config from '../../../config';
import LibraryServices from '../../../services/LibraryServices';
import { handleError } from '../../../services/HttpClient/errorTreatment';
import { EbookInterface } from '../../../interfaces/middlewares';
import { replaceUrlParameters } from '../../../services/HttpClient';
import { DispatchType } from '../../../interfaces/store';
import BookshelfServices from '../../../services/LibraryServices';
import { LanguageKeys } from '../../../interfaces/language';

async function openEbook(
    _ebook: EbookInterface,
    _appStateUid: string,
    _userStateAuthToken: string,
    _userStateKitabooToken: string | null,
    _userStateAuth: { id: number, email: string, token: string },
    _dispatch: DispatchType,
    _translation: (key: keyof LanguageKeys) => string | undefined,
    _appStateStorageDir: string,
    _callback?: { success?: Function, error?: Function },
): Promise<void> {
    //TODO: teste offline
    // _callbackHandler && _callbackHandler();
    // const queryParams = `bookId=${_ebook.readItemId}&kcBookId=${_ebook.kitabooCloudId}&kcToken=${_userStateKitabooToken}&erat=${_userStateAuthToken}&bookVersion=${_ebook.version}&lastPage=&uid=&uemail=${_userStateAuth.email}&ufname=&ulname=`;
    // //(this.props.book.readItemId, this.props.book.kitabooCloudId, this.props.user.kitAt, this.props.user.erAt, this.props.book.version, this.props.lastPage, this.props.user.id, this.props.user.email, userFirstName, userLastName)
    // window.location.href = `/reader/index.html?${queryParams}`;

    if (!_ebook.bookGuid
        && _ebook.gpeId) {
        return LibraryServices({
            getBookMetadataFromMestArtId: {
                urlParams: {
                    serviceId: Config.context.serviceId,
                    mestArtId: _ebook.gpeId,
                    partNo: 1,
                    profile: 'U',
                }
            }
        }).then((response) => {
            response.success
                && response.success.data
                && (_ebook.bookGuid = response.success.data.bookGuid.endsWith("B")
                    ? `${response.success.data.bookGuid}S`
                    : response.success.data.bookGuid)
                && (_ebook.version = response.success.data.version)
                && webReaderAccessInfo(
                    _dispatch,
                    _ebook,
                    _appStateUid,
                    _userStateAuth,
                    _userStateAuthToken,
                    _translation,
                    _callback
                );

            (response.error
                || (response.success && !response.success.data))
                && openPopup(_dispatch, handleError(response.error));
        });
    }

    if (_ebook.readItemId) {
        return webReaderAccessInfo(
            _dispatch,
            _ebook,
            _appStateUid,
            _userStateAuth,
            _userStateAuthToken,
            _translation,
            _callback
        );
    }
}

function webReaderAccessInfo(
    _dispatch: DispatchType,
    _ebook: EbookInterface,
    _appStateUid?: string,
    _userStateAuth?: { id: number, email: string, token: string },
    _userStateAuthToken?: string,
    _translation?: (key: keyof LanguageKeys) => string | undefined,
    _callback?: { success?: Function, error?: Function },
) {
    const _promiseCallback = (response: any) => {
        if (_callback) {
            response.error
                && _callback.error
                && _callback.error(response.error);

            response.success
                && _callback.success
                && _callback.success(response.success);
        }
        if (response.success) {
            const contextConfig = Config.contextParams;
            let purchaseButtonUrl = '',
                addSampleUrl = '',
                productPageUrl = '';

            // abertura no site
            if (_ebook.sample
                && _ebook.bu
                && _ebook.ru) {
                let fromDigitalPage = _ebook.ru.indexOf('/ebook/') > -1
                    || _ebook.ru.indexOf('/audiolivro/') > -1;

                // ”purchase“ : Purchase button URL => “bu”: Purchase button URL
                purchaseButtonUrl = `&purchase=${encodeURIComponent(_ebook.bu)}`;

                // so aparece quando aberto atraves do site, opcao "LER"
                // ”excerptLib” : Add the excerpt to my library URL => ”sample”: Add the sample to my bookshelf” (this can be null, not present if the customer is reading the sample from the bookshelf)
                addSampleUrl = `&excerptLib=${fromDigitalPage
                    ? encodeURIComponent(replaceUrlParameters(
                        contextConfig.addSampleUrl,
                        { bookGuid: _ebook.bookGuid }
                    )) : ''}`;

                // so aparece quando aberto atraves do site, opcao "LER"
                // ”productSheet” : Back to product sheet URL => “ru”: Back to product sheet URL
                productPageUrl = `&productSheet=${encodeURIComponent(_ebook.ru)}`;
            }

            // abertura na biblioteca
            if (_ebook.sample
                && !_ebook.bu
                && !_ebook.ru) {
                purchaseButtonUrl = `&purchase=${encodeURIComponent(replaceUrlParameters(
                    contextConfig.purchaseButtonUrl,
                    { mesArtId: _ebook.gpeId }
                ))}`;

                productPageUrl = `&productSheet=${encodeURIComponent('/')}`;
            }

            const queryParams = response.success.data.url
                ? response.success.data.url.split('?')[1]
                : response.success.data.downloadUrl
                    ? response.success.data.downloadUrl.split('?')[1]
                    : '';
            window.location.href = `/reader/index.html?${queryParams}${purchaseButtonUrl}${addSampleUrl}${productPageUrl}`;
        }
    };

    const _getPackageDetails = (
        _readItemId: number,
        _auth: { id: number, email: string, token: string },
    ) => {
        return LibraryServices({
            getWebReaderAccessInfo: {
                urlParams: {
                    "applicationalContextId": Config.context.applicationContext,
                    "serviceId": Config.context.serviceId,
                    "conAplEntId": _auth.id,
                    "readItemId": _readItemId,
                },
                body: {
                    "pageNo": undefined, // TODO: obter a pagina
                    "webPortalSessId": "reader",
                },
                headers: { "x-token": _auth.token }
            }
        }).then(_promiseCallback);
    };

    // abertura biblioteca
    if (_ebook.readItemId
        && _userStateAuth) {
        return _getPackageDetails(_ebook.readItemId, _userStateAuth);
    }

    // abertura montra
    if (_ebook.sample
        && _ebook.bookGuid
        && _userStateAuth
        && _userStateAuthToken
        && _appStateUid
        && _translation) {
        return BookshelfServices({
            assignBookPartToUser: {
                urlParams: {
                    "applicationalContextId": Config.context.applicationContext,
                    "serviceId": Config.context.serviceId,
                    "conAplEntId": _userStateAuth.id,
                    "bookGuid": _ebook.bookGuid,
                }
            }
        }).then((response) => {
            if (response.error) {
                return _promiseCallback(response);
            }

            // obter a nova biblioteca
            return LibraryServices({
                getBookshelfData: {
                    urlParams: {
                        "applicationalContextId": Config.context.applicationContext,
                        "readerAppId": Config.context.appId[Config.platform],
                    },
                    headers: {
                        "x-di": _appStateUid,
                        "x-at": _userStateAuthToken,
                        "x-userid": _userStateAuth.id,
                        "x-token": _userStateAuth.token,
                        "x-ip": "",
                    }
                }
            }).then((response) => {
                if (response.success) {
                    const _handledBookshelf = handleBookshelfData(response.success.data, _translation);
                    _ebook = getEbookInBookshelf(
                        _handledBookshelf,
                        _ebook,
                        isAudioBook(_ebook.formatType)
                            ? _translation('library.showcase.want.to.ear.title')
                            : _translation('library.showcase.want.to.read.title'),
                    );

                    _dispatch({ type: 'LIBRARY-BOOKSHELF', payload: _handledBookshelf });

                    if (_ebook.readItemId) return _getPackageDetails(_ebook.readItemId, _userStateAuth);
                }
                return _promiseCallback(response);
            });
        });
    }

    // abrertura site
    if (!_ebook.readItemId
        && _ebook.sample
        && _ebook.bookGuid) {
        return LibraryServices({
            getPublicPackageDetailsByReadItemGuid: {
                urlParams: {
                    "applicationalContextId": Config.context.applicationContext,
                    "readerAppId": Config.context.appId[Config.platform],
                    "bookGuid": _ebook.bookGuid,
                }
            }
        }).then(_promiseCallback);
    }
}

export { openEbook, webReaderAccessInfo }