import Config from '../config';
import {
    openPopup,
    services
} from './commons';
import { registAnalyticsSession } from './AnalyticsMiddleware';
import errorTreatment, { handleError } from '../services/HttpClient/errorTreatment';
import { DispatchType } from '../interfaces/store';
import UserServices from '../services/UserServices';
import { ResponseInterface } from '../interfaces/services';

function doLogin(
    _dispatch: DispatchType,
    _appStateUid: string,
    _params: {
        user: string,
        password: string,
        keepLoggedIn?: boolean,
    }
) {
    return services.user(
        _dispatch,
        {
            login: {
                urlParams: {
                    "applicationalContextId": Config.context.applicationContext,
                    "readerAppId": Config.context.appId[Config.platform],
                },
                body: {
                    "username": _params.user.trim().toLowerCase(),
                    "password": _params.password,
                },
                headers: {
                    "x-di": _appStateUid,
                }
            }
        },
        (response) => {
            if (response.error) {
                return _dispatch({
                    type: 'USER-LOGIN_ERROR',
                    payload: response.error,
                });
            }
            registAnalyticsSession(response.success.data);

            const auth = {
                ...response.success.data,
                email: _params.user.trim().toLowerCase(),
            };

            userReset(_dispatch);

            libraryReset(_dispatch);

            _dispatch([
                {
                    type: 'USER-AUTH',
                    payload: {
                        auth: auth,
                        keepLoggedIn: _params.keepLoggedIn,
                        keepLoggedInTimeout: _params.keepLoggedIn
                            ? null
                            : Date.now(),
                    },
                }, {
                    type: 'USER-LOGIN_ERROR',
                    payload: '',
                }, {
                    type: 'USER-REGIST_ERROR',
                    payload: '',
                }
            ]);
        }
    );
}

function socialLoginToken(
    _dispatch: DispatchType,
    _provider: number,
    _token: string,
    _propsFromRegist?: boolean,
    _propsKeepLoggedIn?: boolean,
) {
    const _body = {
        socialRelation: {
            socialId: _provider,
            socialToken: _token,
        }
    };
    return services.user(
        _dispatch,
        { socialLoginToken: { body: _body } },
        (response) => {
            if (response.error) {
                return _dispatch({
                    type: _propsFromRegist
                        ? 'USER-REGIST_ERROR'
                        : 'USER-LOGIN_ERROR',
                    payload: handleError(response.error),
                });
            }

            let _responseToken = response.success.data?.token;
            let _responseEmail = response.success.data?.username;

            // se nao receber token o utilizador nao existe
            if (!_responseToken
                && !_propsFromRegist) {
                response.error = errorTreatment({ response: { status: 500 } }, 'getSocialRelations');
                return _dispatch({
                    type: 'USER-LOGIN_ERROR',
                    payload: handleError(response.error),
                });
            }

            if (!_responseToken
                && _propsFromRegist) {
                return UserServices({
                    doSocialNetworkLogin: {
                        body: { email: _responseEmail.toLowerCase() },
                        headers: {
                            "x-Company": Config.context.company,
                            "x-Context": Config.context.applicationContext,
                            "x-EntityType": Config.context.entityType,
                        }
                    }
                }).then((response) => {
                    if (response.error) {
                        return _dispatch({
                            type: 'USER-REGIST_ERROR',
                            payload: handleError(response.error),
                        });
                    }

                    return UserServices({ socialLoginToken: { body: _body } })
                        .then((response) => {
                            if (response.error
                                || !response.success.data
                                || !response.success.data.token) {
                                response.success
                                    && (!response.success.data
                                        || !response.success.data.token)
                                    && (response.error = errorTreatment({ response: { status: 404 } }, 'socialLoginToken'));
                                return _dispatch({
                                    type: 'USER-REGIST_ERROR',
                                    payload: handleError(response.error),
                                });
                            }

                            _responseToken = response.success.data?.token;
                            _responseEmail = response.success.data?.username;

                            return socialLoginTokenUserId(
                                _dispatch,
                                _body,
                                _responseToken,
                                _responseEmail,
                                _propsFromRegist,
                                _propsKeepLoggedIn,
                            );
                        })
                });
            }

            return socialLoginTokenUserId(
                _dispatch,
                _body,
                _responseToken,
                _responseEmail,
                _propsFromRegist,
                _propsKeepLoggedIn,
            );
        }
    );
};

function socialLoginTokenUserId(
    _dispatch: DispatchType,
    _body: {
        socialRelation: {
            socialId: number,
            socialToken: string,
        }
    },
    _token: string,
    _email: string,
    _propsFromRegist?: boolean,
    _propsKeepLoggedIn?: boolean,
) {
    const auth = {
        id: null,
        token: _token,
        email: _email.toLowerCase(),
    };

    return UserServices({ socialLoginTokenUserId: { body: _body } })
        .then((response) => {
            if (response.error
                || !response.success.data
                || !response.success.data.userId) {
                return _dispatch({
                    type: _propsFromRegist
                        ? 'USER-REGIST_ERROR'
                        : 'USER-LOGIN_ERROR',
                    payload: handleError(response.error),
                });
            }

            auth.id = response.success.data.userId;

            userReset(_dispatch);

            libraryReset(_dispatch);

            return _dispatch([
                {
                    type: 'USER-AUTH',
                    payload: {
                        auth: auth,
                        keepLoggedIn: _propsKeepLoggedIn,
                        keepLoggedInTimeout: _propsKeepLoggedIn
                            ? null
                            : Date.now(),
                    },
                }, {
                    type: 'USER-LOGIN_ERROR',
                    payload: '',
                }, {
                    type: 'USER-REGIST_ERROR',
                    payload: '',
                }
            ]);
        });
}

function doRecover(
    _dispatch: DispatchType,
    _email: string
) {
    return services.user(
        _dispatch,
        {
            recover: {
                body: { email: _email.trim().toLowerCase() }
            }
        },
        (response) => {
            if (response.error) {
                return _dispatch({
                    type: 'USER-RECOVER_ERROR',
                    payload: response.error,
                });
            }
            _dispatch({
                type: 'APP-POPUP',
                payload: {
                    text: 'Por favor, consulte a sua caixa de correio eletrónico.',
                    closeRedirect: Config.routes.login,
                },
            });
        }
    );
}

function doRegist(
    _dispatch: DispatchType,
    _appStateUid: string,
    _form: {
        email: string,
        password: string,
        conditions: boolean,
    }
) {
    return services.user(
        _dispatch,
        {
            regist: {
                body: {
                    email: _form.email.trim().toLowerCase(),
                    password: _form.password,
                }
            }
        },
        (response) => {
            if (response.error) {
                return _dispatch({
                    type: 'USER-REGIST_ERROR',
                    payload: response.error,
                });
            }
            doLogin(
                _dispatch,
                _appStateUid,
                {
                    user: _form.email,
                    password: _form.password,
                    keepLoggedIn: false,
                }
            );
        }
    );
}

function doSocialRegist(
    _dispatch: DispatchType,
    _provider: number,
    _token: string
) {
    return services.user(
        _dispatch,
        {
            socialLoginToken: {
                body: {
                    socialRelation: {
                        socialId: _provider,
                        socialToken: _token,
                    }
                }
            }
        },
        (response) => {
            if (response.error) {
                return _dispatch({
                    type: 'USER-REGIST_ERROR',
                    payload: response.error,
                });
            }
            // if the token is true, the user already exists
            if (response.success.data
                && !response.success.data.token) {

            }
        }
    );
};

function doValidateBertrandCustomer(
    _dispatch: DispatchType,
    _form: {
        email: string,
        leitorBertrand?: number,
    },
    _successFunction?: Function,
) {
    return services.user(
        _dispatch,
        {
            validatebertrandcustomer: {
                body: {
                    email: _form.email.trim().toLowerCase(),
                    readerClientNumber: _form.leitorBertrand,
                }
            }
        },
        (response) => {
            if (response.error) {
                return _dispatch({
                    type: 'USER-REGIST_ERROR',
                    payload: response.error,
                });
            }
            _successFunction && _successFunction();
        }
    );
}

function doRegisterBertrandCustomer(
    _dispatch: DispatchType,
    _appStateUid: string,
    _form: {
        email: string,
        fistName: string,
        lastName: string,
        password: string,
    },
    _successFunction?: Function,
) {
    return services.user(
        _dispatch,
        {
            registerbertrandcustomer: {
                body: {
                    email: _form.email.trim().toLowerCase(),
                    firstName: _form.fistName,
                    lastName: _form.lastName,
                    password: _form.password,
                }
            }
        },
        (response) => {
            if (response.error) {
                return _dispatch({
                    type: 'USER-REGIST_ERROR',
                    payload: response.error,
                });
            }
            doLogin(
                _dispatch,
                _appStateUid,
                {
                    user: _form.email,
                    password: _form.password,
                    keepLoggedIn: false,
                }
            );
        }
    );
}

function getAuthToken(
    _dispatch: DispatchType,
    _userStateAuth: { id: number, token: string }
) {
    return services.user(
        _dispatch,
        {
            getAuthToken: {
                urlParams: { "conAplEntId": _userStateAuth.id },
                headers: { "x-token": _userStateAuth.token }
            }
        },
        (response) => {
            if (response.error) {
                _dispatch({
                    type: 'USER-AUTH_TOKEN_ERROR',
                    payload: response.error,
                });
                openPopup(_dispatch, response.error);
                doLogout(_dispatch);
                return;
            }
            _dispatch({
                type: 'USER-AUTH_TOKEN',
                payload: response.success
                    && response.success.data
                    ? response.success.data
                    : {},
            });
        }
    );
}

function doLogout(
    _dispatch: DispatchType
) {
    appReset(_dispatch);

    userReset(_dispatch);

    libraryReset(_dispatch);
}

function appReset(_dispatch: DispatchType) {
    return _dispatch([
        {
            type: 'APP',
            payload: {
                manager: null,
                uidRegisteredError: '',
                storageDir: null,
            },
        }, {
            type: 'APP-LANGUAGE',
            payload: null,
        }, {
            type: 'APP-UID_REGISTERED',
            payload: null,
        },
    ]);
}

function userReset(_dispatch: DispatchType) {
    return _dispatch([
        {
            type: 'USER',
            payload: {
                authTokenError: '',
                loginByToken: false,
                kitabooTokenError: '',
            },
        }, {
            type: 'USER-AUTH_TOKEN',
            payload: null,
        }, {
            type: 'USER-AUTH',
            payload: {
                auth: null,
                keepLoggedIn: false,
                keepLoggedInTimeout: null,
            },
        }, {
            type: 'USER-KITABOO_TOKEN',
            payload: null,
        },
    ]);
}

function libraryReset(_dispatch: DispatchType) {
    return _dispatch([
        {
            type: 'LIBRARY',
            payload: {
                draggingMode: false,
                siteSidebarError: null,
            },
        }, {
            type: 'LIBRARY-BOOKSHELF',
            payload: null,
        }, {
            type: 'LIBRARY-BOOKSHELF_SELECTED',
            payload: null,
        }, {
            type: 'LIBRARY-SORTED_BOOKSHELF_DESC',
            payload: {},
        },
    ]);
}

function customerByToken(
    _dispatch: DispatchType,
    _appStateUid: string,
    _token: string,
    _callback?: { success?: Function, error?: Function },
) {
    return services.user(
        _dispatch,
        {
            getCustomerByToken: {
                body: {
                    ctxGPE: { applicationContext: Config.context.applicationContext },
                    token: _token
                }
            }
        },
        (response) => {
            if (response.error
                || !response.success.data
                || !response.success.data.autenticacao
                || !response.success.data.autenticacao.user) {
                _dispatch({
                    type: 'USER-LOGIN_ERROR',
                    payload: handleError(response.error),
                });
                _callback?.error
                    && _callback?.error();
                return;
            }

            _dispatch([{
                type: 'USER',
                payload: { loginByToken: true },
            }, {
                type: 'USER-AUTH',
                payload: {
                    auth: {
                        id: response.success.data.clienteID,
                        token: _token,
                        email: response.success.data.autenticacao.user.toLowerCase(),
                    },
                    keepLoggedIn: false,
                    keepLoggedInTimeout: Date.now(),
                },
            }, {
                type: 'USER-LOGIN_ERROR',
                payload: '',
            }]);

            _callback?.success
                && _callback.success();
        }
    );
}

function getPasswordRules(
    _dispatch: DispatchType,
    _setRulesFetched: Function
) {
    return services.user(
        _dispatch,
        {
            getPasswordRules: {
                urlParams: { "applicationalContextId": Config.context.applicationContext }
            }
        },
        (response) => {
            if (response.error) {
                _setRulesFetched([]);
                return openPopup(_dispatch, response.error);
            }

            response.success
                && _setRulesFetched(response.success.data
                    ? response.success.data
                    : []);
        }
    );
}

function setOnBoard(
    _dispatch: DispatchType,
    _onBoard: 1 | 2,
    _useHistory: any,
    _userId: number
) {
    _dispatch([
        {
            type: 'USER-FIRST_ON_BOARD',
            payload: {
                [_userId]: true
            }
        },
        {
            type: 'USER-FIRST_ON_BOARD_DATE',
            payload: {
                [_userId]: new Date().getTime()
            }
        },
        {
            type: 'USER-SECOND_ON_BOARD',
            payload: {
                [_userId]: _onBoard === 2 ? true : false
            }
        },
    ]);

    _useHistory.push(Config.routes.bookshelf);
}

function doAssignPromoBooks(
    _dispatch: DispatchType,
    _userId: number,
    _userStateAuthToken: string,
) {
    return services.user(
        _dispatch,
        {
            assignPromoBooks: {
                urlParams: {
                    applicationalContextId: Config.context.applicationContext,
                    readerAppId: Config.context.appId[Config.platform],
                },
                headers: { "x-at": _userStateAuthToken }
            }
        },
        () => _dispatch({
            type: 'USER-ASSIGN_PROMO_BOOKS',
            payload: { [_userId]: true }
        })
    );
}

function doAssignPromoBooksInCampaign(
    _dispatch: DispatchType,
    _userId: number,
    _userStateAuthToken: string,
    _campaignToken: string,
) {
    const _callback = (response: ResponseInterface) => _dispatch([{
        type: 'USER-ASSIGN_PROMO_BOOKS_IN_CAMPAIGN',
        payload: { [_userId]: true }
    }, {
        type: 'LIBRARY-MESSAGE',
        payload: {
            type: response.error
                ? 'error'
                : 'success',
            text: response.error
                ? response.error
                : 'Artigo(s) adicionado(s) com sucesso!\nConsulte as suas estantes.'
        }
    }]);

    return services.user(
        _dispatch,
        {
            assignPromoBooksInCampaign: {
                urlParams: {
                    applicationalContextId: Config.context.applicationContext,
                    readerAppId: Config.context.appId[Config.platform],
                    campaignToken: _campaignToken,
                },
                headers: { "x-at": _userStateAuthToken }
            }
        },
        _callback
    );
}

export {
    doLogin,
    socialLoginToken,
    doRegist,
    doSocialRegist,
    doValidateBertrandCustomer,
    doRegisterBertrandCustomer,
    doRecover,
    getAuthToken,
    doLogout,
    appReset,
    userReset,
    libraryReset,
    customerByToken,
    getPasswordRules,
    setOnBoard,
    doAssignPromoBooks,
    doAssignPromoBooksInCampaign,
}