import { StyleSheet } from 'react-native';

import theme from '../../styles/theme';

const styles = StyleSheet.create({
    container: {
        flexGrow: 1,
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        maxWidth: 620,
        padding: undefined,
    },
    h1: {
        fontFamily: theme.font.regular,
        fontSize: 14,
        textAlign: 'center',
        color: theme.palette.grey,
    },
    text: {
        fontFamily: theme.font.regular,
        fontSize: 14,
        textAlign: 'center',
        color: theme.palette.grey,
    },
    button: {
        padding: 8,
        backgroundColor: theme.palette.theme,
        borderRadius: 2,
        height: 40,
        margin: "auto",
        justifyContent: "center",
        marginBottom: 10,
        width: "100%",
        maxWidth: undefined,
    },
});

export { styles as default };