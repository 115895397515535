import { PaletteColorsInterface } from '../../../../interfaces/styles';

const palette: PaletteColorsInterface = {
    theme: '#00c8c8',
    white: '#ffffff',
    grey: '#999999',
    lightGrey: '#f2f2f2',
    lighestGrey: '#f7f7f7',
    black: '#000000',
    lightBlack: '#333333',
    red: '#fcc',
    googleRed: '#c30',
    facebookBlue: '#339',
    appleBlack: '#000',
    loginColor: '#399',
    recoverColor: '#f96',
    registColor: '#96c',
    orange: '#ff9933',
};

export { palette as default };