import React, { CSSProperties, MutableRefObject } from 'react';
import { Platform } from 'react-native';
import ReactPerfectScrollbar from 'react-perfect-scrollbar';

/** 
 * PerfectScrollbar so funciona em web browsers 
 */
function PerfectScrollbar(props: {
    children: any,
    style?: CSSProperties,
    scrollDisabled?: boolean,
    horizontal?: boolean
}) {
    let perfectScrollbar: MutableRefObject<ReactPerfectScrollbar | undefined | null> = React.useRef();

    React.useEffect(() => {
        const updateScroll = () => {
            perfectScrollbar.current
                && perfectScrollbar.current.updateScroll();
        };

        /** MOUNT */
        Platform.OS === 'web'
            && window.addEventListener('resize', updateScroll);

        /** UNMOUNT */
        return () => {
            Platform.OS === 'web'
                && window.removeEventListener('resize', updateScroll);
        };
    }, []);


    return React.useMemo(() => {
        return props.scrollDisabled
            ? props.children
            : <ReactPerfectScrollbar
                ref={(ref) => perfectScrollbar.current = ref}
                style={props.style}>
                {props.children}
            </ReactPerfectScrollbar>
    }, [
        props.children,
        props.style,
        props.scrollDisabled
    ]);
}

export { PerfectScrollbar };