import { StyleSheet } from 'react-native';

import theme from '../../styles/theme';

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        padding: undefined,
        alignItems: undefined,
        width: '100%',
        maxWidth: 1200,
        margin: 'auto',
    },
    h1: {
        textTransform: 'uppercase',
        fontFamily: theme.font.light,
        textAlignVertical: 'center',
        paddingTop: 2,
        paddingLeft: 15,
        paddingRight: 15,
        paddingBottom: 2,
    },
    h2: {
        textTransform: 'uppercase',
        fontFamily: theme.font.regular,
        paddingTop: 8.5,
        paddingLeft: 15,
        paddingRight: 15,
        paddingBottom: 8.5,
    },
    h3: {
        textTransform: 'uppercase',
        fontFamily: theme.font.regular,
        textAlignVertical: 'center',
        paddingTop: 12,
        paddingLeft: 15,
        paddingRight: 15,
        paddingBottom: 12,
    },
    selected: {
        color: theme.palette.white,
        backgroundColor: theme.palette.theme,
    },
    childContainer: {
        paddingTop: 15,
        paddingLeft: 30,
        paddingRight: 30,
        paddingBottom: 15,
    },
});

export { styles as default };