import React, { MutableRefObject } from 'react';
import { View, TouchableOpacity, ViewStyle } from 'react-native';

import Config, { Log } from '../../../config';
import { Store } from '../../../store';
import { LibraryStyles, dimensionWidth_Object } from '../../../styles';
import { getTranslation } from '../../../middlewares/AppMiddleware';
import {
    setShelfOptionsDispatch,
    toggleShelfOptions,
    toggleMoveToShelf,
    sortItemsByAuthorsOrTitle,
    toggleManualSorting
} from '../../../middlewares/LibraryMiddleware';
import ShelfRenameForm from '../../Popup/components/ShelfRenameForm';
import ShelfDeleteForm from '../../Popup/components/ShelfDeleteForm';
import Hoverable from '../../Hoverable';
import Icon from '../../Icon';
import DimensionsEventTimeout from '../../DimensionsEvent';
import { EbookInterface } from '../../../interfaces/middlewares';
import GpeText from '../../GpeText';
import { useLocation } from '../../GpeRouter';
import ShelfDeleteMsgNotAllowed from '../../Popup/components/ShelfDeleteMsgNotAllowed';
import { DispatchType } from '../../../interfaces/store';
import { openPopup } from '../../../middlewares/commons';

function ShelfOptions(props: { ebooksList: Array<EbookInterface> }) {
    /** STORE */
    const { state, dispatch } = React.useContext(Store);

    /** PROPS */
    const translation = React.useMemo(() => getTranslation(state.app.language), [state.app.language]);
    const bookshelfSelected = React.useMemo(() => state.library.bookshelfSelected, [state.library.bookshelfSelected]);
    const dispatchRef: MutableRefObject<DispatchType> = React.useRef(dispatch);
    const location = useLocation();
    const inSearch = React.useMemo(() => location.pathname.indexOf(Config.routes.search) > -1, [location]);
    const optionMoveLabel = React.useMemo(() => (
        state.library.bookshelfSelected?.id === translation('library.audiobooks.bookshelf.title')
            ? translation('library.bookshelf.menu.option.audiobooks.move')
            : state.library.bookshelfSelected?.id === translation('library.ebooks.bookshelf.title')
                ? translation('library.bookshelf.menu.option.ebooks.move')
                : translation('library.bookshelf.menu.option.move')
    ), [state.library.bookshelfSelected, translation]);

    /** MEDIAS */
    const buttonCogMedia: { xs: ViewStyle, md: ViewStyle } = {
        xs: { top: '0%', right: 0 },
        md: { top: 90, right: 0 }
    };
    const containerOptionsMedia: { xs: ViewStyle, md: ViewStyle } = {
        xs: { top: 50 },
        md: { top: 140 }
    };

    /** STATE */
    const [shelfOptionsState, shelfOptionsDispatch] = React.useReducer((
        _state: {
            show: boolean,
            buttonCogStyle: Object,
            containerOptionsStyle: Object,
        },
        _payload: {
            show?: false,
            buttonCogStyle?: Object,
            containerOptionsStyle?: Object,
        }
    ) => ({ ..._state, ..._payload }), {
        show: false,
        buttonCogStyle: dimensionWidth_Object(buttonCogMedia),
        containerOptionsStyle: dimensionWidth_Object(containerOptionsMedia),
    });

    /** RESIZE LISTENER */
    DimensionsEventTimeout(() => {
        const _buttonCogStyle = dimensionWidth_Object(buttonCogMedia);
        const _containerOptionsStyle = dimensionWidth_Object(containerOptionsMedia);
        if (_buttonCogStyle !== shelfOptionsState.buttonCogStyle
            || _containerOptionsStyle !== shelfOptionsState.containerOptionsStyle) {
            shelfOptionsDispatch({
                buttonCogStyle: _buttonCogStyle,
                containerOptionsStyle: _containerOptionsStyle,
            });
        }
    });

    React.useEffect(() => {
        /** MOUNT */
        setShelfOptionsDispatch(shelfOptionsDispatch);
        /** UNMOUNT */
        return () => setShelfOptionsDispatch(undefined);
    });

    /** RENDER */
    return React.useMemo(() => {
        Log.debug('component: EbooksListMenu');

        return (
            <React.Fragment>
                <TouchableOpacity
                    onPress={() => toggleShelfOptions(!shelfOptionsState.show)}
                    style={[
                        LibraryStyles.icon,
                        LibraryStyles.greyBackgroundColor,
                        { position: 'absolute' },
                        shelfOptionsState.buttonCogStyle,
                    ]}>
                    {Config.contextName === 'bertrand'
                        ? <Icon icon='n' />
                        : <Icon icon='o' />}
                </TouchableOpacity>

                {shelfOptionsState.show
                    ? (
                        <View style={[
                            LibraryStyles.greyBackgroundColor,
                            {
                                marginTop: 2,
                                width: '100%',
                            },
                        ]}>
                            {props.ebooksList
                                && props.ebooksList.length > 0
                                ? (
                                    <Hoverable>
                                        {(hover: boolean) => (
                                            <TouchableOpacity
                                                onPress={() => toggleMoveToShelf(dispatchRef.current)}>
                                                <GpeText style={[
                                                    LibraryStyles.bookshelfMenuOption,
                                                    LibraryStyles.blackColor,
                                                    hover && LibraryStyles.selected,
                                                ]}>{optionMoveLabel}</GpeText>
                                            </TouchableOpacity>
                                        )}
                                    </Hoverable>
                                ) : null}

                            {!inSearch
                                && bookshelfSelected
                                && bookshelfSelected.id !== translation('library.ebooks.bookshelf.title')
                                && bookshelfSelected.id !== translation('library.audiobooks.bookshelf.title')
                                ? (
                                    <React.Fragment>
                                        <Hoverable>
                                            {(hover: boolean) => (
                                                <TouchableOpacity
                                                    onPress={() => openPopup(dispatchRef.current, <ShelfRenameForm {...bookshelfSelected} />)}>
                                                    <GpeText style={[
                                                        LibraryStyles.bookshelfMenuOption,
                                                        LibraryStyles.blackColor,
                                                        hover && LibraryStyles.selected,
                                                    ]}>{translation('library.bookshelf.menu.option.rename')}</GpeText>
                                                </TouchableOpacity>
                                            )}
                                        </Hoverable>

                                        <Hoverable>
                                            {(hover: boolean) => (
                                                <TouchableOpacity
                                                    onPress={() => openPopup(
                                                        dispatchRef.current,
                                                        Config.contextName === 'bertrand'
                                                            && props.ebooksList
                                                            && props.ebooksList.length
                                                            ? <ShelfDeleteMsgNotAllowed />
                                                            : <ShelfDeleteForm {...bookshelfSelected} />
                                                    )}>
                                                    <GpeText
                                                        style={[
                                                            LibraryStyles.bookshelfMenuOption,
                                                            LibraryStyles.blackColor,
                                                            hover && LibraryStyles.selected,
                                                        ]}>{translation('library.bookshelf.menu.option.delete')}</GpeText>
                                                </TouchableOpacity>
                                            )}
                                        </Hoverable>

                                        {/** so faz sentido apresentar estas opcoes se houver mais do que um ebook na estante */}
                                        {props.ebooksList
                                            && props.ebooksList.length > 1
                                            ? (
                                                <React.Fragment>
                                                    <Hoverable>
                                                        {(hover: boolean) => (
                                                            <TouchableOpacity
                                                                onPress={() => toggleManualSorting(dispatchRef.current)}>
                                                                <GpeText
                                                                    style={[
                                                                        LibraryStyles.bookshelfMenuOption,
                                                                        LibraryStyles.blackColor,
                                                                        hover && LibraryStyles.selected,
                                                                    ]}>
                                                                    {translation('library.bookshelf.menu.option.order.manual')}
                                                                </GpeText>
                                                            </TouchableOpacity>
                                                        )}
                                                    </Hoverable>

                                                    <Hoverable>
                                                        {(hover: boolean) => (
                                                            <TouchableOpacity
                                                                onPress={() => state.app.uid
                                                                    && state.user.authToken
                                                                    && state.user.auth
                                                                    && sortItemsByAuthorsOrTitle(
                                                                        dispatchRef.current,
                                                                        state.app.uid,
                                                                        state.user.authToken,
                                                                        state.user.auth,
                                                                        bookshelfSelected.id,
                                                                        props.ebooksList,
                                                                        state.library.sortedBookshelfDesc,
                                                                        'authors',
                                                                    )}>
                                                                <GpeText
                                                                    style={[
                                                                        LibraryStyles.bookshelfMenuOption,
                                                                        LibraryStyles.blackColor,
                                                                        hover && LibraryStyles.selected,
                                                                    ]}>{translation('library.bookshelf.menu.option.order.author')}</GpeText>
                                                            </TouchableOpacity>
                                                        )}
                                                    </Hoverable>

                                                    <Hoverable>
                                                        {(hover: boolean) => (
                                                            <TouchableOpacity
                                                                onPress={() => state.app.uid
                                                                    && state.user.authToken
                                                                    && state.user.auth
                                                                    && sortItemsByAuthorsOrTitle(
                                                                        dispatchRef.current,
                                                                        state.app.uid,
                                                                        state.user.authToken,
                                                                        state.user.auth,
                                                                        bookshelfSelected.id,
                                                                        props.ebooksList,
                                                                        state.library.sortedBookshelfDesc,
                                                                        'title',
                                                                    )}>
                                                                <GpeText
                                                                    style={[
                                                                        LibraryStyles.bookshelfMenuOption,
                                                                        LibraryStyles.blackColor,
                                                                        hover && LibraryStyles.selected,
                                                                    ]}>{translation('library.bookshelf.menu.option.order.title')}</GpeText>
                                                            </TouchableOpacity>
                                                        )}
                                                    </Hoverable>
                                                </React.Fragment>
                                            ) : null}
                                    </React.Fragment>
                                ) : null}
                        </View>
                    ) : null}
            </React.Fragment>
        )
    }, [
        props.ebooksList,
        state.app.uid,
        state.user.authToken,
        state.user.auth,
        state.library.sortedBookshelfDesc,
        translation,
        inSearch,
        bookshelfSelected,
        shelfOptionsState,
        optionMoveLabel,
    ]);
}

export { ShelfOptions as default }