import Config, { AppContext } from "../../config";
import endpoints from "../../config/jsons/endpoints.json";
import { JavaCodesType } from "../../interfaces/language";
import { errorMapping } from "../../language";

const javaStatusCodes = {
    206: "NO_CONTENT",
    400: "BAD_REQUEST",
    401: "UNAUTHORIZED",
    402: "PAYMENT_REQUIRED",
    403: "FORBIDDEN",
    404: "NOT_FOUND",
    405: "METHOD_NOT_ALLOWED",
    406: "NOT_ACCEPTABLE",
    407: "PROXY_AUTHENTICATION_REQUIRED",
    408: "REQUEST_TIMEOUT",
    409: "CONFLICT",
    410: "GONE",
    411: "LENGTH_REQUIRED",
    412: "PRECONDITION_FAILED",
    413: "REQUEST_ENTITY_TOO_LARGE",
    414: "REQUEST_URI_TOO_LONG",
    415: "UNSUPPORTED_MEDIA_TYPE",
    416: "REQUESTED_RANGE_NOT_SATISFIABLE",
    417: "EXPECTATION_FAILED",
    500: "INTERNAL_SERVER_ERROR",
    501: "NOT_IMPLEMENTED",
    502: "BAD_GATEWAY",
    503: "SERVICE_UNAVAILABLE",
    504: "GATEWAY_TIMEOUT",
    505: "HTTP_VERSION_NOT_SUPPORTED",
} as const;

const errorTreatment = (
    error: { response: { status: number } },
    endpoint: keyof typeof endpoints
) => {
    const errorServices = errorMapping[Config.contextName as AppContext];

    const errorCode = error
        && error.response
        && error.response.status
        ? error.response.status
        : undefined;

    const errorMappingMsg = errorServices
        && errorServices[endpoint]
        ? errorServices[endpoint]
        : undefined;

    const errorDescription = errorCode
        && javaStatusCodes[errorCode as JavaCodesType]
        ? javaStatusCodes[errorCode as JavaCodesType]
        : undefined;

    const errorMsg = errorDescription
        && errorMappingMsg
        && errorMappingMsg[errorDescription]
        ? errorMappingMsg[errorDescription]
        : undefined;

    return errorMsg
        ? errorMsg
        : errorServices.defaultMsg
            ? errorServices.defaultMsg
            : handleError(error, `erro não processado!\n[${endpoint}] -> erro: ${errorCode}`);
}

function handleError(
    error: any,
    defaultMsg?: string,
) {
    if (!defaultMsg) {
        const errorServices = errorMapping[Config.contextName as AppContext];
        errorServices.defaultMsg && (defaultMsg = errorServices.defaultMsg);
    }

    return !error
        ? defaultMsg
        : typeof error === 'string'
            ? error
            : error.message
                ? error.message
                : error.response
                    && error.response.headers
                    && error.response.headers['x-errormessage']
                    ? error.response.headers['x-errormessage']
                    : error.response
                        && error.response.data
                        && error.response.data.message
                        ? error.response.data.message
                        : error.response
                            && error.response.data
                            && typeof error.response.data === 'string'
                            ? error.response.data
                            : defaultMsg;
}

export { errorTreatment as default, handleError, javaStatusCodes }