import Config from '../../../../config';
import { AssetsInterface } from '../../../../interfaces/components';

const bookshelfDir = Config.platform === 'web'
    ? process.env.PUBLIC_URL
    : Config.contextParams.bookshelfUrl;

const Assets: AssetsInterface = {
    version: '1.0.2',
    keys: {
        logo: {
            main: `${bookshelfDir}/assets/images/logotipo/logotipo_1.png`,
            less: `${bookshelfDir}/assets/images/logotipo/logo_bertrand_semlettering.png`,
        },
        onboard: {
            slideAudio0: `${bookshelfDir}/assets/images/onboard_v2/Onboarding_02_bibliobertrand_audiolivro_DEV_1080px.gif`,
            slideAudio1: `${bookshelfDir}/assets/images/onboard_v2/Onboarding_02_bibliobertrand_velocidadeLeitura_DEV_1080px.gif`,
            slideAudio2: `${bookshelfDir}/assets/images/onboard_v2/Onboarding_02_bibliobertrand_marcadores_DEV_1080px.gif`,
            slideEbook0: `${bookshelfDir}/assets/images/onboard_v2/1.gif`,
            slideEbook1: `${bookshelfDir}/assets/images/onboard_v2/2.gif`,
            slideEbook2: `${bookshelfDir}/assets/images/onboard_v2/3.gif`,
            slideEbook3: `${bookshelfDir}/assets/images/onboard_v2/4.gif`,
            slideEbook4: `${bookshelfDir}/assets/images/onboard_v2/5.gif`,
            slideEbook5: `${bookshelfDir}/assets/images/onboard_v2/6.gif`,
            slideEbook6: `${bookshelfDir}/assets/images/onboard_v2/7.gif`,
        },
        cover: {
            default: `${bookshelfDir}/assets/images/default_cover.jpeg`,
        },
    },
};

export { Assets as default }