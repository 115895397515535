import React, { MutableRefObject } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';

import { ContextTheme } from '../../../../../styles';
import Config, { Log } from '../../../../../config';
import SocialButton from '../SocialButton';
import { Store } from '../../../../../store';
import { socialLoginToken } from '../../../../../middlewares/UserMiddleware';
import { DispatchType } from '../../../../../interfaces/store';

declare const window: any;

function FacebookLogin(props: { keepLoggedIn: boolean, onPress?: Function, fromRegist?: boolean }) {
    const { dispatch } = React.useContext(Store);

    const dispatchRef: MutableRefObject<DispatchType> = React.useRef(dispatch);
    const { keepLoggedIn, onPress, fromRegist } = React.useMemo(() => props, [props]);

    const injectScript = React.useCallback(() => {
        const userHandler = (accessToken: string) => {
            window.FB.api('/me', { fields: 'name,email,id' }, (response: { email: string }) => {
                if (response.email) {
                    accessToken
                        && socialLoginToken(
                            dispatchRef.current,
                            Config.social.facebook.provider,
                            accessToken,
                            fromRegist,
                            keepLoggedIn,
                        );
                } else {
                    // the person did not shared the email
                    window.FB.logout();
                }
            });
        };

        // This is called with the results from from window.FB.getLoginStatus().
        const statusChangeCallback = (response: any) => {
            if (response.status === 'connected') {
                userHandler(response.authResponse.accessToken);
            } else {
                // The person is not logged into your app or we are unable to tell.
            }
        };

        // This function is called when someone finishes with the Login button.
        // See the onlogin handler attached to it in the sample code below.
        const checkLoginState = () => {
            window.FB.getLoginStatus(function (response: any) {
                statusChangeCallback(response);
            });
        };

        const facebookLoad = () => {
            window.FB.init({
                appId: Config.context.social.facebook.appId,
                cookie: true, // enable cookies to allow the server to access the session
                xfbml: true, // parse social plugins on this page
                version: Config.context.social.facebook.version
            });

            // Now that we've initialized the JavaScript SDK, we call 
            // window.FB.getLoginStatus().  This function gets the state of the
            // person visiting this page and can return one of three states to
            // the callback you provide.  They can be:
            //
            // 1. Logged into your app ('connected')
            // 2. Logged into Facebook, but not your app ('not_authorized')
            // 3. Not logged into Facebook and can't tell if they are logged into
            //    your app or not.
            //
            // These three cases are handled in the callback function.

            window.FB.logout();
            window.FB.login(
                function (response: any) {
                    response.authResponse && checkLoginState();
                },
                {
                    auth_type: 'rerequest',
                    scope: 'email,public_profile',
                    return_scopes: true
                }
            );
        };

        // inject script
        const scriptId = 'facebook-jssdk';
        const elem = document.getElementById(scriptId);
        elem && facebookLoad();
        !elem && ((document: any, src: string, id: string) => {
            return new Promise((resolve, reject) => {
                // let body = document.getElementsByTagName('body')[0];
                let tag = document.createElement('script');

                tag.id = id;
                tag.type = 'text/javascript';
                tag.async = false; // Load in order

                const handleLoad = () => { facebookLoad(); resolve(src); }
                const handleReject = () => { reject(src) }

                // tag.onreadystatechange = handleCallback;
                tag.addEventListener('load', handleLoad)
                tag.addEventListener('error', handleReject);
                tag.src = src;
                // body.appendChild(tag);
                document.head.appendChild(tag);
                return tag;
            });
        })(window.document, Config.social.facebook.sdk, scriptId);
    }, [keepLoggedIn, fromRegist]);

    return React.useMemo(() => {
        Log.debug('component: FacebookLogin');

        return (
            <SocialButton
                onPress={() => onPress
                    ? onPress()
                    : injectScript()}>
                <FontAwesomeIcon
                    fontSize={16}
                    color={ContextTheme.palette.facebookBlue}
                    icon={faFacebookF} />
            </SocialButton>
        );
    }, [onPress, injectScript]);
}

export { FacebookLogin as default };