import React from 'react';
import { View } from 'react-native';

import { Log } from '../../../../../config';
import { getTranslation } from '../../../../../middlewares/AppMiddleware';
import { LibraryStyles } from '../../../../../styles';
import { MenuOptionInterface } from '../../../../../interfaces/components';
import { Store } from '../../../../../store';
import GpeText from '../../../../GpeText';

function Option(props: {
    hover: boolean,
    option: MenuOptionInterface,
    drawBorder: boolean,
}) {
    const { state } = React.useContext(Store);

    const translation = React.useMemo(() => getTranslation(state.app.language), [state.app.language]);

    return React.useMemo(() => {
        Log.debug('component: Option');

        return (
            <View
                style={[
                    LibraryStyles.menuOption,
                    { backgroundColor: props.hover ? '#d9d9d9' : 'transparent' },
                ]}>
                <View
                    style={
                        props.drawBorder
                            ? {
                                borderStyle: 'solid',
                                borderBottomColor: '#cccccc',
                                borderBottomWidth: 1,
                            }
                            : null
                    }>
                    <GpeText
                        style={[
                            LibraryStyles.menuOptionText,
                            { ...props.option.style },
                        ]}>
                        {translation(props.option.languageKey)}</GpeText>
                </View>
            </View>
        )
    }, [
        translation,
        props
    ]);
}

export { Option as default }