import React, { MutableRefObject } from 'react';
import { View, TextInput } from 'react-native';

import { Store } from '../../../store';
import { PopupStyles } from '../../../styles';
import GpeButton from '../../GpeButton';
import { getTranslation } from '../../../middlewares/AppMiddleware';
import { doRenameBookshelf } from '../../../middlewares/LibraryMiddleware';
import Config from '../../../config';
import ShelfRenameMsgSuccess from './ShelfRenameMsgSuccess';
import GpeText from '../../GpeText';
import { DispatchType } from '../../../interfaces/store';
import { closePopup } from '../../../middlewares/commons';

function ShelfRenameForm(props: { id: number | string, name: string }) {
    const { state, dispatch } = React.useContext(Store);

    const translation = React.useMemo(() => getTranslation(state.app.language), [state.app.language]);
    const dispatchRef: MutableRefObject<DispatchType> = React.useRef(dispatch);

    const [form, setForm] = React.useState({
        id: props.id,
        name: decodeURIComponent(props.name),
    });

    return React.useMemo(() => {
        const handleChange = (type: string, value: string) => {
            setForm({
                ...form,
                [type]: value,
            });
        };

        return (
            <View style={{
                width: '100%',
                maxWidth: 600,
                margin: 'auto',
                alignItems: 'center',
            }}>
                <GpeText
                    style={[
                        PopupStyles.h1,
                        Config.contextName === 'bertrand'
                            ? {
                                fontSize: 16,
                            } : null
                    ]}>
                    {translation('library.rename.bookshelf.form.title')}
                </GpeText>

                <TextInput
                    style={[
                        PopupStyles.input,
                        Config.contextName === 'bertrand'
                            ? {
                                fontSize: 14,
                            } : null
                    ]}
                    onChangeText={value => handleChange('name', value)}
                    value={form.name}
                    placeholder={translation('library.rename.bookshelf.form.placeholder')}
                    disableFullscreenUI={true} />

                <GpeButton
                    style={PopupStyles.button}
                    onPress={() => state.app.uid
                        && state.user.auth
                        && state.user.authToken
                        && doRenameBookshelf(
                            dispatchRef.current,
                            state.app.uid,
                            state.user.auth,
                            state.user.authToken,
                            form,
                            Config.contextName === 'bertrand'
                                ? <ShelfRenameMsgSuccess />
                                : null
                        )}>
                    {translation('app.form.confirm.button.text')}
                </GpeButton>

                {Config.contextName === 'bertrand'
                    ? null
                    : (
                        <GpeButton
                            style={[
                                PopupStyles.button,
                                { backgroundColor: '#d9d9d9' }
                            ]}
                            onPress={() => closePopup(dispatchRef.current)}>
                            <GpeText style={PopupStyles.blackColor}>{translation('app.form.cancel.button.text')}</GpeText>
                        </GpeButton>
                    )}
            </View>
        )
    }, [
        translation,
        state.app.uid,
        state.user.auth,
        state.user.authToken,
        form,
    ]);
}

export { ShelfRenameForm as default }