import React from 'react';
import { TextStyle } from 'react-native';
import GpeText from './GpeText';

function Icon(props: { icon: string, color?: string, style?: TextStyle | Array<TextStyle> }) {
    return (
        <GpeText
            style={[
                {
                    width: 50,
                    height: 50,
                    color: props.color,
                    fontFamily: 'WookIcones',
                    fontSize: 50,
                    lineHeight: 50,
                },
                props.style
            ]}>{props.icon}</GpeText>
    );
}

export { Icon as default };