import React, { MutableRefObject } from 'react';
import { View, TouchableOpacity } from 'react-native';

import Config, { Log } from '../../../config';
import { Store } from '../../../store';
import { ContextTheme, LibraryStyles } from '../../../styles';
import { getTranslation } from '../../../middlewares/AppMiddleware';
import {
    setShelfsListDropdownDispatch,
    toggleShelfsListDropdown,
    changeBookshelf,
    getSelectedShelf,
    clearSelectBookshelf
} from '../../../middlewares/LibraryMiddleware';
import ShelfCreateForm from '../../Popup/components/ShelfCreateForm';
import Icon from '../../Icon';
import Hoverable from '../../Hoverable';
import { useLocation, useHistory } from '../../GpeRouter';
import { CategoryInterface } from '../../../interfaces/middlewares';
import GpeText from '../../GpeText';
import { DispatchType } from '../../../interfaces/store';
import { openPopup } from '../../../middlewares/commons';
import theme from '../../../styles/theme';

function ShelfsListDropdown(props: { showcaseRender?: boolean }) {
    /** STORE */
    const { state, dispatch } = React.useContext(Store);

    /** PROPS */
    const dispatchRef: MutableRefObject<DispatchType> = React.useRef(dispatch);
    const translation = React.useMemo(() => getTranslation(state.app.language), [state.app.language]);
    const location = useLocation();
    const history = useHistory();
    const _bookshelfSelected = React.useMemo(() => getSelectedShelf(
        state.library.bookshelf
            ? state.library.bookshelf.shelfs
            : [],
        state.library.bookshelfSelected
    ), [
        state.library.bookshelf,
        state.library.bookshelfSelected
    ]);
    const _shelfs = React.useMemo(() => (
        state.library.bookshelf
            && state.library.bookshelf.shelfs
            ? state.library.bookshelf.shelfs
            : []
    ), [state.library.bookshelf]);

    /** STATE */
    const [shelfsListDropdownState, shelfsListDropdownDispatch] = React.useReducer((
        _state: { showOptions: boolean },
        _payload: { showOptions?: boolean }
    ) => ({ ..._state, ..._payload }), {
        showOptions: false,
    });

    React.useEffect(() => {
        /** MOUNT */
        setShelfsListDropdownDispatch(shelfsListDropdownDispatch);
        /** UNMOUNT */
        return () => setShelfsListDropdownDispatch(undefined);
    });

    /** RENDER */
    return React.useMemo(() => {
        Log.debug('component: ShelfsListDropdown');

        return (
            <View
                style={{
                    paddingRight: props.showcaseRender ? 52 : 104,
                }}>
                <TouchableOpacity
                    onPress={() => toggleShelfsListDropdown(!shelfsListDropdownState.showOptions)}>
                    <GpeText
                        numberOfLines={1}
                        ellipsizeMode={'tail'}
                        style={[
                            LibraryStyles.greyBackgroundColor,
                            LibraryStyles.bookshelfMenuOption,
                            {
                                paddingRight: 50,
                                textTransform: 'uppercase'
                            }
                        ]}>
                        {location.pathname.indexOf(Config.routes.bookshelf) > -1
                            && _bookshelfSelected
                            ? (
                                <React.Fragment>
                                    {decodeURIComponent(_bookshelfSelected.name)}
                                    <GpeText
                                        style={{ fontFamily: ContextTheme.font.semibold }}> ({_bookshelfSelected.bookAmount})</GpeText>
                                </React.Fragment>
                            )
                            : translation('library.bookshelf.list.title')}
                    </GpeText>
                    <Icon
                        style={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                        }}
                        icon={shelfsListDropdownState.showOptions ? 'w' : 'v'} />
                </TouchableOpacity>

                {shelfsListDropdownState.showOptions
                    ? (
                        <View style={[
                            LibraryStyles.greyBackgroundColor,
                            {
                                // position: 'absolute',
                                // top: 50,
                                // width: '100%',
                                marginRight: props.showcaseRender ? -52 : -104,

                                marginTop: 2,
                                overflow: 'hidden',
                            }
                        ]}>
                            <Hoverable>
                                {(hover: boolean) => (
                                    <TouchableOpacity
                                        onPress={() => openPopup(dispatchRef.current, <ShelfCreateForm />)}>
                                        <GpeText
                                            style={[
                                                LibraryStyles.greyBackgroundColor,
                                                LibraryStyles.bookshelfMenuOption,
                                                hover && LibraryStyles.themeColor
                                            ]}>
                                            <GpeText
                                                style={LibraryStyles.themeColor}>+ </GpeText>{translation('library.bookshelf.list.new.shelf')}
                                        </GpeText>
                                    </TouchableOpacity>
                                )}
                            </Hoverable>

                            <Hoverable>
                                {(hover: boolean) => (
                                    <TouchableOpacity
                                        onPress={() => {
                                            toggleShelfsListDropdown(false);
                                            clearSelectBookshelf(dispatchRef.current);
                                            history.push(Config.routes.bookshelf);
                                        }}>
                                        <GpeText
                                            style={[
                                                LibraryStyles.greyBackgroundColor,
                                                LibraryStyles.bookshelfMenuOption,
                                                { fontFamily: theme.font.semibold },
                                                (location.pathname.indexOf(Config.routes.bookshelf) > -1
                                                    && !_bookshelfSelected
                                                    && LibraryStyles.themeColor),
                                                hover && LibraryStyles.themeColor,
                                            ]}>
                                            {translation('library.bookshelf.list.showcases.title')}
                                        </GpeText>
                                    </TouchableOpacity>
                                )}
                            </Hoverable>

                            {_shelfs.map((shelf: CategoryInterface, index: any) => (
                                <Hoverable key={index}>
                                    {(hover: boolean) => (
                                        <TouchableOpacity
                                            onPress={() => {
                                                toggleShelfsListDropdown(false);
                                                changeBookshelf(dispatchRef.current, shelf);
                                                /** check location */
                                                location.pathname !== Config.routes.bookshelf
                                                    && history.push(Config.routes.bookshelf);
                                            }}>
                                            <GpeText
                                                style={[
                                                    LibraryStyles.greyBackgroundColor,
                                                    LibraryStyles.bookshelfMenuOption,
                                                    (location.pathname.indexOf(Config.routes.bookshelf) > -1
                                                        && _bookshelfSelected
                                                        && _bookshelfSelected.id === shelf.id
                                                        && LibraryStyles.themeColor),
                                                    hover && LibraryStyles.themeColor
                                                ]}>
                                                {decodeURIComponent(shelf.name)}
                                                <GpeText
                                                    style={{ fontFamily: ContextTheme.font.semibold }}> ({shelf.bookAmount})</GpeText>
                                            </GpeText>
                                        </TouchableOpacity>
                                    )}
                                </Hoverable>
                            ))}
                        </View>
                    ) : null}
            </View>
        );
    }, [
        translation,
        props.showcaseRender,
        _shelfs,
        _bookshelfSelected,
        location,
        history,
        shelfsListDropdownState,
    ]);
}

export { ShelfsListDropdown }