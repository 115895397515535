import React from 'react';
import { View } from 'react-native';
import { ContextTheme } from '../../../styles';

import GpeText from '../../GpeText';

function ShelfDeleteMsgNotAllowed() {
    return React.useMemo(() => (
        <View
            style={{ alignItems: 'center' }}>
            <GpeText
                style={{
                    fontFamily: ContextTheme.font.semibold,
                    fontSize: 16,
                    textAlign: 'center',
                    marginBottom: 20,
                }}>
                {'Eliminar estante'}
            </GpeText>
            <GpeText
                style={{
                    fontFamily: ContextTheme.font.regular,
                    fontSize: 14,
                    textAlign: 'center',
                }}>
                {'Só é possível eliminar estantes vazias.\n\nPara eliminar uma estante, mova primeiro o(s) eBook(s) para outra estante.'}
            </GpeText>
        </View>
    ), []);
}

export { ShelfDeleteMsgNotAllowed as default }