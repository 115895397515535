import React, { MutableRefObject } from 'react';
import { ActivityIndicator, View, Image } from 'react-native';

import { Log } from '../../../../../../../config';
import { CarouselContentInterface } from '../../../../../../../interfaces/components';
import { DispatchType } from '../../../../../../../interfaces/store';
import {
    getAssetImageUri,
    redownloadAsset
} from '../../../../../../../middlewares/AppMiddleware';
import { Store } from '../../../../../../../store';
import { CarouselStyles } from '../../../../../../../styles';
import GpeText from '../../../../../../GpeText';

function Slide(
    props: {
        showGif: Boolean,
        height: number,
        content: CarouselContentInterface,
    }
) {
    const { state, dispatch } = React.useContext(Store);

    const dispatchRef: MutableRefObject<DispatchType> = React.useRef(dispatch);
    const headerRef: MutableRefObject<View | null> = React.useRef(null);
    const footerRef: MutableRefObject<View | null> = React.useRef(null);

    /** LOCAL STATE */
    const [slideState, setSlideState] = React.useReducer((
        _state: {
            headerHeight: number,
            footerHeight: number,
        },
        _payload: {
            headerHeight?: number,
            footerHeight?: number,
        }
    ) => ({ ..._state, ..._payload }), {
        headerHeight: 0,
        footerHeight: 0,
    });

    return React.useMemo(() => {
        Log.debug('component: Slide');

        return (
            <View style={CarouselStyles.wrapper}>
                <View
                    ref={(ref) => headerRef.current = ref}
                    onLayout={() => headerRef.current?.measure(
                        (x: number, y: number, width: number, height: number) => height
                            && height !== slideState.headerHeight
                            && setSlideState({ headerHeight: height })
                    )}>
                    <GpeText style={CarouselStyles.header}>{props.content.title}</GpeText>
                    <GpeText style={CarouselStyles.paragraph}>{props.content.subtitle}</GpeText>
                </View>

                <View
                    style={{
                        height: props.height
                            && slideState.headerHeight
                            && slideState.footerHeight
                            ? props.height - slideState.headerHeight - slideState.footerHeight - 40
                            : 0,
                        width: '100%',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>

                    <ActivityIndicator
                        style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}
                        size="large"
                        color="#000" />

                    {props.showGif
                        && <Image
                            style={CarouselStyles.imageStyle}
                            onError={() => state.app.storageDir
                                && redownloadAsset(
                                    dispatchRef.current,
                                    state.app.storageDir,
                                    state.app.downloadedAssets,
                                    props.content.assetKey,
                                )}
                            source={{
                                uri: getAssetImageUri(
                                    props.content.assetKey,
                                    state.app.downloadedAssets,
                                )
                            }} />}
                </View>

                <View
                    ref={(ref) => footerRef.current = ref}
                    onLayout={() => footerRef.current?.measure(
                        (x: number, y: number, width: number, height: number) => height
                            && height !== slideState.footerHeight
                            && setSlideState({ footerHeight: height })
                    )}>
                    <GpeText style={CarouselStyles.footer}>{props.content.footer}</GpeText>
                </View>
            </View>
        );
    }, [
        props,
        slideState,
        state.app.storageDir,
        state.app.downloadedAssets,
    ]);
}

export { Slide as default }