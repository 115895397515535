import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
    h1: {
        marginBottom: 30,
    },
    h3: {
        marginBottom: 30,
    },
    input: {
        marginBottom: 20,
    },
    button: {
        marginBottom: 20,
        width: '100%',
        maxWidth: undefined,
    },
});

export { styles as default };