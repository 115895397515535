import { StateLibraryInterface, ActionInterface } from '../../interfaces/store';
import { setStorage } from '../storage';

const library: StateLibraryInterface = {
    bookshelf: null,
    moveToShelf: false,
    manualSorting: false,
    bookshelfSelected: null,
    readingStarted: {},
    sortedBookshelfDesc: {},
    showcases: null,
    downloadedVersions: {},
    downloadedCovers: {},
    message: null,
};

const reducer = (
    _library: StateLibraryInterface = library,
    _dispatch: ActionInterface
) => {
    switch (_dispatch.type) {
        case 'LIBRARY':
            return { ..._library, ..._dispatch.payload };
        case 'LIBRARY-BOOKSHELF':
            setStorage(_dispatch);
            return { ..._library, bookshelf: _dispatch.payload };
        case 'LIBRARY-MOVE_TO_SHELF':
            return { ..._library, moveToShelf: _dispatch.payload };
        case 'LIBRARY-MANUAL_SORTING':
            return { ..._library, manualSorting: _dispatch.payload };
        case 'LIBRARY-BOOKSHELF_SELECTED':
            setStorage(_dispatch);
            return { ..._library, bookshelfSelected: _dispatch.payload };
        case 'LIBRARY-READING_STARTED':
            setStorage(_dispatch);
            return { ..._library, readingStarted: _dispatch.payload };
        case 'LIBRARY-SORTED_BOOKSHELF_DESC':
            setStorage(_dispatch);
            return { ..._library, sortedBookshelfDesc: _dispatch.payload };
        case 'LIBRARY-SHOWCASES':
            setStorage(_dispatch);
            return { ..._library, showcases: _dispatch.payload };
        case 'LIBRARY-DOWNLOADED_VERSIONS':
            setStorage(_dispatch);
            return { ..._library, downloadedVersions: _dispatch.payload };
        case 'LIBRARY-ADD_DOWNLOADED_VERSIONS':
            const _downloadedVersions = { ..._library.downloadedVersions, ..._dispatch.payload };
            setStorage({
                type: 'LIBRARY-DOWNLOADED_VERSIONS',
                payload: _downloadedVersions
            });
            return { ..._library, downloadedVersions: _downloadedVersions };
        case 'LIBRARY-DELETE_DOWNLOADED_VERSIONS':
            _library.downloadedVersions[_dispatch.payload]
                && (delete _library.downloadedVersions[_dispatch.payload]);
            setStorage({
                type: 'LIBRARY-DOWNLOADED_VERSIONS',
                payload: _library.downloadedVersions
            });
            return { ..._library, downloadedVersions: { ..._library.downloadedVersions } };
        case 'LIBRARY-DOWNLOADED_COVERS':
            setStorage(_dispatch);
            return { ..._library, downloadedCovers: _dispatch.payload };
        case 'LIBRARY-ADD_DOWNLOADED_COVERS':
            const _downloadedCovers = { ..._library.downloadedCovers, ..._dispatch.payload };
            setStorage({
                type: 'LIBRARY-DOWNLOADED_COVERS',
                payload: _downloadedCovers
            });
            return { ..._library, downloadedCovers: _downloadedCovers };
        case 'LIBRARY-REMOVE_DOWNLOADED_COVERS':
            delete _library.downloadedCovers[_dispatch.payload];
            setStorage({
                type: 'LIBRARY-DOWNLOADED_COVERS',
                payload: _library.downloadedCovers
            });
            return { ..._library, downloadedCovers: { ..._library.downloadedCovers } };
        case 'LIBRARY-MESSAGE':
            return { ..._library, message: _dispatch.payload };
    }
    return _library;
}

export { reducer as default, library };