import { StyleSheet } from 'react-native';

import theme from '../../styles/theme';

const styles = StyleSheet.create({
    mainView: {
        flex: 1,
        height: '100%',
        width: '100%',
        overflow: 'hidden',
        position: 'absolute',
        backgroundColor: theme.palette.white,
    },
    themeColor: {
        color: theme.palette.theme,
    },
    whiteColor: {
        color: theme.palette.white,
    },
    blackColor: {
        color: theme.palette.lightBlack,
    },
    themeBackgroundColor: {
        backgroundColor: theme.palette.theme,
    },
    greyBackgroundColor: {
        backgroundColor: theme.palette.lightGrey,
    },
    orangeBackgroundColor: {
        backgroundColor: theme.palette.orange,
    },
    container: {
        flexGrow: 1,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 20,
    },
    row: {
        flexWrap: 'wrap',
        flexDirection: 'row',
    },
    spaceRight: {
        marginRight: 10,
    },
    bold: {
        fontWeight: '600',
    },
    h1: {
        fontFamily: theme.font.semibold,
        fontSize: 32,
        color: theme.palette.lightBlack,
    },
    h2: {
        fontFamily: theme.font.regular,
        fontSize: 28,
    },
    h3: {
        fontFamily: theme.font.regular,
        fontSize: 20,
    },
    text: {
        fontFamily: theme.font.regular,
        fontSize: 16,
        color: theme.palette.lightBlack,
    },
    inputLabel: {
        fontFamily: theme.font.regular,
        fontSize: 16,
        color: theme.palette.grey,
        paddingTop: 5,
        paddingBottom: 5,
    },
    input: {
        fontFamily: theme.font.regular,
        fontSize: 16,
        height: 60,
        width: '100%',
        padding: 13,
        marginBottom: 10,
        backgroundColor: theme.palette.white,
    },
    link: {
        textDecorationLine: 'underline',
        textDecorationColor: theme.palette.lightBlack,
    },
    buttonText: {
        fontFamily: theme.font.semibold,
        fontSize: 20,
        color: theme.palette.white,
        textAlign: "center",
    },
    button: {
        backgroundColor: theme.palette.theme,
        padding: 8,
        borderRadius: 0,
        height: 50,
        width: "100%",
        maxWidth: 200,
        margin: "auto",
        alignItems: 'center',
        justifyContent: 'center',
    },
    googleBtnBackground: {
        backgroundColor: theme.palette.googleRed,
    },
    facebookBtnBackground: {
        backgroundColor: theme.palette.facebookBlue,
    },
    error: {
        fontFamily: theme.font.regular,
        fontSize: 16,
        width: '100%',
        padding: 15,
        color: "red",
        backgroundColor: theme.palette.red,
    },
    icon: {
        flex: 1,
        height: 50,
        width: 50,
        alignItems: 'center',
        justifyContent: 'center',
    },
    iconText: {
        fontSize: 26,
        height: 26,
        width: 26,
        lineHeight: 26,
        textAlignVertical: 'center',
    },
});

export { styles as default };