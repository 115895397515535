import React, { MutableRefObject } from 'react';
import { View, TextInput, Platform, TouchableOpacity, TouchableWithoutFeedback } from 'react-native';

import Config, { Log } from '../../../../config';
import { Store } from '../../../../store';
import { ContextTheme, LoginStyles } from '../../../../styles';
import { Link } from '../../../GpeRouter';
import GpeButton from '../../../GpeButton';
import { doLogin } from '../../../../middlewares/UserMiddleware';
import Icon from '../../../Icon';
import GpeScrollView from '../../../GpeScrollView';
import BertrandLogo from './BertrandLogo';
import GpeText from '../../../GpeText';
import { DispatchType } from '../../../../interfaces/store';

function LoginForm() {
    const { state, dispatch } = React.useContext(Store);

    const dispatchRef: MutableRefObject<DispatchType> = React.useRef(dispatch);

    /** LOCAL STATE */
    const [form, setForm] = React.useState<{
        user: string,
        password: string,
        keepLoggedIn: boolean,
        secureTextEntry: boolean,
        showKeepLoggedInInfo: boolean,
        notValid: { [key: string]: string | undefined },
    }>({
        user: '',
        password: '',
        keepLoggedIn: Config.platform === 'web' ? false : true,
        secureTextEntry: true,
        showKeepLoggedInInfo: false,
        notValid: {},
    });

    return React.useMemo(() => {
        Log.debug('component: LoginForm');

        /** VALIDATE */
        const formValid = () => {
            const notValid: { user?: string, password?: string } = {};

            form.user.match(new RegExp('@', 'g'))?.length !== 1 && (notValid.user = 'e-mail inválido!');
            form.user.lastIndexOf('.') < 3 && (notValid.user = 'e-mail inválido!');
            (form.user.lastIndexOf('@') < 1
                || form.user.lastIndexOf('.') < form.user.lastIndexOf('@')) && (notValid.user = 'e-mail inválido!');
            form.password.length < 1 && (notValid.password = 'insira a password!');

            Object.keys(notValid).length && setForm({
                ...form,
                notValid: notValid,
            });

            return !Object.keys(notValid).length;
        }

        const loginSubmit = () => formValid()
            && state.app.uid
            && doLogin(dispatchRef.current, state.app.uid, form);

        const handleChange = (type: string, value: string | boolean) => {
            state.user.loginError
                && dispatchRef.current({
                    type: 'USER-LOGIN_ERROR',
                    payload: '',
                });

            const notValid: any = form.notValid;
            notValid[type] && (delete notValid[type]);

            setForm({
                ...form,
                [type]: value,
                notValid: notValid,
            });
        }

        return (
            <GpeScrollView
                horizontal={false}
                style={{ backgroundColor: ContextTheme.palette.lightGrey }}
                contentContainerStyle={{ padding: 20, alignItems: 'center' }}>
                <View
                    style={LoginStyles.container}>
                    <View
                        style={{ width: '100%' }}>
                        <View
                            style={{
                                flexDirection: 'row',
                                justifyContent: 'center',
                                marginBottom: 20,
                            }}>
                            <BertrandLogo
                                style={{
                                    width: 80,
                                    height: 80,
                                    marginRight: 20,
                                }} />

                            <View
                                style={{ alignItems: 'center', justifyContent: 'center' }}>
                                <GpeText
                                    style={{
                                        fontFamily: ContextTheme.font.regular,
                                        fontSize: 14,
                                        color: ContextTheme.palette.grey
                                    }}>
                                    {'BIBLIO BERTRAND'}
                                </GpeText>
                            </View>
                        </View>
                        <GpeText
                            style={[
                                LoginStyles.h1,
                                {
                                    fontFamily: ContextTheme.font.regular,
                                    fontSize: 14,
                                    lineHeight: undefined,
                                    paddingTop: 0,
                                    paddingBottom: 0,
                                    color: ContextTheme.palette.grey,
                                }
                            ]}>
                            {'JÁ ESTÁ REGISTADO(A) EM WWW.BERTRAND.PT?'}
                        </GpeText>
                    </View>

                    <View
                        style={{ width: '100%' }}>
                        <View
                            style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
                            <Link
                                to={Config.routes.firstpage}>
                                <Icon
                                    icon=">"
                                    color={ContextTheme.palette.theme}
                                    style={{ transform: [{ rotate: '180deg' }] }} />
                            </Link>
                            <GpeText
                                style={[
                                    LoginStyles.text,
                                    {
                                        fontSize: 14,
                                        flex: 1,
                                        textAlign: 'left',
                                        color: ContextTheme.palette.grey
                                    }
                                ]}>
                                {'Faça login com os dados que utiliza em www.bertrand.pt'}
                            </GpeText>
                        </View>

                        {Object.keys(form.notValid).length
                            ? <GpeText style={LoginStyles.error}>{form.notValid[Object.keys(form.notValid)[0]]}</GpeText>
                            : state.user.loginError
                                ? <GpeText style={LoginStyles.error}>{state.user.loginError}</GpeText>
                                : null}

                        <View style={[
                            LoginStyles.inputContainer,
                            form.notValid.user
                                ? { borderColor: 'red' }
                                : null,
                            { marginBottom: 20 }
                        ]}>
                            <Icon icon='u' style={{ color: '#ccc', backgroundColor: ContextTheme.palette.white }} />
                            <TextInput
                                style={[
                                    LoginStyles.input,
                                    {
                                        fontSize: 14,
                                        color: ContextTheme.palette.grey
                                    }
                                ]}
                                keyboardType="email-address"
                                onChangeText={value => handleChange('user', value)}
                                onSubmitEditing={loginSubmit}
                                value={form.user}
                                {...(Platform.OS !== "web" ? { autoCompleteType: "email" } : null)}
                                disableFullscreenUI={true}
                                autoCapitalize="none"
                                placeholder="utilizador@email.com" />
                        </View>

                        <View
                            style={[
                                LoginStyles.inputContainer,
                                form.notValid.password
                                    ? { borderColor: 'red' }
                                    : null,
                                { marginBottom: 20 }
                            ]}>
                            <Icon icon='U' style={{ color: '#ccc', backgroundColor: ContextTheme.palette.white }} />
                            <TextInput
                                style={[
                                    LoginStyles.input,
                                    {
                                        fontSize: 14,
                                        color: ContextTheme.palette.grey
                                    }
                                ]}
                                onChangeText={value => handleChange('password', value)}
                                onSubmitEditing={loginSubmit}
                                value={form.password}
                                {...(Platform.OS !== "web" ? { autoCompleteType: "password" } : null)}
                                secureTextEntry={form.secureTextEntry}
                                disableFullscreenUI={true}
                                autoCapitalize="none"
                                placeholder="password" />
                            <TouchableOpacity
                                onPress={() => handleChange('secureTextEntry', !form.secureTextEntry)}>
                                <Icon
                                    icon={form.secureTextEntry ? 't' : 'T'}
                                    style={{ color: ContextTheme.palette.loginColor, backgroundColor: ContextTheme.palette.white }} />
                            </TouchableOpacity>
                        </View>

                        {Config.platform === 'web'
                            ? (
                                <View style={{ flexDirection: 'row', marginBottom: 10 }}>
                                    <TouchableWithoutFeedback
                                        onPress={() => handleChange('keepLoggedIn', !form.keepLoggedIn)}>
                                        <View
                                            style={{ flexDirection: 'row' }}>
                                            <React.Fragment>
                                                <GpeText
                                                    style={{
                                                        fontFamily: 'WookIcones',
                                                        color: ContextTheme.palette.grey,
                                                        fontSize: 15,
                                                        lineHeight: 18,
                                                        height: 20,
                                                        width: 20,
                                                        borderWidth: 1,
                                                        borderColor: '#ccc',
                                                        borderRadius: 2,
                                                        textAlign: 'center',
                                                        marginRight: 10,
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        backgroundColor: ContextTheme.palette.white,
                                                    }}>{form.keepLoggedIn ? 'X' : null}</GpeText>
                                                <GpeText
                                                    style={{
                                                        fontFamily: ContextTheme.font.regular,
                                                        fontSize: 14,
                                                        color: ContextTheme.palette.grey,
                                                    }}>Manter a sessão iniciada</GpeText>
                                                <TouchableOpacity
                                                    onPress={() => handleChange('showKeepLoggedInInfo', !form.showKeepLoggedInInfo)}>
                                                    <GpeText
                                                        style={{
                                                            fontFamily: ContextTheme.font.semibold,
                                                            fontSize: 16,
                                                            color: ContextTheme.palette.loginColor,
                                                            textAlign: 'center',
                                                            width: 24,
                                                            borderRadius: 10,
                                                            backgroundColor: '#c7c5c2',
                                                            marginLeft: 5,
                                                        }}>?</GpeText>
                                                </TouchableOpacity>
                                            </React.Fragment>
                                        </View>
                                    </TouchableWithoutFeedback>
                                </View>
                            ) : null}
                        {form.showKeepLoggedInInfo
                            ? (
                                <View style={{
                                    borderWidth: 1,
                                    borderColor: '#c7c5c2',
                                    borderRadius: 2,
                                    paddingTop: 4,
                                    paddingRight: 10,
                                    paddingBottom: 4,
                                    paddingLeft: 10,
                                    marginTop: 5,
                                    marginBottom: 10,
                                }}>
                                    <View style={{
                                        width: 0,
                                        height: 0,
                                        backgroundColor: 'transparent',
                                        borderStyle: 'solid',
                                        borderLeftWidth: 10,
                                        borderRightWidth: 10,
                                        borderBottomWidth: 10,
                                        borderLeftColor: 'transparent',
                                        borderRightColor: 'transparent',
                                        borderBottomColor: '#c7c5c2',
                                        position: 'absolute',
                                        top: 0,
                                        left: 158,
                                        marginTop: -10,
                                    }}></View>
                                    <GpeText
                                        style={{
                                            fontFamily: ContextTheme.font.regular,
                                            fontSize: 14,
                                            lineHeight: 16,
                                            color: ContextTheme.palette.grey,
                                            textAlign: 'justify',
                                        }}>{'Esta opção reduz o número de vezes que a sua identificação é pedida por este dispositivo. Pela sua segurança, utilize esta opção apenas nos seus dispositivos pessoais.'}
                                    </GpeText>
                                    <GpeText
                                        style={{
                                            fontFamily: ContextTheme.font.regular,
                                            fontSize: 14,
                                            lineHeight: 16,
                                            color: ContextTheme.palette.grey,
                                            textAlign: 'justify',
                                        }}>{'Para manter a segurança da sua conta, utilize esta opção apenas nos seus dispositivos pessoais.'}
                                    </GpeText>
                                </View>
                            ) : null}

                        <GpeButton
                            style={[LoginStyles.button, { width: '100%', maxWidth: 'auto' }]} onPress={loginSubmit}>
                            <GpeText
                                style={{ fontFamily: ContextTheme.font.semibold, fontSize: 16 }}>
                                {'ENTRAR'}
                            </GpeText>
                        </GpeButton>

                        <Link
                            to={Config.routes.recover}
                            style={[
                                LoginStyles.button,
                                {
                                    width: '100%',
                                    maxWidth: 'auto',
                                    backgroundColor: 'transparent',
                                    borderWidth: 1,
                                    borderColor: ContextTheme.palette.grey,
                                }]}>
                            <GpeText
                                style={[LoginStyles.text, { fontFamily: ContextTheme.font.semibold, color: ContextTheme.palette.grey }]}>
                                {'RECUPERAR PASSWORD'}
                            </GpeText>
                        </Link>
                    </View>
                </View>
            </GpeScrollView>
        )
    }, [form, state.app.uid, state.user.loginError]);
}

export { LoginForm as default }