import React from 'react';

import Config from '../../config';

function styles() {
    const BertrandTheme = React.lazy(() => import('./contexts/bertrand'));
    const WookTheme = React.lazy(() => import('./contexts/wook'));

    return (
        <React.Suspense fallback={<></>}>
            {Config.contextName === 'bertrand'
                ? <BertrandTheme />
                : <WookTheme />}
        </React.Suspense>
    );
}

export { styles as default };