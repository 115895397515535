import React, { MutableRefObject } from 'react';
import { DimensionValue, View } from 'react-native';

import { Log } from '../../../config';
import { Store } from '../../../store';
import { dimensionColumns_Value, dimensionWidth, LibraryStyles } from '../../../styles';
import { getShelf } from '../../../middlewares/LibraryMiddleware';
import AnimatedCover from './AnimatedCover';
import ShelfOptions from './ShelfOptions';
import SearchBar from '../../Search/components/SearchBar';
import { ShelfsListDropdown } from './ShelfsListDropdown';
import { EbookInterface } from '../../../interfaces/middlewares';
import TitleH1 from './TitleH1';
import DimensionsEventTimeout from '../../DimensionsEvent';

const timeoutDelay = 100;

function ShelfEbooks(props: { containerStyle: DimensionValue, showTitle: boolean, showShelfsDropdown: boolean }) {
    /** STORE */
    const { state } = React.useContext(Store);

    /** MEDIA */
    const containerMoveMedia = {
        xs: '65%',
        md: '75%',
    };
    const coverMedia = React.useMemo(() => ({ xs: 2, sm: 3, lg: 5 }), []);
    const coverDragMedia = React.useMemo(() => ({ xs: 1, sm: 2, md: 3, lg: 5 }), []);

    /** PROPS */
    const shelfEbookRef: MutableRefObject<View | null> = React.useRef(null);

    const timeout: MutableRefObject<NodeJS.Timeout | undefined> = React.useRef();

    const draggingMode = React.useMemo(() => (
        state.library.moveToShelf || state.library.manualSorting
    ), [
        state.library.moveToShelf,
        state.library.manualSorting
    ]);

    const _shelf = React.useMemo(() => getShelf(
        state.library.bookshelf
            ? state.library.bookshelf.shelfs
            : [],
        state.library.bookshelfSelected
    ), [
        state.library.bookshelf,
        state.library.bookshelfSelected
    ]);

    /** LOCAL STATE */
    const [shelfEbooksState, setShelfEbooksState] = React.useReducer((
        _state: {
            coverWidth: number | undefined,
            coverDragWidth: number | undefined,
            containerMoveStyle: DimensionValue,
        },
        _payload: {
            coverWidth?: number,
            coverDragWidth?: number,
            containerMoveStyle?: DimensionValue,
        },
    ) => ({ ..._state, ..._payload }), {
        coverWidth: undefined,
        coverDragWidth: undefined,
        containerMoveStyle: dimensionWidth(containerMoveMedia),
    });

    const componentMeasure = React.useCallback(() => {
        timeout.current
            && clearTimeout(timeout.current);
        timeout.current = setTimeout(() => shelfEbookRef.current?.measure(
            (x: number, y: number, width: number) => {
                const _width = Math.trunc(dimensionColumns_Value(coverMedia, width - 30) - 30);
                const _widthDrag = Math.trunc(dimensionColumns_Value(coverDragMedia, width - 30) - 30);

                if (_width !== shelfEbooksState.coverWidth
                    || _widthDrag !== shelfEbooksState.coverDragWidth) {
                    setShelfEbooksState({
                        coverWidth: _width,
                        coverDragWidth: _widthDrag,
                    });
                }
            }
        ), timeoutDelay);
    }, [
        coverMedia,
        coverDragMedia,
        shelfEbooksState.coverWidth,
        shelfEbooksState.coverDragWidth,
    ]);

    React.useEffect(() => {

        return () => {
            timeout.current
                && clearTimeout(timeout.current);
        }
    }, []);

    /** RESIZE LISTENER */
    DimensionsEventTimeout(() => {
        const _containerMoveStyle = dimensionWidth(containerMoveMedia);
        if (_containerMoveStyle !== shelfEbooksState.containerMoveStyle) {
            setShelfEbooksState({
                containerMoveStyle: _containerMoveStyle,
            });
        }
    });

    /** RENDER */
    return React.useMemo(() => {
        Log.debug('component: ShelfEbooks');

        return (
            <View
                ref={(ref) => shelfEbookRef.current = ref}
                onLayout={componentMeasure}
                style={{
                    width: state.library.moveToShelf
                        ? shelfEbooksState.containerMoveStyle
                        : props.containerStyle,
                }}>
                {!draggingMode
                    ? (
                        <View
                            style={[
                                LibraryStyles.bookshelfHeader,
                                state.library.moveToShelf ? { zIndex: -1 } : null,
                            ]}>

                            {/** barra de pesquisa */}
                            <SearchBar />

                            {/** titulo da estante */}
                            {props.showTitle
                                && _shelf
                                ? <TitleH1
                                    style={{ minHeight: 50 }}
                                    title={decodeURIComponent(_shelf.name)} />
                                : null}

                            {/** listagem das estantes numa dropdown */}
                            {props.showShelfsDropdown
                                ? <ShelfsListDropdown />
                                : null}

                            {/** opcoes para acoes sobre a estante selecionada */}
                            {_shelf
                                ? <ShelfOptions ebooksList={_shelf.ebooksList} />
                                : null}
                        </View>
                    ) : null}

                {/** estante */}
                {shelfEbooksState.coverWidth
                    && shelfEbooksState.coverDragWidth
                    && (
                        <View style={[LibraryStyles.row, { paddingLeft: 30 }]}>
                            {/* {!props.showTitle
                                && !draggingMode
                                && _shelf
                                ? <TitleH3
                                    style={{ paddingLeft: 0 }}
                                    title={decodeURIComponent(_shelf.name)} />
                                : null} */}

                            {_shelf
                                && _shelf.ebooksList
                                && _shelf.ebooksList.length
                                ? _shelf.ebooksList.map((item: EbookInterface, index: number) =>
                                    <AnimatedCover
                                        key={item.subArtid + '_' + index}
                                        index={index}
                                        ebook={item}
                                        shelfId={_shelf.id}
                                        width={state.library.moveToShelf
                                            ? shelfEbooksState.coverDragWidth
                                            : shelfEbooksState.coverWidth} />)
                                : null}
                        </View>
                    )}
            </View>
        );
    }, [
        props,
        _shelf,
        draggingMode,
        state.library.moveToShelf,
        shelfEbooksState.coverWidth,
        shelfEbooksState.coverDragWidth,
        shelfEbooksState.containerMoveStyle,
        componentMeasure,
    ]);
}

export { ShelfEbooks as default }