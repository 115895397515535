import React from 'react';
import { View, TouchableOpacity, SafeAreaView } from 'react-native';

import Config, { Log } from '../../config';
import { ContextTheme } from '../../styles';
import Icon from '../Icon';
import GpeScrollView from '../GpeScrollView';
import GpeText from '../GpeText';
import { PopupInit } from './utils';

function Popup() {
    const {
        popup,
        closePopupCallback,
    } = PopupInit();

    return React.useMemo(() => {
        Log.debug('component: Popup');

        return popup
            && (
                <View
                    style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                        backgroundColor: ContextTheme.palette.lightGrey,
                        zIndex: 8000,
                    }}>
                    <SafeAreaView
                        style={{
                            flex: 1,
                        }}>
                        <GpeScrollView
                            contentContainerStyle={{
                                minHeight: '100%',
                                alignItems: 'center',
                            }}>
                            <View
                                style={{
                                    alignItems: 'flex-end',
                                    position: 'absolute',
                                    top: 0,
                                    right: 0,
                                    zIndex: 1,
                                }}>
                                <TouchableOpacity
                                    onPress={closePopupCallback}>
                                    <Icon
                                        style={{
                                            marginTop: 20,
                                            marginRight: 20,
                                        }}
                                        icon='X' />
                                </TouchableOpacity>
                            </View>
                            <View
                                style={{
                                    flex: 1,
                                    margin: 'auto',
                                    maxWidth: 850,
                                    width: '100%',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    paddingLeft: 30,
                                    paddingRight: 30,
                                }}>

                                {popup.component
                                    ? popup.component
                                    : popup.text
                                        ? (
                                            <GpeText
                                                style={{
                                                    fontFamily: Config.contextName === 'bertrand'
                                                        ? ContextTheme.font.semibold
                                                        : ContextTheme.font.regular,
                                                    fontSize: Config.contextName === 'bertrand'
                                                        ? 16
                                                        : 14,
                                                    textAlign: 'center'
                                                }}>
                                                {popup.text}
                                            </GpeText>
                                        ) : null}
                            </View>
                        </GpeScrollView>
                    </SafeAreaView>
                </View>
            );
    }, [
        popup,
        closePopupCallback,
    ]);
}

export { Popup as default }