import React, { MutableRefObject } from 'react';

import Config, { Log } from '../config';
import { Store } from '../store';
import Context from '../components/ByContext';
import { Redirect, useHistory, useLocation } from '../components/GpeRouter';
import { customerByToken, doLogout } from '../middlewares/UserMiddleware';
import { DispatchType } from '../interfaces/store';

function LoginScreen() {
    const { state, dispatch } = React.useContext(Store);

    const dispatchRef: MutableRefObject<DispatchType> = React.useRef(dispatch);

    const location = useLocation();

    const history = useHistory();

    const token = React.useMemo(() => {
        if (Config.platform === 'web') {
            const searchParams = new URLSearchParams(location.search);

            if (searchParams.has('token'))
                return searchParams.get('token');
        }

        return undefined;
    }, [location.search]);

    const loggedInTimeout = React.useMemo(() => {
        return state.user.auth
            && !state.user.keepLoggedIn
            && state.user.keepLoggedInTimeout
            && (Date.now() - state.user.keepLoggedInTimeout) / 1000 / (60 * 60) > 1
            ? true
            : false
    }, [state.user.auth, state.user.keepLoggedIn, state.user.keepLoggedInTimeout]);

    React.useEffect(() => {
        token
            && !state.user.loginError
            && !state.user.loginByToken
            && state.app.uid
            && customerByToken(
                dispatchRef.current,
                state.app.uid,
                token,
                {
                    success: () => {
                        if (Config.platform === 'web') {
                            const searchParams = new URLSearchParams(location.search);
                            if (searchParams.has('token')) {
                                searchParams.delete('token');
                                const params = searchParams.toString();
                                history.push(location.pathname, params ? `?${params}` : '');
                            }
                        }
                    }
                }
            );

        !token
            && loggedInTimeout
            && doLogout(dispatchRef.current);
    }, [
        history,
        location.pathname,
        location.search,
        state.app.uid,
        state.user.loginError,
        state.user.loginByToken,
        token,
        loggedInTimeout,
    ]);

    return React.useMemo(() => {
        Log.debug('screen: LOGIN');
        Log.debug('screen: LOGIN: token:', Boolean(
            token
            && !state.user.loginError
            && !state.user.loginByToken
        ));

        return (token
            && !state.user.loginError
            && !state.user.loginByToken)
            || (!token && loggedInTimeout)
            ? null
            : !state.user.auth
                ? <Context.Login />
                : <Redirect to={Config.routes.bookshelf} />
    }, [
        state.user.auth,
        state.user.loginError,
        state.user.loginByToken,
        token,
        loggedInTimeout,
    ]);
}

export { LoginScreen as default };