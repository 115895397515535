import { ErrorMappingInterface, Languages } from '../../interfaces/language';

const errorMapping: ErrorMappingInterface = {
    "pt-pt": {
        defaultMsg: "Ocorreu um erro. Por favor, tente novamente.",
        createBookshelfCategory: {
            UNAUTHORIZED: "A sua sessão expirou.",
        },
        createMobileSession: {
            UNAUTHORIZED: "A sua sessão expirou.",
            FORBIDDEN: "O seu dispositivo não está registado. Verifique a área OS MEUS DISPOSITIVOS."
        },
        deleteBookFromUserBookshelf: {
            BAD_REQUEST: "Ocorreu um problema ao aceder ao eBook. Tente mais tarde, por favor."
        },
        deleteBookshelfCategory: {
            UNAUTHORIZED: "A sua sessão expirou.",
            BAD_REQUEST: "Ocorreu um problema ao aceder à estante. Tente mais tarde, por favor."
        },
        getBookMetadataFromMestArtId: {
            NO_CONTENT: "Ocorreu um problema ao aceder ao eBook. Tente mais tarde, por favor."
        },
        getBookshelfData: {
            FORBIDDEN: "A sua sessão expirou."
        },
        getPackageDetailsByReadItemId: {
            UNAUTHORIZED: "A sua sessão expirou.",
            FORBIDDEN: "O seu dispositivo não está registado. Verifique a área OS MEUS DISPOSITIVOS."
        },
        login: {
            UNAUTHORIZED: "Dados de autenticação inválidos. Por favor, corrija o seu e-mail e/ou password.",
            FORBIDDEN: "Foi atingido o número máximo de dispositivos permitidos."
        },
        moveBookToCategory: {
            UNAUTHORIZED: "A sua sessão expirou."
        },
        orderCategoryItems: {
            UNAUTHORIZED: "A sua sessão expirou."
        },
        recover: {
            NOT_FOUND: "Utilizador não existe."
        },
        validatebertrandcustomer: {
            CONFLICT: "Utilizador já existe."
        },
        regist: {
            NOT_ACCEPTABLE: "Inativo por falta de validação.",
            CONFLICT: "Utilizador já existe."
        },
        registerDevice: {
            UNAUTHORIZED: "A sua sessão expirou.",
            NO_CONTENT: "Dispositivo já se encontra associado.",
            FORBIDDEN: "O seu dispositivo não está registado. Verifique a área OS MEUS DISPOSITIVOS."
        },
        renameBookshelfCategory: {
            UNAUTHORIZED: "A sua sessão expirou."
        },
        socialLoginToken: {
            UNAUTHORIZED: "Credenciais inválidas.",
            NOT_FOUND: "Utilizador não se encontra registado."
        },
        assignBookPartToUser: {
            BAD_REQUEST: "Não foi possível abrir o excerto."
        }
    }
}

const language: Languages = {
    "pt-pt": {
        "app.form.cancel.button.text": "CANCELAR",
        "app.form.confirm.button.text": "CONFIRMAR",
        "app.loading.text": "a carregar...",
        "app.must.update.text": "Atualizar aplicação!",
        "app.page.not.found.text": "Área não encontrada!",
        "app.storage.no.space.title": "Armazenamento",
        "app.storage.no.space.text.1": "Sem espaço disponível para carregar o {0}.",
        "app.storage.no.space.text.2": "Por favor, remova da memória os eBooks e audiolivros que estejam disponíveis offline, ou verifique o espaço disponível no seu dispositivo.",
        "facebook.button.text": "FACEBOOK",
        "google.button.text": "GOOGLE",
        "help.title": "Ajuda biblioteca bertrand",
        "library.audiobooks.bookshelf.title": "AUDIOLIVROS",
        "library.bookshelf.ebook.sample.text": "EXCERTO",
        "library.bookshelf.ebook.offline.text": "DISPONÍVEL OFFLINE",
        "library.bookshelf.list.showcases.title": "Sugestões",
        "library.bookshelf.list.new.shelf": "criar estante",
        "library.bookshelf.list.title": "As minhas estantes",
        "library.bookshelf.menu.option.move": "Mover artigos para outra estante",
        "library.bookshelf.menu.option.ebooks.move": "Mover eBooks para outra estante",
        "library.bookshelf.menu.option.audiobooks.move": "Mover Audiolivros para outra estante",
        "library.bookshelf.menu.option.rename": "Editar nome da estante",
        "library.bookshelf.menu.option.delete": "Eliminar estante",
        "library.bookshelf.menu.option.order.author": "Ordenar artigos por autor (A-Z|Z-A)",
        "library.bookshelf.menu.option.order.manual": "Ordenar artigos manualmente",
        "library.bookshelf.menu.option.order.title": "Ordenar artigos por título (A-Z|Z-A)",
        "library.bookshelf.search.placeholder": "pesquisar por título ou autor",
        "library.create.bookshelf.form.placeholder": "nome da estante",
        "library.create.bookshelf.form.title": "Criar nova estante",
        "library.create.bookshelf.form.success.text": "A sua nova estante foi criada.",
        "library.delete.bookshelf.form.subtitle": "Confirma que pretende eliminar a estante {0}?",
        "library.delete.bookshelf.form.title": "Pretende eliminar esta estante?",
        "library.delete.bookshelf.form.success.text": "A sua estante foi eliminada",
        "library.ebooks.bookshelf.title": "EBOOKS",
        "library.header.title": "A MINHA BIBLIO",
        "library.rename.bookshelf.form.placeholder": "nome da estante",
        "library.rename.bookshelf.form.title": "Editar nome da estante",
        "library.rename.bookshelf.form.success.text": "O nome da sua estante foi alterado",
        "library.search.bookshelf.title": "{0} resultados para \"{1}\"",
        "library.sidebar.personal.link.text": "OS MEUS DADOS PESSOAIS",
        "library.sidebar.introduction.link.text": "COMO UTILIZAR A BIBLIO",
        "library.sidebar.library.link.text": "LIVRARIA ONLINE",
        "library.sidebar.devices.link.text": "OS MEUS DISPOSITIVOS",
        "library.sidebar.wishlist.link.text": "LISTA DE DESEJOS",
        "library.sidebar.conditions.link.text": "CONDIÇÕES DE VENDA",
        "library.sidebar.privacy.link.text": "POLÍTICA DE PRIVACIDADE",
        "library.sidebar.contact.link.text": "FALE CONNOSCO",
        "library.sidebar.help.link.text": "PERGUNTAS FREQUENTES",
        "library.sidebar.app.link.text": "APP BERTRAND 1732",
        "library.sidebar.logout.button.text": "TERMINAR SESSÃO",
        "library.sidebar.forget.link.text": "APAGAR CONTA",
        "library.sidebar.offline.state.text.1": "OFFLINE",
        "library.sidebar.online.state.text.1": "ONLINE",
        "library.sidebar.offline.state.text.2": "ligue à internet para atualizar",
        "library.sidebar.online.state.text.2": "atualizar as estantes",
        "library.showcase.reading.title": "ABERTOS RECENTEMENTE",
        "library.showcase.editor.title": "Novidades",
        "library.showcase.want.to.read.title": "Quero ler",
        "library.showcase.want.to.ear.title": "Quero ouvir",
        "library.ebooks.not.available.offline": "O artigo não está disponível para leitura offline.\nPor favor, verifique a ligação à internet para carregar o eBook no seu dispositivo.",
        "library.floatingmessage.shelf.move.finished": "Quando terminar, selecione a opção:",
        "password.progressbar.description": "Password ",
    },
};

export { language as default, errorMapping }