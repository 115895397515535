import React from 'react';

import { Store } from '../store';
import Config, { Log } from '../config';
import { Redirect } from '../components/GpeRouter';
import HelpWebPage from '../components/HelpWebPage';

function HelpScreen() {
    const { state } = React.useContext(Store);

    return React.useMemo(() => {
        Log.debug('screen: HELP');

        return state.user.auth
            ? <HelpWebPage />
            : <Redirect to={Config.routes.firstpage} />
    }, [state.user.auth]);
}

export { HelpScreen as default }