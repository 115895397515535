import React from 'react';
import { View, ViewStyle } from 'react-native';
import { Path, Svg } from 'react-native-svg';

function BertrandLogo(props: { style?: ViewStyle }) {

    return (
        <View style={[{ width: 60, height: 60 }, props.style]}>
            <Svg
                width="100%"
                height="100%"
                viewBox="0 0 40 40"
                x="0px"
                y="0px"
                preserveAspectRatio="">
                <Path
                    fill="#f05323"
                    d="M24.38,12.91A49,49,0,0,0,16,24.21l0,0a.53.53,0,0,1-.31.22.49.49,0,0,1-.42-.12A17.61,17.61,0,0,0,5.12,19.8a19.1,19.1,0,0,0-2-.07A19.84,19.84,0,0,0,0,20.07,50.62,50.62,0,0,1,16.54,38.36c2.23-4.65,4.71-9.61,7.43-14.48C28.66,15.46,34.05,7.33,40,1.64A55.51,55.51,0,0,0,24.38,12.91Z" />
            </Svg>
        </View>
    );
}

export { BertrandLogo as default }