import React from 'react';
import { Image } from 'react-native';
import { getAssetImageUri } from '../../../../middlewares/AppMiddleware';

import { generateRandom } from '../../../../middlewares/commons';
import { Store } from '../../../../store';

function Logo(props: { width: number }) {
    const { state } = React.useContext(Store);

    /** RENDER */
    return React.useMemo(() => {

        return (
            <Image
                style={{
                    height: '100%',
                    marginLeft: 20,
                    marginRight: 20,
                    resizeMode: 'contain',
                    width: props.width
                }}

                source={{ uri: getAssetImageUri(`logo.${generateRandom(13)}`, state.app.downloadedAssets) }} />
        );
    }, [props.width, state.app.downloadedAssets]);
}

export { Logo as default }