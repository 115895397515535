import React, { MutableRefObject } from 'react';
import {
    Animated,
    View,
    Image,
    PanResponder,
    GestureResponderEvent,
    PanResponderGestureState
} from 'react-native';

import Config from '../../../../config';
import { EbookInterface } from '../../../../interfaces/middlewares';
import { DispatchType } from '../../../../interfaces/store';
import { getAssetImageUri, getTranslation } from '../../../../middlewares/AppMiddleware';
import { coverFadeOut, coverResetPosition } from '../../../../middlewares/commons';
import {
    changeBookCategory,
    changeBookOrder,
    checkCoverZone,
    checkShelfZone,
    getBookshelf,
    getCoverImageUri,
    openReadItemId,
    redownloadCover,
    removeDownloadedCover,
    setCoverZone,
    toggleContentScroll,
    toggleCoverDraggingIcon,
    toggleCoverHighlight,
    toggleShelfHighlight,
    toggleShelfsListHover
} from '../../../../middlewares/LibraryMiddleware';
import { Store } from '../../../../store';

export function AnimatedCoverInit(
    props: {
        ebook: EbookInterface,
        height?: number,
        width?: number,
        index?: number,
        shelfId?: number | string,
    }
) {
    /** STORE */
    const { state, dispatch } = React.useContext(Store);

    /** LOCAL STATE */
    const [coverState, setCoverState] = React.useReducer((
        _state: {
            show: Boolean,
            pan: any,
            opacity: any,
            zIndex: number,
            dragging: Boolean,
        },
        _payload: {
            show?: Boolean,
            pan?: any,
            opacity?: any,
            zIndex?: number,
            dragging?: Boolean,
        },
    ) => ({ ..._state, ..._payload }), {
        show: true,
        pan: React.useRef(new Animated.ValueXY()).current,
        opacity: new Animated.Value(1),
        zIndex: 0,
        dragging: false,
    });

    const [coverSizeState, setCoverSizeState] = React.useReducer((
        _state: {
            height: number | undefined,
            width: number | undefined,
            propsHeight: number | undefined,
            propsWidth: number | undefined,
        },
        _payload: {
            height?: number,
            width?: number,
            propsHeight?: number,
            propsWidth?: number,
        },
    ) => ({ ..._state, ..._payload }), {
        height: undefined,
        width: undefined,
        propsHeight: undefined,
        propsWidth: undefined,
    });

    const [coverHoveringState, setCoverHoveringState] = React.useState(false);

    const [coverDragIconState, setCoverDragIconState] = React.useState(true);

    /** PROPS */
    const dispatchRef: MutableRefObject<DispatchType> = React.useRef(dispatch);

    const mountedRef: MutableRefObject<boolean | undefined> = React.useRef();

    const coverRef: MutableRefObject<View | null> = React.useRef(null);

    const draggingMode = React.useMemo(() => (
        state.library.moveToShelf || state.library.manualSorting
    ), [state.library.moveToShelf, state.library.manualSorting]);

    const translation = React.useMemo(() => getTranslation(state.app.language), [state.app.language]);

    const downloadedVersion = React.useMemo(() => (
        state.library.downloadedVersions[props.ebook.gpeId]
            && state.library.downloadedVersions[props.ebook.gpeId] === Config.ebook.downloadFileName
            ? state.library.downloadedVersions[props.ebook.gpeId]
            : undefined
    ), [
        state.library.downloadedVersions,
        props.ebook.gpeId
    ]);

    const coverUri = React.useMemo(() => getCoverImageUri(
        props.ebook,
        state.app.storageDir,
        state.library.downloadedCovers,
    ), [
        props.ebook,
        state.app.storageDir,
        state.library.downloadedCovers,
    ]);

    const imageSourceUri = React.useMemo(() => ({
        uri: coverUri
            ? coverUri
            : getAssetImageUri('cover.default', state.app.downloadedAssets)
    }), [
        coverUri,
        state.app.downloadedAssets,
    ]);

    const getBookshelfCallback = React.useCallback(() => (
        state.app.uid
        && state.app.storageDir
        && state.user.authToken
        && state.user.auth
        && getBookshelf(
            dispatchRef.current,
            state.app.uid,
            state.app.storageDir,
            state.user.authToken,
            state.user.auth,
            state.library.showcases,
            state.library.downloadedCovers,
            translation
        )
    ), [
        state.app.uid,
        state.app.storageDir,
        state.user.authToken,
        state.user.auth,
        state.library.showcases,
        state.library.downloadedCovers,
        translation,
    ]);

    const setState = React.useCallback((_payload: {
        show?: Boolean,
        pan?: any,
        opacity?: any,
        zIndex?: number,
        dragging?: Boolean,
    }) => (
        mountedRef.current
        && setCoverState(_payload)
    ), []);

    const panResponder = React.useMemo(() => PanResponder.create({
        onStartShouldSetPanResponder: () => draggingMode,
        onPanResponderGrant: (evt: GestureResponderEvent, gestureState: PanResponderGestureState) => {
            toggleContentScroll(false);
            toggleShelfsListHover(true);
            toggleCoverDraggingIcon(false, String(props.shelfId), String(props.ebook.gpeId));
        },
        onPanResponderStart: () => setState({
            zIndex: 200,
            dragging: true,
        }),
        onPanResponderMove: (evt: GestureResponderEvent, gestureState: PanResponderGestureState) => {
            state.library.moveToShelf
                && toggleShelfHighlight(
                    gestureState.moveX,
                    gestureState.moveY,
                    [
                        String(props.shelfId),
                        translation('library.audiobooks.bookshelf.title'),
                        translation('library.ebooks.bookshelf.title'),
                    ],
                );
            state.library.manualSorting
                && toggleCoverHighlight(
                    gestureState.moveX,
                    gestureState.moveY,
                    String(props.shelfId),
                    [String(props.ebook.gpeId)],
                );
            return Animated.event([
                null,
                {
                    dx: coverState.pan.x,
                    dy: coverState.pan.y,
                }],
                { useNativeDriver: false })(evt, gestureState);
        },
        onPanResponderEnd: () => {
            toggleContentScroll(true);
            toggleShelfsListHover(false);
            toggleCoverDraggingIcon(true, String(props.shelfId), String(props.ebook.gpeId));
        },
        onPanResponderRelease: (evt: GestureResponderEvent, gestureState: PanResponderGestureState) => {
            state.library.moveToShelf
                && checkShelfZone(
                    gestureState.moveX,
                    gestureState.moveY,
                    [
                        String(props.shelfId),
                        translation('library.audiobooks.bookshelf.title'),
                        translation('library.ebooks.bookshelf.title'),
                    ],
                    (shelfId: string | null) => {
                        if (!shelfId
                            || !props.ebook.bookGuid)
                            return coverResetPosition(setState, coverState);
                        changeBookCategory(
                            dispatchRef.current,
                            state.user.authToken,
                            state.user.auth,
                            props.ebook.bookGuid,
                            shelfId,
                            (response) => {
                                if (response.error)
                                    return coverResetPosition(setState, coverState);
                                coverFadeOut(setState, coverState);
                                getBookshelfCallback();
                            }
                        );
                    }
                );
            state.library.manualSorting
                && checkCoverZone(
                    gestureState.moveX,
                    gestureState.moveY,
                    String(props.shelfId),
                    [String(props.ebook.gpeId)],
                    (coverId: string | null) => {
                        if (!coverId)
                            return coverResetPosition(setState, coverState);
                        changeBookOrder(
                            dispatchRef.current,
                            coverId,
                            props.index,
                            props.shelfId,
                            state.library.bookshelf,
                        );
                    }
                );
        },
        onShouldBlockNativeResponder: () => false,
        onPanResponderTerminationRequest: () => true,
    }), [
        coverState,
        draggingMode,
        getBookshelfCallback,
        props.ebook.bookGuid,
        props.ebook.gpeId,
        props.index,
        props.shelfId,
        setState,
        state.library.bookshelf,
        state.library.manualSorting,
        state.library.moveToShelf,
        state.user.auth,
        state.user.authToken,
        translation,
    ]);

    const openReadItemIdCallback = React.useCallback(() => (
        state.app.storageDir
        && state.app.uid
        && state.user.authToken
        && state.user.auth
        && openReadItemId(
            dispatchRef.current,
            state.app.storageDir,
            state.app.uid,
            state.user.authToken,
            state.user.auth,
            state.user.kitabooToken,
            state.library.readingStarted,
            props.ebook,
            translation,
        )
    ), [
        translation,
        state.app.storageDir,
        state.app.uid,
        state.user.authToken,
        state.user.auth,
        state.user.kitabooToken,
        state.library.readingStarted,
        props.ebook,
    ]);

    const setHoveringState = React.useCallback((_bool: boolean) => (
        mountedRef.current
        && setCoverHoveringState(_bool)
    ), []);

    const setDragIconState = React.useCallback((_bool: boolean) => (
        mountedRef.current
        && setCoverDragIconState(_bool)
    ), []);

    const imageSize = React.useCallback((
        propsHeight: number | undefined,
        propsWidth: number | undefined,
    ) => {
        imageSourceUri
            && imageSourceUri.uri
            && Image.getSize(
                imageSourceUri.uri,
                (width, height) => {
                    let _height = propsHeight;
                    let _width = propsWidth;

                    if (propsWidth && !propsHeight) {
                        _height = height * (propsWidth / width);
                    } else if (!propsWidth && propsHeight) {
                        _width = width * (propsHeight / height);
                    }

                    if (_height !== coverSizeState.height
                        || _width !== coverSizeState.width) {
                        mountedRef.current
                            && setCoverSizeState({
                                height: _height,
                                width: _width,
                            });
                    }
                },
                () => removeDownloadedCover(dispatchRef.current, props.ebook, state.app.storageDir)
            );
        mountedRef.current
            && setCoverSizeState({
                propsHeight: propsHeight,
                propsWidth: propsWidth,
            });
    }, [
        props.ebook,
        state.app.storageDir,
        imageSourceUri,
        coverSizeState.height,
        coverSizeState.width,
    ]);

    const imageOnErrorCallback = React.useCallback(() => {
        state.app.storageDir
            && redownloadCover(
                dispatchRef.current,
                state.app.storageDir,
                props.ebook,
            )
    }, [
        props.ebook,
        state.app.storageDir
    ]);

    const onLayoutCallback = React.useCallback(() => setCoverZone(
        setHoveringState,
        setDragIconState,
        coverRef.current,
        String(props.shelfId),
        String(props.ebook.gpeId),
    ), [
        props.ebook.gpeId,
        props.shelfId,
        setDragIconState,
        setHoveringState
    ]);

    React.useEffect(() => {
        if (props.height !== coverSizeState.propsHeight
            || props.width !== coverSizeState.propsWidth) {
            imageSize(props.height, props.width);
        }
    }, [
        props.height,
        props.width,
        coverSizeState.propsHeight,
        coverSizeState.propsWidth,
        imageSize,
    ]);

    React.useEffect(() => {
        mountedRef.current = true;

        return () => {
            mountedRef.current = false;
        }
    }, []);

    return React.useMemo(() => ({
        coverState,
        draggingMode,
        translation,
        coverRef,
        dispatchRef,
        coverSizeState,
        openReadItemIdCallback,
        coverHoveringState,
        downloadedVersion,
        coverDragIconState,
        panResponder,
        imageOnErrorCallback,
        imageSourceUri,
        onLayoutCallback,
    }), [
        coverState,
        draggingMode,
        translation,
        coverRef,
        dispatchRef,
        coverSizeState,
        openReadItemIdCallback,
        coverHoveringState,
        downloadedVersion,
        coverDragIconState,
        panResponder,
        imageOnErrorCallback,
        imageSourceUri,
        onLayoutCallback,
    ]);
}