import { ActionInterface, StateUserInterface } from '../../interfaces/store';
import { setStorage } from '../storage';

const user: StateUserInterface = {
    auth: null,
    authToken: null,
    authTokenError: '',
    loginError: '',
    registError: '',
    recoverError: '',
    keepLoggedIn: false,
    keepLoggedInTimeout: null,
    kitabooToken: null,
    kitabooTokenError: '',
    loginByToken: false,
    firstOnBoard: {},
    secondOnBoard: {},
    firstOnBoardDate: {},
    assignPromoBooks: {},
    assignPromoBooksInCampaign: {},
};

const reducer = (
    _user: StateUserInterface = user,
    _dispatch: ActionInterface
) => {
    switch (_dispatch.type) {
        case 'USER':
            return { ..._user, ..._dispatch.payload };
        case 'USER-AUTH':
            setStorage(_dispatch);
            return {
                ..._user,
                auth: _dispatch.payload.auth,
                keepLoggedIn: _dispatch.payload.keepLoggedIn,
                keepLoggedInTimeout: _dispatch.payload.keepLoggedInTimeout,
            };
        case 'USER-AUTH_TOKEN':
            setStorage(_dispatch);
            return { ..._user, authToken: _dispatch.payload };
        case 'USER-AUTH_TOKEN_ERROR':
            return { ..._user, authTokenError: _dispatch.payload };
        case 'USER-LOGIN_ERROR':
            return { ..._user, loginError: _dispatch.payload };
        case 'USER-REGIST_ERROR':
            return { ..._user, registError: _dispatch.payload };
        case 'USER-RECOVER_ERROR':
            return { ..._user, recoverError: _dispatch.payload };
        case 'USER-KITABOO_TOKEN':
            setStorage(_dispatch);
            return { ..._user, kitabooToken: _dispatch.payload };
        case 'USER-KITABOO_TOKEN_ERROR':
            return { ..._user, kitabooTokenError: _dispatch.payload };
        case 'USER-LOGIN_BY_TOKEN':
            return { ..._user, loginByToken: _dispatch.payload };
        case 'USER-FIRST_ON_BOARD':
            const _firstOnBoard = { ..._user.firstOnBoard, ..._dispatch.payload };
            setStorage({ type: _dispatch.type, payload: _firstOnBoard });
            return { ..._user, firstOnBoard: _firstOnBoard };
        case 'USER-SECOND_ON_BOARD':
            const _secondOnBoard = { ..._user.secondOnBoard, ..._dispatch.payload };
            setStorage({ type: _dispatch.type, payload: _secondOnBoard });
            return { ..._user, secondOnBoard: _secondOnBoard };
        case 'USER-FIRST_ON_BOARD_DATE':
            const _firstOnBoardDate = { ..._user.firstOnBoardDate, ..._dispatch.payload };
            setStorage({ type: _dispatch.type, payload: _firstOnBoardDate });
            return { ..._user, firstOnBoardDate: _firstOnBoardDate };
        case 'USER-ASSIGN_PROMO_BOOKS':
            return { ..._user, assignPromoBooks: { ..._user.assignPromoBooks, ..._dispatch.payload } };
        case 'USER-ASSIGN_PROMO_BOOKS_IN_CAMPAIGN':
            return { ..._user, assignPromoBooksInCampaign: { ..._user.assignPromoBooksInCampaign, ..._dispatch.payload } };
    }
    return _user;
}

export { reducer as default, user };