import React from 'react';

import Config, { Log } from '../config';
import { Redirect } from '../components/GpeRouter';
import GpeText from '../components/GpeText';

function PrePrintScreen() {

    let epub: string | null = '';
    let url: string = '';
    if (Config.platform === 'web') {
        const urlParams = new URLSearchParams(window.location.search);
        epub = urlParams.get('epub');
        url = Config.params.preprint.url + (epub ? decodeURIComponent(epub) : "");
    }

    return React.useMemo(() => {
        Log.debug('screen: PREPRINT');

        return !epub
            ? <Redirect to={Config.routes.firstpage} />
            : <GpeText>Vou para o preprint com o url: {url}</GpeText>;
    }, [epub, url]);

}

export { PrePrintScreen as default };