import Config from '../../../../config';
import { AssetsInterface } from '../../../../interfaces/components';

const bookshelfDir = Config.platform === 'web'
    ? process.env.PUBLIC_URL
    : Config.contextParams.bookshelfUrl;

const Assets: AssetsInterface = {
    version: '1.0.1',
    keys: {
        logo: {
            0: `${bookshelfDir}/assets/images/logotipo/logotipo_1.png`,
            1: `${bookshelfDir}/assets/images/logotipo/logotipo_2.png`,
            2: `${bookshelfDir}/assets/images/logotipo/logotipo_3.png`,
            3: `${bookshelfDir}/assets/images/logotipo/logotipo_4.png`,
            4: `${bookshelfDir}/assets/images/logotipo/logotipo_5.png`,
            5: `${bookshelfDir}/assets/images/logotipo/logotipo_6.png`,
            6: `${bookshelfDir}/assets/images/logotipo/logotipo_7.png`,
            7: `${bookshelfDir}/assets/images/logotipo/logotipo_8.png`,
            8: `${bookshelfDir}/assets/images/logotipo/logotipo_9.png`,
            9: `${bookshelfDir}/assets/images/logotipo/logotipo_10.png`,
            10: `${bookshelfDir}/assets/images/logotipo/logotipo_11.png`,
            11: `${bookshelfDir}/assets/images/logotipo/logotipo_12.png`,
            12: `${bookshelfDir}/assets/images/logotipo/logotipo_13.png`,
        },
        onboard: {
            slideEbook0: `${bookshelfDir}/assets/images/onboard_v2/Onboarding_01_lerEbook_DEV_1080px.gif`,
            slideEbook1: `${bookshelfDir}/assets/images/onboard_v2/Onboarding_01_offline_DEV_1080px.gif`,
            slideEbook2: `${bookshelfDir}/assets/images/onboard_v2/Onboarding_01_moverEstante_DEV_1080px.gif`,
            slideEbook3: `${bookshelfDir}/assets/images/onboard_v2/Onboarding_01_assistenteLeitura_DEV_1080px.gif`,
            slideAudio0: `${bookshelfDir}/assets/images/onboard_v2/Onboarding_02_audiolivro_DEV_1080px.gif`,
            slideAudio1: `${bookshelfDir}/assets/images/onboard_v2/Onboarding_02_velocidadeLeitura_DEV_1080px.gif`,
            slideAudio2: `${bookshelfDir}/assets/images/onboard_v2/Onboarding_02_marcadores_DEV_1080px.gif`,
            slideAudio3: `${bookshelfDir}/assets/images/onboard_v2/Onboarding_02_auriculares_DEV_1080px.gif`,
        },
        cover: {
            default: `${bookshelfDir}/assets/images/default_cover.png`,
        },
    }
};

export { Assets as default }