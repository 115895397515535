import React, { MutableRefObject } from 'react';

import { Store } from '../store';
import { openSample } from '../middlewares/LibraryMiddleware';
import { useLocation } from './GpeRouter';
import { openPopup } from '../middlewares/commons';
import Config from '../config';
import { DispatchType } from '../interfaces/store';

// http://192.168.1.88:3000/sample?mesArtId=16248364

function Sample() {
    const { dispatch } = React.useContext(Store);

    const dispatchRef: MutableRefObject<DispatchType> = React.useRef(dispatch);

    const location = useLocation();

    const { bookGuid, ru, bu } = React.useMemo(() => {
        let _bookGuid,
            _ru,
            _bu;

        if (Config.platform === 'web') {
            const searchParams = new URLSearchParams(location.search);
            _bookGuid = searchParams.get('bookGuid');
            _ru = searchParams.get('ru');
            _bu = searchParams.get('bu');
        }

        return {
            bookGuid: _bookGuid
                ? _bookGuid
                : undefined,
            ru: _ru
                ? decodeURI(_ru)
                : undefined,
            bu: _bu
                ? decodeURI(_bu)
                : undefined,
        };
    }, [location.search]);

    /** STATE */
    /** LOCAL STATE */
    const [sampleState, setSampleState] = React.useState<{
        errorBookMetadata?: string,
    }>({
        errorBookMetadata: undefined,
    });

    React.useEffect(() => {
        !sampleState.errorBookMetadata
            && bookGuid
            && openSample(
                dispatchRef.current,
                {
                    sample: true,
                    bookGuid: bookGuid,
                    ru: ru,
                    bu: bu,
                    gpeId: 0,
                },
                {
                    error: (error: string) => {
                        setSampleState({ ...sampleState, errorBookMetadata: error });
                        openPopup(dispatchRef.current, error);
                    }
                }
            );
    }, [
        bookGuid,
        ru,
        bu,
        sampleState
    ]);

    /** RENDER */
    return React.useMemo(() => null, []);
}

export { Sample as default }