import React, { MutableRefObject } from 'react';
import { View, Dimensions, TouchableOpacity, DimensionValue } from 'react-native';

import Config, { Log } from '../../../config';
import { Store } from '../../../store';
import { LibraryStyles, dimensionWidth_Boolean, dimensionWidth } from '../../../styles';
import { getTranslation } from '../../../middlewares/AppMiddleware';
import { clearSelectBookshelf, setShelfsListDispatch } from '../../../middlewares/LibraryMiddleware';
import Shelf from './Shelf';
import ShelfCreate from './ShelfCreate';
import DimensionsEventTimeout from '../../DimensionsEvent';
import { CategoryInterface } from '../../../interfaces/middlewares';
import GpeScrollView from '../../GpeScrollView';
import GpeText from '../../GpeText';
import { useHistory, useLocation } from '../../GpeRouter';
import Hoverable from '../../Hoverable';
import { DispatchType } from '../../../interfaces/store';
import theme from '../../../styles/theme';

function ShelfsList() {
    /** STORE */
    const { state, dispatch } = React.useContext(Store);

    /** PROPS */
    const dispatchRef: MutableRefObject<DispatchType> = React.useRef(dispatch);
    const location = useLocation();
    const history = useHistory();
    const translation = React.useMemo(() => getTranslation(state.app.language), [state.app.language]);
    const _bookshelfSelected = React.useMemo(() => state.library.bookshelfSelected, [state.library.bookshelfSelected]);
    const _shelfs = React.useMemo(() => (
        state.library.bookshelf
            && state.library.bookshelf.shelfs
            ? state.library.bookshelf.shelfs
            : []
    ), [state.library.bookshelf]);

    /** MEDIAS */
    const containerMedia = {
        xs: '35%',
        md: '25%',
    };
    const showShelfsListMedia: { xs: 'true' | 'false', md: 'true' | 'false' } = {
        xs: 'false',
        md: 'true',
    };

    /** LOCAL STATE */
    const [shelfsListState, shelfsListDispatch] = React.useReducer((
        _state: {
            hoverEnabled: boolean,
            showShelfsList: boolean,
            marginTop: number,
            containerStyle: DimensionValue,
        },
        _payload: {
            hoverEnabled?: boolean,
            showShelfsList?: boolean,
            marginTop?: number,
            containerStyle?: DimensionValue,
        }
    ) => ({ ..._state, ..._payload }), {
        hoverEnabled: false,
        showShelfsList: dimensionWidth_Boolean(showShelfsListMedia),
        marginTop: Dimensions.get('window').height,
        containerStyle: dimensionWidth(containerMedia),
    });

    /** atualiza o state conforme a dimensao do ecra */
    DimensionsEventTimeout(() => {
        const _containerStyle = dimensionWidth(containerMedia);
        const _showShelfsList = dimensionWidth_Boolean(showShelfsListMedia);
        const _marginTop = Dimensions.get('window').height;
        if (_containerStyle !== shelfsListState.containerStyle
            || _showShelfsList !== shelfsListState.showShelfsList
            || _marginTop !== shelfsListState.marginTop) {
            shelfsListDispatch({
                containerStyle: _containerStyle,
                showShelfsList: _showShelfsList,
                marginTop: _marginTop,
            });
        }
    });

    React.useEffect(() => {
        setShelfsListDispatch(shelfsListDispatch);

        return () => {
            setShelfsListDispatch(undefined);
        }
    }, []);

    /** RENDER */
    return React.useMemo(() => {
        Log.debug('component: ShelfsList');

        const _audiobooksTitle = translation('library.audiobooks.bookshelf.title');
        const _ebooksTitle = translation('library.ebooks.bookshelf.title');

        return (
            shelfsListState.showShelfsList
                || state.library.moveToShelf
                ? (
                    <View
                        key='shelfList'
                        style={[
                            LibraryStyles.leftContainer,
                            {
                                marginTop: shelfsListState.marginTop,
                                zIndex: Config.platform !== ('ios' && 'android')
                                    && shelfsListState.hoverEnabled
                                    ? -1
                                    : undefined
                            }
                        ]}>
                        <View
                            style={{
                                width: '100%',
                                height: '100%',
                                maxWidth: 1200,
                                margin: 'auto',
                            }}>
                            <View
                                style={[
                                    {
                                        width: shelfsListState.containerStyle,
                                        height: '100%',
                                        marginTop: -shelfsListState.marginTop,
                                        zIndex: shelfsListState.hoverEnabled ? -1 : undefined,
                                    },
                                ]}>
                                {/* <GpePerfectScrollbar> */}
                                <GpeScrollView
                                    // showsVerticalScrollIndicator={Platform.OS !== 'web'}
                                    contentContainerStyle={LibraryStyles.shelfsListContainer}
                                    key='shelfs_list'>
                                    <GpeText
                                        style={[
                                            LibraryStyles.h3,
                                            LibraryStyles.themeColor,
                                        ]}>
                                        {translation('library.bookshelf.list.title')}</GpeText>

                                    {!state.library.manualSorting
                                        ? <ShelfCreate />
                                        : null}

                                    <Hoverable>
                                        {(hover: boolean) => (
                                            <TouchableOpacity
                                                onPress={() => {
                                                    clearSelectBookshelf(dispatchRef.current);
                                                    history.push(Config.routes.bookshelf);
                                                }}>
                                                <GpeText
                                                    style={[
                                                        LibraryStyles.shelf,
                                                        { fontFamily: theme.font.semibold },
                                                        (location.pathname.indexOf(Config.routes.bookshelf) > -1
                                                            && !_bookshelfSelected
                                                            && LibraryStyles.themeColor),
                                                        hover && LibraryStyles.themeColor,
                                                    ]}>
                                                    {translation('library.bookshelf.list.showcases.title')}</GpeText>
                                            </TouchableOpacity>
                                        )}
                                    </Hoverable>

                                    {_shelfs
                                        && _shelfs.length
                                        ? _shelfs.map(
                                            (shelf: CategoryInterface, index: number) =>
                                                !state.library.manualSorting
                                                    || (shelf.id !== _audiobooksTitle
                                                        && shelf.id !== _ebooksTitle)
                                                    ? (
                                                        <Shelf
                                                            key={index}
                                                            shelf={shelf}
                                                            selected={
                                                                Boolean(
                                                                    _bookshelfSelected
                                                                    && _bookshelfSelected.id === shelf.id
                                                                )}
                                                        />
                                                    ) : null
                                        ) : null}
                                </GpeScrollView>
                                {/* </GpePerfectScrollbar> */}
                            </View>
                        </View>
                    </View>
                ) : null
        );
    }, [
        translation,
        location,
        history,
        _shelfs,
        _bookshelfSelected,
        shelfsListState,
        state.library.moveToShelf,
        state.library.manualSorting,
    ]);
}

export { ShelfsList as default }