import React, { MutableRefObject } from 'react';
import { DimensionValue, View } from 'react-native';

import Config, { Log } from '../../../config';
import { Store } from '../../../store';
import { LibraryStyles, dimensionWidth_Boolean, dimensionWidth, dimensionColumns_Value } from '../../../styles';
import { getTranslation } from '../../../middlewares/AppMiddleware';
import { searchFilterBookshelf, setContentDispatch } from '../../../middlewares/LibraryMiddleware';
import DimensionsEventTimeout from '../../DimensionsEvent';
import AnimatedCover from '../../Bookshelf/components/AnimatedCover';
import ShelfOptions from '../../Bookshelf/components/ShelfOptions';
import SearchBar from '../../Search/components/SearchBar';
import { ShelfsListDropdown } from '../../Bookshelf/components/ShelfsListDropdown';
import { useLocation } from '../../GpeRouter';
import Base64 from '../../../middlewares/commons/Base64';
import GpeScrollView from '../../GpeScrollView';
import GpeText from '../../GpeText';
import { EbookInterface } from '../../../interfaces/middlewares';

function SearchList() {
    /** STORE */
    const { state } = React.useContext(Store);

    /** PROPS */
    const shelfEbookRef: MutableRefObject<View | null> = React.useRef(null);

    const draggingMode = React.useMemo(() => (
        state.library.moveToShelf || state.library.manualSorting
    ), [
        state.library.moveToShelf,
        state.library.manualSorting
    ]);

    const translation = React.useMemo(() => getTranslation(state.app.language), [state.app.language]);

    const location = useLocation();

    const searchString = React.useMemo(() => location.pathname.indexOf(Config.routes.search) > -1
        ? Base64.atob(location.pathname.split(`${Config.routes.search}/`)[1])
        : '', [location]);

    const { _ebooksSlice } = React.useMemo(() => searchFilterBookshelf(
        state.library.bookshelf
            ? state.library.bookshelf.shelfs
            : [],
        state.library.showcases
            ? state.library.showcases
            : [],
        searchString,
    ), [
        state.library.bookshelf,
        state.library.showcases,
        searchString,
    ]);

    /** MEDIAS */
    const containerMoveMedia = {
        xs: '65%',
        md: '75%',
    };
    const containerMedia = {
        xs: '100%',
        md: '75%',
    };
    const showTitleMedia: { xs: 'true' | 'false', md: 'true' | 'false' } = {
        xs: 'false',
        md: 'true',
    };
    const showShelfsDropdownMedia: { xs: 'true' | 'false', md: 'true' | 'false' } = {
        xs: 'true',
        md: 'false',
    };
    const coverMedia = React.useMemo(() => ({ xs: 2, sm: 3, lg: 5 }), []);
    const coverDragMedia = React.useMemo(() => ({ xs: 1, sm: 2, md: 3, lg: 5 }), []);

    /** LOCAL STATE */
    const [searchListState, searchListDispatch] = React.useReducer((
        _state: {
            showTitle: boolean,
            containerStyle: DimensionValue,
            showShelfsDropdown: boolean,
            scrollEnabled: boolean,
            coverWidth: number | undefined,
            coverDragWidth: number | undefined,
            containerMoveStyle: DimensionValue,
        },
        _payload: {
            showTitle?: boolean,
            containerStyle?: DimensionValue,
            showShelfsDropdown?: boolean,
            scrollEnabled?: boolean,
            coverWidth?: number,
            coverDragWidth?: number,
            containerMoveStyle?: DimensionValue,
        }
    ) => ({ ..._state, ..._payload }), {
        showTitle: dimensionWidth_Boolean(showTitleMedia),
        containerStyle: dimensionWidth(containerMedia),
        showShelfsDropdown: dimensionWidth_Boolean(showShelfsDropdownMedia),
        scrollEnabled: true,
        coverWidth: undefined,
        coverDragWidth: undefined,
        containerMoveStyle: dimensionWidth(containerMoveMedia),
    });

    const componentMeasure = React.useCallback(() => shelfEbookRef.current?.measure(
        (x: number, y: number, width: number) => {
            const _width = Math.trunc(dimensionColumns_Value(coverMedia, width - 30) - 30);
            const _widthDrag = Math.trunc(dimensionColumns_Value(coverDragMedia, width - 30) - 30);

            if (_width !== searchListState.coverWidth
                || _widthDrag !== searchListState.coverDragWidth) {
                searchListDispatch({
                    coverWidth: _width,
                    coverDragWidth: _widthDrag,
                });
            }
        }
    ), [
        coverMedia,
        coverDragMedia,
        searchListState.coverWidth,
        searchListState.coverDragWidth,
    ]);

    /** RESIZE LISTENER */
    DimensionsEventTimeout(() => {
        componentMeasure();
        const _containerMoveStyle = dimensionWidth(containerMoveMedia);
        const _containerStyle = dimensionWidth(containerMedia);
        const _showTitle = dimensionWidth_Boolean(showTitleMedia);
        const _showShelfsDropdown = dimensionWidth_Boolean(showShelfsDropdownMedia);
        if (_containerMoveStyle !== searchListState.containerMoveStyle
            || _containerStyle !== searchListState.containerStyle
            || _showTitle !== searchListState.showTitle
            || _showShelfsDropdown !== searchListState.showShelfsDropdown) {
            searchListDispatch({
                showTitle: _showTitle,
                containerStyle: _containerStyle,
                showShelfsDropdown: _showShelfsDropdown,
                containerMoveStyle: _containerMoveStyle,
            });
        }
    });

    React.useEffect(() => {
        setContentDispatch(searchListDispatch);

        return () => {
            setContentDispatch(undefined);
        }
    }, []);

    /** RENDER */
    return React.useMemo(() => {
        Log.debug('component: SearchList');

        return (
            <View
                style={LibraryStyles.rightContainer}>
                {/* <GpePerfectScrollbar> */}
                <GpeScrollView
                    key='ebooks_list'
                    scrollEnabled={searchListState.scrollEnabled}
                    style={{ minHeight: '100%', width: '100%', }}
                    contentContainerStyle={[
                        LibraryStyles.contentContainer,
                        {
                            width: '100%',
                            maxWidth: 1200,
                            margin: 'auto',
                            alignItems: 'flex-end',
                        },
                    ]}>
                    <View
                        ref={(ref) => shelfEbookRef.current = ref}
                        onLayout={() => (!searchListState.coverWidth
                            || !searchListState.coverDragWidth)
                            && componentMeasure()}
                        style={{
                            width: state.library.moveToShelf
                                ? searchListState.containerMoveStyle
                                : searchListState.containerStyle,
                        }}>

                        {!draggingMode
                            ? (
                                <View
                                    style={[
                                        LibraryStyles.bookshelfHeader,
                                        state.library.moveToShelf ? { zIndex: -1 } : null,
                                    ]}>

                                    {/** barra de pesquisa */}
                                    <SearchBar />

                                    {/** titulo da pesquisa */}
                                    {searchListState.showTitle
                                        && searchString
                                        ? (
                                            <GpeText
                                                style={[
                                                    LibraryStyles.h1,
                                                    {
                                                        paddingRight: 60,
                                                        paddingBottom: 13,
                                                        paddingLeft: undefined
                                                    },
                                                    Config.contextName === 'bertrand'
                                                        ? { fontSize: 26 }
                                                        : null
                                                ]}>
                                                {translation(
                                                    'library.search.bookshelf.title',
                                                    [String(_ebooksSlice.length), searchString]
                                                )}
                                            </GpeText>
                                        ) : null}

                                    {searchListState.showShelfsDropdown
                                        ? <ShelfsListDropdown />
                                        : null}

                                    {_ebooksSlice
                                        ? <ShelfOptions ebooksList={_ebooksSlice} />
                                        : null}
                                </View>
                            ) : null}

                        <View style={[LibraryStyles.row, { paddingLeft: 30 }]}>
                            {!searchListState.showTitle
                                && !draggingMode
                                && searchString
                                ? (
                                    <GpeText
                                        style={[
                                            LibraryStyles.h3,
                                            {
                                                width: '100%',
                                                paddingTop: 13,
                                                paddingRight: 30,
                                                paddingBottom: 13,
                                                paddingLeft: 0,
                                            },
                                            Config.contextName === 'bertrand'
                                                ? { fontSize: 14 }
                                                : null
                                        ]}>
                                        {translation(
                                            'library.search.bookshelf.title',
                                            [String(_ebooksSlice.length), searchString]
                                        )}
                                    </GpeText>
                                ) : null}

                            {_ebooksSlice
                                && _ebooksSlice.length
                                ? _ebooksSlice.map((value: EbookInterface, index: number) =>
                                    <AnimatedCover
                                        key={value.subArtid + '_' + index}
                                        ebook={value}
                                        width={state.library.moveToShelf
                                            ? searchListState.coverDragWidth
                                            : searchListState.coverWidth} />)
                                : null}
                        </View>
                    </View>

                </GpeScrollView>
                {/* </GpePerfectScrollbar> */}
            </View>
        );
    }, [
        translation,
        searchListState,
        _ebooksSlice,
        searchString,
        draggingMode,
        state.library.moveToShelf,
        componentMeasure,
    ]);
}

export { SearchList as default }