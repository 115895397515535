import { ActionInterface, StateAppInterface } from '../../interfaces/store';
import { setStorage } from '../storage';

const app: StateAppInterface = {
    manager: null,
    uid: null,
    fetching: false,
    percentage: null,
    storageLoaded: false,
    popup: null,
    language: null,
    uidRegistered: null,
    uidRegisteredError: '',
    analyticsLoaded: false,
    storageDir: null,
    assetsVersion: '',
    downloadedAssets: {},
};

const reducer = (
    _app: StateAppInterface = app,
    _dispatch: ActionInterface
) => {
    switch (_dispatch.type) {
        case 'APP':
            return { ..._app, ..._dispatch.payload };
        case 'APP-MANAGER':
            return { ..._app, manager: _dispatch.payload };
        case 'APP-UID':
            setStorage(_dispatch);
            return { ..._app, uid: _dispatch.payload };
        case 'APP-FETCHING':
            return { ..._app, fetching: _dispatch.payload };
        case 'APP-PERCENTAGE':
            return { ..._app, percentage: _dispatch.payload };
        case 'APP-STORAGE_LOADED':
            return { ..._app, storageLoaded: _dispatch.payload };
        case 'APP-ANALYTICS_LOADED':
            return { ..._app, analyticsLoaded: _dispatch.payload };
        case 'APP-POPUP':
            return { ..._app, popup: _dispatch.payload };
        case 'APP-LANGUAGE':
            setStorage(_dispatch);
            return { ..._app, language: _dispatch.payload };
        case 'APP-UID_REGISTERED':
            setStorage(_dispatch);
            return { ..._app, uidRegistered: _dispatch.payload };
        case 'APP-UID_REGISTERED_ERROR':
            return { ..._app, uidRegisteredError: _dispatch.payload };
        case 'APP-STORAGE_DIR':
            return { ..._app, storageDir: _dispatch.payload };
        case 'APP-ASSETS_VERSION':
            setStorage(_dispatch);
            return { ..._app, assetsVersion: _dispatch.payload };
        case 'APP-DOWNLOADED_ASSETS':
            setStorage(_dispatch);
            return { ..._app, downloadedAssets: _dispatch.payload };
        case 'APP-ADD_DOWNLOADED_ASSETS':
            const _downloadedAssets = { ..._app.downloadedAssets, ..._dispatch.payload };
            setStorage({
                type: 'APP-DOWNLOADED_ASSETS',
                payload: _downloadedAssets
            });
            return { ..._app, downloadedAssets: _downloadedAssets };
        case 'APP-REMOVE_DOWNLOADED_ASSETS':
            delete _app.downloadedAssets[_dispatch.payload];
            setStorage({
                type: 'APP-DOWNLOADED_ASSETS',
                payload: _app.downloadedAssets
            });
            return { ..._app, downloadedAssets: { ..._app.downloadedAssets } };
    }
    return _app;
};

export { reducer as default, app };