import * as wook from './wook';
import * as bertrand from './bertrand';
import Config from '../../config';

const contexts = {
    wook,
    bertrand
};

const Context = contexts[Config.contextName as keyof typeof contexts];

export { Context as default }