import React from 'react';

import Config, { Log } from '../config';
import { Store } from '../store';
import { Redirect } from '../components/GpeRouter';
import Bookshelf from '../components/Bookshelf';
import BookshelfInitializer from '../components/BookshelfInitializer';

function LibraryScreen() {
    const { state } = React.useContext(Store);

    const loggedInTimeout = React.useMemo(() => {
        return state.user.auth
            && !state.user.keepLoggedIn
            && state.user.keepLoggedInTimeout
            && (Date.now() - state.user.keepLoggedInTimeout) / 1000 / (60 * 60) > 1
            ? true
            : false
    }, [state.user.auth, state.user.keepLoggedIn, state.user.keepLoggedInTimeout]);

    return React.useMemo(() => {
        Log.debug('screen: BOOKSHELF');

        return state.user.auth
            && !loggedInTimeout
            ? (
                <BookshelfInitializer>
                    <Bookshelf />
                </BookshelfInitializer>
            ) : <Redirect to={Config.routes.firstpage} />
    }, [state.user.auth, loggedInTimeout]);
}

export { LibraryScreen as default }