import { UserServicesInterface } from '../interfaces/services';
import { doGetRequest, doPostRequest } from './HttpClient';

const UserServices = async (props: UserServicesInterface) => {
    const serviceName = Object.keys(props)[0];
    let _request;
    switch (serviceName) {
        case "login":
            return doPostRequest(
                serviceName,
                props[serviceName]?.urlParams,
                props[serviceName]?.body,
                props[serviceName]?.headers
            );
        case "socialLoginToken":
            return doPostRequest(
                serviceName,
                undefined,
                props[serviceName]?.body
            );
        case "socialLoginTokenUserId":
            return doPostRequest(
                serviceName,
                undefined,
                props[serviceName]?.body
            );
        case "recover":
            return doPostRequest(
                serviceName,
                undefined,
                props[serviceName]?.body
            );
        case "regist":
            return doPostRequest(
                serviceName,
                undefined,
                props[serviceName]?.body
            );
        case "validatebertrandcustomer":
            return doPostRequest(
                serviceName,
                undefined,
                props[serviceName]?.body
            );
        case "registerbertrandcustomer":
            return doPostRequest(
                serviceName,
                undefined,
                props[serviceName]?.body
            );
        case "getAuthToken":
            return doPostRequest(
                serviceName,
                props[serviceName]?.urlParams,
                null,
                props[serviceName]?.headers
            );
        case "getCustomerByToken":
            return doPostRequest(
                serviceName,
                undefined,
                props[serviceName]?.body
            );
        case "getPasswordRules":
            return doGetRequest(
                serviceName,
                props[serviceName]?.urlParams
            );
        case "getSocialRelations":
            _request = (): Promise<{
                success?: {
                    data: Array<{
                        "conAplId": number | null,
                        "userId": number | null,
                        "user": string | null,
                        "socialId": number,
                        "username": string | null,
                        "socialToken": string | null
                    }>
                },
                error?: string
            }> => doGetRequest(
                serviceName,
                props[serviceName]?.urlParams,
                props[serviceName]?.headers
            );
            return _request();
        case "requestPasswordForSocialAccount":
            return doPostRequest(
                serviceName,
                props[serviceName]?.urlParams,
                undefined,
                props[serviceName]?.headers
            );
        case "associateSocialAccount":
            return doPostRequest(
                serviceName,
                undefined,
                props[serviceName]?.body,
                props[serviceName]?.headers
            );
        case "assignPromoBooks":
            const body = {
                "operation": {
                    "id": 0,
                    "type": "OTRS"
                }
            };
            return doPostRequest(
                serviceName,
                { ...props[serviceName]?.urlParams, "dateTs": 0 },
                body,
                props[serviceName]?.headers
            );
        case "assignPromoBooksInCampaign":
            return doPostRequest(
                serviceName,
                { ...props[serviceName]?.urlParams, "dateTs": 0 },
                undefined,
                props[serviceName]?.headers
            );
        case "doSocialNetworkLogin":
            return doPostRequest(
                serviceName,
                undefined,
                props[serviceName]?.body,
                props[serviceName]?.headers
            );
        default:
            return {};
    }
}

export { UserServices as default };