import React from "react";
import { TextStyle } from "react-native";

import GpeText from "../../GpeText";
import { LibraryStyles } from "../../../styles";
import Config from "../../../config";

export default function TitleH3(props: {
    title: string | undefined,
    style?: TextStyle,
}) {

    return React.useMemo(() => (
        <GpeText
            style={[
                LibraryStyles.h3,
                Config.contextName === 'bertrand'
                    ? LibraryStyles.themeColor
                    : LibraryStyles.blackColor,
                Config.contextName === 'bertrand'
                    ? { fontSize: 14 }
                    : null,
                {
                    width: '100%',
                    paddingTop: 13,
                    paddingRight: 30,
                    paddingBottom: 13,
                    paddingLeft: 30
                },
                { ...props.style }
            ]}>
            {props.title}
        </GpeText>
    ), [
        props.title,
        props.style,
    ])
}
