import React, { MutableRefObject } from "react";
import { TouchableOpacity, View } from "react-native";

import Config, { Log } from '../../../config';
import { Store } from '../../../store';
import { ContextTheme, LibraryStyles } from '../../../styles';
import { setShelfZone, changeBookshelf } from '../../../middlewares/LibraryMiddleware';
import Hoverable from '../../Hoverable';
import { useLocation, useHistory } from "../../GpeRouter";
import { CategoryInterface } from "../../../interfaces/middlewares";
import GpeText from "../../GpeText";
import { DispatchType } from "../../../interfaces/store";

function Shelf(props: { shelf: CategoryInterface, selected: boolean }) {
    /** STORE */
    const { dispatch } = React.useContext(Store);

    /** PROPS */
    const location = useLocation();
    const history = useHistory();
    const dispatchRef: MutableRefObject<DispatchType> = React.useRef(dispatch);
    const shelfRef: MutableRefObject<View | null> = React.useRef(null);

    /** LOCAL STATE */
    const [shelfHoveringState, shelfHoveringDispatch] = React.useState(false);

    /** RENDER */
    return React.useMemo(() => {
        Log.debug('component: Shelf');

        return (
            <Hoverable>
                {(hover: boolean) => (
                    <TouchableOpacity
                        onPress={() => {
                            changeBookshelf(dispatchRef.current, props.shelf);
                            /** check location */
                            location.pathname !== Config.routes.bookshelf
                                && history.push(Config.routes.bookshelf);
                        }}>
                        <View
                            ref={(ref) => { shelfRef.current = ref }}
                            onLayout={() => setShelfZone(shelfHoveringDispatch, shelfRef.current, String(props.shelf.id))}>
                            <GpeText
                                style={[
                                    LibraryStyles.shelf,
                                    (location.pathname.indexOf(Config.routes.bookshelf) > -1
                                        && props.selected
                                        && LibraryStyles.themeColor),
                                    (hover
                                        && LibraryStyles.themeColor),
                                    (shelfHoveringState
                                        && LibraryStyles.selected)
                                ]}>
                                {decodeURIComponent(props.shelf.name)}
                                <GpeText
                                    style={{ fontFamily: ContextTheme.font.semibold }}> ({props.shelf.bookAmount})</GpeText>
                            </GpeText>
                        </View>
                    </TouchableOpacity>
                )}
            </Hoverable>
        )
    }, [
        props.shelf,
        props.selected,
        location,
        history,
        shelfHoveringState,
    ]);
}

export { Shelf as default };