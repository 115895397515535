import React, { ForwardedRef } from 'react';
import { ScrollView, ScrollViewProps } from 'react-native';

import Config from "../config";

const GpeScrollView = React.forwardRef((
    props: ScrollViewProps & { children: any },
    ref: ForwardedRef<ScrollView>,
) => (
    <ScrollView
        showsVerticalScrollIndicator={
            Config.platform === 'android'
                || Config.platform === 'ios'
                ? false
                : true
        }
        showsHorizontalScrollIndicator={
            Config.platform === 'android'
                || Config.platform === 'ios'
                ? false
                : true
        }
        ref={ref}
        {...props} />
));


export { GpeScrollView as default }