import Axios from 'axios';

import Config, { Log } from '../../config';
import { AxiosTypes } from '../../interfaces/middlewares';
import { ResponseInterface } from '../../interfaces/services';
import errorTreatment from './errorTreatment';

const HTTP_DEFAULT_HEADERS: object = {
    'Content-Type': 'application/json; charset=utf-8',
    'x-Context': Config.context.applicationContext,
    'x-Company': Config.context.company,
    'x-EntityType': Config.context.entityType,
    'x-Site': Config.context.siteId,
    'key': Config.params.webservices.kongConsumer,
};

const HTTP_DEFAULT_OPTIONS: object = {
    timeout: 10000,                  // in ms, default is `0` (no timeout). If any longer, the request will be aborted.
    headers: HTTP_DEFAULT_HEADERS
};

const analyticsEndpoints: Array<keyof typeof Config.endpoints> = [
    "gpeAnalytics"
];

const onGoingRequests: {
    [key in AxiosTypes]: {
        [key: string]: boolean
    }
} = {
    "GET": {},
    "POST": {},
    "PUT": {},
    "PATCH": {},
    "DELETE": {},
    "HEAD": {},
    "OPTIONS": {},
};

/**
 * Executes a HTTP GET request.
 * 
 * @param url          The webservice's url
 * @param extraHeaders Any extra headers that should be added to the request
 * @param extraOptions Any extra options (Axios options) to be passed to the Http client
 */
const doGetRequest = async (
    endpoint: keyof typeof Config.endpoints,
    urlParams: object = {},
    extraHeaders: object = {},
    extraOptions: object = {},
    alternativeName?: string,
) => doRestRequest("GET", endpoint, urlParams, null, extraHeaders, extraOptions, alternativeName);

/**
 * Executes a HTTP POST request.
 *
 * @param url          The webservice's url
 * @param body         The request's body
 * @param extraHeaders Any extra headers that should be added to the request
 * @param extraOptions Any extra options (Axios options) to be passed to the Http client
 */
const doPostRequest = (
    endpoint: keyof typeof Config.endpoints,
    urlParams: object | undefined,
    body: any,
    extraHeaders: object = {},
    extraOptions: object = {},
    alternativeName?: string,
) => doRestRequest("POST", endpoint, urlParams, body, extraHeaders, extraOptions, alternativeName);

/**
 * Executes a HTTP PUT request.
 *
 * @param url          The webservice's url
 * @param body         The request's body
 * @param extraHeaders Any extra headers that should be added to the request
 * @param extraOptions Any extra options (Axios options) to be passed to the Http client
 */
const doPutRequest = (
    endpoint: keyof typeof Config.endpoints,
    urlParams: object | undefined,
    body: any,
    extraHeaders: object = {},
    extraOptions: object = {},
    alternativeName?: string,
) => doRestRequest("PUT", endpoint, urlParams, body, extraHeaders, extraOptions, alternativeName);

/**
 * Executes a HTTP DELETE request.
 *
 * @param url          The webservice's url
 * @param body         The request's body
 * @param extraHeaders Any extra headers that should be added to the request
 * @param extraOptions Any extra options (Axios options) to be passed to the Http client
 */
const doDeleteRequest = (
    endpoint: keyof typeof Config.endpoints,
    urlParams: object | undefined,
    body: any,
    extraHeaders: object = {},
    extraOptions: object = {},
    alternativeName?: string,
) => doRestRequest("DELETE", endpoint, urlParams, body, extraHeaders, extraOptions, alternativeName);

/**
 * Executes a HTTP HEAD request.
 * 
 * @param url          The webservice's url
 * @param extraHeaders Any extra headers that should be added to the request
 * @param extraOptions Any extra options (Axios options) to be passed to the Http client
 */
const doHeadRequest = async (
    endpoint: keyof typeof Config.endpoints,
    urlParams: object | undefined,
    extraHeaders: object = {},
    extraOptions: object = {},
    alternativeName?: string,
) => doRestRequest("HEAD", endpoint, urlParams, null, extraHeaders, extraOptions, alternativeName);

/**
 * Executes a HTTP OPTIONS request.
 * 
 * @param url          The webservice's url
 * @param extraHeaders Any extra headers that should be added to the request
 * @param extraOptions Any extra options (Axios options) to be passed to the Http client
 */
const doOptionsRequest = async (
    endpoint: keyof typeof Config.endpoints,
    urlParams: object | undefined,
    extraHeaders: object = {},
    extraOptions: object = {},
    alternativeName?: string,
) => doRestRequest("OPTIONS", endpoint, urlParams, null, extraHeaders, extraOptions, alternativeName);

/**
 * Executes a generic HTTP request.
 * 
 * @param method       The request's method (also knows as HTTP verbs)
 * @param url          The webservice's url
 * @param body         The request's body
 * @param extraHeaders Any extra headers that should be added to the request
 * @param extraOptions Any extra options (Axios options) to be passed to the Http client
 */
const doRestRequest = async (
    method: AxiosTypes,
    endpoint: keyof typeof Config.endpoints,
    urlParams: object | undefined,
    body: any,
    extraHeaders: object = {},
    extraOptions: object = {},
    alternativeName?: string,
) => {
    let result: ResponseInterface = {};
    let alternativeRequestName = alternativeName ? alternativeName : endpoint;

    if (onGoingRequests[method]
        && onGoingRequests[method][alternativeRequestName])
        return result;

    onGoingRequests[method][alternativeRequestName] = true;

    const options = mergeOptions(extraOptions, extraHeaders);
    const endpointUrl = urlParams
        ? replaceUrlParameters(Config.endpoints[endpoint], urlParams)
        : Config.endpoints[endpoint];
    const url = analyticsEndpoints.includes(endpoint)
        ? `${Config.analyticsUrl}${endpointUrl}`
        : `${Config.baseUrl}${endpointUrl}`;

    Log.debug(`Axios vai fazer um ${method} para ${url}`);
    Log.debug(`body: `, body);
    Log.debug(`options: `, options);

    try {
        switch (method) {
            case "GET":
                result.success = await Axios.get(url, options)
                    .then((response: any) => validateResponseData(response));
                break;
            case "POST":
                result.success = await Axios.post(url, body, options)
                    .then((response: any) => validateResponseData(response));
                break;
            case "PUT":
                result.success = await Axios.put(url, body, options)
                    .then((response: any) => validateResponseData(response));
                break;
            case "PATCH":
                result.success = await Axios.patch(url, body, options)
                    .then((response: any) => validateResponseData(response));
                break;
            case "DELETE":
                result.success = await Axios.delete(url, options)
                    .then((response: any) => validateResponseData(response));
                break;
            case "HEAD":
                result.success = await Axios.head(url, options)
                    .then((response: any) => validateResponseData(response));
                break;
            case "OPTIONS":
                Log.debug("Foi pedido um request OPTIONS que aparentemente o Axios deixou de implementar.");
                break;
            default:
                Log.debug("Foi pedido um verbo HTTP desconhecido ou não implementado: ", method);
        }
        Log.debug("A comunicação foi bem sucedida. Resposta do servidor.");
        Log.debug("result success: ", result.success);
    }
    catch (error: any) {
        result.error = errorTreatment(error, endpoint);
        if (error.response) {
            // The request was made and the server responded with a status code that falls out of the range of 2xx
            Log.debug("O servidor respondeu mas reportou erros!");
            Log.debug("data: ", error.response.data);
            Log.debug("status: ", error.response.status);
            Log.debug("headers: ", error.response.headers);
        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in node.js
            Log.debug("O servidor não respondeu!");
            Log.debug("request: ", error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            Log.debug("Ocorreu um erro desconhecido a tentar comunicar com o servidor!");
            Log.debug('message: ', error.message);
        }
        Log.debug("config: ", error.config);
        Log.debug("result error: ", result.error);
    }

    delete onGoingRequests[method][alternativeRequestName];

    return result;
};

const validateResponseData = (response: any) => {
    // return response && response.data ? response.data : null;
    return response;
}

/**
 * Merges any extra options (Axios options) with the default Http client's options
 * 
 * @param extraOptions The options to be merged
 */
const mergeOptions = (extraOptions: object = {}, extraHeaders: object = {}) => {
    const headers = { ...HTTP_DEFAULT_HEADERS, ...extraHeaders };
    const options = { ...HTTP_DEFAULT_OPTIONS, headers };

    return { ...options, ...extraOptions };
}

const replaceUrlParameters = (url: string, parameters: any) => {
    if (parameters) {
        Object.keys(parameters).forEach(key => {
            url = url.replace(`{${key}}`, parameters[key]);
        });
    }

    return url;
};

export {
    doGetRequest,
    doPostRequest,
    doPutRequest,
    doDeleteRequest,
    doHeadRequest,
    doOptionsRequest,
    replaceUrlParameters,
};