import React, { MutableRefObject } from 'react';
import { View } from 'react-native';

import { Log } from '../../config';
import { Store } from '../../store';
import { LibraryStyles, dimensionWidth, dimensionWidth_Boolean } from '../../styles';
import { getBookshelf } from '../../middlewares/LibraryMiddleware';
import Header from '../Header';
import ShelfsList from '../Bookshelf/components/ShelfsList';
import DimensionsEventTimeout from '../DimensionsEvent';
import FloatingMessage from '../Bookshelf/components/FloatingMessage';
import SearchList from './components/SearchList';
import { BackButton } from '../GpeRouter';
import { getTranslation } from '../../middlewares/AppMiddleware';
import { inIframe } from '../../middlewares/commons';
import { DispatchType } from '../../interfaces/store';

function Search() {
    /** STORE */
    const { state, dispatch } = React.useContext(Store);

    /** PROPS */
    const dispatchRef: MutableRefObject<DispatchType> = React.useRef(dispatch);
    const translation = React.useMemo(() => getTranslation(state.app.language), [state.app.language]);
    const heightMedia = { xs: 60, md: 80 };
    const scrollEnabledMedia: { xs: 'true' | 'false', md: 'true' | 'false' } = { xs: 'true', md: 'false' };
    const hideHeader = inIframe();

    /** LOCAL STATE */
    const [bookshelfState, setBookshelfState,] = React.useState({
        height: dimensionWidth(heightMedia),
        scrollEnabled: dimensionWidth_Boolean(scrollEnabledMedia),
    });

    /** RESIZE LISTENER */
    DimensionsEventTimeout(() => {
        const _height = dimensionWidth(heightMedia);
        const _scrollEnabled = dimensionWidth_Boolean(scrollEnabledMedia);
        if (_height !== bookshelfState.height) {
            setBookshelfState({
                ...bookshelfState,
                height: _height,
                scrollEnabled: _scrollEnabled,
            });
        }
    });

    /** obter informacao da livraria do utilizador caso nao exista */
    React.useEffect(() => {
        (!state.library.bookshelf
            || !(state.library.bookshelf.shelfs || state.library.bookshelf.showcases))
            && state.app.uid
            && state.app.storageDir
            && state.user.authToken
            && state.user.auth
            && getBookshelf(
                dispatchRef.current,
                state.app.uid,
                state.app.storageDir,
                state.user.authToken,
                state.user.auth,
                state.library.showcases,
                state.library.downloadedCovers,
                translation
            );
    }, [
        state.app.uid,
        state.app.storageDir,
        state.user.authToken,
        state.user.auth,
        state.library.bookshelf,
        state.library.showcases,
        state.library.downloadedCovers,
        translation,
    ]);

    /** RENDER */
    return React.useMemo(() => {
        Log.debug('component: Search');

        return (state.library.bookshelf
            && state.library.bookshelf.shelfs
            ? (
                <View style={{ flex: 1, width: '100%', height: '100%' }}>
                    {state.library.moveToShelf
                        ? <FloatingMessage />
                        : hideHeader
                            ? null
                            : <Header height={bookshelfState.height} />}

                    <View style={[
                        LibraryStyles.container,
                        {
                            marginTop: hideHeader
                                || state.library.moveToShelf
                                ? undefined
                                : bookshelfState.height
                        }]}>
                        <SearchList />
                        <ShelfsList />
                    </View>
                    <BackButton />
                </View>
            ) : null
        )
    }, [
        hideHeader,
        state.library.bookshelf,
        state.library.moveToShelf,
        bookshelfState
    ]);
}

export { Search as default };