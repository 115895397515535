import React, { MutableRefObject } from "react";
import { EmitterSubscription, NativeEventEmitter } from "react-native";
import KitabooReader from "react-native-ebooks-reader";

import Config, { AppContext, Log } from "../config";
import { DispatchType } from "../interfaces/store";
import { errorMapping } from "../language";
import { createMobileSession } from "../middlewares/AppMiddleware";
import { openPopup, presentFirstOnboard, presentSecondOnboard } from "../middlewares/commons";
import { doAssignPromoBooks, getAuthToken } from "../middlewares/UserMiddleware";
import { Store } from "../store";
import { Redirect } from "./GpeRouter";

function BookshelfInitializer(props: { children: any }) {
    const { state, dispatch } = React.useContext(Store);

    const dispatchRef: MutableRefObject<DispatchType> = React.useRef(dispatch);

    const kitabooReaderEventEmitter: MutableRefObject<NativeEventEmitter> = React.useRef(new NativeEventEmitter(KitabooReader));

    const listeners: MutableRefObject<Array<EmitterSubscription>> = React.useRef([]);

    const nativeEvents: Array<{ eventType: string, callback: (event: any) => void }> = React.useMemo(() => [
        {
            eventType: 'updateToken',
            callback: (event: { token: string }) => {
                Log.debug('NATIVE EVENT: updateToken');
                dispatchRef.current({
                    type: 'USER-KITABOO_TOKEN',
                    payload: event.token,
                });
            }
        },
        {
            eventType: 'toggleLoading',
            callback: (flg: boolean) => {
                Log.debug('NATIVE EVENT: toggleLoading');
                dispatchRef.current({
                    type: 'APP',
                    payload: { fetching: flg, percentage: null },
                });
            }
        },
        {
            eventType: 'bookUnzipped',
            callback: (event: { bookId: string }) => {
                Log.debug('NATIVE EVENT: bookUnzipped');
                let ebookId: string | number | undefined;
                if (state.library.bookshelf) {
                    for (const shelf of state.library.bookshelf.shelfs) {
                        for (const ebook of shelf.ebooksList) {
                            if (String(ebook.kitabooCloudId) === event.bookId) {
                                ebookId = ebook.gpeId;
                                break;
                            }
                        }
                        if (ebookId) break;
                    }
                    if (!ebookId) {
                        for (const showcase of state.library.bookshelf.showcases) {
                            for (const ebook of showcase.ebooksList) {
                                if (String(ebook.kitabooCloudId) === event.bookId) {
                                    ebookId = ebook.gpeId;
                                    break;
                                }
                            }
                            if (ebookId) break;
                        }
                    }
                }
                dispatchRef.current([{
                    type: 'APP',
                    payload: { fetching: false, percentage: null },
                }, {
                    type: 'LIBRARY-ADD_DOWNLOADED_VERSIONS',
                    payload: ebookId
                        ? { [ebookId]: Config.ebook.downloadFileName }
                        : null
                }]);
            }
        },
        {
            eventType: 'bookUnzipFailed',
            callback: (event: { bookId: string }) => {
                Log.debug('NATIVE EVENT: bookUnzipFailed');
                openPopup(dispatchRef.current, `bookUnzipFailed: ${event.bookId}`);
                dispatchRef.current({
                    type: 'APP',
                    payload: { fetching: false, percentage: null },
                });
            }
        },
        {
            eventType: 'bookDownloadFailed',
            callback: (event: { bookId: string }) => {
                Log.debug('NATIVE EVENT: bookDownloadFailed');
                const errorServices = errorMapping[Config.contextName as AppContext];
                openPopup(dispatchRef.current, errorServices.defaultMsg);
                dispatchRef.current({
                    type: 'APP',
                    payload: { fetching: false, percentage: null },
                });
            }
        },
        {
            eventType: 'downloadStatus',
            callback: (event: { bookId: string, progress: string, text: string }) => {
                Log.debug(`NATIVE EVENT: downloadStatus: ${event.progress}`);
                dispatchRef.current({
                    type: 'APP',
                    payload: { fetching: true, percentage: event.progress },
                });
            }
        },
    ], [state.library.bookshelf]);

    const secondOnboard = React.useMemo(() => presentSecondOnboard(
        state.user.auth,
        state.user.firstOnBoard,
        state.user.firstOnBoardDate,
        state.user.secondOnBoard,
        state.library.bookshelf,
    ), [
        state.library.bookshelf,
        state.user.auth,
        state.user.firstOnBoard,
        state.user.firstOnBoardDate,
        state.user.secondOnBoard
    ]);

    const firstOnboard = React.useMemo(() => presentFirstOnboard(
        state.user.auth,
        state.user.firstOnBoard,
        state.user.secondOnBoard,
    ), [
        state.user.auth,
        state.user.firstOnBoard,
        state.user.secondOnBoard,
    ]);

    React.useEffect(() => {
        state.user.auth
            && !state.user.authToken
            && !state.user.authTokenError
            && getAuthToken(dispatchRef.current, state.user.auth);

        !state.app.uidRegistered
            && !state.app.uidRegisteredError
            && state.app.uid
            && state.user.auth
            && state.user.authToken
            && createMobileSession(
                dispatchRef.current,
                state.app.uid,
                state.user.auth,
                state.user.authToken
            );

        state.user.auth
            && state.user.authToken
            && !state.user.assignPromoBooks[state.user.auth.id]
            && doAssignPromoBooks(
                dispatchRef.current,
                state.user.auth.id,
                state.user.authToken
            );
    }, [
        state.app.uid,
        state.app.uidRegistered,
        state.app.uidRegisteredError,
        state.user.auth,
        state.user.authToken,
        state.user.authTokenError,
        state.user.assignPromoBooks,
    ]);

    React.useEffect(() => {
        listeners.current = [];
        nativeEvents.forEach((event) => {
            kitabooReaderEventEmitter.current.listenerCount(event.eventType) > 0
                && kitabooReaderEventEmitter.current.removeAllListeners(event.eventType);
            listeners.current.push(
                kitabooReaderEventEmitter.current.addListener(event.eventType, event.callback)
            );
        });

        // Remove event listener on cleanup
        return () => {
            listeners.current.forEach((listener) => {
                listener.remove();
            });
        };
    }, [nativeEvents]);

    return React.useMemo(() => {
        Log.debug('component: BookshelfInitializer: ', Boolean(
            state.user.authToken
            && state.app.uidRegistered
            && (state.user.auth
                && state.user.assignPromoBooks[state.user.auth.id])
            && (state.user.auth
                && !firstOnboard
                && !secondOnboard)
        ));

        return (state.user.authToken
            && state.app.uidRegistered
            && (state.user.auth
                && state.user.assignPromoBooks[state.user.auth.id])
            && (!state.library.bookshelf
                || (state.user.auth
                    && !firstOnboard
                    && !secondOnboard))
            ? props.children
            : state.library.bookshelf
                && state.user.auth
                && (firstOnboard
                    || secondOnboard)
                ? <Redirect to={Config.routes.introduction} />
                : null
        )
    }, [
        state.app.uidRegistered,
        state.user.auth,
        state.user.authToken,
        state.user.assignPromoBooks,
        state.library.bookshelf,
        props.children,
        firstOnboard,
        secondOnboard,
    ]);
}

export { BookshelfInitializer as default };