import { View } from "react-native";

import Config, { Log } from '../config';
import { LanguageKeys } from "../interfaces/language";
import {
    handleBookshelfData,
    closePopup,
    getCurrentTime,
    compareObjField,
    openPopup,
    normalizeString,
    services,
    loading,
    removeFromStorage,
    getAssetDirectory,
    imageUrlTreatment,
    showcaseProductToEbookFormat,
    verifyConnection,
} from './commons';
import { openEbook, webReaderAccessInfo, } from "./commons/KitabooReader";
import { CategoryInterface, EbookInterface } from "../interfaces/middlewares";
import { doLogout } from "./UserMiddleware";
import { DispatchType, ShowcaseInterface } from "../interfaces/store";
import { directoryExist, downloadFile, removeDirectory } from "./FilesystemMiddleware";
import LibraryServices from "../services/LibraryServices";
import { ResponseInterface } from "../interfaces/services";
import BookshelfServices from "../services/LibraryServices";

/** ********** LIBRARY GLOBALS ********** */

/** contem as estantes para verificar a sua posicao em runtime */
const shelfs: {
    [key: string]: {
        shelfHoveringDispatch: Function,
        shelf: View | null,
    }
} = {};
const covers: {
    [key: string]: {
        [key: string]: {
            coverHoveringDispatch: Function,
            coverDragIconDispatch: Function,
            cover: View | null,
        }
    }
} = {};
const downloadFailed: { [key: string]: string | undefined } = {};
const DEFAULT_IMAGE_NAME = 'cover.image';

let downloadTimeout: NodeJS.Timeout;
let shelfOptionsDispatch: Function | undefined;
let contentDispatch: Function | undefined;
let shelfsListDispatch: Function | undefined;
let shelfsListDropdownDispatch: Function | undefined;
let searchDispatch: Function | undefined;
let moveToShelf: boolean = false;
let manualSorting: boolean = false;
let hoveringShelfId: string | null;
let hoveringCoverId: string | null;
let MANUAL_SORTED_SHELFS: { [key: string]: CategoryInterface } = {};

/** ********** END - LIBRARY GLOBALS ********** */

function setShelfsListDispatch(_dispatch?: Function) {
    shelfsListDispatch = _dispatch;
}

function setContentDispatch(_dispatch?: Function) {
    contentDispatch = _dispatch;
}

function setShelfOptionsDispatch(_dispatch?: Function) {
    shelfOptionsDispatch = _dispatch;
}

function setShelfsListDropdownDispatch(_dispatch?: Function) {
    shelfsListDropdownDispatch = _dispatch;
}

function setSearchDispatch(_dispatch?: Function) {
    searchDispatch = _dispatch;
}

function toggleContentScroll(_boolean: Boolean) {
    contentDispatch
        && contentDispatch({ scrollEnabled: _boolean });
}

function toggleShelfOptions(_boolean: Boolean, _toggle: Boolean = true) {
    _toggle && toggleSearch(false, !_toggle);
    _toggle && toggleShelfsListDropdown(false, !_toggle);
    shelfOptionsDispatch
        && shelfOptionsDispatch({ show: _boolean });
}

function toggleShelfsListDropdown(_boolean: Boolean, _toggle: Boolean = true) {
    _toggle && toggleSearch(false, !_toggle);
    _toggle && toggleShelfOptions(false, !_toggle);
    shelfsListDropdownDispatch
        && shelfsListDropdownDispatch({ showOptions: _boolean });
}

function toggleSearch(_boolean: Boolean, _toggle: Boolean = true) {
    _toggle && toggleShelfOptions(false, !_toggle);
    _toggle && toggleShelfsListDropdown(false, !_toggle);
    searchDispatch && searchDispatch(_boolean);
}

function toggleShelfsListHover(_boolean: Boolean) {
    shelfsListDispatch
        && shelfsListDispatch({ hoverEnabled: _boolean });
}

function toggleMoveToShelf(_dispatch: DispatchType) {
    _dispatch({
        type: 'LIBRARY-MOVE_TO_SHELF',
        payload: moveToShelf = !moveToShelf,
    });
    toggleShelfOptions(false);
}

function toggleManualSorting(
    _dispatch: DispatchType,
    _appStateUid?: string | null,
    _userStateAuthToken?: string | null,
    _userStateAuth?: { id: number, token: string } | null,
) {
    if (manualSorting
        && _appStateUid
        && _userStateAuthToken
        && _userStateAuth
        && Object.keys(MANUAL_SORTED_SHELFS).length) {
        const promisesEvents: Array<{}> = [];
        Object.keys(MANUAL_SORTED_SHELFS).forEach((categoryId: string) => {
            promisesEvents.push(doOrderCategoryItems(
                _dispatch,
                _appStateUid,
                _userStateAuthToken,
                _userStateAuth,
                categoryId,
                MANUAL_SORTED_SHELFS[categoryId].ebooksList,
            ));
        });
        Promise.all(promisesEvents).then(() => {
            _dispatch({
                type: 'LIBRARY-BOOKSHELF', payload: null
            });
        });
    }
    _dispatch({
        type: 'LIBRARY-MANUAL_SORTING',
        payload: manualSorting = !manualSorting,
    });
    MANUAL_SORTED_SHELFS = {};
    toggleShelfOptions(false);
}

function setShelfZone(
    _shelfHoveringDispatch: Function,
    _shelf: View | null,
    _id: string,
) {
    /** a categoria zero foi criada apenas para agrupar os ebooks sem categoria */
    if (_id !== '0')
        shelfs[_id] = { shelfHoveringDispatch: _shelfHoveringDispatch, shelf: _shelf };
}

async function toggleShelfHighlight(
    _moveX: number,
    _moveY: number,
    _ignoreShelfs: Array<string | undefined> = [],
) {
    for (const key in shelfs) {
        if (!_ignoreShelfs.includes(key)) {
            shelfs[key].shelf?.measure((x: any, y: any, width: any, height: any, pageX: any, pageY: any) => {
                /** toggle highlight */
                if (
                    _moveY > pageY
                    && _moveY < pageY + height
                    && _moveX > pageX
                    && _moveX < pageX + width
                ) shelfs[key].shelfHoveringDispatch(true);
                else shelfs[key].shelfHoveringDispatch(false);
            });
        }
    }
}

function setHoveringShelfId(_id: string | null) {
    hoveringShelfId = _id;
}

async function checkShelfZone(
    _moveX: number,
    _moveY: number,
    _ignoreShelfs: Array<string | undefined> = [],
    _callback: Function,
) {
    const checkingShelfZone = [];
    setHoveringShelfId(null);
    for (const key in shelfs) {
        if (!_ignoreShelfs.includes(key)) {
            checkingShelfZone.push(
                new Promise((resolve, reject) => {
                    shelfs[key].shelf?.measure((
                        x: any,
                        y: any,
                        width: any,
                        height: any,
                        pageX: any,
                        pageY: any,
                    ) => {
                        if (
                            _moveY > pageY
                            && _moveY < pageY + height
                            && _moveX > pageX
                            && _moveX < pageX + width
                        ) setHoveringShelfId(key);
                        /** remove highlight */
                        shelfs[key].shelfHoveringDispatch(false);
                        resolve(true);
                    });
                })
            );
        }
    }

    return Promise.all(checkingShelfZone).then(() => _callback(hoveringShelfId))
}

function setCoverZone(
    _coverHoveringDispatch: Function,
    _coverDragIconDispatch: Function,
    _cover: View | null,
    _shelfId: string,
    _id: string,
) {
    if (!covers[_shelfId]) covers[_shelfId] = {}
    covers[_shelfId][_id] = {
        coverHoveringDispatch: _coverHoveringDispatch,
        coverDragIconDispatch: _coverDragIconDispatch,
        cover: _cover,
    };
}

async function toggleCoverHighlight(
    _moveX: number,
    _moveY: number,
    _shelfId: string,
    _ignoreCovers: Array<string> = [],
) {
    for (const key in covers[_shelfId]) {
        if (!_ignoreCovers.includes(key)) {
            covers[_shelfId][key].cover?.measure((
                x: any,
                y: any,
                width: any,
                height: any,
                pageX: any,
                pageY: any,
            ) => {
                /** toggle highlight */
                if (
                    _moveY > pageY
                    && _moveY < pageY + height
                    && _moveX > pageX
                    && _moveX < pageX + width
                ) covers[_shelfId][key].coverHoveringDispatch(true);
                else covers[_shelfId][key].coverHoveringDispatch(false);
            });
        }
    }
}

function setHoveringCoverId(_id: string | null) {
    hoveringCoverId = _id;
}

async function checkCoverZone(
    _moveX: number,
    _moveY: number,
    _shelfId: string,
    _ignoreCovers: Array<string> = [],
    _callback: Function,
) {
    const checkingCoverZone = [];
    setHoveringCoverId(null);
    for (const key in covers[_shelfId]) {
        if (!_ignoreCovers.includes(key)) {
            checkingCoverZone.push(
                new Promise((resolve, reject) => {
                    covers[_shelfId][key].cover?.measure((
                        x: any,
                        y: any,
                        width: any,
                        height: any,
                        pageX: any,
                        pageY: any,
                    ) => {
                        if (
                            _moveY > pageY
                            && _moveY < pageY + height
                            && _moveX > pageX
                            && _moveX < pageX + width
                        ) setHoveringCoverId(key);
                        /** remove highlight */
                        covers[_shelfId][key].coverHoveringDispatch(false);
                        resolve(true);
                    });
                })
            );
        }
    }

    return Promise.all(checkingCoverZone).then(() => _callback(hoveringCoverId))
}

async function toggleCoverDraggingIcon(
    _toggle: boolean,
    _shelfId: string,
    _movingCoverId: string,
) {
    for (const key in covers[_shelfId]) {
        if (_movingCoverId !== key)
            covers[_shelfId][key].coverDragIconDispatch(_toggle);
    }
}

async function getBookshelf(
    _dispatch: DispatchType,
    _appStateUid: string,
    _appStateStorageDir: string,
    _userStateAuthToken: string,
    _userStateAuth: { id: number, token: string },
    _libraryShowcases: Array<ShowcaseInterface> | null,
    _libraryDownloadedCovers: { [key: string]: { file: string } },
    _translation: (key: keyof LanguageKeys) => string | undefined,
) {
    return services.library(
        _dispatch,
        {
            getBookshelfData: {
                urlParams: {
                    "applicationalContextId": Config.context.applicationContext,
                    "readerAppId": Config.context.appId[Config.platform]
                },
                headers: {
                    "x-di": _appStateUid,
                    "x-at": _userStateAuthToken,
                    "x-userid": _userStateAuth.id,
                    "x-token": _userStateAuth.token,
                    "x-ip": "",
                }
            }
        },
        (response) => {
            if (response.error) {
                openPopup(_dispatch, response.error);
                doLogout(_dispatch);
                return;
            }

            const _bookshelf = response.success.data;

            _dispatch({
                type: 'LIBRARY-BOOKSHELF',
                payload: handleBookshelfData(
                    _bookshelf,
                    _translation
                ),
            });

            // obter todas as capas 
            if (!_libraryShowcases) {
                return getAllShowcases(_dispatch, _userStateAuth)
                    .then((_showcases) => downloadCovers(
                        _dispatch,
                        _appStateStorageDir,
                        _libraryDownloadedCovers,
                        _bookshelf,
                        _showcases,
                    ));
            }

            return downloadCovers(
                _dispatch,
                _appStateStorageDir,
                _libraryDownloadedCovers,
                _bookshelf,
                _libraryShowcases,
            );
        }
    );
}

function doCreateBookshelf(
    _dispatch: DispatchType,
    _appStateUid: string,
    _userStateAuth: { id: number, token: string },
    _userStateAuthToken: string,
    _body: { name: string },
    _successMsg?: any,
) {
    return services.library(
        _dispatch,
        {
            createBookshelfCategory: {
                urlParams: {
                    "applicationalContextId": Config.context.applicationContext,
                    "readerAppId": Config.context.appId[Config.platform],
                    "token": _userStateAuthToken,
                },
                body: { name: encodeURIComponent(_body.name) },
                headers: { "x-token": _userStateAuth.token }
            }
        },
        (response) => {
            if (response.error) {
                return openPopup(_dispatch, response.error);
            }
            _successMsg
                ? openPopup(_dispatch, _successMsg)
                : closePopup(_dispatch);
            _dispatch({ type: 'LIBRARY-BOOKSHELF', payload: null });
        }
    );
}

function changeBookshelf(
    _dispatch: DispatchType,
    _shelf: CategoryInterface
) {
    return _dispatch({
        type: 'LIBRARY-BOOKSHELF_SELECTED',
        payload: _shelf,
    });
}

function clearSelectBookshelf(_dispatch: DispatchType) {
    return _dispatch({
        type: 'LIBRARY-BOOKSHELF_SELECTED',
        payload: null,
    });
}

function doRenameBookshelf(
    _dispatch: DispatchType,
    _appStateUid: string,
    _userStateAuth: { id: number, token: string },
    _userStateAuthToken: string,
    _body: { id: number | string, name: string },
    _successMsg?: any,
) {
    return services.library(
        _dispatch,
        {
            renameBookshelfCategory: {
                urlParams: {
                    "applicationalContextId": Config.context.applicationContext,
                    "readerAppId": Config.context.appId[Config.platform],
                    "token": _userStateAuthToken,
                },
                body: { ..._body, name: encodeURIComponent(_body.name) },
                headers: { "x-token": _userStateAuth.token }
            }
        },
        (response) => {
            if (response.error) {
                return openPopup(_dispatch, response.error);
            }
            _successMsg
                ? openPopup(_dispatch, _successMsg)
                : closePopup(_dispatch);
            _dispatch({ type: 'LIBRARY-BOOKSHELF', payload: null });
        }
    );
}

function doDeleteBookshelf(
    _dispatch: DispatchType,
    _appStateUid: string,
    _userStateAuth: { id: number, token: string },
    _userStateAuthToken: string,
    _selectedBookshelfId: number | string | undefined,
    _id: number,
    _successMsg?: any,
) {
    return services.library(
        _dispatch,
        {
            deleteBookshelfCategory: {
                urlParams: {
                    "companyId": Config.context.company,
                    "applicationalContextId": Config.context.applicationContext,
                    "readerAppId": Config.context.appId[Config.platform],
                    "categoryId": _id,
                    "authToken": _userStateAuthToken,
                },
                headers: { "x-token": _userStateAuth.token }
            }
        },
        (response) => {
            if (response.error) {
                return openPopup(_dispatch, response.error);
            }
            _successMsg
                ? openPopup(_dispatch, _successMsg)
                : closePopup(_dispatch);
            _dispatch({ type: 'LIBRARY-BOOKSHELF', payload: null });
            (_id === _selectedBookshelfId && clearSelectBookshelf(_dispatch));
        }
    );
}

function getShelf(
    shelfs: Array<CategoryInterface>,
    bookshelfSelected: { id: number | string, name: string } | null
) {
    let _shelf: CategoryInterface | undefined;

    /** obter a estante selecionada ou a primeira com ebooks */
    if (shelfs && shelfs.length) {
        for (const shelf of shelfs) {
            if ((bookshelfSelected
                && bookshelfSelected.id === shelf.id)
                || (!bookshelfSelected
                    && shelf.ebooksList.length)) {
                _shelf = shelf;
                /** paginar os resultados */
                // _shelf.ebooksList = _shelf.ebooksList.slice(0, 50);
                break;
            }
        }
    }

    /** obter a primeira estante caso nao exista estante com conteudo */
    !_shelf
        && shelfs
        && shelfs.length
        && (_shelf = shelfs[0]);

    return _shelf;
}

function getSelectedShelf(
    shelfs: Array<CategoryInterface> | null,
    bookshelfSelected: { id: number | string, name: string } | null
) {
    let _shelf: CategoryInterface | undefined;

    if (shelfs
        && shelfs.length
        && bookshelfSelected
        && bookshelfSelected.id) {
        for (const shelf of shelfs) {
            if (shelf.id
                && bookshelfSelected.id === shelf.id) {
                _shelf = shelf;
                break;
            }
        }
    }

    return _shelf;
}

function searchFilterBookshelf(
    _shelfs: Array<CategoryInterface>,
    _showcases: Array<ShowcaseInterface>,
    _search: string,
) {
    /** tratar a pesquisa */
    _search = normalizeString(_search);
    const regSearch = new RegExp(_search, 'gi');

    const _uniqueEbooks: {
        [key: number]: EbookInterface,
        [key: string]: EbookInterface,
    } = {};
    // pesquisa nas estantes
    if (_shelfs && _shelfs.length) {
        _shelfs.forEach((shelf: CategoryInterface) => {
            if (shelf.ebooksList && shelf.ebooksList.length) {
                shelf.ebooksList.forEach(ebook => {
                    if (!_uniqueEbooks[ebook.gpeId]
                        && _search
                        && ((ebook.title && normalizeString(ebook.title).search(regSearch) > -1) //titulo
                            || (ebook.publisherName && normalizeString(ebook.publisherName).search(regSearch) > -1) //editor
                            || (ebook.authors && ebook.authors.filter((author) => normalizeString(author).search(regSearch) > -1).length))) //autor
                        _uniqueEbooks[ebook.gpeId] = ebook;
                });
            }
        });
    }
    // pesquisa nas montras
    if (_showcases && _showcases.length) {
        _showcases.forEach((showcase: ShowcaseInterface) => {
            if (showcase.products && showcase.products.length) {
                showcase.products.forEach(ebook => {
                    if (!_uniqueEbooks[ebook.mesArtID]
                        && _search
                        && ((ebook.titulo && normalizeString(ebook.titulo).search(regSearch) > -1) //titulo
                            || (ebook.editor && normalizeString(ebook.editor).search(regSearch) > -1) //editor
                            || (ebook.autores && ebook.autores.filter((author) => normalizeString(author.nome).search(regSearch) > -1).length))) //autor
                        _uniqueEbooks[ebook.mesArtID] = showcaseProductToEbookFormat(ebook);
                });
            }
        });
    }

    let _ebooks: Array<EbookInterface> = Object.keys(_uniqueEbooks).map((key) => _uniqueEbooks[key]);

    /** paginar os resultados */
    // _ebooks = _ebooks.slice(0, 50);

    return {
        _ebooksSlice: _ebooks,
    };
}

function doDeleteBookFromUserBookshelf(
    _dispatch: DispatchType,
    _ebook: EbookInterface,
    _appStateUid: string,
    _appStateStorageDir: string,
    _userStateAuth: { id: number, token: string },
    _userStateAuthToken: string,
    bookSample: boolean = false,
) {
    if (_ebook.subArtid)
        return services.library(
            _dispatch,
            {
                deleteBookFromUserBookshelf: {
                    urlParams: {
                        "serviceId": Config.context.serviceId,
                        "applicationalContextId": Config.context.applicationContext,
                        "conAplEntId": _userStateAuth.id,
                        "subArtId": _ebook.subArtid,
                        bookSample
                    },
                    headers: { "x-token": _userStateAuth.token }
                }
            },
            (response) => {
                if (response.error) return openPopup(_dispatch, response.error);

                closePopup(_dispatch);
                _dispatch({ type: 'LIBRARY-BOOKSHELF', payload: null });
                removeFromStorage(
                    _dispatch,
                    _appStateStorageDir,
                    _ebook
                );
            }
        );
}

function openReadItemId(
    _dispatch: DispatchType,
    _appStateStorageDir: string,
    _appStateUid: string,
    _userStateAuthToken: string,
    _userStateAuth: { id: number, email: string, token: string },
    _userStateKitabooToken: string | null,
    _libraryStateReadingStarted: { [id: number]: Array<EbookInterface> },
    _ebook: EbookInterface,
    _translation: (key: keyof LanguageKeys) => string | undefined,
    _callback?: { success?: Function, error?: Function },
    _fromShowcase?: boolean,
) {
    const _successCallback = (data: any) => {
        _callback
            && _callback.success
            && _callback.success(data);
        ebookReadingStarted(_ebook, _userStateAuth, _libraryStateReadingStarted, _dispatch);
    };

    const _errorCallback = (error: string) => {
        _callback
            && _callback.error
            && _callback.error(error);
        openPopup(_dispatch, error);
    };

    return loading(
        _dispatch,
        "openEbook",
        () => openEbook(
            _ebook,
            _appStateUid,
            _userStateAuthToken,
            _userStateKitabooToken,
            _userStateAuth,
            _dispatch,
            _translation,
            _appStateStorageDir,
            { success: _successCallback, error: _errorCallback }
        )
    );
}

function openSample(
    _dispatch: DispatchType,
    _ebook: EbookInterface,
    _callback?: { success?: Function, error?: Function },
) {
    const _errorCallback = (error: string) => {
        _callback
            && _callback.error
            && _callback.error(error);
        openPopup(_dispatch, error);
    };

    return loading(
        _dispatch,
        "openSample",
        () => webReaderAccessInfo(
            _dispatch,
            _ebook,
            undefined,
            undefined,
            undefined,
            undefined,
            { success: undefined, error: _errorCallback },
        )
    );
}

function ebookReadingStarted(
    _ebook: EbookInterface,
    _userStateAuth: { id: number, token: string },
    _libraryStateReadingStarted: { [id: number]: Array<EbookInterface> },
    _dispatch: DispatchType,
) {
    /** nao adicionar excertos */
    if (!_ebook.sample) {
        typeof _libraryStateReadingStarted !== 'object'
            && (_libraryStateReadingStarted = {});
        !_libraryStateReadingStarted[_userStateAuth.id]
            && (_libraryStateReadingStarted[_userStateAuth.id] = []);

        /** verifica se o ebook ja se encontra na lista */
        const indexEbook = _libraryStateReadingStarted[_userStateAuth.id].findIndex((readingEbook: any) => _ebook.subArtid === readingEbook.subArtid);
        indexEbook > -1 && _libraryStateReadingStarted[_userStateAuth.id].splice(indexEbook, 1);
        /** verifica se ja tem o numero maximo permitido */
        _libraryStateReadingStarted[_userStateAuth.id].length >= 4 && _libraryStateReadingStarted[_userStateAuth.id].pop();
        /** insere o ebook */
        _libraryStateReadingStarted[_userStateAuth.id].unshift(_ebook);
    }

    _dispatch({
        type: 'LIBRARY-READING_STARTED',
        payload: _libraryStateReadingStarted,
    });

    closePopup(_dispatch);
}

function sortItemsByAuthorsOrTitle(
    _dispatch: DispatchType,
    _appStateUid: string,
    _userStateAuthToken: string,
    _userStateAuth: { id: number, token: string },
    _categoryId: string | number,
    _ebooksList: Array<EbookInterface>,
    _sortedBookshelfDesc: { [key: string]: { 'authors'?: 'desc' | 'asc', 'title'?: 'desc' | 'asc' } },
    _orderBy: 'authors' | 'title',
) {
    !_sortedBookshelfDesc && (_sortedBookshelfDesc = {});
    !_sortedBookshelfDesc[_categoryId] && (_sortedBookshelfDesc[_categoryId] = { 'authors': 'asc', 'title': 'asc' });
    _sortedBookshelfDesc[_categoryId][_orderBy] = _sortedBookshelfDesc[_categoryId][_orderBy]
        && _sortedBookshelfDesc[_categoryId][_orderBy] === 'desc'
        ? 'asc'
        : 'desc';

    _ebooksList.sort((a: any, b: any) => compareObjField(
        a,
        b,
        _sortedBookshelfDesc[_categoryId][_orderBy],
        _orderBy,
        _orderBy === 'authors'
            ? 0
            : undefined,
    ));

    return doOrderCategoryItems(
        _dispatch,
        _appStateUid,
        _userStateAuthToken,
        _userStateAuth,
        _categoryId,
        _ebooksList,
    ).then((response) => {
        !response.error
            && _dispatch([{
                type: 'LIBRARY-BOOKSHELF', payload: null
            }, {
                type: 'LIBRARY-SORTED_BOOKSHELF_DESC', payload: _sortedBookshelfDesc
            }]);
        toggleShelfOptions(false);
    });
}

async function doOrderCategoryItems(
    _dispatch: DispatchType,
    _appStateUid: string,
    _userStateAuthToken: string,
    _userStateAuth: { id: number, token: string },
    _categoryId: string | number,
    _ebooksList: Array<EbookInterface>,
) {
    return verifyConnection(() => loading(
        _dispatch,
        `orderCategoryItems_${_categoryId}`,
        () => {
            return BookshelfServices({
                orderCategoryItems: {
                    urlParams: {
                        "applicationalContextId": Config.context.applicationContext,
                        "serviceId": Config.context.serviceId,
                        "readerAppId": Config.context.appId[Config.platform],
                        "categoryId": _categoryId,
                    },
                    body: {
                        "orderDate": getCurrentTime(),
                        "bookGuidList": _ebooksList.map((ebook: EbookInterface) => ebook.bookGuid),
                    },
                    headers: {
                        "x-di": _appStateUid,
                        "x-at": _userStateAuthToken,
                    }
                }
            }, `orderCategoryItems_${_categoryId}`).then((response) => {
                response.error
                    && openPopup(_dispatch, response.error);

                return response;
            })
        }
    ));
}

function getAllShowcases(
    _dispatch: DispatchType,
    _userStateAuth: { token: string }
) {
    return LibraryServices({
        getAllShowcaseProducts: {
            body: {
                sectionDTO: {
                    contextGPE: {
                        applicationContext: Config.context.applicationContext,
                        company: Config.context.company,
                    },
                    temaID: "13908",
                    areaID: "13908",
                    localizacao: ""
                }
            },
            headers: { "x-token": _userStateAuth.token }
        }
    }).then((response) => {
        const _showcases = response.success
            && response.success.data
            ? response.success.data
            : [];

        _dispatch({
            type: 'LIBRARY-SHOWCASES',
            payload: _showcases,
        });

        return _showcases;
    });
}

function downloadCovers(
    _dispatch: DispatchType,
    _appStateStorageDir: string,
    _libraryDownloadedCovers: { [key: string]: { file: string } },
    _bookshelf: { ebooks: Array<EbookInterface> },
    _showcases: Array<ShowcaseInterface>,
) {
    if (Config.platform !== 'web'
        && (_bookshelf.ebooks.length || _showcases.length)) {
        const _downloadingCovers: { [key: string]: boolean } = {};
        const _fileDirectory = getAssetDirectory(_appStateStorageDir, 'covers');
        const _promisesEvents: Array<{}> = [];

        _bookshelf.ebooks.forEach(ebook => {
            if (!_libraryDownloadedCovers[ebook.gpeId]
                && !_downloadingCovers[ebook.gpeId]) {
                _downloadingCovers[ebook.gpeId] = true;
                _promisesEvents.push(
                    downloadFile(
                        imageUrlTreatment(ebook.coverUrl),
                        DEFAULT_IMAGE_NAME,
                        `${_fileDirectory}/${ebook.gpeId}`,
                    ).then(() => {
                        _dispatch({
                            type: 'LIBRARY-ADD_DOWNLOADED_COVERS',
                            payload: { [ebook.gpeId]: { file: `${ebook.gpeId}/${DEFAULT_IMAGE_NAME}` } },
                        });
                    }).catch(() => {
                        Log.error("ERROR IMAGE DOWNLOAD:", ebook.gpeId);
                        downloadFailed[ebook.gpeId] = ebook.coverUrl;
                        delete _downloadingCovers[ebook.gpeId];
                    })
                );
            }
        });

        _showcases.forEach(showcase => {
            showcase.products
                && showcase.products.forEach(product => {
                    if (!_libraryDownloadedCovers[product.artID]
                        && !_downloadingCovers[product.artID]) {
                        _downloadingCovers[product.artID] = true;
                        _promisesEvents.push(
                            downloadFile(
                                imageUrlTreatment(product.galleryURL),
                                DEFAULT_IMAGE_NAME,
                                `${_fileDirectory}/${product.artID}`,
                            ).then(() => {
                                _dispatch({
                                    type: 'LIBRARY-ADD_DOWNLOADED_COVERS',
                                    payload: { [product.artID]: { file: `${product.artID}/${DEFAULT_IMAGE_NAME}` } },
                                });
                            }).catch(() => {
                                Log.error("ERROR IMAGE DOWNLOAD:", product.artID);
                                downloadFailed[product.artID] = product.galleryURL;
                                delete _downloadingCovers[product.artID];
                            })
                        );
                    }
                });
        });

        _promisesEvents.length
            && Promise.all(_promisesEvents)
                .then(() => checkDownloadFailed(_dispatch, _appStateStorageDir));
    }
}

function checkDownloadFailed(
    _dispatch: DispatchType,
    _appStateStorageDir: string,
) {
    if (Config.platform !== 'web'
        && Object.keys(downloadFailed).length) {
        clearTimeout(downloadTimeout);

        downloadTimeout = setTimeout(() => {
            const _downloadingCovers: { [key: string]: boolean } = {};
            const _fileDirectory = getAssetDirectory(_appStateStorageDir, 'covers');
            const _retryPromises: Array<{}> = [];

            Object.keys(downloadFailed).forEach(gpeId => {
                if (!_downloadingCovers[gpeId]) {
                    _downloadingCovers[gpeId] = true;
                    _retryPromises.push(
                        downloadFile(
                            imageUrlTreatment(downloadFailed[gpeId]),
                            DEFAULT_IMAGE_NAME,
                            `${_fileDirectory}/${gpeId}`,
                        ).then(() => {
                            _dispatch({
                                type: 'LIBRARY-ADD_DOWNLOADED_COVERS',
                                payload: { [gpeId]: { file: `${gpeId}/${DEFAULT_IMAGE_NAME}` } },
                            });
                            delete downloadFailed[gpeId];
                        }).catch(() => {
                            Log.error("ERROR IMAGE DOWNLOAD:", gpeId);
                            delete _downloadingCovers[gpeId];
                        })
                    );
                }
            });

            _retryPromises.length
                && Promise.all(_retryPromises)
                    .then(() => checkDownloadFailed(_dispatch, _appStateStorageDir));
        }, 60000);
    }
};

function getCoverImageUri(
    _ebook: EbookInterface,
    _appStateStorageDir: string | null,
    _libraryDownloadedCovers: { [key: string]: { file: string } },
): string {
    if (Config.platform === 'web')
        return imageUrlTreatment(_ebook.coverUrl);

    if (!_appStateStorageDir
        || !_libraryDownloadedCovers[_ebook.gpeId]
        || !_libraryDownloadedCovers[_ebook.gpeId].file)
        return '';

    const _fileDirectory = getAssetDirectory(_appStateStorageDir, 'covers');

    return `file://${_fileDirectory}/${_libraryDownloadedCovers[_ebook.gpeId].file}`;
}

function removeDownloadedCover(
    _dispatch: DispatchType,
    _ebook: EbookInterface,
    _appStateStorageDir: string | null,
): void {
    if (_appStateStorageDir) {
        const _fileDirectory = getAssetDirectory(_appStateStorageDir, 'covers');

        removeDirectory(`file://${_fileDirectory}/${_ebook.gpeId}`)
            .then(() => {
                _dispatch({
                    type: 'LIBRARY-REMOVE_DOWNLOADED_COVERS',
                    payload: _ebook.gpeId,
                });
            })
            .catch((error: any) => { });
    }
}

function redownloadCover(
    _dispatch: DispatchType,
    _appStateStorageDir: string,
    _ebook: EbookInterface,
) {
    const _errorCallback = () => {
        Log.error("ERROR IMAGE DOWNLOAD:", _ebook.gpeId);
        _dispatch({
            type: 'LIBRARY-REMOVE_DOWNLOADED_COVERS',
            payload: _ebook.gpeId,
        });
        downloadFailed[_ebook.gpeId] = _ebook.coverUrl;
        checkDownloadFailed(_dispatch, _appStateStorageDir);
    };

    const _callback = () => {
        _dispatch({
            type: 'LIBRARY-ADD_DOWNLOADED_COVERS',
            payload: { [_ebook.gpeId]: { file: `${_ebook.gpeId}/${DEFAULT_IMAGE_NAME}` } },
        });
    };

    if (Config.platform !== 'web') {
        const _fileDirectory = getAssetDirectory(_appStateStorageDir, 'covers');

        downloadFile(
            imageUrlTreatment(_ebook.coverUrl),
            DEFAULT_IMAGE_NAME,
            `${_fileDirectory}/${_ebook.gpeId}`,
        ).then(() => {
            const _cover = `${_fileDirectory}/${_ebook.gpeId}/${DEFAULT_IMAGE_NAME}`;

            directoryExist(_cover)
                .then((fileExists: boolean) => fileExists
                    ? _callback()
                    : _errorCallback())
                .catch(_errorCallback);

        }).catch(_errorCallback);
    }
}

function changeBookCategory(
    _dispatch: DispatchType,
    _userStateAuthToken: string | null,
    _userStateAuth: { token: string } | null,
    _bookGuid: string,
    _shelfId: string,
    _callback?: (response: ResponseInterface) => void,
) {
    if (_userStateAuthToken
        && _userStateAuth) {

        return services.library(
            _dispatch,
            {
                moveBookToCategory: {
                    urlParams: {
                        "companyId": Config.context.company,
                        "applicationalContextId": Config.context.applicationContext,
                        "readerAppId": Config.context.appId[Config.platform],
                        "bookGuid": _bookGuid,
                        "newCategoryId": _shelfId,
                        "authToken": _userStateAuthToken,
                    },
                    headers: { "x-token": _userStateAuth.token }
                }
            },
            _callback
        );
    }
}

function changeBookOrder(
    _dispatch: DispatchType,
    _coverId: string,
    _oldIndex: number | undefined,
    _shelfId: number | string | undefined,
    _bookshelf: {
        shelfs: Array<CategoryInterface>,
        showcases: Array<{ title: string, ebooksList: Array<EbookInterface> }>
    } | null,
) {
    if (typeof _oldIndex !== 'undefined'
        && typeof _shelfId !== 'undefined'
        && _bookshelf
        && _bookshelf.shelfs.length) {
        for (const shelf of _bookshelf.shelfs) {
            if (shelf.id === _shelfId) {
                const newIndex = shelf.ebooksList.findIndex((ebook) => String(ebook.gpeId) === String(_coverId));
                if (newIndex > -1) {
                    const element = shelf.ebooksList[_oldIndex];
                    shelf.ebooksList.splice(_oldIndex, 1);
                    shelf.ebooksList.splice(newIndex, 0, element);
                    MANUAL_SORTED_SHELFS[shelf.id] = shelf;
                    _dispatch({
                        type: 'LIBRARY-BOOKSHELF',
                        payload: { ..._bookshelf },
                    });
                }
                break;
            }
        }
    }
}

export {
    setSearchDispatch,
    setShelfsListDispatch,
    setContentDispatch,
    setShelfOptionsDispatch,
    setShelfsListDropdownDispatch,
    toggleShelfOptions,
    toggleShelfsListDropdown,
    toggleSearch,
    toggleContentScroll,
    toggleShelfsListHover,
    toggleMoveToShelf,
    toggleManualSorting,
    setShelfZone,
    toggleShelfHighlight,
    checkShelfZone,
    setCoverZone,
    toggleCoverHighlight,
    checkCoverZone,
    toggleCoverDraggingIcon,
    getBookshelf,
    doCreateBookshelf,
    changeBookshelf,
    clearSelectBookshelf,
    doRenameBookshelf,
    doDeleteBookshelf,
    getShelf,
    getSelectedShelf,
    searchFilterBookshelf,
    doDeleteBookFromUserBookshelf,
    openReadItemId,
    openSample,
    sortItemsByAuthorsOrTitle,
    getAllShowcases,
    getCoverImageUri,
    removeDownloadedCover,
    redownloadCover,
    changeBookCategory,
    changeBookOrder,
}