import React, { MutableRefObject } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faApple } from '@fortawesome/free-brands-svg-icons';

import Config, { Log } from '../../../../../config';
import SocialButton from '../SocialButton';
import { ContextTheme } from '../../../../../styles';
import { Store } from '../../../../../store';
import { socialLoginToken } from '../../../../../middlewares/UserMiddleware';
import { DispatchType } from '../../../../../interfaces/store';

declare const window: any;

function AppleLogin(props: { keepLoggedIn: boolean, onPress?: Function, fromRegist?: boolean }) {
    const { dispatch } = React.useContext(Store);

    const dispatchRef: MutableRefObject<DispatchType> = React.useRef(dispatch);
    const { keepLoggedIn, onPress, fromRegist } = React.useMemo(() => props, [props]);

    const injectScript = React.useCallback(() => {
        const userHandler = (token: string, code: string) => {
            token
                && socialLoginToken(
                    dispatchRef.current,
                    Config.social.apple.provider,
                    token,
                    fromRegist,
                    keepLoggedIn,
                );
        };

        const initializeEventListeners = () => {
            // Listen for authorization success
            document.removeEventListener("AppleIDSignInOnSuccess", () => { });
            document.addEventListener("AppleIDSignInOnSuccess", (data: any) => {
                // handle successful response
                userHandler(data.detail.authorization['id_token'], data.detail.authorization['code']);
            });

            // Listen for authorization failures
            document.removeEventListener("AppleIDSignInOnFailure", () => { });
            document.addEventListener("AppleIDSignInOnFailure", (error) => {
                // handle error.
            });
        };

        const appleLoad = () => {
            window.AppleID.auth.init({
                clientId: Config.context.social.apple.clientId,
                scope: "name email",
                redirectURI: `${window.location.origin}/login`,
                // state: "[STATE]",
                nonce: Config.context.social.apple.nonce,
                usePopup: true
            });

            initializeEventListeners();

            window.AppleID.auth.signIn();
        };

        // inject script
        const scriptId = 'apple-api';
        const elem = document.getElementById(scriptId);
        elem && appleLoad();
        !elem && ((document: any, src: string, id: string) => {
            return new Promise((resolve, reject) => {
                // let body = document.getElementsByTagName('body')[0];
                let tag = document.createElement('script');

                tag.id = id;
                tag.type = 'text/javascript';
                tag.async = false; // Load in order

                const handleLoad = () => { appleLoad(); resolve(src); }
                const handleReject = () => { reject(src) }

                // tag.onreadystatechange = handleCallback;
                tag.addEventListener('load', handleLoad)
                tag.addEventListener('error', handleReject);
                tag.src = src;
                // body.appendChild(tag);
                document.head.appendChild(tag);
                return tag;
            });
        })(window.document, Config.social.apple.sdk, scriptId);
    }, [keepLoggedIn, fromRegist]);

    return React.useMemo(() => {
        Log.debug('component: AppleLogin');

        return (
            <SocialButton
                onPress={() => onPress
                    ? onPress()
                    : injectScript()}>
                <FontAwesomeIcon
                    fontSize={16}
                    color={ContextTheme.palette.appleBlack}
                    icon={faApple} />
            </SocialButton>
        );
    }, [onPress, injectScript]);
}

export { AppleLogin as default }