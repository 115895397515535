import React, { MutableRefObject } from 'react';
import { View } from 'react-native';

import { Store } from '../../../store';
import { PopupStyles } from '../../../styles';
import GpeButton from '../../GpeButton';
import { getTranslation } from '../../../middlewares/AppMiddleware';
import { doDeleteBookshelf } from '../../../middlewares/LibraryMiddleware';
import Config from '../../../config';
import ShelfDeleteMsgSuccess from './ShelfDeleteMsgSuccess';
import GpeText from '../../GpeText';
import { DispatchType } from '../../../interfaces/store';
import { closePopup } from '../../../middlewares/commons';

function ShelfDeleteForm(props: any) {
    const { state, dispatch } = React.useContext(Store);

    const translation = React.useMemo(() => getTranslation(state.app.language), [state.app.language]);
    const dispatchRef: MutableRefObject<DispatchType> = React.useRef(dispatch);
    const _shelf = React.useMemo(() => ({ id: props.id, name: props.name }), [props.id, props.name]);
    const _bookshelfSelectedId = React.useMemo(() => (
        state.library.bookshelfSelected
            ? state.library.bookshelfSelected.id
            : undefined
    ), [state.library.bookshelfSelected]);

    return React.useMemo(() => (
        <View style={{
            width: '100%',
            maxWidth: 600,
            margin: 'auto',
            alignItems: 'center',
        }}>
            <GpeText
                style={[
                    PopupStyles.h1,
                    Config.contextName === 'bertrand'
                        ? {
                            fontSize: 16,
                        } : null
                ]}>
                {translation('library.delete.bookshelf.form.title')}
            </GpeText>
            {Config.contextName !== 'bertrand'
                ? (
                    <GpeText
                        style={PopupStyles.h3}>
                        {translation('library.delete.bookshelf.form.subtitle', [decodeURIComponent(_shelf.name)])}
                    </GpeText>
                ) : null}

            <GpeButton
                style={PopupStyles.button}
                onPress={() => state.app.uid
                    && state.user.auth
                    && state.user.authToken
                    && doDeleteBookshelf(
                        dispatchRef.current,
                        state.app.uid,
                        state.user.auth,
                        state.user.authToken,
                        _bookshelfSelectedId,
                        _shelf.id,
                        Config.contextName === 'bertrand'
                            ? <ShelfDeleteMsgSuccess />
                            : null
                    )}>
                {translation('app.form.confirm.button.text')}
            </GpeButton>

            {Config.contextName === 'bertrand'
                ? null
                : (
                    <GpeButton
                        style={[
                            PopupStyles.button,
                            { backgroundColor: '#d9d9d9' }
                        ]}
                        onPress={() => closePopup(dispatchRef.current)}>
                        <GpeText
                            style={PopupStyles.blackColor}>
                            {translation('app.form.cancel.button.text')}</GpeText>
                    </GpeButton>
                )}
        </View>
    ), [
        translation,
        state.app.uid,
        state.user.auth,
        state.user.authToken,
        _bookshelfSelectedId,
        _shelf,
    ]);
}

export { ShelfDeleteForm as default }