import React from 'react';
import { View } from 'react-native';

import { Store } from '../../../store';
import { ContextTheme } from '../../../styles';
import GpeText from '../../GpeText';

function AppInfo() {
    const { state } = React.useContext(Store);

    return React.useMemo(() => {
        return (
            <View>
                <GpeText
                    style={{ fontFamily: ContextTheme.font.regular }}>
                    <GpeText
                        style={{ fontFamily: ContextTheme.font.semibold }}>
                        {`utilizador: `}
                    </GpeText>
                    {state.user.auth?.email}
                </GpeText>
                <GpeText
                    style={{ fontFamily: ContextTheme.font.regular }}>
                    <GpeText
                        style={{ fontFamily: ContextTheme.font.semibold }}>
                        {`armazenamento: `}
                    </GpeText>
                    {state.app.storageDir}
                </GpeText>
                <GpeText
                    style={{ fontFamily: ContextTheme.font.regular }}>
                    <GpeText
                        style={{ fontFamily: ContextTheme.font.semibold }}>
                        {`assets: `}
                    </GpeText>
                    {state.app.assetsVersion}
                </GpeText>
            </View>
        )
    }, [
        state.app.storageDir,
        state.app.assetsVersion,
        state.user.auth,
    ]);
}

export { AppInfo as default }