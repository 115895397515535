import React from 'react';

import Config, { Log } from '../config';
import { Store } from '../store';
import { Redirect } from '../components/GpeRouter';
import Search from '../components/Search';
import BookshelfInitializer from '../components/BookshelfInitializer';

function SearchScreen() {
    const { state } = React.useContext(Store);

    return React.useMemo(() => {
        Log.debug('screen: SEARCH');

        return state.user.auth
            ? (
                <BookshelfInitializer>
                    <Search />
                </BookshelfInitializer>
            ) : <Redirect to={Config.routes.login} />
    }, [state.user.auth]);
}

export { SearchScreen as default }