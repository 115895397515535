import React from 'react';
import { Image, ImageStyle } from 'react-native';
import { getAssetImageUri } from '../../../../middlewares/AppMiddleware';
import { Store } from '../../../../store';

function BertrandLogo(props: { style?: ImageStyle }) {
    const { state } = React.useContext(Store);

    return React.useMemo(() => {

        return (
            <Image
                style={{
                    resizeMode: 'contain',
                    aspectRatio: 1,
                }}
                source={{ uri: getAssetImageUri('logo.main', state.app.downloadedAssets) }}
                {...props} />
        );
    }, [props, state.app.downloadedAssets]);
}

export { BertrandLogo as default }