import React from 'react';

import { Log } from '../config';
import Sample from '../components/Sample';

function SampleScreen() {
    return React.useMemo(() => {
        Log.debug('screen: SAMPLE');

        return <Sample />
    }, []);
}

export { SampleScreen as default };