import React, { MutableRefObject } from 'react';
import {
    View,
    TextInput,
    Platform,
    TouchableWithoutFeedback,
    TouchableOpacity,
} from 'react-native';

import Config, { Log } from '../../../../config';
import { Store } from '../../../../store';
import { RegistStyles, ContextTheme, dimensionWidth } from '../../../../styles';
import { Link } from '../../../GpeRouter';
import GpeButton from '../../../GpeButton';
import { doRegist } from '../../../../middlewares/UserMiddleware';
import ModalBackground from '../../../ModalBackground';
import Icon from '../../../Icon';
import { openLink } from '../../../../middlewares/AppMiddleware';
import DimensionsEventTimeout from '../../../DimensionsEvent';
import GpeScrollView from '../../../GpeScrollView';
import { getPasswordRules } from '../../../../middlewares/UserMiddleware';
import GpeText from '../../../GpeText';
import ProgressBar from './ProgressBar';
import FacebookLogin from './FacebookLogin';
import GoogleLogin from './GoogleLogin';
import AppleLogin from './AppleLogin';
import { DispatchType } from '../../../../interfaces/store';

function RegistForm() {
    const { state, dispatch } = React.useContext(Store);

    /** MEDIA */
    const titleMedia = React.useMemo(() => ({ xs: 22, lg: 32 }), []);
    const rulesFlexDirection = React.useMemo(() => ({ xs: 'column', sm: 'row', md: 'row', lg: 'row' }), []);
    const contextConfig = React.useMemo(() => (
        Config.contextParams
    ), []);

    /** LOCAL STATE */
    const [rulesFetch, setRulesFetched] = React.useState<Array<string> | null>(null);
    const [progress, setProgress] = React.useState(0);
    const [rulesOrientation, setRulesOrientation] = React.useState(dimensionWidth(rulesFlexDirection));
    const [form, setForm] = React.useState<{
        email: string,
        emailConfirm: string,
        password: string,
        conditions: boolean,
        secureTextEntry: boolean,
        titleSize: number | undefined,
        notValid: { [key: string]: string | undefined },
    }>({
        email: '',
        emailConfirm: '',
        password: '',
        conditions: false,
        secureTextEntry: true,
        titleSize: dimensionWidth(titleMedia),
        notValid: {},
    });
    const [rulesValidation, setRules] = React.useState<{
        uppercase: boolean,
        lowercase: boolean,
        number: boolean,
        specialChar: boolean,
        size: boolean,
        repeat: boolean,
        equalUsername: boolean,
    }>({
        uppercase: false,
        lowercase: false,
        number: false,
        specialChar: false,
        size: false,
        repeat: false,
        equalUsername: false,
    });

    const dispatchRef: MutableRefObject<DispatchType> = React.useRef(dispatch);

    /* Mapping rules array to object */
    const rulesCheck = React.useMemo(() => {
        var rulesReturn = {
            uppercase: false,
            lowercase: false,
            number: false,
            specialChar: false,
            size: 0,
            repeat: false,
            equalUsername: false,
        }
        if (rulesFetch) {
            rulesFetch.forEach((element: string | any) => {
                if (element === "LOWERCASE_LETTER")
                    rulesReturn.lowercase = true;
                else if (element === "UPPERCASE_LETTER")
                    rulesReturn.uppercase = true;
                else if (element === "ONE_DIGIT")
                    rulesReturn.number = true;
                else if (element === "SPECIAL_CHARACTER")
                    rulesReturn.specialChar = true;
                else if (element === "EQUAL_CONTIGUOUS_CHARACTERS")
                    rulesReturn.repeat = true;
                else if (element === "EQUAL_USERNAME")
                    rulesReturn.equalUsername = true;
                else {
                    let size = element.split(":");
                    if (size[0] === "MIN_LENGTH_CHAR")
                        rulesReturn.size = size[1];
                }
            })
        }
        return rulesReturn;
    }, [rulesFetch]);

    /** RESIZE LISTENER */
    DimensionsEventTimeout(() => {
        const _titleSize = dimensionWidth(titleMedia);
        if (_titleSize !== form.titleSize) {
            setForm({
                ...form,
                titleSize: _titleSize,
            });
        }
        const _rulesOrientation = dimensionWidth(rulesFlexDirection);
        if (_rulesOrientation !== rulesOrientation) {
            setRulesOrientation(_rulesOrientation);
        }
    });

    const updateProgressBar = React.useCallback((valid: boolean, password: string) => {
        /* Very Strong Criteria */
        const doubleuppercaseReg = /.*[A-Z].*[A-Z].*/;
        const doublelowercaseReg = /.*[a-z].*[a-z].*/;
        const doubledigitoReg = /.*\d.*\d.*/;
        const rulesCompleted = Object.values(rulesValidation).reduce((a, item) => a + (item === true ? 1 : 0), 0)

        if (valid && doubleuppercaseReg.test(password) && doublelowercaseReg.test(password) && doubledigitoReg.test(password)) setProgress(100);
        else if (valid) setProgress(75);
        else if (!valid && rulesCompleted > 3) setProgress(50);
        else if (password.length) setProgress(25);
        else setProgress(0);
    }, [
        rulesValidation,
    ]);

    const checkPasswordRules = React.useCallback((password: string) => {
        /* REGEX */
        const specialcharReg = /[!@#$%^&*()_+\-=\]{};':"\\|,.<>?]+/;
        const consecutivecharReg = /(.)\1\1/;
        const lowercaseReg = /[a-z]/;
        const uppercaseReg = /[A-Z]/;
        const digitoReg = /\d/
        let valid = false;

        let rulesCurrentValidation = {
            uppercase: !(rulesCheck.uppercase && !uppercaseReg.test(password)),
            lowercase: !(rulesCheck.lowercase && !lowercaseReg.test(password)),
            number: !(rulesCheck.number && !digitoReg.test(password)),
            specialChar: !(rulesCheck.specialChar && !specialcharReg.test(password)),
            size: !(password.length < rulesCheck.size),
            repeat: !(rulesCheck.repeat && consecutivecharReg.test(password)),
            equalUsername: !(rulesCheck.equalUsername && password === form.email.substring(0, form.email.lastIndexOf("@"))),
        }

        valid = Object.values(rulesCurrentValidation).every(Boolean);
        setRules(rulesCurrentValidation);
        updateProgressBar(valid, password);

        return valid;
    }, [
        form.email,
        rulesCheck.equalUsername,
        rulesCheck.lowercase,
        rulesCheck.number,
        rulesCheck.repeat,
        rulesCheck.size,
        rulesCheck.specialChar,
        rulesCheck.uppercase,
        updateProgressBar,
    ]);

    const formValid = React.useCallback((onlyValidateConditions = false) => {
        const notValid: { email?: string, emailConfirm?: string, password?: string, conditions?: string } = {};

        if (!onlyValidateConditions) {
            form.email.match(new RegExp('@', 'g'))?.length !== 1 && (notValid.email = 'e-mail inválido!');
            form.email.lastIndexOf('.') < 3 && (notValid.email = 'e-mail inválido!');
            (form.email.lastIndexOf('@') < 1
                || form.email.lastIndexOf('.') < form.email.lastIndexOf('@')) && (notValid.email = 'e-mail inválido!');

            form.emailConfirm.match(new RegExp('@', 'g'))?.length !== 1 && (notValid.emailConfirm = 'e-mail inválido!');
            form.emailConfirm.lastIndexOf('.') < 3 && (notValid.emailConfirm = 'e-mail inválido!');
            (form.emailConfirm.lastIndexOf('@') < 1
                || form.emailConfirm.lastIndexOf('.') < form.emailConfirm.lastIndexOf('@')) && (notValid.emailConfirm = 'e-mail inválido!');

            form.email !== form.emailConfirm && (notValid.emailConfirm = 'e-mail diferente!');

            form.password.length < 1 && (notValid.password = 'insira a password!');

            !checkPasswordRules(form.password) && (notValid.password = 'A password inserida não é válida. Para sua segurança, deve cumprir todos os requisitos indicados.')
        }

        !form.conditions && (notValid.conditions = 'é necessário aceitar as condições!');

        Object.keys(notValid).length && setForm({
            ...form,
            notValid: notValid,
        });

        return !Object.keys(notValid).length;
    }, [
        form,
        checkPasswordRules,
    ]);

    const registSubmit = React.useCallback(() => (
        !state.user.registError
        && formValid()
        && state.app.uid
        && doRegist(dispatchRef.current, state.app.uid, form)
    ), [
        form,
        formValid,
        state.app.uid,
        state.user.registError,
    ]);

    const handleChange = React.useCallback((type: string, value: string | boolean) => {
        state.user.registError && dispatchRef.current({
            type: 'USER-REGIST_ERROR',
            payload: '',
        });

        const notValid: any = form.notValid;
        notValid[type] && (delete notValid[type]);

        setForm({
            ...form,
            [type]: value,
            notValid: notValid,
        });
    }, [
        form,
        state.user.registError
    ]);

    React.useEffect(() => {
        !rulesFetch
            && getPasswordRules(dispatchRef.current, setRulesFetched);
    }, [rulesFetch]);

    return React.useMemo(() => {
        Log.debug('component: RegistForm');

        return (
            <GpeScrollView
                horizontal={false}
                style={{ backgroundColor: ContextTheme.palette.white }}
                contentContainerStyle={{ padding: 10, alignItems: 'center' }}>
                {Config.platform === 'web'
                    ? (
                        <View
                            style={{
                                alignItems: 'flex-end',
                                position: 'absolute',
                                top: 0,
                                right: 0,
                                zIndex: 1,
                            }}>
                            <TouchableOpacity
                                onPress={() => openLink(`${contextConfig.libraryUrl}`)}>
                                <Icon
                                    style={{
                                        color: '#ffffff',
                                        marginTop: 15,
                                        marginRight: 15,
                                    }}
                                    icon='*' />
                            </TouchableOpacity>
                        </View>
                    ) : null}

                <ModalBackground color={ContextTheme.palette.registColor} />

                <View
                    style={RegistStyles.container}>
                    <GpeText style={[RegistStyles.h1, { fontSize: form.titleSize }]}>
                        {'novo registo wook'}
                    </GpeText>

                    <View
                        style={{
                            backgroundColor: 'rgba(255,255,255,0.85)',
                            padding: 10,
                            width: '100%',
                            borderRadius: 2,
                        }}>

                        <GpeText style={[RegistStyles.text, { marginBottom: 10 }]}>
                            {'Para usufruir de imediato de todas as funcionalidades do nosso site, crie um registo rápido indicando apenas o endereço de email e password'}
                        </GpeText>

                        {Object.keys(form.notValid).length
                            ? <GpeText style={RegistStyles.error}>{form.notValid[Object.keys(form.notValid)[0]]}</GpeText>
                            : state.user.registError
                                ? <GpeText style={RegistStyles.error}>{state.user.registError}</GpeText>
                                : null}

                        <View style={[RegistStyles.inputContainer, form.notValid.email ? { borderColor: 'red' } : null]}>
                            <Icon icon='u' style={{ color: '#ccc', backgroundColor: ContextTheme.palette.white }} />
                            <TextInput
                                style={RegistStyles.input}
                                contextMenuHidden={true}
                                textContentType={'emailAddress'}
                                onChangeText={value => handleChange('email', value)}
                                onSubmitEditing={registSubmit}
                                value={form.email}
                                {...(Platform.OS !== "web" ? { autoCompleteType: "email" } : null)}
                                disableFullscreenUI={true}
                                autoCapitalize="none"
                                placeholder="endereço de email" />
                        </View>

                        <View style={[RegistStyles.inputContainer, form.notValid.emailConfirm ? { borderColor: 'red' } : null]}>
                            <Icon icon='u' style={{ color: '#ccc', backgroundColor: ContextTheme.palette.white }} />
                            <TextInput
                                style={RegistStyles.input}
                                contextMenuHidden={true}
                                textContentType={'emailAddress'}
                                onChangeText={value => handleChange('emailConfirm', value)}
                                onSubmitEditing={registSubmit}
                                value={form.emailConfirm}
                                {...(Platform.OS !== "web" ? { autoCompleteType: "email" } : null)}
                                disableFullscreenUI={true}
                                autoCapitalize="none"
                                placeholder="confirmar endereço de email" />
                        </View>

                        <View style={[RegistStyles.inputContainer, form.notValid.password ? { borderColor: 'red' } : null]}>
                            <Icon icon='U' style={{ color: '#ccc', backgroundColor: ContextTheme.palette.white }} />
                            <TextInput
                                style={RegistStyles.input}
                                contextMenuHidden={true}
                                textContentType={'password'}
                                onChangeText={value => { handleChange('password', value); checkPasswordRules(value) }}
                                onSubmitEditing={registSubmit}
                                value={form.password}
                                {...(Platform.OS !== "web" ? { autoCompleteType: "password" } : null)}
                                secureTextEntry={form.secureTextEntry}
                                disableFullscreenUI={true}
                                autoCapitalize="none"
                                placeholder="password" />
                            <TouchableOpacity
                                onPress={() => handleChange('secureTextEntry', !form.secureTextEntry)}>
                                <Icon icon={form.secureTextEntry ? 't' : 'T'} style={{ color: ContextTheme.palette.registColor, backgroundColor: ContextTheme.palette.white }} />
                            </TouchableOpacity>
                        </View>

                        {form.password.length
                            ? (
                                <View style={{ marginBottom: 10 }}>
                                    <ProgressBar style={{ width: "100%" }} progress={progress} />
                                    <GpeText
                                        style={RegistStyles.rulesIntroLabel}>
                                        {'A tua password deve ter:'}
                                    </GpeText>
                                    <View
                                        style={[
                                            RegistStyles.rowRules,
                                            {
                                                width: '100%',
                                                justifyContent: 'flex-start',
                                                flexDirection: rulesOrientation
                                            }
                                        ]}>
                                        <GpeText style={rulesOrientation === 'row' ? RegistStyles.desktopRulesRowStart : null} >
                                            <Icon
                                                icon="y"
                                                style={[
                                                    RegistStyles.checkIcon,
                                                    { color: rulesValidation.size ? 'green' : 'white' }
                                                ]} />
                                            <GpeText style={RegistStyles.label}>
                                                {`Um mínimo de ${rulesCheck.size} carateres`}
                                            </GpeText>
                                        </GpeText>
                                        <GpeText style={rulesOrientation === 'row' ? RegistStyles.desktopRulesRowEnd : null}>
                                            <Icon
                                                icon="y"
                                                style={[
                                                    RegistStyles.checkIcon,
                                                    { color: rulesValidation.number ? 'green' : 'white' }
                                                ]} />
                                            <GpeText style={RegistStyles.label}>
                                                {'Pelo menos um algarismo'}
                                            </GpeText>
                                        </GpeText>
                                        <GpeText style={rulesOrientation === 'row' ? RegistStyles.desktopRulesRowStart : null}>
                                            <Icon
                                                icon="y"
                                                style={[
                                                    RegistStyles.checkIcon,
                                                    { color: rulesValidation.uppercase ? 'green' : 'white' }
                                                ]} />
                                            <GpeText style={RegistStyles.label}>
                                                {'Pelo menos uma maiúscula'}
                                            </GpeText>
                                        </GpeText>
                                        <GpeText style={rulesOrientation === 'row' ? RegistStyles.desktopRulesRowEnd : null}>
                                            <Icon
                                                icon="y"
                                                style={[
                                                    RegistStyles.checkIcon,
                                                    { color: rulesValidation.specialChar ? 'green' : 'white' }
                                                ]} />
                                            <GpeText style={RegistStyles.label}>
                                                {'Pelo menos um carater especial'}
                                            </GpeText>
                                        </GpeText>
                                        <GpeText style={rulesOrientation === 'row' ? RegistStyles.desktopRulesRowStart : null}>
                                            <Icon
                                                icon="y"
                                                style={[
                                                    RegistStyles.checkIcon,
                                                    { color: rulesValidation.lowercase ? 'green' : 'white' }
                                                ]} />
                                            <GpeText style={RegistStyles.label}>
                                                {'Pelo menos uma minúscula'}
                                            </GpeText>
                                        </GpeText>
                                        <GpeText style={rulesOrientation === 'row' ? RegistStyles.desktopRulesRowEnd : null}>
                                            <Icon
                                                icon="y"
                                                style={[
                                                    RegistStyles.checkIcon,
                                                    { color: rulesValidation.equalUsername ? 'green' : 'white' }
                                                ]} />
                                            <GpeText style={RegistStyles.label}>
                                                {'Diferente do seu endereço de email'}
                                            </GpeText>
                                        </GpeText>
                                    </View>
                                </View>
                            ) : null}

                        <View style={{ flexDirection: 'row', marginBottom: 10 }}>
                            <TouchableWithoutFeedback
                                onPress={() => handleChange('conditions', !form.conditions)}>
                                <View
                                    style={{ width: '100%', flexDirection: 'row' }}>
                                    <React.Fragment>
                                        <GpeText
                                            style={{
                                                fontFamily: 'WookIcones',
                                                fontSize: 15,
                                                lineHeight: 20,
                                                height: 20,
                                                width: 20,
                                                borderWidth: 1,
                                                borderColor: form.notValid.conditions ? 'red' : '#ccc',
                                                borderRadius: 2,
                                                textAlign: 'center',
                                                marginRight: 10,
                                                display: 'flex',
                                                justifyContent: 'center',
                                            }}>{form.conditions ? 'X' : null}</GpeText>
                                        <View style={{ flex: 1 }}>
                                            <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                                                <GpeText style={[RegistStyles.text, { textAlign: 'left' }]}>
                                                    {'Declaro que li e aceito os '}
                                                </GpeText>
                                                <TouchableOpacity
                                                    onPress={() => openLink(
                                                        Config.contextParams.conditionsUrl,
                                                        '_blank'
                                                    )}>
                                                    <GpeText style={[RegistStyles.text, { textDecorationLine: 'underline' }]}>
                                                        {'termos e condições de venda'}
                                                    </GpeText>
                                                </TouchableOpacity>
                                                <GpeText style={[RegistStyles.text, { textAlign: 'left' }]}>
                                                    {' e a '}
                                                </GpeText>
                                                <TouchableOpacity
                                                    onPress={() => openLink(
                                                        Config.contextParams.privacyUrl,
                                                        '_blank'
                                                    )}>
                                                    <GpeText style={[RegistStyles.text, { textDecorationLine: 'underline' }]}>
                                                        {'política de privacidade'}
                                                    </GpeText>
                                                </TouchableOpacity>
                                                <GpeText style={[RegistStyles.text, { textAlign: 'left' }]}>
                                                    {' da WOOK.'}
                                                </GpeText>
                                            </View>
                                        </View>
                                    </React.Fragment>
                                </View>
                            </TouchableWithoutFeedback>
                        </View>

                        <GpeButton
                            style={[RegistStyles.button, { width: '100%', maxWidth: undefined }]}
                            onPress={registSubmit}>
                            <GpeText style={{ fontFamily: ContextTheme.font.semibold, fontSize: 16 }}>
                                {'CONFIRMAR'}
                            </GpeText>
                        </GpeButton>

                        <GpeText style={[RegistStyles.text, { textAlign: 'center', marginBottom: 10 }]}>
                            {'ou registe-se com a sua conta'}
                        </GpeText>

                        <View style={[RegistStyles.row, { marginBottom: 10 }]}>
                            <View style={{ marginRight: 5, marginLeft: 5 }}>
                                <FacebookLogin
                                    fromRegist={true}
                                    keepLoggedIn={false}
                                    onPress={!form.conditions
                                        ? () => formValid(true)
                                        : undefined} />
                            </View>
                            <View style={{ marginRight: 5, marginLeft: 5 }}>
                                <GoogleLogin
                                    fromRegist={true}
                                    keepLoggedIn={false}
                                    onPress={!form.conditions
                                        ? () => formValid(true)
                                        : undefined} />
                            </View>
                            <View style={{ marginRight: 5, marginLeft: 5 }}>
                                <AppleLogin
                                    fromRegist={true}
                                    keepLoggedIn={false}
                                    onPress={!form.conditions
                                        ? () => formValid(true)
                                        : undefined} />
                            </View>
                        </View>

                        <Link
                            to={Config.routes.login}
                            style={RegistStyles.row}>
                            <View
                                style={{
                                    backgroundColor: ContextTheme.palette.lightGrey,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderRadius: 2,
                                    width: '100%',
                                    height: 40,
                                    padding: 8,
                                }}>
                                <GpeText
                                    style={{
                                        fontFamily: ContextTheme.font.regular,
                                        fontSize: 16,
                                        color: ContextTheme.palette.registColor
                                    }}>
                                    {'JÁ TEM CONTA? ACEDA AQUI'}
                                </GpeText>
                            </View>
                        </Link>
                    </View>
                </View>
            </GpeScrollView>
        )
    }, [
        form,
        rulesValidation,
        state.user.registError,
        progress,
        rulesCheck,
        rulesOrientation,
        contextConfig.libraryUrl,
        checkPasswordRules,
        formValid,
        handleChange,
        registSubmit,
    ]);
}

export { RegistForm as default }
