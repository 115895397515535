import React, { MutableRefObject } from 'react';

import Config, { Log } from '../../config';
import { DispatchType } from '../../interfaces/store';
import { doAssignPromoBooksInCampaign, getAuthToken } from '../../middlewares/UserMiddleware';
import { Store } from '../../store';
import { Redirect, useLocation } from '../GpeRouter';

function Offer() {
    const { state, dispatch } = React.useContext(Store);

    const dispatchRef: MutableRefObject<DispatchType> = React.useRef(dispatch);

    const location = useLocation();

    const pathToken = React.useMemo(() => (
        location.pathname.indexOf(Config.routes.offer) > -1
            ? location.pathname.split(`${Config.routes.offer}/`)[1]
            : ''
    ), [location]);

    React.useEffect(() => {
        state.user.auth
            && !state.user.authToken
            && !state.user.authTokenError
            && getAuthToken(dispatchRef.current, state.user.auth);

        state.user.auth
            && state.user.authToken
            && !state.user.assignPromoBooksInCampaign[state.user.auth.id]
            && doAssignPromoBooksInCampaign(
                dispatchRef.current,
                state.user.auth.id,
                state.user.authToken,
                pathToken
            );
    }, [
        state.user.auth,
        state.user.authToken,
        state.user.authTokenError,
        state.user.assignPromoBooksInCampaign,
        pathToken
    ]);

    /** RENDER */
    return React.useMemo(() => {
        Log.debug('component: Offer');

        return state.user.auth
            && state.user.auth.id
            && state.user.assignPromoBooksInCampaign[state.user.auth.id]
            ? <Redirect to={Config.routes.bookshelf} />
            : null;
    }, [
        state.user.auth,
        state.user.assignPromoBooksInCampaign,
    ]);
}

export { Offer as default }