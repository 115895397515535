import React from "react";
import {
    Image,
    TouchableOpacity,
    View,
} from "react-native";

import { Log } from '../../../../config';
import {
    LibraryStyles,
    ContextTheme,
} from '../../../../styles';
import TypeIcon from "../TypeIcon";
import { EbookInterface } from "../../../../interfaces/middlewares";
import GpeText from "../../../GpeText";
import { SimpleCoverInit } from "./utils";

function SimpleCover(
    props: {
        ebook: EbookInterface,
        width?: number,
        height?: number,
        showcaseRender?: boolean,
        bookshelfSample?: boolean,
    }
) {
    const {
        componentState,
        translation,
        imageSourceUri,
        downloadedVersion,
        openReadItemIdCallback,
        openPopupCallback,
        imageOnErrorCallback,
    } = SimpleCoverInit(props);

    /** RENDER */
    return React.useMemo(() => {
        Log.debug('component: SimpleCover');

        return (
            componentState.height
                && componentState.width
                ? (
                    <View
                        style={LibraryStyles.ebookContainer}>
                        <TouchableOpacity
                            onPress={openReadItemIdCallback}
                            onLongPress={openPopupCallback}>
                            <View style={{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                flex: 1,
                                justifyContent: 'center',
                                alignItems: 'center',
                                overflow: 'hidden',
                                flexWrap: 'wrap'
                            }}>
                                <GpeText
                                    style={{
                                        fontFamily: ContextTheme.font.regular,
                                        color: ContextTheme.palette.grey,
                                        textAlign: 'center',
                                        textTransform: 'uppercase',
                                        padding: 5,
                                        width: '100%',
                                    }}>
                                    {props.ebook.title}
                                </GpeText>
                            </View>
                            <Image
                                style={{ height: componentState.height, width: componentState.width }}
                                onError={imageOnErrorCallback}
                                source={imageSourceUri} />
                            <TypeIcon ebook={props.ebook} />
                        </TouchableOpacity>

                        {props.ebook.sample
                            && (
                                <GpeText
                                    style={{
                                        fontFamily: ContextTheme.font.regular,
                                        fontSize: 12,
                                        color: ContextTheme.palette.grey,
                                        marginTop: 5
                                    }}>
                                    {translation('library.bookshelf.ebook.sample.text')}
                                </GpeText>
                            )}

                        {downloadedVersion
                            && (
                                <GpeText
                                    style={{
                                        fontFamily: ContextTheme.font.regular,
                                        fontSize: 12,
                                        color: ContextTheme.palette.grey,
                                        marginTop: 5
                                    }}>
                                    {translation('library.bookshelf.ebook.offline.text')}
                                </GpeText>
                            )}
                    </View>
                ) : null
        )
    }, [
        props.ebook,
        componentState,
        translation,
        imageSourceUri,
        downloadedVersion,
        openReadItemIdCallback,
        imageOnErrorCallback,
        openPopupCallback,
    ]);
}

export { SimpleCover as default };