import React, { MutableRefObject } from 'react';
import { TouchableOpacity, View, Platform } from 'react-native';

import Config, { Log } from '../../../../../config';
import Hoverable from '../../../../Hoverable';
import { Link } from '../../../../GpeRouter';
import { MenuOptionInterface } from '../../../../../interfaces/components';
import Option from './Option';
import OnlineStateOption from './OnlineStateOption';
import Context from '../../../../ByContext';
import GpeText from '../../../../GpeText';
import { ContextTheme } from '../../../../../styles';
import { Store } from '../../../../../store';
import { openPopup } from '../../../../../middlewares/commons';
import VkdWdGNHbDFjdz09 from '../../../../Popup/components/VkdWdGNHbDFjdz09';
import { DispatchType } from '../../../../../interfaces/store';
import AppInfo from '../../../../Popup/components/AppInfo';

let _day = 0;

function Menu(props: { headerDispatch: Function }) {
    /** STORE */
    const { dispatch } = React.useContext(Store);

    const dispatchRef: MutableRefObject<DispatchType> = React.useRef(dispatch);
    const menuOptions: Array<MenuOptionInterface> = Context.MenuOptionsList();

    return React.useMemo(() => {
        Log.debug(`component: Menu`);

        return (
            <React.Fragment>
                <View testID='sidebar-menu'>
                    {Config.contextName !== 'bertrand'
                        ? (
                            <OnlineStateOption
                                headerDispatch={props.headerDispatch} />
                        ) : null}

                    {menuOptions.map((option: MenuOptionInterface, index: number) => (
                        <Hoverable key={index}>
                            {(hover: boolean) => (
                                <TouchableOpacity
                                    testID={option.testID ? option.testID : ''}
                                    onPress={option.onPress}>
                                    {option.accessibilityRole === 'link'
                                        && option.to
                                        ? <Link
                                            style={Platform.OS === 'web'
                                                ? { display: 'flex', textDecorationLine: 'none' }
                                                : { display: 'flex' }}
                                            to={option.to}>
                                            <Option
                                                drawBorder={index !== menuOptions.length - 1}
                                                option={option}
                                                hover={hover} /></Link>
                                        : <Option
                                            drawBorder={index !== menuOptions.length - 1}
                                            option={option}
                                            hover={hover} />}
                                </TouchableOpacity>
                            )}
                        </Hoverable>
                    ))}
                </View>

                <View
                    style={{
                        paddingTop: 24,
                        paddingRight: 20,
                        paddingBottom: 24,
                        paddingLeft: 20,
                    }}>
                    <TouchableOpacity
                        onPress={() => ++_day}
                        onLongPress={() => {
                            if (++_day === 13) openPopup(dispatchRef.current, <VkdWdGNHbDFjdz09 />);
                            else openPopup(dispatchRef.current, <AppInfo />);
                            _day = 0;
                        }}>
                        <View>
                            <GpeText
                                style={{ fontFamily: ContextTheme.font.regular, fontSize: 12, textAlign: 'right' }}>
                                {Config.appVersion}
                            </GpeText>
                        </View>
                    </TouchableOpacity>
                </View>
            </React.Fragment>
        )
    }, [props.headerDispatch, menuOptions]);
}

export { Menu as default }