import React, { MutableRefObject } from 'react';
import { TouchableOpacity, View } from 'react-native';

import Config from '../../../config';
import GpeText from '../../GpeText';
import { useHistory } from '../../GpeRouter';
import BaseStyles from '../../../styles';
import { openLink } from '../../../middlewares/AppMiddleware';
import { Store } from '../../../store';
import { closePopup } from '../../../middlewares/commons';
import Base64 from '../../../middlewares/commons/Base64';
import { DispatchType } from '../../../interfaces/store';

let _month = 0;

function VkdWdGNHbDFjdz09() {
    const { dispatch } = React.useContext(Store);

    const dispatchRef: MutableRefObject<DispatchType> = React.useRef(dispatch);

    const history = useHistory();
    const timeout: MutableRefObject<NodeJS.Timeout | undefined> = React.useRef();

    const [localState, setLocalState] = React.useReducer((
        _localState: { countdown: number, valid: boolean },
        _payload: { countdown?: number, valid?: boolean },
    ) => ({ ..._localState, ..._payload }), { countdown: 10, valid: false });

    const close = React.useCallback(() => {
        history.push(Config.routes.firstpage);
        closePopup(dispatchRef.current);
    }, [history]);

    return React.useMemo(() => {
        timeout.current
            && clearTimeout(timeout.current);

        !localState.valid
            && (timeout.current = setTimeout(() => {
                localState.countdown > 0
                    && setLocalState({ countdown: --localState.countdown });
                localState.countdown < 1
                    && close();
            }, 1000));

        return (localState.valid
            ? (
                <View
                    style={{ alignItems: 'center' }}>
                    <GpeText style={BaseStyles.h3}>{Base64.atob(Base64.atob('VW1sallYSmtieUJRYVc1MGJ3PT0='))}</GpeText>
                    <TouchableOpacity onPress={() => openLink(Base64.atob(Base64.atob('YUhSMGNITTZMeTkzZDNjdWJHbHVhMlZrYVc0dVkyOXRMMmx1TDNKcFkyRnlaRzh0Y0dsdWRHOHRNVFExTnpJM01qQT0=')), '_blank')}>
                        <GpeText style={[BaseStyles.text, { textDecorationLine: 'underline' }]}>
                            {Base64.atob(Base64.atob('VEdsdWEyVmtTVzQ9'))}
                        </GpeText>
                    </TouchableOpacity>
                </View>
            ) : (
                <View
                    style={{ alignItems: 'center' }}>
                    <TouchableOpacity
                        onPress={() => ++_month}
                        onLongPress={() => {
                            if (++_month === 7) setLocalState({ valid: true });
                            _month = 0;
                        }}>
                        <View><GpeText style={BaseStyles.h3}>{Base64.atob(Base64.atob('VkdWdGNHbDFjeUUv'))}</GpeText></View>
                    </TouchableOpacity>
                    <GpeText style={BaseStyles.text}>{Base64.atob(Base64.atob('UVNCeVpXUnBjbVZqYVc5dVlYSWdjR0Z5WVNCaElHSnBZbXhwYjNSbFkyRXVMaTRn'))}{localState.countdown}</GpeText>
                </View>
            )
        );
    }, [localState, close]);
}

export { VkdWdGNHbDFjdz09 as default };