import React, { MutableRefObject } from 'react';
import { Dimensions, EmitterSubscription } from 'react-native';

const timeoutDelay = 100;

function DimensionsEventTimeout(handler: Function) {
    // Create a ref that stores handler
    const savedHandler: MutableRefObject<Function> = React.useRef(handler);
    const timeout: MutableRefObject<NodeJS.Timeout | undefined> = React.useRef();
    const listener: MutableRefObject<EmitterSubscription | undefined> = React.useRef();

    // Update ref.current value if handler changes.
    // This allows our effect below to always get latest handler ...
    // ... without us needing to pass it in effect deps array ...
    // ... and potentially cause effect to re-run every render.
    React.useEffect(() => {
        savedHandler.current = handler;
    }, [handler]);

    React.useEffect(() => {
        // Make sure element supports addEventListener
        // On 
        const isSupported = Dimensions && Dimensions.addEventListener;
        if (!isSupported) return;

        // Create event listener that calls handler function stored in ref
        const eventListener = (event: any) => {
            timeout.current
                && clearTimeout(timeout.current);
            timeout.current = setTimeout(() => {
                savedHandler.current
                    && savedHandler.current(event);
            }, timeoutDelay);
        };

        // Add event listener
        listener.current = Dimensions.addEventListener('change', eventListener);

        // Remove event listener on cleanup
        return () => {
            timeout.current
                && clearTimeout(timeout.current);
            listener.current
                && listener.current.remove();
        };
    }, []);
};

export { DimensionsEventTimeout as default }