import { BookMetadataFromMestArtIdInterface } from '../interfaces/middlewares';
import { BookshelfServicesInterface, ResponseInterface } from '../interfaces/services';
import {
    doGetRequest,
    doPostRequest,
    doPutRequest,
    doDeleteRequest
} from './HttpClient';

const BookshelfServices = async (
    props: BookshelfServicesInterface,
    alternativeServiceName?: string,
    page: number = 1,
    previousResponse?: ResponseInterface,
): Promise<ResponseInterface> => {
    const serviceName = Object.keys(props)[0];

    let _request;

    switch (serviceName) {
        case "getBookshelfData":
            return doGetRequest(
                serviceName,
                { ...props[serviceName]?.urlParams, page, limit: 50 },
                props[serviceName]?.headers,
                undefined,
                alternativeServiceName,
            ).then((response: ResponseInterface) => {
                if (previousResponse?.success?.data?.ebooks)
                    response.success.data.ebooks = [
                        ...previousResponse.success.data.ebooks,
                        ...response.success.data.ebooks,
                    ]

                const ebooksPagination = response.success?.data?.ebooksPagination;

                if (!ebooksPagination
                    || ebooksPagination.currentPage >= ebooksPagination.totalPages)
                    return response;

                return BookshelfServices(props, alternativeServiceName, page + 1, response);
            });
        case "createBookshelfCategory":
            return doPostRequest(
                serviceName,
                props[serviceName]?.urlParams,
                props[serviceName]?.body,
                props[serviceName]?.headers,
                undefined,
                alternativeServiceName,
            );
        case "renameBookshelfCategory":
            return doPutRequest(
                serviceName,
                props[serviceName]?.urlParams,
                props[serviceName]?.body,
                props[serviceName]?.headers,
                undefined,
                alternativeServiceName,
            );
        case "deleteBookshelfCategory":
            return doDeleteRequest(
                serviceName,
                props[serviceName]?.urlParams,
                null,
                props[serviceName]?.headers,
                undefined,
                alternativeServiceName,
            );
        case "moveBookToCategory":
            return doPostRequest(
                serviceName,
                props[serviceName]?.urlParams,
                null,
                props[serviceName]?.headers,
                undefined,
                alternativeServiceName,
            );
        case "deleteBookFromUserBookshelf":
            return doDeleteRequest(
                serviceName,
                props[serviceName]?.urlParams,
                null,
                props[serviceName]?.headers,
                undefined,
                alternativeServiceName,
            );
        case "getPackageDetailsByReadItemId":
            _request = (): Promise<{
                success?: {
                    data: {
                        bat: null,
                        downloadUrl: string,
                        offlineType: string,
                        size: number
                    }
                },
                error?: string
            }> => doGetRequest(
                serviceName,
                props[serviceName]?.urlParams,
                props[serviceName]?.headers,
                undefined,
                alternativeServiceName,
            );
            return _request();
        case "orderCategoryItems":
            return doPostRequest(
                serviceName,
                props[serviceName]?.urlParams,
                props[serviceName]?.body,
                props[serviceName]?.headers,
                undefined,
                alternativeServiceName,
            );
        case "getWebReaderAccessInfo":
            return doPostRequest(
                serviceName,
                props[serviceName]?.urlParams,
                props[serviceName]?.body,
                props[serviceName]?.headers,
                undefined,
                alternativeServiceName,
            );
        case "getAllShowcaseProducts":
            return doPostRequest(
                serviceName,
                {},
                props[serviceName]?.body,
                props[serviceName]?.headers,
                undefined,
                alternativeServiceName,
            );
        case "getPackageDetailsByReadItemGuid":
            return doGetRequest(
                serviceName,
                props[serviceName]?.urlParams,
                props[serviceName]?.headers,
                undefined,
                alternativeServiceName,
            );
        case "getPublicPackageDetailsByReadItemGuid":
            _request = (): Promise<{
                success?: {
                    data: {
                        downloadUrl: string,
                        bat: null,
                        size: number,
                        offlineType: string
                    }
                },
                error?: string
            }> => doGetRequest(
                serviceName,
                props[serviceName]?.urlParams,
                undefined,
                undefined,
                alternativeServiceName,
            );
            return _request();
        case "getBookMetadataFromMestArtId":
            _request = (): Promise<{
                success?: { data: BookMetadataFromMestArtIdInterface },
                error?: string
            }> => doGetRequest(
                serviceName,
                props[serviceName]?.urlParams,
                undefined,
                undefined,
                alternativeServiceName,
            );
            return _request();
        case "getBookPackageChunks":
            return doGetRequest(
                serviceName,
                props[serviceName]?.urlParams,
                props[serviceName]?.headers,
                undefined,
                alternativeServiceName,
            );
        case "assignBookPartToUser":
            const body = { "operation": { "type": "SAMPLE_ADDED_BY_USER" } };
            return doPostRequest(
                serviceName,
                props[serviceName]?.urlParams,
                body,
                undefined,
                undefined,
                alternativeServiceName,
            );
        case "getLastPageAccessed":
            return doGetRequest(
                serviceName,
                props[serviceName]?.urlParams,
                props[serviceName]?.headers,
                undefined,
                alternativeServiceName,
            );
        default:
            return {};
    }
}

export { BookshelfServices as default };