import React from 'react';

function RefreshButtonWook(props: {
    width: number,
    headerDispatch: Function
}) {
    /** RENDER */
    return React.useMemo(() => {
        return null;
    }, []);
}

export { RefreshButtonWook as default }