import React from 'react';
import { View, ActivityIndicator } from 'react-native';

import { Log } from '../config';
import { Store } from '../store';
import { ContextTheme } from '../styles';
import { getTranslation } from '../middlewares/AppMiddleware';
import GpeText from './GpeText';

function Loader() {
    const { state } = React.useContext(Store);

    const _fetching = React.useMemo(() => state.app.fetching, [state.app.fetching]);
    const translation = React.useMemo(() => getTranslation(state.app.language), [state.app.language]);
    const _percentage = React.useMemo(() => state.app.percentage, [state.app.percentage]);

    return React.useMemo(() => {
        Log.debug('component: Loader');

        return (_fetching
            ? (
                <View
                    style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                        backgroundColor: ContextTheme.palette.theme,
                        zIndex: 9000,
                        opacity: 0.85,
                        minHeight: '100%',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                    <ActivityIndicator size="large" color="#ffffff" />
                    {_percentage === null
                        ? (
                            <GpeText style={{ color: ContextTheme.palette.white }}>
                                {translation('app.loading.text', undefined, false)}
                            </GpeText>
                        ) : (
                            <GpeText style={{ color: ContextTheme.palette.white }}>
                                {typeof _percentage === 'string'
                                    && _percentage !== ''
                                    ? (`${_percentage}`)
                                    : typeof _percentage === 'number'
                                        && _percentage > 0
                                        ? (`${Math.min(Math.floor(_percentage), 100)}%`)
                                        : (`downloading...`)}
                            </GpeText>
                        )}
                </View>
            ) : null);
    }, [
        translation,
        _fetching,
        _percentage
    ]);
}

export { Loader as default }