import React, { MutableRefObject } from 'react';
import {
    View,
    TextInput,
    Platform,
    TouchableOpacity
} from 'react-native';

import { Log } from '../../../../config';
import { Store } from '../../../../store';
import { LoginStyles, dimensionWidth, ContextTheme } from '../../../../styles';
import GpeButton from '../../../GpeButton';
import { doLogin } from '../../../../middlewares/UserMiddleware';
import Icon from '../../../Icon';
import ModalBackground from '../../../ModalBackground';
import DimensionsEventTimeout from '../../../DimensionsEvent';
import GpeScrollView from '../../../GpeScrollView';
import GpeText from '../../../GpeText';
import { DispatchType } from '../../../../interfaces/store';

function Associate() {
    const { state, dispatch } = React.useContext(Store);

    const dispatchRef: MutableRefObject<DispatchType> = React.useRef(dispatch);

    /** MEDIA */
    const titleMedia = React.useMemo(() => ({ xs: 22, lg: 32 }), []);

    /** LOCAL STATE */
    const [form, setForm] = React.useState<{
        user: string,
        password: string,
        secureTextEntry: boolean,
        titleSize: number | undefined,
        notValid: { [key: string]: string | undefined },
    }>({
        user: '',
        password: '',
        secureTextEntry: true,
        titleSize: dimensionWidth(titleMedia),
        notValid: {},
    });

    /** RESIZE LISTENER */
    DimensionsEventTimeout(() => {
        const _titleSize = dimensionWidth(titleMedia);
        if (_titleSize !== form.titleSize) {
            setForm({
                ...form,
                titleSize: _titleSize,
            });
        }
    });

    return React.useMemo(() => {
        Log.debug('component: LoginForm');

        /** VALIDATE */
        const formValid = () => {
            const notValid: { user?: string, password?: string } = {};

            form.user.match(new RegExp('@', 'g'))?.length !== 1 && (notValid.user = 'e-mail inválido!');
            form.user.lastIndexOf('.') < 3 && (notValid.user = 'e-mail inválido!');
            (form.user.lastIndexOf('@') < 1
                || form.user.lastIndexOf('.') < form.user.lastIndexOf('@')) && (notValid.user = 'e-mail inválido!');
            form.password.length < 1 && (notValid.password = 'insira a password!');

            Object.keys(notValid).length && setForm({
                ...form,
                notValid: notValid,
            });

            return !Object.keys(notValid).length;
        }

        const loginSubmit = () => formValid()
            && state.app.uid
            && doLogin(dispatchRef.current, state.app.uid, form);

        const handleChange = (type: string, value: string | boolean) => {
            state.user.loginError
                && dispatchRef.current({
                    type: 'USER-LOGIN_ERROR',
                    payload: '',
                });

            const notValid: any = form.notValid;
            notValid[type] && (delete notValid[type]);

            setForm({
                ...form,
                [type]: value,
                notValid: notValid,
            });
        }

        return (
            <GpeScrollView
                horizontal={false}
                style={{ backgroundColor: ContextTheme.palette.white }}
                contentContainerStyle={{ padding: 10, alignItems: 'center' }}>

                <ModalBackground color={ContextTheme.palette.loginColor} />

                <View
                    style={LoginStyles.container}>

                    <GpeText style={[LoginStyles.h1, { fontSize: form.titleSize }]}>
                        {'já existe um registo com este email'}
                    </GpeText>

                    <View
                        style={{
                            backgroundColor: 'rgba(255,255,255,0.85)',
                            padding: 10,
                            width: '100%',
                            borderRadius: 2,
                        }}>

                        <GpeText style={[LoginStyles.text, { marginBottom: 10 }]}>
                            {'Já existe uma conta Wook registada com este email.\n'}
                            {'Por motivos de segurança necessitamos de confirmar a sua conta.'}
                        </GpeText>

                        <GpeText style={[LoginStyles.text, { marginBottom: 10 }]}>
                            {'Por favor, introduza os seus dados de login em baixo.'}
                        </GpeText>

                        {Object.keys(form.notValid).length
                            ? <GpeText style={LoginStyles.error}>{form.notValid[Object.keys(form.notValid)[0]]}</GpeText>
                            : state.user.loginError
                                ? <GpeText style={LoginStyles.error}>{state.user.loginError}</GpeText>
                                : null}

                        <View style={[LoginStyles.inputContainer, form.notValid.user ? { borderColor: 'red' } : null]}>
                            <Icon icon='u' style={{ color: '#ccc', backgroundColor: ContextTheme.palette.white }} />
                            <TextInput
                                style={LoginStyles.input}
                                keyboardType="email-address"
                                onChangeText={value => handleChange('user', value)}
                                onSubmitEditing={loginSubmit}
                                value={form.user}
                                {...(Platform.OS !== "web" ? { autoCompleteType: "email" } : null)}
                                disableFullscreenUI={true}
                                autoCapitalize="none"
                                placeholder="endereço de email" />
                        </View>

                        <View style={[LoginStyles.inputContainer, form.notValid.password ? { borderColor: 'red' } : null]}>
                            <Icon icon='U' style={{ color: '#ccc', backgroundColor: ContextTheme.palette.white }} />
                            <TextInput
                                style={LoginStyles.input}
                                onChangeText={value => handleChange('password', value)}
                                onSubmitEditing={loginSubmit}
                                value={form.password}
                                {...(Platform.OS !== "web" ? { autoCompleteType: "password" } : null)}
                                secureTextEntry={form.secureTextEntry}
                                disableFullscreenUI={true}
                                autoCapitalize="none"
                                placeholder="password" />
                            <TouchableOpacity
                                onPress={() => handleChange('secureTextEntry', !form.secureTextEntry)}>
                                <Icon icon={form.secureTextEntry ? 't' : 'T'} style={{ color: ContextTheme.palette.loginColor, backgroundColor: ContextTheme.palette.white }} />
                            </TouchableOpacity>
                        </View>

                        <GpeButton
                            style={[LoginStyles.button, { width: '100%', maxWidth: undefined }]} onPress={loginSubmit}>
                            <GpeText style={{ fontFamily: ContextTheme.font.semibold, fontSize: 16 }}>
                                {'CONFIRMAR'}
                            </GpeText>
                        </GpeButton>
                    </View>
                </View>
            </GpeScrollView>
        )
    }, [form, state.app.uid, state.user.loginError]);
}

export { Associate as default }