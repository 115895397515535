import React, { MutableRefObject } from 'react';
import { View, TextInput, Platform } from 'react-native';

import Config, { Log } from '../../../../config';
import { Store } from '../../../../store';
import { RecoverStyles, ContextTheme, dimensionWidth } from '../../../../styles';
import { Link } from '../../../GpeRouter';
import GpeButton from '../../../GpeButton';
import { doRecover } from '../../../../middlewares/UserMiddleware';
import Icon from '../../../Icon';
import ModalBackground from '../../../ModalBackground';
import DimensionsEventTimeout from '../../../DimensionsEvent';
import GpeScrollView from '../../../GpeScrollView';
import GpeText from '../../../GpeText';
import { DispatchType } from '../../../../interfaces/store';

function RecoverForm() {
    const { state, dispatch } = React.useContext(Store);

    /** MEDIA */
    const titleMedia = React.useMemo(() => ({ xs: 22, lg: 32 }), []);

    /** LOCAL STATE */
    const [form, setForm] = React.useState<{
        email: string,
        titleSize: number | undefined,
        notValid: { [key: string]: string | undefined },
    }>({
        email: '',
        titleSize: dimensionWidth(titleMedia),
        notValid: {},
    });

    const dispatchRef: MutableRefObject<DispatchType> = React.useRef(dispatch);

    /** RESIZE LISTENER */
    DimensionsEventTimeout(() => {
        const _titleSize = dimensionWidth(titleMedia);
        if (_titleSize !== form.titleSize) {
            setForm({
                ...form,
                titleSize: _titleSize,
            });
        }
    });

    return React.useMemo(() => {
        Log.debug('component: RecoverForm');

        /** VALIDATE */
        const formValid = () => {
            const notValid: { email?: string, password?: string } = {};

            form.email.match(new RegExp('@', 'g'))?.length !== 1 && (notValid.email = 'e-mail inválido!');
            form.email.lastIndexOf('.') < 3 && (notValid.email = 'e-mail inválido!');
            (form.email.lastIndexOf('@') < 1
                || form.email.lastIndexOf('.') < form.email.lastIndexOf('@')) && (notValid.email = 'e-mail inválido!');

            setForm({
                ...form,
                notValid: notValid,
            });

            return !Object.keys(notValid).length;
        }

        const recoverSubmit = () => !state.user.recoverError
            && formValid()
            && doRecover(dispatchRef.current, form.email);

        const handleChange = (type: string, value: string) => {
            state.user.recoverError && dispatchRef.current({
                type: 'USER-RECOVER_ERROR',
                payload: '',
            });

            const notValid: any = form.notValid;
            notValid[type] && (delete notValid[type]);

            setForm({
                ...form,
                [type]: value,
                notValid: notValid,
            });
        };

        return (
            <GpeScrollView
                horizontal={false}
                style={{ backgroundColor: ContextTheme.palette.white }}
                contentContainerStyle={{ padding: 10, alignItems: 'center' }}>

                <ModalBackground color={ContextTheme.palette.recoverColor} />

                <View style={{ position: 'absolute', top: 20, left: 15, transform: [{ rotate: '180deg' }], zIndex: 1 }}>
                    <Link to={Config.routes.login} style={{ textDecorationLine: 'none' }}>
                        <Icon icon='>' style={{ color: ContextTheme.palette.white }} />
                    </Link>
                </View>

                <View
                    style={RecoverStyles.container}>

                    <GpeText style={[RecoverStyles.h1, { fontSize: form.titleSize }]}>
                        {'recuperar password wook'}
                    </GpeText>

                    <View
                        style={{
                            backgroundColor: 'rgba(255,255,255,0.85)',
                            padding: 10,
                            width: '100%',
                            borderRadius: 2,
                        }}>

                        <GpeText style={[RecoverStyles.text, { marginBottom: 10 }]}>
                            {'Caso se tenha esquecido da password, nós ajudamos! Por favor, insira o seu endereço de email:'}
                        </GpeText>

                        {Object.keys(form.notValid).length
                            ? <GpeText style={RecoverStyles.error}>{form.notValid[Object.keys(form.notValid)[0]]}</GpeText>
                            : state.user.recoverError
                                ? <GpeText style={RecoverStyles.error}>{state.user.recoverError}</GpeText>
                                : null}

                        <View style={[RecoverStyles.inputContainer, form.notValid.email ? { borderColor: 'red' } : null]}>
                            <Icon icon='u' style={{ color: '#ccc', backgroundColor: ContextTheme.palette.white }} />
                            <TextInput
                                style={RecoverStyles.input}
                                onChangeText={value => handleChange('email', value)}
                                onSubmitEditing={recoverSubmit}
                                value={form.email}
                                {...(Platform.OS !== "web" ? { autoCompleteType: "email" } : null)}
                                disableFullscreenUI={true}
                                autoCapitalize="none"
                                placeholder="endereço de email" />
                        </View>

                        <GpeButton
                            style={[RecoverStyles.button, { width: '100%', maxWidth: undefined }]}
                            onPress={recoverSubmit}>
                            <GpeText style={{ fontFamily: ContextTheme.font.semibold, fontSize: 16 }}>
                                {'CONFIRMAR'}
                            </GpeText>
                        </GpeButton>

                        <Link
                            to={Config.routes.regist}
                            style={RecoverStyles.row}>
                            <View
                                style={{
                                    backgroundColor: ContextTheme.palette.lightGrey,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderRadius: 2,
                                    width: '100%',
                                    height: 40,
                                    padding: 8,
                                }}>
                                <GpeText
                                    style={{
                                        fontFamily: ContextTheme.font.regular,
                                        fontSize: 16,
                                        color: ContextTheme.palette.recoverColor
                                    }}>
                                    {'NÃO TEM CONTA? REGISTE-SE AQUI'}
                                </GpeText>
                            </View>
                        </Link>
                    </View>
                </View>
            </GpeScrollView>
        )
    }, [form, state.user.recoverError]);
}

export { RecoverForm as default }