import React, { MutableRefObject } from "react";

import Config from "../../../../config";
import { MenuOptionInterface } from "../../../../interfaces/components";
import { DispatchType } from "../../../../interfaces/store";
import { openLink, openLinkByPlatform } from "../../../../middlewares/AppMiddleware";
import { doLogout } from "../../../../middlewares/UserMiddleware";
import { Store } from "../../../../store";
import { ContextTheme } from "../../../../styles";

function MenuOptionsList() {
    const { state, dispatch } = React.useContext(Store);

    const dispatchRef: MutableRefObject<DispatchType> = React.useRef(dispatch);
    const doLogoutCallback = React.useCallback(() => doLogout(dispatchRef.current), []);
    const contextConfig = React.useMemo(() => (
        Config.contextParams
    ), []);
    const token = React.useMemo(() => (state.user.auth ? `?token=${state.user.auth.token}` : ''
    ), [state.user.auth]);

    const menuOptions: Array<MenuOptionInterface> = React.useMemo(() => [
        {
            accessibilityRole: 'link',
            languageKey: 'library.sidebar.introduction.link.text',
            to: Config.routes.introduction,
            style: { fontSize: 16 },
        },
        {
            accessibilityRole: 'button',
            languageKey: 'library.sidebar.personal.link.text',
            onPress: () => openLink(`${contextConfig.personalUrl}${token}`),
            style: { fontSize: 16 },
        },
        {
            accessibilityRole: 'button',
            languageKey: 'library.sidebar.devices.link.text',
            onPress: () => openLink(`${contextConfig.devicesUrl}${token}`),
            style: { fontSize: 16 },
        },
        {
            accessibilityRole: 'link',
            languageKey: 'library.sidebar.help.link.text',
            onPress: () => openLink(`${contextConfig.questionsUrl}${token}`),
            style: { fontSize: 16 },
        },
        {
            accessibilityRole: 'button',
            languageKey: 'library.sidebar.contact.link.text',
            onPress: () => openLink(`${contextConfig.contactUrl}${token}`),
            style: { fontSize: 16 },
        },
        {
            accessibilityRole: 'button',
            languageKey: 'library.sidebar.conditions.link.text',
            onPress: () => openLink(`${contextConfig.conditionsUrl}${token}`),
            style: { fontSize: 16 },
        },
        {
            accessibilityRole: 'button',
            languageKey: 'library.sidebar.privacy.link.text',
            onPress: () => openLink(`${contextConfig.privacyUrl}${token}`),
            style: { fontSize: 16 },
        },
        {
            accessibilityRole: 'link',
            languageKey: 'library.sidebar.app.link.text',
            onPress: () => openLinkByPlatform(
                `market://details?id=pt.bertrand.bertrand1732`,
                `itms-apps://apps.apple.com/id/app/bertrand-1732/id1471679198?l=id`,
                `https://www.bertrand.pt/template/app-bertrand-1732`),
            style: { fontSize: 16 },
        },
        {
            accessibilityRole: 'button',
            languageKey: 'library.sidebar.forget.link.text',
            onPress: () => openLink(`${contextConfig.forgetUrl}${token}`),
            style: { fontSize: 16 },
        },
        {
            accessibilityRole: 'button',
            languageKey: 'library.sidebar.logout.button.text',
            onPress: () => doLogoutCallback(),
            style: {
                fontSize: 16,
                textAlign: 'center',
                color: ContextTheme.palette.white,
                backgroundColor: ContextTheme.palette.grey,
                marginTop: 17.5,
                marginBottom: 17.5,
            },
        },
    ], [token, doLogoutCallback, contextConfig]);

    return React.useMemo(() => menuOptions, [menuOptions]);
};

export { MenuOptionsList as default }