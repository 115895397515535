import React from 'react';

import Config from "../config";
import WebPage from './WebPage';

/**
 * Creates Privacy Policies WebView
 */
function PrivacyWebPage() {
    const contextConfig = React.useMemo(() => (
        Config.contextParams
    ), []);

    const isFromAppParam = React.useMemo(() => ('&APP=true'), []);

    const uri = React.useMemo(() => (
        `${contextConfig.privacyUrl}${isFromAppParam}`
    ), [contextConfig.privacyUrl, isFromAppParam]);

    return React.useMemo(() => {
        return (
            <WebPage uri={uri} />
        )
    }, [uri]);
}

export { PrivacyWebPage as default }