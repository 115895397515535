import React from 'react';

import Config, { Log } from '../config';
import { Store } from '../store';
import Associate from '../components/ByContext/wook/components/Associate';
import { Redirect } from '../components/GpeRouter';

function AssociateScreen() {
    const { state } = React.useContext(Store);

    return React.useMemo(() => {
        Log.debug('screen: ASSOCIATE');

        return !state.user.auth
            ? <Associate />
            : <Redirect to={Config.routes.login} />
    }, [
        state.user.auth,
    ]);
}

export { AssociateScreen as default };