import React, { MutableRefObject } from 'react';
import { Image } from "react-native";

import Config from '../../../../config';
import { EbookInterface } from '../../../../interfaces/middlewares';
import { DispatchType } from '../../../../interfaces/store';
import { getAssetImageUri, getTranslation } from '../../../../middlewares/AppMiddleware';
import { getEbookInBookshelf, isAudioBook, openPopup } from '../../../../middlewares/commons';
import { getCoverImageUri, openReadItemId, redownloadCover, removeDownloadedCover } from '../../../../middlewares/LibraryMiddleware';
import { Store } from '../../../../store';
import EbookInfo from '../../../Popup/components/EbookInfo';

export function SimpleCoverInit(
    props: {
        ebook: EbookInterface,
        width?: number,
        height?: number,
        showcaseRender?: boolean,
        bookshelfSample?: boolean,
    }
) {
    /** STORE */
    const { state, dispatch } = React.useContext(Store);

    const [componentState, componentDispatch] = React.useReducer((
        _state: {
            height: number | undefined,
            width: number | undefined,
            propsHeight: number | undefined,
            propsWidth: number | undefined,
        },
        _payload: {
            height?: number,
            width?: number,
            propsHeight?: number,
            propsWidth?: number,
        },
    ) => ({ ..._state, ..._payload }), {
        height: undefined,
        width: undefined,
        propsHeight: undefined,
        propsWidth: undefined,
    });

    /** PROPS */
    const mountedRef: MutableRefObject<boolean | undefined> = React.useRef();

    const dispatchRef: MutableRefObject<DispatchType> = React.useRef(dispatch);

    const translation = React.useMemo(() => getTranslation(state.app.language), [state.app.language]);

    const downloadedVersion = React.useMemo(() => (state.library.downloadedVersions[props.ebook.gpeId]
        && state.library.downloadedVersions[props.ebook.gpeId] === Config.ebook.downloadFileName
        ? state.library.downloadedVersions[props.ebook.gpeId]
        : undefined
    ), [
        state.library.downloadedVersions,
        props.ebook.gpeId
    ]);

    const coverUri = React.useMemo(() => getCoverImageUri(
        props.ebook,
        state.app.storageDir,
        state.library.downloadedCovers,
    ), [
        props.ebook,
        state.app.storageDir,
        state.library.downloadedCovers,
    ]);

    const imageSourceUri = React.useMemo(() => ({
        uri: coverUri
            ? coverUri
            : getAssetImageUri('cover.default', state.app.downloadedAssets)
    }), [
        coverUri,
        state.app.downloadedAssets,
    ]);

    const openReadItemIdCallback = React.useCallback(() => {
        let _ebook: EbookInterface = props.ebook;

        if (!_ebook.kitabooCloudId
            && state.library.bookshelf)
            _ebook = getEbookInBookshelf(
                state.library.bookshelf,
                _ebook,
                isAudioBook(_ebook.formatType)
                    ? translation('library.showcase.want.to.ear.title')
                    : translation('library.showcase.want.to.read.title'),
            );

        state.app.storageDir
            && state.app.uid
            && state.user.authToken
            && state.user.auth
            && openReadItemId(
                dispatchRef.current,
                state.app.storageDir,
                state.app.uid,
                state.user.authToken,
                state.user.auth,
                state.user.kitabooToken,
                state.library.readingStarted,
                _ebook,
                translation,
                undefined,
                props.showcaseRender
            );
    }, [
        state.app.storageDir,
        state.app.uid,
        state.user.authToken,
        state.user.auth,
        state.user.kitabooToken,
        state.library.bookshelf,
        state.library.readingStarted,
        translation,
        props.ebook,
        props.showcaseRender,
    ]);

    const openPopupCallback = React.useCallback(() => openPopup(
        dispatchRef.current,
        <EbookInfo ebook={props.ebook} bookshelfSample={props.bookshelfSample} />
    ), [
        props.ebook,
        props.bookshelfSample,
    ]);

    const imageOnErrorCallback = React.useCallback(() => {
        state.app.storageDir
            && redownloadCover(
                dispatchRef.current,
                state.app.storageDir,
                props.ebook,
            );
    }, [
        state.app.storageDir,
        props.ebook,
    ]);

    const imageSizeCallback = React.useCallback((
        propsHeight: number | undefined,
        propsWidth: number | undefined,
    ) => {
        imageSourceUri
            && imageSourceUri.uri
            && Image.getSize(
                imageSourceUri.uri,
                (width, height) => {
                    let _height = propsHeight;
                    let _width = propsWidth;

                    if (propsWidth && !propsHeight) {
                        _height = height * (propsWidth / width);
                    } else if (!propsWidth && propsHeight) {
                        _width = width * (propsHeight / height);
                    }

                    if (_height !== componentState.height
                        || _width !== componentState.width) {
                        mountedRef.current
                            && componentDispatch({
                                height: _height,
                                width: _width,
                            });
                    }
                },
                () => {
                    imageSourceUri.uri !== getAssetImageUri('cover.default', state.app.downloadedAssets)
                        && removeDownloadedCover(dispatchRef.current, props.ebook, state.app.storageDir)
                }
            );
        mountedRef.current
            && componentDispatch({
                propsHeight: propsHeight,
                propsWidth: propsWidth,
            });
    }, [
        props.ebook,
        state.app.storageDir,
        state.app.downloadedAssets,
        imageSourceUri,
        componentState.height,
        componentState.width,
    ]);

    React.useEffect(() => {
        if (props.height !== componentState.propsHeight
            || props.width !== componentState.propsWidth) {
            imageSizeCallback(props.height, props.width);
        }
    }, [
        props.height,
        props.width,
        componentState.propsHeight,
        componentState.propsWidth,
        imageSizeCallback,
    ]);

    React.useEffect(() => {
        mountedRef.current = true;

        return () => {
            mountedRef.current = false;
        }
    }, []);

    return React.useMemo(() => ({
        componentState,
        translation,
        imageSourceUri,
        downloadedVersion,
        openReadItemIdCallback,
        openPopupCallback,
        imageOnErrorCallback,
    }), [
        componentState,
        translation,
        imageSourceUri,
        downloadedVersion,
        openReadItemIdCallback,
        openPopupCallback,
        imageOnErrorCallback,
    ]);
}