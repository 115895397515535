import { StyleSheet, Dimensions } from 'react-native';

import BaseStyles from './base';
import WelcomeStyles from './welcome';
import LoginStyles from './login';
import RecoverStyles from './recover';
import RegistStyles from './regist';
import CarouselStyles from './carousel';
import Library from './library';
import Help from './help';
import PerfectScrollbar from './perfectScrollbar';
import Fonts from './fonts';
import Popup from './popup';
import ContextTheme from './theme';

function mergeStyles(obj1: any, obj2: any) {
    for (let p in obj2) {
        if (obj1[p]) {
            obj1[p] = StyleSheet.flatten([obj1[p], obj2[p]]);
        }
        else {
            obj1[p] = obj2[p];
        }
    }
    return obj1;
};

const LibraryStyles = mergeStyles(
    { ...BaseStyles },
    Library
);

const HelpStyles = mergeStyles(
    { ...BaseStyles },
    Help
);

const PopupStyles = mergeStyles(
    { ...BaseStyles },
    Popup
);

const PerfectScrollbarStyles = PerfectScrollbar;

const FontFamilyStyles = Fonts

const dimension = (_val: {
    xs?: any,
    sm?: any,
    md?: any,
    lg?: any,
}, _default: any) => {
    const _dimensions = {
        lg: 1080,
        md: 688,
        sm: 504,
        xs: 0,
    };

    const _window = Dimensions.get('window');

    let _value;
    if (_window && _window.width >= _dimensions.lg && _val.lg) {
        _value = _val.lg ? _val.lg : _val.md ? _val.md : _val.sm ? _val.sm : _val.xs;
    }
    else if (_window && _window.width >= _dimensions.md && _val.md) {
        _value = _val.md ? _val.md : _val.sm ? _val.sm : _val.xs ? _val.xs : _val.lg;
    }
    else if (_window && _window.width >= _dimensions.sm && _val.sm) {
        _value = _val.sm ? _val.sm : _val.xs ? _val.xs : _val.md ? _val.md : _val.lg;
    }
    else if (_window && _window.width >= _dimensions.xs && _val.xs) {
        _value = _val.xs ? _val.xs : _val.sm ? _val.sm : _val.md ? _val.md : _val.lg;
    }

    return _value ? _value : _default;
}

const dimensionWidth = (_val: {
    xs?: number | string,
    sm?: number | string,
    md?: number | string,
    lg?: number | string,
}) => {
    let _default = 1;

    if (!Object.keys(_val).length
        || (!_val.xs && !_val.sm && !_val.md && !_val.lg)) return _default;

    return dimension(_val, _default);
}

const dimensionColumns = (_val: { xs?: number, sm?: number, md?: number, lg?: number }) => {
    let _default = '100%';

    if (!Object.keys(_val).length
        || (!_val.xs && !_val.sm && !_val.md && !_val.lg)) return _default;

    const _number = dimensionWidth(_val);

    return _number ? ((100 / _number) + '%') : _default;
}

const dimensionColumns_Value = (
    _val: { xs?: number, sm?: number, md?: number, lg?: number },
    _parentWidth?: number,
) => {
    const _default = _parentWidth ? _parentWidth : Dimensions.get('window').width;

    if (!Object.keys(_val).length
        || (!_val.xs && !_val.sm && !_val.md && !_val.lg)) return _default;

    const _number = dimensionWidth(_val);

    return _number ? (_default / _number) : _default;
}

const dimensionWidth_Object = (_val: { xs?: object, sm?: object, md?: object, lg?: object }) => {
    let _default = {};

    if (!Object.keys(_val).length
        || (!_val.xs && !_val.sm && !_val.md && !_val.lg)) return _default;

    const _obj = dimension(_val, _default);

    return _obj ? _obj : _default;
}

const dimensionWidth_Boolean = (_val: { xs?: 'true' | 'false', sm?: 'true' | 'false', md?: 'true' | 'false', lg?: 'true' | 'false' }) => {
    let _default = false;

    if (!Object.keys(_val).length
        || (!_val.xs && !_val.sm && !_val.md && !_val.lg)) return _default;

    const _obj = dimension(_val, _default);

    return _obj && _obj === 'true' ? true : _default;
}

export {
    BaseStyles as default,
    WelcomeStyles,
    LoginStyles,
    RecoverStyles,
    RegistStyles,
    CarouselStyles,
    LibraryStyles,
    HelpStyles,
    PopupStyles,
    PerfectScrollbarStyles,
    FontFamilyStyles,
    ContextTheme,
    dimensionWidth,
    dimensionColumns,
    dimensionColumns_Value,
    dimensionWidth_Object,
    dimensionWidth_Boolean,
};