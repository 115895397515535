import React from 'react';
import { View } from 'react-native';

import { getTranslation } from '../../../middlewares/AppMiddleware';
import Config from '../../../config';
import BertrandCheck from './BertrandCheck';
import { Store } from '../../../store';
import GpeText from '../../GpeText';
import { ContextTheme } from '../../../styles';

function ShelfDeleteMsgSuccess() {
    const { state } = React.useContext(Store);

    const translation = React.useMemo(() => getTranslation(state.app.language), [state.app.language]);

    return React.useMemo(() => (
        <View
            style={{ alignItems: 'center' }}>
            <GpeText
                style={{
                    fontFamily: Config.contextName === 'bertrand'
                        ? ContextTheme.font.semibold
                        : ContextTheme.font.regular,
                    fontSize: Config.contextName === 'bertrand'
                        ? 16
                        : 14,
                    textAlign: 'center',
                    marginBottom: 20,
                }}>
                {translation('library.delete.bookshelf.form.success.text')}
            </GpeText>
            {Config.contextName === 'bertrand'
                ? <BertrandCheck />
                : null}
        </View>
    ), [
        translation
    ]);
}

export { ShelfDeleteMsgSuccess as default }