import { StyleSheet } from 'react-native';

import theme from '../../styles/theme';

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        padding: undefined,
        alignItems: undefined,
        width: '100%',
        // maxWidth: 1200,
        // margin: 'auto',
    },
    h1: {
        textTransform: 'uppercase',
        paddingRight: 50,
        fontFamily: theme.font.light,
    },
    h3: {
        paddingRight: 15,
        paddingLeft: 15,
        textTransform: 'uppercase',
        fontFamily: theme.font.light,
    },
    shelf: {
        fontSize: 16,
        lineHeight: 20,
        padding: 15,
        fontFamily: theme.font.regular,
        color: theme.palette.black
    },
    selected: {
        color: theme.palette.white,
        backgroundColor: theme.palette.theme,
    },
    leftContainer: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
    },
    rightContainer: {
        width: '100%',
        alignItems: 'flex-start',
    },
    shelfsListContainer: {
        paddingTop: 40,
    },
    contentContainer: {
        paddingTop: 40,
        minHeight: '100%',
    },
    ebookContainer: {
        justifyContent: 'flex-start',
        paddingRight: 30,
        marginBottom: 20,
    },
    draggingElevation: {
        shadowColor: theme.palette.black,
        shadowOffset: {
            width: 0,
            height: 0.5 * 7,
        },
        shadowOpacity: 0.29,
        shadowRadius: 0.3 * 7,
        elevation: 7,
    },
    dragIcon: {
        position: 'absolute',
        right: -15,
        bottom: 30,
    },
    typeIcon: {
        position: 'absolute',
        left: 0,
        bottom: 10,
    },
    bookshelfHeader: {
        position: 'relative',
        marginLeft: 30,
        marginRight: 30,
        marginBottom: 20,
        justifyContent: 'center',
        zIndex: 1,
    },
    bookshelfMenuOption: {
        flex: 1,
        fontSize: 14,
        lineHeight: 16,
        minHeight: 50,
        paddingTop: 17,
        paddingRight: 15,
        paddingBottom: 17,
        paddingLeft: 15,
        textTransform: 'none',
        fontFamily: theme.font.regular,
    },
    floatingMessage: {
        alignItems: 'center',
        backgroundColor: theme.palette.lightGrey,
        paddingTop: 30,
        paddingRight: 20,
        paddingBottom: 30,
        paddingLeft: 20,
        zIndex: 2000,
    },
    floatingMessageText: {
        fontFamily: theme.font.regular,
        textAlign: 'center',
    },
    menuOption: {
        paddingRight: 20,
        paddingLeft: 20,
        width: '100%',
    },
    menuOptionText: {
        fontFamily: theme.font.regular,
        fontSize: 20,
        lineHeight: 24,
        paddingTop: 17.5,
        paddingBottom: 17.5,
    },
});

export { styles as default };