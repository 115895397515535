import React from "react";
import { DimensionValue, TouchableOpacity, View } from "react-native";

import { Log } from "../../../../config";
import { Store } from "../../../../store";
import GpeText from "../../../GpeText";

function Message(props: {
    containerWidth: DimensionValue
}) {
    /** STORE */
    const { state, dispatch } = React.useContext(Store);

    const clearMessage = React.useCallback(() => dispatch({ type: 'LIBRARY-MESSAGE', payload: null }), [dispatch]);

    /** RENDER */
    return React.useMemo(() => {
        Log.debug('component: Message');

        return state.library.message
            ? (
                <View
                    style={{
                        paddingLeft: 30,
                        paddingRight: 30,
                        marginBottom: 20,
                        width: props.containerWidth
                    }}
                >
                    <TouchableOpacity
                        onPress={clearMessage}
                        style={{
                            backgroundColor: state.library.message.type === 'error'
                                ? '#FFCCCC'
                                : '#CFF',
                            borderRadius: 2,
                            padding: 10,
                            width: '100%',
                        }}
                    >
                        <GpeText
                            style={{
                                color: state.library.message.type === 'error'
                                    ? '#FF0000'
                                    : '#399',
                                textAlign: 'center'
                            }}
                        >{state.library.message.text}</GpeText>
                    </TouchableOpacity>
                </View>

            ) : null;
    }, [
        props.containerWidth,
        state.library.message,
        clearMessage
    ]);
}

export { Message as default }