import React from 'react';

import Config, { Log } from '../config';
import { Store } from '../store';
import { Redirect, useLocation } from '../components/GpeRouter';
import Context from '../components/ByContext';

function WelcomeScreen() {
    const { state } = React.useContext(Store);

    const location = useLocation();

    const token = React.useMemo(() => {
        let _token = undefined;
        if (Config.platform === 'web') {
            const searchParams = new URLSearchParams(location.search);
            _token = searchParams.has('token')
                ? searchParams.get('token')
                : undefined;
        }
        return _token;
    }, [location.search]);

    return React.useMemo(() => {
        Log.debug('screen: WELCOME');

        return token
            || state.user.auth
            ? <Redirect to={Config.routes.login} />
            : <Context.Welcome />
    }, [
        token,
        state.user.auth
    ]);
}

export { WelcomeScreen as default };