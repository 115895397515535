import { Platform } from "react-native";
import Log, { LogLevelDesc } from "loglevel";

import environment from "./jsons/environment.json";
import app from "./jsons/app.json";
import environmentParams from "./jsons/environmentParams.json";
import contexts from "./jsons/contexts.json";
import endpoints from "./jsons/endpoints.json";
import social from "./jsons/social.json";
import routes from "./jsons/routes.json";

export type AppContext = keyof typeof contexts;
export type RunningMode = keyof typeof environmentParams;

const initializeLogger = () => {
    const level = environmentParams[environment.runningMode as RunningMode].log.minimumLevel as LogLevelDesc;
    Log.setDefaultLevel(level);
};

const platform = (): 'android' | 'ios' | 'macos' | 'web' | 'windows' | 'linux' => {
    let OS: typeof Platform.OS | 'linux' = Platform.OS;
    if (OS === "web") {
        const _process: any = window
            && window.process
            ? window.process
            : null;

        let platformWeb = _process
            && _process.versions
            && _process.versions['electron']
            && _process.platform
            ? _process.platform
            : 'web';

        switch (platformWeb) {
            case 'darwin':
                OS = 'macos';
                break;
            case 'win32':
                OS = 'windows';
                break;
            case 'linux':
                OS = 'linux';
                break;
            default:
                break;
        }
    }
    return OS;
};

const baseUrl = () => {
    const _environmentParams = environmentParams[environment.runningMode as RunningMode];

    const webservice = _environmentParams.webservices;
    return `${webservice.protocol}${webservice.baseUrl}${webservice.port}`;
};

const analyticsUrl = () => {
    const _environmentParams = environmentParams[environment.runningMode as RunningMode];

    const analyticsService = _environmentParams.analyticsService;
    return `${analyticsService.protocol}${analyticsService.baseUrl}${analyticsService.port}`;
};

const Config = {
    analyticsUrl: analyticsUrl(),
    baseUrl: baseUrl(),
    params: environmentParams[environment.runningMode as RunningMode],
    contextParams: environmentParams[environment.runningMode as RunningMode][environment.context as AppContext],
    context: contexts[environment.context as AppContext],
    contextName: environment.context,
    endpoints: endpoints,
    platform: platform(),
    routes: routes,
    social: social,
    ...app,
};

export { Config as default, initializeLogger, Log };