import React, { MutableRefObject } from 'react';
import {
    View,
    TouchableOpacity,
    StyleSheet,
} from 'react-native';

import Config, { Log } from '../../config';
import { Store } from '../../store';
import { ContextTheme, dimensionWidth } from '../../styles';
import { getTranslation } from '../../middlewares/AppMiddleware';
import { clearSelectBookshelf } from '../../middlewares/LibraryMiddleware';
import HeaderButton from './components/HeaderButton';
import Sidebar from './components/Sidebar';
import DimensionsEventTimeout from '../DimensionsEvent';
import { useHistory } from '../GpeRouter';
import Context from '../ByContext';
import GpeText from '../GpeText';
import { DispatchType } from '../../interfaces/store';

function Header(props: { height: number }) {
    /** STORE */
    const { state, dispatch } = React.useContext(Store);

    /** PROPS */
    const dispatchRef: MutableRefObject<DispatchType> = React.useRef(dispatch);

    const history = useHistory();

    const translation = React.useMemo(() => getTranslation(state.app.language), [state.app.language]);

    /** MEDIAS */
    const labelSizeMedia = { xs: 16, md: 32, };
    const buttonWidthMedia = { xs: 45, md: 74 };
    const logoWidthMedia = Config.contextName === 'bertrand'
        ? { xs: 34, md: 51 }
        : { xs: 105, md: 195 };

    /** STATE */
    const [headerState, headerDispatch] = React.useReducer((
        _state: {
            showSidebar: boolean,
            showUserMenu: boolean,
            labelSize: number,
            buttonWidth: number,
            logoWidth: number,
        },
        _payload: {
            showSidebar?: boolean,
            showUserMenu?: boolean,
            labelSize?: number,
            buttonWidth?: number,
            logoWidth?: number,
        }
    ) => ({ ..._state, ..._payload }), {
        showSidebar: false,
        showUserMenu: false,
        labelSize: dimensionWidth(labelSizeMedia),
        buttonWidth: dimensionWidth(buttonWidthMedia),
        logoWidth: dimensionWidth(logoWidthMedia),
    });

    /** RESIZE LISTENER */
    DimensionsEventTimeout(() => {
        const _labelSize = dimensionWidth(labelSizeMedia);
        const _buttonWidth = dimensionWidth(buttonWidthMedia);
        const _logoWidth = dimensionWidth(logoWidthMedia);
        if (_labelSize !== headerState.labelSize
            || _buttonWidth !== headerState.buttonWidth
            || _logoWidth !== headerState.logoWidth) {
            headerDispatch({
                labelSize: _labelSize,
                buttonWidth: _buttonWidth,
                logoWidth: _logoWidth,
            });
        }
    });

    /** RENDER */
    return React.useMemo(() => {
        Log.debug('component: Header');

        const alphaPress = () => headerDispatch({
            showUserMenu: false,
            showSidebar: false,
        });

        const menuPress = () => headerDispatch({
            showSidebar: !headerState.showSidebar,
            showUserMenu: false,
        });

        const homePress = () => {
            clearSelectBookshelf(dispatchRef.current);
            history.push(Config.routes.bookshelf);
        };

        const _styles = Styles({
            height: props.height,
            fontSize: headerState.labelSize,
        });

        return (
            <React.Fragment>
                {/** header */}
                <View
                    testID='header'
                    style={_styles.headerContainerView}>
                    <View
                        style={{ flexDirection: 'row' }}>

                        {/** botao menu */}
                        <HeaderButton
                            icon='M'
                            onPress={menuPress}
                            width={headerState.buttonWidth} />

                        {/** botao biblioteca */}
                        <TouchableOpacity
                            onPress={homePress}
                            style={_styles.logoTouchableOpacity}>

                            <Context.Logo width={headerState.logoWidth} />

                            <GpeText
                                testID='title'
                                style={_styles.logoText}>
                                {translation('library.header.title')}
                            </GpeText>
                        </TouchableOpacity>
                    </View>

                    <View style={{ flexDirection: 'row' }}>
                        {/** botao sincronizar */}
                        <Context.RefreshButton
                            width={headerState.buttonWidth}
                            headerDispatch={headerDispatch} />
                    </View>
                </View>

                {/** alpha para bloquear o conteudo da biblioteca */}
                {headerState.showSidebar
                    || headerState.showUserMenu
                    ? <TouchableOpacity
                        onPress={alphaPress}
                        style={_styles.alphaTouchableOpacity} />
                    : null}

                {/** side menu */}
                <Sidebar
                    headerDispatch={headerDispatch}
                    show={headerState.showSidebar}
                    top={props.height} />
            </React.Fragment>
        )
    }, [
        translation,
        props.height,
        headerState,
        history,
    ]);
}

const Styles = (props: {
    height: number,
    fontSize: number,
}) => StyleSheet.create({
    alphaTouchableOpacity: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 1000,
    },
    headerContainerView: {
        position: 'absolute',
        zIndex: 1000,
        right: 0,
        left: 0,
        backgroundColor: 'rgba(250, 250, 250, 0.85)',
        flexDirection: 'row',
        justifyContent: 'space-between',
        overflow: 'hidden',
        height: props.height,
    },
    logoTouchableOpacity: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    logoText: {
        fontWeight: '200',
        fontFamily: ContextTheme.font.light,
        fontSize: props.fontSize
    }
});

export { Header as default };