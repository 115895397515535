import React from "react";

import { AppContextInterface, ActionInterface } from "../interfaces/store";
import appReducer, { app as stateApp } from './states/app';
import libraryReducer, { library as stateLibrary } from './states/library';
import userReducer, { user as stateUser } from './states/user';

const Store = React.createContext<AppContextInterface>({
    state: {
        app: stateApp,
        library: stateLibrary,
        user: stateUser
    },
    dispatch: () => { }
});

function StoreProvider(props: { children: any }) {
    const [app, appDispatch] = React.useReducer(appReducer, stateApp);
    const [user, userDispatch] = React.useReducer(userReducer, stateUser);
    const [library, libraryDispatch] = React.useReducer(libraryReducer, stateLibrary);

    const dispatchs = [
        appDispatch,
        userDispatch,
        libraryDispatch,
    ];

    const triggerDispatchs = (action: ActionInterface) => {
        for (const iterator of dispatchs) {
            iterator(action)
        }
    };

    const middleware = (action: ActionInterface | Array<ActionInterface>) => (next: any) => {
        Array.isArray(action)
            ? action.filter(Boolean).map((_action) => next(_action))
            : next(action)
    };

    const middlewareConstructor = (action: ActionInterface | Array<ActionInterface>) => {
        middleware(action)(triggerDispatchs);
    };

    const combinedReducers = {
        state: {
            app,
            user,
            library,
        },
        dispatch: middlewareConstructor
    };

    return (
        <Store.Provider value={combinedReducers}>
            {props.children}
        </Store.Provider>
    );
}

export { Store, StoreProvider };