import React, { MutableRefObject } from 'react';
import { DimensionValue, RefreshControl, View } from 'react-native';
import NetInfo from "@react-native-community/netinfo";

import { Log } from '../../../config';
import { setContentDispatch } from '../../../middlewares/LibraryMiddleware';
import { Store } from '../../../store';
import { LibraryStyles, dimensionWidth_Boolean, dimensionWidth } from '../../../styles';
import DimensionsEventTimeout from '../../DimensionsEvent';
import GpeScrollView from '../../GpeScrollView';
import ShelfEbooks from './ShelfEbooks';
import Showcases from './Showcases';
import { DispatchType } from '../../../interfaces/store';
import { forceRefresh, verifyConnection } from '../../../middlewares/commons';
import Message from './Message';

function Content() {
    /** STORE */
    const { state, dispatch } = React.useContext(Store);
    const dispatchRef: MutableRefObject<DispatchType> = React.useRef(dispatch);
    const mountedRef: MutableRefObject<boolean | undefined> = React.useRef();

    /** MEDIAS */
    const containerMedia = {
        xs: '100%',
        md: '75%',
    };
    const showTitleMedia: { xs: 'true' | 'false', md: 'true' | 'false' } = {
        xs: 'false',
        md: 'true',
    };
    const showShelfsDropdownMedia: { xs: 'true' | 'false', md: 'true' | 'false' } = {
        xs: 'true',
        md: 'false',
    };

    /** LOCAL STATE */
    const [contentState, contentDispatch] = React.useReducer((
        _state: {
            showTitle: boolean,
            containerStyle: DimensionValue,
            showShelfsDropdown: boolean,
            scrollEnabled: boolean,
            refreshing: boolean,
        },
        _payload: {
            showTitle?: boolean,
            containerStyle?: DimensionValue,
            showShelfsDropdown?: boolean,
            scrollEnabled?: boolean,
            refreshing?: boolean,
        }
    ) => ({ ..._state, ..._payload }), {
        showTitle: dimensionWidth_Boolean(showTitleMedia),
        containerStyle: dimensionWidth(containerMedia),
        showShelfsDropdown: dimensionWidth_Boolean(showShelfsDropdownMedia),
        scrollEnabled: true,
        refreshing: false,
    });

    const [onlineStateOptionState, setOnlineStateOptionState] = React.useState(false);

    React.useEffect(() => {
        mountedRef.current = true;

        // verifica o estado atual da ligacao
        verifyConnection(() => !onlineStateOptionState
            && mountedRef.current
            && setOnlineStateOptionState(true));

        // evento para alteracoes na ligacao
        NetInfo.addEventListener(state => {
            const connectedState = Boolean(state.type && state.isConnected);
            connectedState !== onlineStateOptionState
                && mountedRef.current
                && setOnlineStateOptionState(connectedState);
        });

        setContentDispatch(contentDispatch);

        return () => {
            mountedRef.current = false;
            setContentDispatch(undefined);
        }
    }, [onlineStateOptionState]);

    /** PROPS */
    const draggingMode = React.useMemo(() => (
        state.library.moveToShelf || state.library.manualSorting
    ), [state.library.moveToShelf, state.library.manualSorting]);
    const refreshBookshelfCallback = React.useCallback(() => {
        contentDispatch({ refreshing: true });
        state.app.uid
            && forceRefresh(
                dispatchRef.current,
                state.app.uid,
                state.user.auth,
                state.user.authToken,
            ).then(() => mountedRef.current
                && contentDispatch({ refreshing: false }));
    }, [
        state.app.uid,
        state.user.auth,
        state.user.authToken,
    ]);

    /** RESIZE LISTENER */
    DimensionsEventTimeout(() => {
        const _containerStyle = dimensionWidth(containerMedia);
        const _showTitle = dimensionWidth_Boolean(showTitleMedia);
        const _showShelfsDropdown = dimensionWidth_Boolean(showShelfsDropdownMedia);
        if (_containerStyle !== contentState.containerStyle
            || _showTitle !== contentState.showTitle
            || _showShelfsDropdown !== contentState.showShelfsDropdown) {
            contentDispatch({
                showTitle: _showTitle,
                containerStyle: _containerStyle,
                showShelfsDropdown: _showShelfsDropdown,
            });
        }
    });

    /** RENDER */
    return React.useMemo(() => {
        Log.debug(`component: Content`);

        return (
            <View
                style={LibraryStyles.rightContainer}
            >
                {/* <GpePerfectScrollbar> */}
                <GpeScrollView
                    key='content'
                    scrollEnabled={contentState.scrollEnabled}
                    style={{ minHeight: '100%', width: '100%' }}
                    refreshControl={onlineStateOptionState
                        && !draggingMode
                        ? <RefreshControl
                            refreshing={contentState.refreshing}
                            onRefresh={refreshBookshelfCallback} />
                        : undefined
                    }
                    contentContainerStyle={[
                        LibraryStyles.contentContainer,
                        {
                            width: '100%',
                            maxWidth: 1200,
                            marginVertical: 0,
                            marginHorizontal: 'auto',
                            alignItems: 'flex-end',
                        },
                    ]}
                >
                    <Message containerWidth={contentState.containerStyle} />

                    {!state.library.bookshelfSelected
                        && state.library.bookshelf
                        && state.library.bookshelf.showcases
                        ? (
                            <Showcases
                                showTitle={contentState.showTitle}
                                containerStyle={contentState.containerStyle}
                                showShelfsDropdown={contentState.showShelfsDropdown} />
                        ) : state.library.bookshelf
                            && state.library.bookshelf.shelfs
                            && state.library.bookshelf.shelfs.length
                            ? (
                                <ShelfEbooks
                                    showTitle={contentState.showTitle}
                                    containerStyle={contentState.containerStyle}
                                    showShelfsDropdown={contentState.showShelfsDropdown} />
                            ) : null}

                </GpeScrollView>
                {/* </GpePerfectScrollbar> */}
            </View>
        );
    }, [
        contentState,
        state.library.bookshelfSelected,
        state.library.bookshelf,
        refreshBookshelfCallback,
        onlineStateOptionState,
        draggingMode
    ]);
}

export { Content as default }