import { Dimensions } from "react-native";
import NetInfo from "@react-native-community/netinfo";

import Config, { Log } from '../config';
import { verifyConnection } from './commons';
import { getCurrentTime, generateRandom, getEpochTime } from './commons';
import { setStorage, getStorage } from '../store/storage';
import AppServices from "../services/AppServices";
import { DispatchType } from "../interfaces/store";

type AnalyticsApp = {
    acid?: number,
    don?: boolean, // navigator.onLine
    dor?: 'L' | 'P', // L - landscape, P - portrait
    dvt?: string,
    raid?: number,
    ua?: string, //navigator.userAgent
    ver?: string,
};

type AnalyticsUser = {
    cat?: 'SESSION',
    id?: number | string,
    uid?: number,
    ts?: number,
    type?: 'NEW',
};

const syncInterval = 60000; //in milliseconds

const eventTypes = {
    // 'BOOK_CLOSE': 'BCL' /* 206 */,
    // 'TOC_CLICK': 'TCL' /* 207 */,
    // 'SCRUBBER_MOVE': 'SCM' /* 208 */,
    // 'FONT_TYPE_CHANGED': 'FTC' /* 209 */,
    // 'FONT_SIZE_CHANGED': 'FSC' /* 210 */,
    // 'THEME_CHANGED': 'THC' /* 211 */,
    // 'DICT_TERM_LOOKUP': 'DTL' /* 212 */,
    'USER_LOGIN': 'ULI' /* 202 */,
    'BOOK_DOWNLOADED': 'BDW' /* 203 */,
    'BOOK_OPEN': 'BOP' /* 204 */,
    'PAGE_READ': 'PGR' /* 205 */,
    'USER_LOGOUT': 'ULO' /* 213 */,
    'CONVERT_EDITOR_SAMPLE_TO_USER': 'CESTU' /* 215 */,
    'MOVE_BOOK_TO_OTHER_BOOKSHELF': 'MBTOB' /* 216 */,
    'CHANGE_BOOK_ORDER_BY_AUTHOR': 'CBOA' /* 217 */,
    'CHANGE_BOOK_ORDER_BY_TITLE': 'CBOT' /* 218 */,
    'CHANGE_BOOK_ORDER_MANUAL': 'CBOM' /* 219 */,
    'CREATE_BOOKSHELF': 'CEBS' /* 220 */,
    'RENAME_BOOKSHELF': 'RNBS' /* 221 */,
    'DELETE_BOOKSHELF': 'DLBS' /* 222 */,
};

const analyticsStorageKey: {
    'user': string,
    'events': string,
    'sessions': string,
} = {
    user: 'GPE_ANALYTICS-USER',
    events: 'GPE_ANALYTICS-EVENTS',
    sessions: 'GPE_ANALYTICS-SESSIONS',
};

let analytics: {
    app: AnalyticsApp
    user: AnalyticsUser,
    kongToken?: string,
} = {
    app: {},
    user: {},
    kongToken: undefined,
};

const analyticsQueue: {
    'events': {
        [key: string]: {}
    },
    'sessions': {
        [key: string]: {}
    },
} = {
    events: {},
    sessions: {}
};

//TODO: flag para desativar analytics
const desativarAnalytics = true;

function loadAnalytics(_dispatch: DispatchType, _appStateUid: string, _userAuth: any) {
    if (desativarAnalytics)
        return _dispatch({
            type: 'APP-ANALYTICS_LOADED',
            payload: true,
        });;

    const promises = [];

    promises.push(
        getStorage(analyticsStorageKey.user).then((storage) => {
            storage && (analytics.user = storage);
        })
    );

    promises.push(
        getStorage(analyticsStorageKey.events).then((storage) => {
            storage && (analyticsQueue.events = storage);
        })
    );

    promises.push(
        getStorage(analyticsStorageKey.sessions).then((storage) => {
            storage && (analyticsQueue.sessions = storage);
        })
    );

    promises.length
        && Promise.all(promises).then(() => {
            analytics.kongToken = _userAuth && _userAuth.token ? _userAuth.token : null;

            analytics.app = {
                dvt: _appStateUid,
                ver: Config.appVersion,
                ua: Config.platform,
                acid: Config.context.applicationContext,
                raid: Config.context.appId[Config.platform],
                don: undefined,
                dor: (Dimensions.get('window').width > Dimensions.get('window').height)
                    ? 'L'
                    : 'P',
            };

            // verifica a rotacao do ecra
            Dimensions.addEventListener('change', ({ window }) => analytics.app.dor = (window.width > window.height) ? 'L' : 'P');

            // verifica o estado atual da ligacao
            NetInfo.fetch().then(state => analytics.app.don = Boolean(state.type && state.isConnected));

            // evento para alteracoes na ligacao
            NetInfo.addEventListener(state => analytics.app.don = Boolean(state.type && state.isConnected));

            // ativar a sincronicacao periodica
            setInterval(() => verifyConnection(submitQueue), syncInterval);

            _dispatch({
                type: 'APP-ANALYTICS_LOADED',
                payload: true,
            });
        });
}

function submitQueue() {
    if (Object.keys(analyticsQueue.events).length) {
        const promisesEvents: Array<{}> = [];
        Object.keys(analyticsQueue.events).forEach(key => {
            promisesEvents.push(
                submit(analyticsQueue.events[key]).then((response: any) => !response.error
                    ? delete analyticsQueue.events[key]
                    : null)
            );
        });
        promisesEvents.length
            && Promise.all(promisesEvents).then(() => {
                setStorage({ type: analyticsStorageKey.events, payload: analyticsQueue.events });
            });
    }

    if (Object.keys(analyticsQueue.sessions).length) {
        const promisesSessions: Array<{}> = [];
        Object.keys(analyticsQueue.sessions).forEach(key => {
            promisesSessions.push(
                submit(analyticsQueue.sessions[key]).then((response: any) => !response.error
                    ? delete analyticsQueue.sessions[key]
                    : null)
            );
        });
        promisesSessions.length
            && Promise.all(promisesSessions).then(() => {
                setStorage({ type: analyticsStorageKey.sessions, payload: analyticsQueue.sessions });
            });
    }
}

function submit(data: object) {
    /** verificar se existe o token */
    if (!analytics.kongToken) return new Promise((resolve, reject) => { resolve({ error: true }) });

    Log.debug('Sending analytics');

    // TODO: verificas qual o token "an-usr" para a bertrand
    return AppServices({
        gpeAnalytics: {
            urlParams: {
                "analyticsServiceId": Config.context.serviceId,
                "applicationalContextId": Config.context.applicationContext,
                "random": generateRandom(),
            },
            body: [data],
            headers: {
                "an-usr": "NzmQywFoE3UXHKdn0e",
                "x-token": analytics.kongToken,
            },
        }
    });
}

function registAnalyticsEvent(evtType: keyof typeof eventTypes, evtData: {}) {
    if (desativarAnalytics
        || !analytics.user.id) return false;

    const time = getCurrentTime();
    const epochTime = getEpochTime(time);
    const random = generateRandom();

    const event = {
        'type': eventTypes[evtType],
        'ts': epochTime,
        'sessId': analytics.user.id,
        'cat': 'EVENT',
        ...evtData,
    };

    const insertQueue = () => insertAnalyticsQueue('events', time + '-' + random, event);
    const online = () => submit(event).then((response: any) => response.error ? insertQueue() : null);
    const offline = () => insertQueue();
    verifyConnection(online, offline);
}

function registAnalyticsSession(_userAuth: { id: number, token: string }) {
    if (desativarAnalytics
        || !analytics.app) return false;

    const time = getCurrentTime();
    const epochTime = getEpochTime(time);
    const random = generateRandom();

    analytics.user = {
        id: time,
        ts: epochTime,
        cat: 'SESSION',
        uid: _userAuth.id,
        type: 'NEW',
    };
    analytics.kongToken = _userAuth.token;

    // persist user data
    setStorage({ type: analyticsStorageKey.user, payload: analytics.user });

    const session = { ...analytics.app, ...analytics.user };
    const insertQueue = () => insertAnalyticsQueue('sessions', time + '-' + random, session);
    const online = () => submit(session).then((response: any) => response.error ? insertQueue() : null);
    const offline = () => insertQueue();
    verifyConnection(online, offline);
}

function insertAnalyticsQueue(type: 'events' | 'sessions', key: string, obj: object) {
    Log.debug('Analytics insert queue');

    analyticsQueue[type][key] = obj;
    // persist data
    setStorage({ type: analyticsStorageKey[type], payload: analyticsQueue[type] });
}

export { eventTypes, loadAnalytics, registAnalyticsEvent, registAnalyticsSession }