import React from "react";
import { TouchableOpacity, TouchableOpacityProps } from "react-native";

function SocialButton(props: TouchableOpacityProps & { children: any }) {
    return (
        <TouchableOpacity
            style={{
                borderWidth: 1,
                borderRadius: 2,
                borderColor: '#ccc',
                backgroundColor: '#fff',
                width: 40,
                height: 40,
                justifyContent: 'center',
                alignItems: 'center'
            }}
            {...props} />
    );
}

export { SocialButton as default }