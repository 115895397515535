import React, { MutableRefObject } from 'react';

import { DispatchType } from '../../interfaces/store';
import { closePopup } from '../../middlewares/commons';
import { Store } from '../../store';
import { useHistory } from '../GpeRouter';

export function PopupInit() {
    const { state, dispatch } = React.useContext(Store);

    const dispatchRef: MutableRefObject<DispatchType> = React.useRef(dispatch);

    const history = useHistory();

    const popup = React.useMemo(() => state.app.popup, [state.app.popup]);

    const closePopupCallback = React.useCallback(() => {
        popup
            && popup.closeRedirect
            && history.replace(popup.closeRedirect);
        closePopup(dispatchRef.current);
    }, [
        history,
        popup,
    ]);

    return React.useMemo(() => ({
        popup,
        closePopupCallback,
    }), [
        popup,
        closePopupCallback,
    ]);
}