import React from 'react';

import { Store } from '../store';
import Config, { Log } from '../config';
import { Redirect } from '../components/GpeRouter';
import ContactWebPage from '../components/ContactWebPage';

function ContactScreen(props: any) {
    const { state } = React.useContext(Store);

    return React.useMemo(() => {
        Log.debug('screen: CONTACT');

        return state.user.auth
            ? <ContactWebPage />
            : <Redirect to={Config.routes.firstpage} />
    }, [state.user.auth]);
}

export { ContactScreen as default }