import React, { MutableRefObject } from 'react';
import {
    View,
    TextInput,
    Platform,
    TouchableWithoutFeedback,
    TouchableOpacity
} from 'react-native';

import Config, { Log } from '../../../../config';
import { Store } from '../../../../store';
import { LoginStyles, ContextTheme, dimensionWidth } from '../../../../styles';
import { Link } from '../../../GpeRouter';
import GpeButton from '../../../GpeButton';
import { doLogin } from '../../../../middlewares/UserMiddleware';
import Icon from '../../../Icon';
import ModalBackground from '../../../ModalBackground';
import DimensionsEventTimeout from '../../../DimensionsEvent';
import GpeScrollView from '../../../GpeScrollView';
import FacebookLogin from './FacebookLogin';
import GoogleLogin from './GoogleLogin';
import AppleLogin from './AppleLogin';
import GpeText from '../../../GpeText';
import { DispatchType } from '../../../../interfaces/store';
import { openLink } from '../../../../middlewares/AppMiddleware';

function LoginForm() {
    const { state, dispatch } = React.useContext(Store);

    const dispatchRef: MutableRefObject<DispatchType> = React.useRef(dispatch);
    const contextConfig = React.useMemo(() => (
        Config.contextParams
    ), []);

    /** MEDIA */
    const titleMedia = React.useMemo(() => ({ xs: 22, lg: 32 }), []);

    /** LOCAL STATE */
    const [form, setForm] = React.useState<{
        user: string,
        password: string,
        keepLoggedIn: boolean,
        secureTextEntry: boolean,
        titleSize: number | undefined,
        showKeepLoggedInInfo: boolean,
        notValid: { [key: string]: string | undefined },
    }>({
        user: '',
        password: '',
        keepLoggedIn: Config.platform === 'web' ? false : true,
        secureTextEntry: true,
        titleSize: dimensionWidth(titleMedia),
        showKeepLoggedInInfo: false,
        notValid: {},
    });

    /** RESIZE LISTENER */
    DimensionsEventTimeout(() => {
        const _titleSize = dimensionWidth(titleMedia);
        if (_titleSize !== form.titleSize) {
            setForm({
                ...form,
                titleSize: _titleSize,
            });
        }
    });

    const formValid = React.useCallback(() => {
        const notValid: { user?: string, password?: string } = {};

        form.user.match(new RegExp('@', 'g'))?.length !== 1 && (notValid.user = 'e-mail inválido!');
        form.user.lastIndexOf('.') < 3 && (notValid.user = 'e-mail inválido!');
        (form.user.lastIndexOf('@') < 1
            || form.user.lastIndexOf('.') < form.user.lastIndexOf('@')) && (notValid.user = 'e-mail inválido!');
        form.password.length < 1 && (notValid.password = 'insira a password!');

        Object.keys(notValid).length && setForm({
            ...form,
            notValid: notValid,
        });

        return !Object.keys(notValid).length;
    }, [form]);

    const loginSubmit = React.useCallback(() => (
        formValid()
        && state.app.uid
        && doLogin(dispatchRef.current, state.app.uid, form)
    ), [state.app.uid, formValid, form]);

    const handleChange = React.useCallback((type: string, value: string | boolean) => {
        state.user.loginError
            && dispatchRef.current
            && dispatchRef.current({
                type: 'USER-LOGIN_ERROR',
                payload: '',
            });

        const notValid: any = form.notValid;
        notValid[type] && (delete notValid[type]);

        setForm({
            ...form,
            [type]: value,
            notValid: notValid,
        });
    }, [state.user.loginError, form]);

    return React.useMemo(() => {
        Log.debug('component: LoginForm');

        return (
            <GpeScrollView
                testID='login-form'
                horizontal={false}
                style={{ backgroundColor: ContextTheme.palette.white }}
                contentContainerStyle={{ padding: 10, alignItems: 'center' }}>
                {Config.platform === 'web'
                    ? (
                        <View
                            style={{
                                alignItems: 'flex-end',
                                position: 'absolute',
                                top: 0,
                                right: 0,
                                zIndex: 1,
                            }}>
                            <TouchableOpacity
                                onPress={() => openLink(`${contextConfig.libraryUrl}`)}>
                                <Icon
                                    style={{
                                        color: '#ffffff',
                                        marginTop: 15,
                                        marginRight: 15,
                                    }}
                                    icon='*' />
                            </TouchableOpacity>
                        </View>
                    ) : null}

                <ModalBackground color={ContextTheme.palette.loginColor} />

                <View
                    style={LoginStyles.container}>

                    <GpeText style={[LoginStyles.h1, { fontSize: form.titleSize }]}>
                        {'login wook'}
                    </GpeText>

                    <View
                        style={{
                            backgroundColor: 'rgba(255,255,255,0.85)',
                            padding: 10,
                            width: '100%',
                            borderRadius: 2,
                        }}>

                        {Object.keys(form.notValid).length
                            ? (
                                <GpeText style={LoginStyles.error}>
                                    {form.notValid[Object.keys(form.notValid)[0]]}
                                </GpeText>
                            )
                            : state.user.loginError
                                ? <GpeText style={LoginStyles.error}>{state.user.loginError}</GpeText>
                                : null}

                        <View style={[LoginStyles.inputContainer, form.notValid.user ? { borderColor: 'red' } : null]}>
                            <Icon icon='u' style={{ color: '#ccc', backgroundColor: ContextTheme.palette.white }} />
                            <TextInput
                                style={LoginStyles.input}
                                keyboardType="email-address"
                                onChangeText={value => handleChange('user', value)}
                                onSubmitEditing={loginSubmit}
                                value={form.user}
                                {...(Platform.OS !== "web" ? { autoCompleteType: "email" } : null)}
                                disableFullscreenUI={true}
                                autoCapitalize="none"
                                placeholder="endereço de email" />
                        </View>

                        <View style={[LoginStyles.inputContainer, form.notValid.password ? { borderColor: 'red' } : null]}>
                            <Icon icon='U' style={{ color: '#ccc', backgroundColor: ContextTheme.palette.white }} />
                            <TextInput
                                style={LoginStyles.input}
                                onChangeText={value => handleChange('password', value)}
                                onSubmitEditing={loginSubmit}
                                value={form.password}
                                {...(Platform.OS !== "web" ? { autoCompleteType: "password" } : null)}
                                secureTextEntry={form.secureTextEntry}
                                disableFullscreenUI={true}
                                autoCapitalize="none"
                                placeholder="password" />
                            <TouchableOpacity
                                onPress={() => handleChange('secureTextEntry', !form.secureTextEntry)}>
                                <Icon icon={form.secureTextEntry ? 't' : 'T'} style={{ color: ContextTheme.palette.loginColor, backgroundColor: ContextTheme.palette.white }} />
                            </TouchableOpacity>
                        </View>

                        <View style={{ flexDirection: 'row', justifyContent: 'flex-end', marginBottom: 10 }}>
                            <Link to={Config.routes.recover} style={{}}>
                                <GpeText
                                    style={[
                                        LoginStyles.text,
                                        {
                                            textAlign: 'right',
                                            textDecorationLine: 'underline',
                                            textDecorationColor: '#262626'
                                        }
                                    ]}>
                                    {'esqueceu a sua password?'}
                                </GpeText>
                            </Link>
                        </View>

                        {Config.platform === 'web'
                            ? (
                                <View style={{ flexDirection: 'row', marginBottom: 10 }}>
                                    <TouchableWithoutFeedback
                                        onPress={() => handleChange('keepLoggedIn', !form.keepLoggedIn)}>
                                        <View
                                            style={{ flexDirection: 'row' }}>
                                            <React.Fragment>
                                                <GpeText
                                                    style={{
                                                        fontFamily: 'WookIcones',
                                                        fontSize: 15,
                                                        lineHeight: 18,
                                                        height: 20,
                                                        width: 20,
                                                        borderWidth: 1,
                                                        borderColor: '#ccc',
                                                        borderRadius: 2,
                                                        textAlign: 'center',
                                                        marginRight: 10,
                                                    }}>{form.keepLoggedIn ? 'X' : null}</GpeText>
                                                <GpeText style={LoginStyles.text}>
                                                    {'manter a sessão iniciada'}
                                                </GpeText>
                                                <TouchableOpacity
                                                    onPress={() => handleChange('showKeepLoggedInInfo', !form.showKeepLoggedInInfo)}>
                                                    <GpeText
                                                        style={{
                                                            fontFamily: ContextTheme.font.semibold,
                                                            fontSize: 16,
                                                            color: ContextTheme.palette.loginColor,
                                                            textAlign: 'center',
                                                            width: 24,
                                                            borderRadius: 10,
                                                            backgroundColor: '#f2f2f2',
                                                            marginLeft: 5,
                                                        }}>?</GpeText>
                                                </TouchableOpacity>
                                            </React.Fragment>
                                        </View>
                                    </TouchableWithoutFeedback>
                                </View>
                            ) : null}
                        {form.showKeepLoggedInInfo
                            ? (
                                <View style={{
                                    backgroundColor: '#f2f2f2',
                                    borderRadius: 2,
                                    paddingTop: 4,
                                    paddingRight: 10,
                                    paddingBottom: 4,
                                    paddingLeft: 10,
                                    marginTop: 5,
                                    marginBottom: 10,
                                }}>
                                    <View style={{
                                        width: 0,
                                        height: 0,
                                        backgroundColor: 'transparent',
                                        borderStyle: 'solid',
                                        borderLeftWidth: 10,
                                        borderRightWidth: 10,
                                        borderBottomWidth: 10,
                                        borderLeftColor: 'transparent',
                                        borderRightColor: 'transparent',
                                        borderBottomColor: '#f2f2f2',
                                        position: 'absolute',
                                        top: 0,
                                        left: 210,
                                        marginTop: -10,
                                    }}></View>
                                    <GpeText
                                        style={{
                                            fontFamily: ContextTheme.font.regular,
                                            fontSize: 12,
                                            lineHeight: 16,
                                            textAlign: 'center',
                                        }}>
                                        {'A opção "manter a sessão iniciada" reduz o número de vezes que iremos solicitar a sua identificação neste dispositivo. Para manter a segurança da sua conta, utilize esta opção apenas nos seus dispositivos pessoais.'}
                                    </GpeText>
                                </View>
                            ) : null}

                        <GpeButton
                            testID='submit'
                            style={[LoginStyles.button, { width: '100%', maxWidth: undefined }]}
                            onPress={loginSubmit}>
                            <GpeText style={{ fontFamily: ContextTheme.font.semibold, fontSize: 16 }}>
                                {'CONFIRMAR'}
                            </GpeText>
                        </GpeButton>

                        <GpeText style={[LoginStyles.text, { textAlign: 'center', marginBottom: 10 }]}>
                            {'ou entre com a sua conta:'}
                        </GpeText>

                        <View style={[LoginStyles.row, { marginBottom: 10 }]}>
                            <View style={{ marginRight: 5, marginLeft: 5 }}>
                                <FacebookLogin keepLoggedIn={form.keepLoggedIn} />
                            </View>
                            <View style={{ marginRight: 5, marginLeft: 5 }}>
                                <GoogleLogin keepLoggedIn={form.keepLoggedIn} />
                            </View>
                            <View style={{ marginRight: 5, marginLeft: 5 }}>
                                <AppleLogin keepLoggedIn={form.keepLoggedIn} />
                            </View>
                        </View>

                        <Link
                            to={Config.routes.regist}
                            style={LoginStyles.row}>
                            <View
                                style={{
                                    backgroundColor: ContextTheme.palette.lightGrey,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderRadius: 2,
                                    width: '100%',
                                    height: 40,
                                    padding: 8,
                                }}>
                                <GpeText
                                    style={{
                                        fontFamily: ContextTheme.font.regular,
                                        fontSize: 16,
                                        color: ContextTheme.palette.loginColor,
                                    }}>
                                    {'NÃO TEM CONTA? REGISTE-SE AQUI'}
                                </GpeText>
                            </View>
                        </Link>
                    </View>
                </View>
            </GpeScrollView>
        )
    }, [
        state.user.loginError,
        contextConfig.libraryUrl,
        form,
        handleChange,
        loginSubmit,
    ]);
}

export { LoginForm as default }