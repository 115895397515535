import React, { MutableRefObject } from 'react';
import { View, TouchableOpacity } from 'react-native';

import { Log } from '../../../config';
import { Store } from '../../../store';
import { LibraryStyles } from '../../../styles';
import Icon from '../../Icon';
import { toggleMoveToShelf, toggleManualSorting } from '../../../middlewares/LibraryMiddleware';
import { getTranslation } from '../../../middlewares/AppMiddleware';
import GpeText from '../../GpeText';
import { DispatchType } from '../../../interfaces/store';

function FloatingMessage() {
    /** STORE */
    const { state, dispatch } = React.useContext(Store);

    const dispatchRef: MutableRefObject<DispatchType> = React.useRef(dispatch);
    const translation = React.useMemo(() => getTranslation(state.app.language), [state.app.language]);
    const floatingLabel = React.useMemo(() => (
        state.library.bookshelfSelected?.id === translation('library.audiobooks.bookshelf.title')
            || state.library.bookshelfSelected?.id === translation('library.ebooks.bookshelf.title')
            ? state.library.bookshelfSelected?.id
            : 'artigos'
    ), [state.library.bookshelfSelected, translation]);

    /** RENDER */
    return React.useMemo(() => {
        Log.debug('component: FloatingMessage');

        return (
            <React.Fragment>
                {state.library.moveToShelf
                    ? (
                        <View style={LibraryStyles.floatingMessage}>
                            <View
                                style={{
                                    flexDirection: 'row',
                                    flexWrap: 'wrap',
                                    justifyContent: 'center',
                                }}>
                                <GpeText
                                    style={LibraryStyles.floatingMessageText}>
                                    {`Para mover os ${floatingLabel} clique no botão`}</GpeText>
                                <View
                                    style={{
                                        height: 16,
                                        width: 26,
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}>
                                    <Icon style={LibraryStyles.iconText} icon='d' />
                                </View>
                                <GpeText
                                    style={LibraryStyles.floatingMessageText}>
                                    {`e arraste para a estante que pretende.`}</GpeText>
                            </View>

                            <GpeText
                                style={LibraryStyles.floatingMessageText}>
                                {translation("library.floatingmessage.shelf.move.finished")}</GpeText>

                            <TouchableOpacity
                                onPress={() => toggleMoveToShelf(dispatchRef.current)}
                                style={{ marginTop: 20 }}>
                                <GpeText
                                    style={[
                                        LibraryStyles.floatingMessageText,
                                        LibraryStyles.themeColor,
                                    ]}>
                                    {`FECHAR O MODO DE ORGANIZAÇÃO MANUAL`}</GpeText>
                            </TouchableOpacity>
                        </View>
                    ) : null}

                {state.library.manualSorting
                    ? (
                        <View style={LibraryStyles.floatingMessage}>
                            <View
                                style={{
                                    flexDirection: 'row',
                                    flexWrap: 'wrap',
                                    justifyContent: 'center',
                                }}>
                                <GpeText
                                    style={LibraryStyles.floatingMessageText}>
                                    {`Para ordenar os artigos clique no botão`}</GpeText>
                                <View
                                    style={{
                                        height: 16,
                                        width: 26,
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}>
                                    <Icon style={LibraryStyles.iconText} icon='d' />
                                </View>
                                <GpeText
                                    style={LibraryStyles.floatingMessageText}>
                                    {`e arraste para a posição que pretende.`}</GpeText>
                            </View>

                            <GpeText
                                style={LibraryStyles.floatingMessageText}>
                                {translation("library.floatingmessage.shelf.move.finished")}</GpeText>

                            <TouchableOpacity
                                onPress={() => toggleManualSorting(
                                    dispatchRef.current,
                                    state.app.uid,
                                    state.user.authToken,
                                    state.user.auth,
                                )}
                                style={{ marginTop: 20 }}>
                                <GpeText
                                    style={[
                                        LibraryStyles.floatingMessageText,
                                        LibraryStyles.themeColor,
                                    ]}>
                                    {`FECHAR MODO DE ORDENAÇÃO MANUAL`}</GpeText>
                            </TouchableOpacity>
                        </View>
                    ) : null}
            </React.Fragment>
        );
    }, [
        state.app.uid,
        state.user.authToken,
        state.user.auth,
        state.library.moveToShelf,
        state.library.manualSorting,
        translation,
        floatingLabel,
    ]);
}

export { FloatingMessage as default }