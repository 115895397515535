import React from 'react';

import { Log } from '../../../../config';
import { ContextTheme } from '../../../../styles';
import GpeScrollView from '../../../GpeScrollView';
import Menu from './components/Menu';

function Sidebar(props: { show: boolean, top: number, headerDispatch: Function }) {
    const _props = React.useMemo(() => props, [props]);

    return React.useMemo(() => {
        Log.debug('component: Sidebar');

        return (
            <GpeScrollView
                style={{
                    position: 'absolute',
                    zIndex: 1000,
                    top: _props.top,
                    bottom: 0,
                    left: _props.show ? 0 : -640,
                    backgroundColor: ContextTheme.palette.lightGrey,
                }}
                contentContainerStyle={{
                    flexGrow: 1,
                    width: 320,
                    justifyContent: 'space-between',
                }}>
                <Menu
                    headerDispatch={_props.headerDispatch} />
            </GpeScrollView>
        )
    }, [_props]);
}

export { Sidebar as default }