import { StyleSheet } from 'react-native';

import theme from '../../styles/theme';

const styles = StyleSheet.create({
  imageStyle: {
    height: '100%',
    width: '100%',
    aspectRatio: 1,
    resizeMode: 'contain',
  },
  wrapper: {
    justifyContent: 'space-between',
    height: '100%',
    width: '100%',
    padding: 20,
  },
  header: {
    fontSize: 30,
    marginBottom: 20,
    marginTop: 30,
    fontFamily: theme.font.semibold,
    textAlign: 'center',
  },
  paragraph: {
    fontSize: 16,
    fontFamily: theme.font.regular,
    color: theme.palette.lightBlack,
    paddingBottom: 20,
    textAlign: 'center',
  },
  footer: {
    fontSize: 16,
    marginVertical: 40,
    textAlign: 'center',
    fontFamily: theme.font.regular,
    width: '100%',
    color: theme.palette.lightBlack,
  },
  paginationWrapper: {
    position: 'absolute',
    bottom: 30,
    left: 0,
    right: 0,
    justifyContent: 'center',
    flexDirection: 'row',
  },
  paginationDots: {
    height: 10,
    width: 10,
    borderRadius: 10 / 2,
    backgroundColor: '#000000',
    marginLeft: 10,
  },
  exitStyle: {
    right: 0,
    position: 'absolute',
    zIndex: 1
  }
});

export default styles;