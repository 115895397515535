import { StyleSheet } from 'react-native';

import theme from '../../styles/theme';

const styles = StyleSheet.create({
    container: {
        flexGrow: 1,
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        maxWidth: 620,
        padding: undefined,
    },
    h1: {
        fontFamily: theme.font.semibold,
        lineHeight: 40,
        textAlign: 'center',
        color: theme.palette.white,
        paddingTop: 10,
        paddingRight: 0,
        paddingBottom: 10,
        paddingLeft: 0,
        marginBottom: 10,
    },
    text: {
        fontFamily: theme.font.regular,
        fontSize: 16,
        textAlign: 'center',
        color: theme.palette.lightBlack,
    },
    input: {
        flex: 1,
        fontFamily: theme.font.regular,
        fontSize: 16,
        height: 40,
        padding: 10,
        backgroundColor: theme.palette.white,
    },
    inputContainer: {
        flexDirection: 'row',
        height: 40,
        width: '100%',
        borderWidth: 1,
        borderColor: '#ccc',
        borderRadius: 2,
        marginBottom: 10,
        alignItems: 'center',
        overflow: 'hidden',
    },
    link: {
        fontFamily: theme.font.regular,
        textDecorationLine: 'underline',
        textDecorationColor: theme.palette.lightBlack,
    },
    button: {
        backgroundColor: theme.palette.recoverColor,
        padding: 8,
        borderRadius: 2,
        height: 40,
        width: "100%",
        maxWidth: 200,
        margin: "auto",
        justifyContent: "center",
        marginBottom: 10,
    },
    row: {
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    error: {
        fontFamily: theme.font.regular,
        fontSize: 16,
        width: '100%',
        padding: 10,
        minHeight: 40,
        borderRadius: 2,
        color: "red",
        backgroundColor: theme.palette.red,
        marginBottom: 10,
        textAlign: 'center',
    },
});

export default styles;