import React, { MutableRefObject } from "react";
import { TouchableOpacity } from "react-native";

import { Log } from '../../../config';
import { Store } from '../../../store';
import { LibraryStyles } from '../../../styles';
import { getTranslation } from '../../../middlewares/AppMiddleware';
import Hoverable from '../../Hoverable';
import ShelfCreateForm from '../../Popup/components/ShelfCreateForm';
import GpeText from "../../GpeText";
import { DispatchType } from "../../../interfaces/store";
import { openPopup } from "../../../middlewares/commons";

function ShelfCreate() {
    const { state, dispatch } = React.useContext(Store);

    const dispatchRef: MutableRefObject<DispatchType> = React.useRef(dispatch);
    const translation = React.useMemo(() => getTranslation(state.app.language), [state.app.language]);

    return React.useMemo(() => {
        Log.debug('component: ShelfCreate');

        return (
            <Hoverable>
                {(hover: boolean) => (
                    <TouchableOpacity
                        testID="create-shelf"
                        onPress={() => openPopup(dispatchRef.current, <ShelfCreateForm />)}>
                        <GpeText
                            style={[
                                LibraryStyles.shelf,
                                hover && LibraryStyles.themeColor
                            ]}>
                            <GpeText
                                style={LibraryStyles.themeColor}>+ </GpeText>{translation('library.bookshelf.list.new.shelf')}
                        </GpeText>
                    </TouchableOpacity>
                )}
            </Hoverable>
        )
    }, [
        translation
    ]);

}

export { ShelfCreate as default };