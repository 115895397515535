import React, { MutableRefObject } from 'react';
import { View, TextInput, Platform } from 'react-native';

import Config, { Log } from '../../../../config';
import { Store } from '../../../../store';
import { RecoverStyles, ContextTheme } from '../../../../styles';
import { Link } from '../../../GpeRouter';
import GpeButton from '../../../GpeButton';
import { doRecover } from '../../../../middlewares/UserMiddleware';
import Icon from '../../../Icon';
import GpeScrollView from '../../../GpeScrollView';
import BertrandLogo from './BertrandLogo';
import GpeText from '../../../GpeText';
import { DispatchType } from '../../../../interfaces/store';

function RecoverForm() {
    const { state, dispatch } = React.useContext(Store);

    /** LOCAL STATE */
    const [form, setForm] = React.useState<{
        email: string,
        notValid: { [key: string]: string | undefined },
    }>({
        email: '',
        notValid: {},
    });

    const dispatchRef: MutableRefObject<DispatchType> = React.useRef(dispatch);

    return React.useMemo(() => {
        Log.debug('component: RecoverForm');

        /** VALIDATE */
        const formValid = () => {
            const notValid: { email?: string, password?: string } = {};

            form.email.match(new RegExp('@', 'g'))?.length !== 1 && (notValid.email = 'e-mail inválido!');
            form.email.lastIndexOf('.') < 3 && (notValid.email = 'e-mail inválido!');
            (form.email.lastIndexOf('@') < 1
                || form.email.lastIndexOf('.') < form.email.lastIndexOf('@')) && (notValid.email = 'e-mail inválido!');

            setForm({
                ...form,
                notValid: notValid,
            });

            return !Object.keys(notValid).length;
        }

        const recoverSubmit = () => !state.user.recoverError
            && formValid()
            && doRecover(dispatchRef.current, form.email);

        const handleChange = (type: string, value: string) => {
            state.user.recoverError && dispatchRef.current({
                type: 'USER-RECOVER_ERROR',
                payload: '',
            });

            const notValid: any = form.notValid;
            notValid[type] && (delete notValid[type]);

            setForm({
                ...form,
                [type]: value,
                notValid: notValid,
            });
        };

        return (
            <GpeScrollView
                horizontal={false}
                style={{ backgroundColor: ContextTheme.palette.lightGrey }}
                contentContainerStyle={{ padding: 20, alignItems: 'center' }}>
                <View
                    style={RecoverStyles.container}>
                    <View
                        style={{ width: '100%' }}>
                        <View
                            style={{
                                flexDirection: 'row',
                                justifyContent: 'center',
                                marginBottom: 20,
                            }}>
                            <BertrandLogo
                                style={{
                                    width: 80,
                                    height: 80,
                                    marginRight: 20,
                                }} />

                            <View
                                style={{ alignItems: 'center', justifyContent: 'center' }}>
                                <GpeText
                                    style={{
                                        fontFamily: ContextTheme.font.regular,
                                        fontSize: 14,
                                        color: ContextTheme.palette.grey
                                    }}>
                                    {'BIBLIO BERTRAND'}</GpeText>
                            </View>
                        </View>
                    </View>

                    <View
                        style={{ width: '100%' }}>
                        <View
                            style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
                            <Link
                                to={Config.routes.login}>
                                <Icon
                                    icon=">"
                                    color={ContextTheme.palette.theme}
                                    style={{ transform: [{ rotate: '180deg' }] }} />
                            </Link>
                            <View style={{
                                flex: 1,
                                flexDirection: 'row',
                                flexWrap: 'wrap',
                                justifyContent: 'flex-start'
                            }}>
                                <GpeText
                                    style={[
                                        RecoverStyles.text,
                                        {
                                            fontSize: 14,
                                            textAlign: 'left',
                                            color: ContextTheme.palette.grey,
                                        }
                                    ]}>
                                    Esqueceu-se da sua password? Introduza o e-mail com que se registou em <GpeText
                                        style={[
                                            RecoverStyles.text,
                                            {
                                                fontFamily: ContextTheme.font.semibold,
                                                fontSize: 14,
                                                color: ContextTheme.palette.grey,
                                                textDecorationLine: 'underline',
                                                textDecorationColor: ContextTheme.palette.grey,
                                            }
                                        ]}>
                                        Bertrand.pt</GpeText></GpeText>
                            </View>
                        </View>

                        {Object.keys(form.notValid).length
                            ? <GpeText style={RecoverStyles.error}>
                                {form.notValid[Object.keys(form.notValid)[0]]}</GpeText>
                            : state.user.recoverError
                                ? <GpeText style={RecoverStyles.error}>{state.user.recoverError}</GpeText>
                                : null}

                        <View style={[
                            RecoverStyles.inputContainer,
                            form.notValid.email
                                ? { borderColor: 'red' }
                                : null,
                            { marginBottom: 20 }
                        ]}>
                            <Icon icon='u' style={{ color: '#ccc', backgroundColor: ContextTheme.palette.white }} />
                            <TextInput
                                style={[
                                    RecoverStyles.input,
                                    {
                                        fontSize: 14,
                                        color: ContextTheme.palette.grey
                                    }
                                ]}
                                onChangeText={value => handleChange('email', value)}
                                onSubmitEditing={recoverSubmit}
                                value={form.email}
                                {...(Platform.OS !== "web" ? { autoCompleteType: "email" } : null)}
                                disableFullscreenUI={true}
                                autoCapitalize="none"
                                placeholder="utilizador@email.com" />
                        </View>

                        <GpeButton
                            style={[RecoverStyles.button, { width: '100%', maxWidth: undefined }]}
                            onPress={recoverSubmit}>
                            <GpeText
                                style={{ fontFamily: ContextTheme.font.semibold, fontSize: 16 }}>
                                RECUPERAR PASSWORD</GpeText>
                        </GpeButton>
                    </View>
                </View>
            </GpeScrollView>
        )
    }, [form, state.user.recoverError]);
}

export { RecoverForm as default }