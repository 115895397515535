import React, { MutableRefObject } from 'react';
import {
    View,
    TouchableOpacity,
    SafeAreaView,
    ScrollView,
    Dimensions,
    NativeScrollEvent,
    NativeSyntheticEvent
} from 'react-native';

import Config, { Log } from '../../../../../config';
import {
    CarouselStyles,
    ContextTheme,
    LibraryStyles
} from '../../../../../styles';
import { Store } from '../../../../../store';
import { useHistory } from '../../../../GpeRouter';
import Icon from '../../../../Icon';
import GpeScrollView from '../../../../GpeScrollView';
import { setOnBoard } from '../../../../../middlewares/UserMiddleware';
import { DispatchType } from '../../../../../interfaces/store';
import { CarouselContentInterface } from '../../../../../interfaces/components';
import {
    presentFirstOnboard,
    presentSecondOnboard
} from '../../../../../middlewares/commons';
import DimensionsEventTimeout from '../../../../DimensionsEvent';
import Slide from './components/partials/Slide';

/**
 * Creates onBoard slider
 */
function OnBoard() {
    const { state, dispatch } = React.useContext(Store);

    const firstOnBoardContent: Array<CarouselContentInterface> = React.useMemo(() => [
        {
            title: 'wook deve saber',
            subtitle: 'Para ler um eBook',
            assetKey: 'onboard.slideEbook0',
            footer: 'Toque na capa e comece a ler.'
        },
        {
            title: 'wook deve saber',
            subtitle: 'Disponível offline',
            assetKey: 'onboard.slideEbook1',
            footer: 'Estes ebooks serão carregados no seu dispositivos e podem ser lidos sem ligação à internet.'
        },
        {
            title: 'wook deve saber',
            subtitle: 'Mova eBooks para outras estantes.',
            assetKey: 'onboard.slideEbook2',
            footer: 'Acione o menu de configurações, escolha a opção pretendida e mova os seus ebooks usando o ícone.'
        },
        {
            title: 'wook deve saber',
            subtitle: 'Assistente de leitura.',
            assetKey: 'onboard.slideEbook3',
            footer: 'No seu ebook toque prolongadamente numa palavra e veja a sua definição.'
        },
    ], []);

    const secondOnBoardContent: Array<CarouselContentInterface> = React.useMemo(() => [
        {
            title: 'wook deve saber',
            subtitle: 'Sabia que a biblioteca WOOK agora também tem audiolivros?',
            assetKey: 'onboard.slideAudio0',
            footer: (
                <React.Fragment>
                    {'Na sua biblioteca estão todos identificados pelo ícone '}
                    <Icon
                        style={[
                            LibraryStyles.iconText,
                            { backgroundColor: ContextTheme.palette.theme },
                        ]}
                        color={ContextTheme.palette.white}
                        icon={'S'} />
                    {' que se encontra na imagem da capa.'}
                </React.Fragment>
            )
        },
        {
            title: 'wook deve saber',
            subtitle: 'Uma nova forma de ler, ao seu gosto.',
            assetKey: 'onboard.slideAudio1',
            footer: 'Selecione a velocidade de narração, a passagem de capítulos rápida ou programe o tempo que deseja ouvir antes de uma noite descansada.'
        },
        {
            title: 'wook deve saber',
            subtitle: 'Não perca as passagens que mais gostou do seu audiolivro com os marcadores.',
            assetKey: 'onboard.slideAudio2',
            footer: 'Insira ou elimine os marcadores que desejar. Cada um indica o capítulo e hora dentro do audiolivro, bem como a data de criação. Clique em qualquer um deles para voltar à sua posição de audio.'
        },
        {
            title: 'wook deve saber',
            subtitle: 'Os audiolivros trazem novas emoções. Para ouvir, onde e como quiser.',
            assetKey: 'onboard.slideAudio3',
            footer: 'Sozinho ou acompanhado, a conduzir, a cozinhar, nos transportes públicos ou antes de adormecer, é tempo de viajar com audiolivros!'
        },
    ], []);

    const secondOnboard = React.useMemo(() => presentSecondOnboard(
        state.user.auth,
        state.user.firstOnBoard,
        state.user.firstOnBoardDate,
        state.user.secondOnBoard,
        state.library.bookshelf,
    ), [
        state.library.bookshelf,
        state.user.auth,
        state.user.firstOnBoard,
        state.user.firstOnBoardDate,
        state.user.secondOnBoard
    ]);

    const firstOnboard = React.useMemo(() => presentFirstOnboard(
        state.user.auth,
        state.user.firstOnBoard,
        state.user.secondOnBoard,
    ), [
        state.user.auth,
        state.user.firstOnBoard,
        state.user.secondOnBoard,
    ]);

    const sliderContent = React.useMemo(() => (
        secondOnboard
            ? secondOnBoardContent
            : firstOnboard
                ? firstOnBoardContent
                : [...firstOnBoardContent, ...secondOnBoardContent]
    ), [
        firstOnboard,
        secondOnboard,
        firstOnBoardContent,
        secondOnBoardContent,
    ]);

    const carousel = React.useMemo(() => (
        secondOnboard
            ? 2
            : firstOnboard
                ? 1
                : 2
    ), [
        firstOnboard,
        secondOnboard,
    ]);

    /** PROPS */
    const history = useHistory();
    const dispatchRef: MutableRefObject<DispatchType> = React.useRef(dispatch);
    const scrollRef: MutableRefObject<ScrollView | null> = React.useRef(null);
    const swiperRef: MutableRefObject<SafeAreaView | null> = React.useRef(null);

    /** LOCAL STATE */
    const [sliderState, setSliderState] = React.useReducer((
        _state: {
            sliderWidth: number,
            sliderHeight: number,
            currentPage: number,
        },
        _payload: {
            sliderWidth?: number,
            sliderHeight?: number,
            currentPage?: number,
        }
    ) => ({ ..._state, ..._payload }), {
        sliderWidth: Dimensions.get('window').width,
        sliderHeight: 0,
        currentPage: 0,
    });

    const setSliderPage = React.useCallback(
        (event: NativeSyntheticEvent<NativeScrollEvent>) => {
            const { x } = event.nativeEvent.contentOffset;
            const indexOfNextScreen = Math.round(x / sliderState.sliderWidth);
            setSliderState({ currentPage: indexOfNextScreen });
        },
        [sliderState.sliderWidth]
    );

    const scrollNextPage = React.useCallback((page: 1 | -1) => {
        const currentPage = sliderState.currentPage + page;
        scrollRef.current?.scrollTo({
            x: sliderState.sliderWidth * currentPage,
            animated: true
        });
        setSliderState({ currentPage: currentPage });
    }, [sliderState.sliderWidth, sliderState.currentPage]);

    /** RESIZE LISTENER */
    DimensionsEventTimeout(() => {
        const { width, height } = Dimensions.get('window');
        if (width !== sliderState.sliderWidth
            || height !== sliderState.sliderHeight) {
            setSliderState({ sliderHeight: height, sliderWidth: width });
        }
    });

    return React.useMemo(() => {
        Log.debug('component: Onboard');

        return (
            <SafeAreaView
                ref={(ref) => swiperRef.current = ref}
                onLayout={() => swiperRef.current?.measure(
                    (x: number, y: number, width: number, height: number) => {
                        (height !== sliderState.sliderHeight
                            || width !== sliderState.sliderWidth)
                            && setSliderState({ sliderHeight: height, sliderWidth: width })
                    })}
                style={{ flex: 1 }}>
                <GpeScrollView
                    style={{ backgroundColor: ContextTheme.palette.lighestGrey }}
                    horizontal={true}
                    scrollEventThrottle={16}
                    pagingEnabled={true}
                    showsHorizontalScrollIndicator={false}
                    ref={scrollRef}
                    onMomentumScrollEnd={(event) => setSliderPage(event)}>
                    {sliderContent.map((content, index) => (
                        <View
                            key={index}
                            style={{
                                width: sliderState.sliderWidth,
                                height: sliderState.sliderHeight,
                            }}>
                            {(sliderState.currentPage === index + 1
                                || sliderState.currentPage === index
                                || sliderState.currentPage === index - 1)
                                && <Slide
                                    showGif={sliderState.currentPage === index}
                                    height={sliderState.sliderHeight}
                                    content={content} />}
                        </View>
                    ))}
                </GpeScrollView>

                <TouchableOpacity
                    testID='close-onboard'
                    style={[CarouselStyles.exitStyle]}
                    onPress={() => state.user.auth?.id
                        && setOnBoard(dispatchRef.current, carousel, history, state.user.auth.id)}>
                    <Icon icon='X' style={{ fontSize: 35 }} />
                </TouchableOpacity>

                {Config.platform === 'web'
                    && sliderState.currentPage > 0
                    ? (
                        <View
                            style={{
                                position: 'absolute',
                                left: 20,
                                top: '45%',
                                backgroundColor: '#CCCCCC',
                            }}>
                            <TouchableOpacity
                                onPress={() => scrollNextPage(-1)}>
                                <Icon
                                    style={{
                                        height: 80,
                                        lineHeight: 80,
                                    }}
                                    color={ContextTheme.palette.black}
                                    icon={'('} />
                            </TouchableOpacity>
                        </View>
                    ) : null}

                {Config.platform === 'web'
                    && sliderState.currentPage < sliderContent.length - 1
                    ? (
                        <View
                            style={{
                                position: 'absolute',
                                right: 20,
                                top: '45%',
                                backgroundColor: '#CCCCCC',
                            }}>
                            <TouchableOpacity
                                onPress={() => scrollNextPage(1)}>
                                <Icon
                                    style={{
                                        height: 80,
                                        lineHeight: 80,
                                    }}
                                    color={ContextTheme.palette.black}
                                    icon={')'} />
                            </TouchableOpacity>
                        </View>
                    ) : null}

                <View style={CarouselStyles.paginationWrapper}>
                    {sliderContent.map((key, index) => (
                        <View
                            key={`${index}_${sliderState.currentPage}`}
                            style={[
                                CarouselStyles.paginationDots,
                                { opacity: sliderState.currentPage === index ? 1 : 0.2 }
                            ]} />
                    ))}
                </View>
            </SafeAreaView>
        )
    }, [
        state.user.auth,
        sliderState,
        setSliderPage,
        scrollNextPage,
        sliderContent,
        carousel,
        history,
        scrollRef,
    ]);
}

export { OnBoard as default }