import React from 'react';
import { SafeAreaView } from 'react-native';

import Config, { Log } from '../config';
import { StoreProvider } from '../store';
import Styles, { PerfectScrollbarStyles, FontFamilyStyles } from '../styles';
import { Router, Route, Switch, Redirect } from '../components/GpeRouter';
import Popup from '../components/Popup';
import Loader from '../components/Loader';
import WelcomeScreen from '../screens/WelcomeScreen';
import PrePrintScreen from '../screens/PrePrintScreen';
import BookshelfScreen from '../screens/BookshelfScreen';
import LoginScreen from '../screens/LoginScreen';
import RegistScreen from '../screens/RegistScreen';
import RecoverScreen from '../screens/RecoverScreen';
import SearchScreen from '../screens/SearchScreen';
import NotFoundScreen from '../screens/NotFoundScreen';
import HelpScreen from '../screens/HelpScreen';
import IntroductionScreen from '../screens/IntroductionScreen';
import ErrorBoundary from '../components/ErrorBoundary';
import SampleScreen from '../screens/SampleScreen';
import AssociateScreen from '../screens/AssociateScreen';
import OfferScreen from '../screens/OfferScreen';
import AppInitializer from '../components/AppInitializer';
import DevicesScreen from '../screens/DevicesScreen';
import ContactScreen from '../screens/ContactScreen';
import ConditionsScreen from '../screens/ConditionsScreen';
import PrivacyScreen from '../screens/PrivacyScreen';
import ForgetScreen from '../screens/ForgetScreen';

// import OneSignal from 'react-native-onesignal';

function App() {
    // FIXME: Tirar o OneSignal daqui...
    /** ONESIGNAL - BEGIN */
    // console.log("[DEBUG] A registar o OneSignal...");
    // OneSignal.setLogLevel(OneSignal.LOG_LEVEL.DEBUG, OneSignal.LOG_LEVEL.DEBUG);
    // OneSignal.setLogLevel(6, 3);
    // OneSignal.init("04047859-bd4d-4bcc-97b8-bd9c4ad98148");
    // OneSignal.sendTag("conAplEntId", "12345");
    // console.log("[DEBUG] OneSignal registado com sucesso.");
    /** ONESIGNAL - END */

    return React.useMemo(() => {
        Log.debug('component: App');

        return (
            <ErrorBoundary>
                <FontFamilyStyles />
                <PerfectScrollbarStyles />
                <StoreProvider>
                    <Router>
                        <SafeAreaView style={Styles.mainView}>
                            <AppInitializer>
                                <Switch>
                                    <Route exact path='/index.html'>
                                        <Redirect to={Config.routes.firstpage} />
                                    </Route>
                                    <Route exact path={Config.routes.firstpage}>
                                        <WelcomeScreen />
                                    </Route>
                                    <Route exact path={Config.routes.login}>
                                        <LoginScreen />
                                    </Route>
                                    <Route exact path={Config.routes.associate}>
                                        <AssociateScreen />
                                    </Route>
                                    <Route exact path={Config.routes.logout}>
                                        <WelcomeScreen />
                                    </Route>
                                    <Route exact path={Config.routes.recover}>
                                        <RecoverScreen />
                                    </Route>
                                    <Route exact path={Config.routes.regist}>
                                        <RegistScreen />
                                    </Route>
                                    <Route exact path={Config.routes.bookshelf}>
                                        <BookshelfScreen />
                                    </Route>
                                    <Route exact path={Config.routes.help}>
                                        <HelpScreen />
                                    </Route>
                                    <Route exact path={Config.routes.introduction}>
                                        <IntroductionScreen />
                                    </Route>
                                    <Route exact path={Config.routes.devices}>
                                        <DevicesScreen />
                                    </Route>
                                    <Route exact path={Config.routes.prePrint}>
                                        <PrePrintScreen />
                                    </Route>
                                    <Route exact path={Config.routes.contact}>
                                        <ContactScreen />
                                    </Route>
                                    <Route exact path={Config.routes.conditions}>
                                        <ConditionsScreen />
                                    </Route>
                                    <Route exact path={Config.routes.privacy}>
                                        <PrivacyScreen />
                                    </Route>
                                    <Route exact path={Config.routes.sample}>
                                        <SampleScreen />
                                    </Route>
                                    <Route exact path={Config.routes.forget}>
                                        <ForgetScreen />
                                    </Route>
                                    <Route path={Config.routes.search}>
                                        <SearchScreen />
                                    </Route>
                                    <Route path={Config.routes.offer}>
                                        <OfferScreen />
                                    </Route>
                                    <Route path={Config.routes.notFound}>
                                        <NotFoundScreen />
                                    </Route>
                                </Switch>
                            </AppInitializer>
                        </SafeAreaView>
                        <Popup />
                        <Loader />
                    </Router>
                </StoreProvider>
            </ErrorBoundary>
        );
    }, []);
}

export { App as default }