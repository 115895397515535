import React from 'react';

import Config from '../../../../config';
import { Redirect } from '../../../GpeRouter';

function Welcome() {
    return (
        <Redirect to={Config.routes.login} />
    );
}

export { Welcome as default }