import React from "react";
import {
    Animated,
    Image,
    TouchableOpacity,
    View,
} from "react-native";

import { Log } from '../../../../config';
import {
    LibraryStyles,
    ContextTheme,
} from '../../../../styles';
import {
    openPopup
} from "../../../../middlewares/commons";
import CoverDraggingIcon from '../CoverDraggingIcon';
import EbookInfo from "../../../Popup/components/EbookInfo";
import TypeIcon from "../TypeIcon";
import { EbookInterface } from "../../../../interfaces/middlewares";
import GpeText from "../../../GpeText";
import { AnimatedCoverInit } from "./utils";

function AnimatedCover(
    props: {
        ebook: EbookInterface,
        height?: number,
        width?: number,
        index?: number,
        shelfId?: number | string,
    }
) {
    const {
        coverState,
        draggingMode,
        translation,
        coverRef,
        dispatchRef,
        coverSizeState,
        openReadItemIdCallback,
        coverHoveringState,
        downloadedVersion,
        coverDragIconState,
        panResponder,
        imageOnErrorCallback,
        imageSourceUri,
        onLayoutCallback,
    } = AnimatedCoverInit(props);

    /** RENDER */
    return React.useMemo(() => {
        Log.debug('component: AnimatedCover');

        return (
            coverState.show
            && (
                <Animated.View
                    ref={(ref: any) => { coverRef.current = ref }}
                    onLayout={onLayoutCallback}
                    style={[
                        LibraryStyles.ebookContainer,
                        {
                            opacity: coverState.opacity,
                            zIndex: coverState.zIndex,
                            marginBottom: 30,
                            justifyContent: 'flex-end',
                        }
                    ]}>
                    {coverSizeState.height
                        && coverSizeState.width
                        && (
                            <React.Fragment>
                                <Animated.View
                                    style={[
                                        coverState.pan.getLayout(),
                                        {
                                            zIndex: coverState.zIndex,
                                            opacity: coverState.dragging ? 0.7 : 1,
                                        }
                                    ]}>
                                    <TouchableOpacity
                                        onPress={() => !draggingMode
                                            && openReadItemIdCallback()}
                                        onLongPress={() => !draggingMode
                                            && openPopup(
                                                dispatchRef.current,
                                                <EbookInfo ebook={props.ebook} />
                                            )}>
                                        <View
                                            style={{
                                                position: 'absolute',
                                                height: '100%',
                                                width: '100%',
                                                flex: 1,
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                overflow: 'hidden',
                                                flexWrap: 'wrap'
                                            }}>
                                            <GpeText
                                                style={{
                                                    fontFamily: ContextTheme.font.regular,
                                                    color: ContextTheme.palette.grey,
                                                    textAlign: 'center',
                                                    textTransform: 'uppercase',
                                                    padding: 5,
                                                    width: '100%',
                                                }}>
                                                {props.ebook.title}
                                            </GpeText>
                                        </View>

                                        <Image
                                            style={{ height: coverSizeState.height, width: coverSizeState.width }}
                                            onError={imageOnErrorCallback}
                                            source={imageSourceUri} />

                                        <TypeIcon ebook={props.ebook} />

                                        {coverHoveringState
                                            && <View style={{
                                                position: 'absolute',
                                                left: -10,
                                                top: -10,
                                                right: -10,
                                                bottom: -10,
                                                borderColor: ContextTheme.palette.theme,
                                                borderWidth: 1,
                                            }}></View>}
                                    </TouchableOpacity>

                                    {coverDragIconState
                                        && <TouchableOpacity
                                            style={[
                                                LibraryStyles.dragIcon,
                                                { opacity: 1, }
                                            ]}>
                                            <Animated.View {...panResponder.panHandlers}>
                                                <CoverDraggingIcon />
                                            </Animated.View>
                                        </TouchableOpacity>}
                                </Animated.View>

                                <GpeText
                                    style={{
                                        fontFamily: ContextTheme.font.regular,
                                        fontSize: 12,
                                        color: ContextTheme.palette.grey,
                                        marginTop: 5
                                    }}>
                                    {downloadedVersion
                                        ? translation('library.bookshelf.ebook.offline.text')
                                        : ' '}
                                </GpeText>
                            </React.Fragment>
                        )}
                </Animated.View>
            )
        )
    }, [
        coverDragIconState,
        coverHoveringState,
        coverRef,
        coverSizeState.height,
        coverSizeState.width,
        coverState.dragging,
        coverState.opacity,
        coverState.pan,
        coverState.show,
        coverState.zIndex,
        dispatchRef,
        downloadedVersion,
        draggingMode,
        imageOnErrorCallback,
        imageSourceUri,
        onLayoutCallback,
        openReadItemIdCallback,
        panResponder.panHandlers,
        props.ebook,
        translation
    ]);
}

export { AnimatedCover as default };