import React from 'react';
import { TouchableOpacity, TextStyle, TouchableOpacityProps } from 'react-native';

import { Log } from '../config';
import Styles from '../styles';
import GpeText from './GpeText';

const GpeButton = React.memo((props: {
    onPress: () => void,
    style: TextStyle | Array<TextStyle>,
    children: Element | JSX.Element | string | undefined
} & TouchableOpacityProps) => {

    return React.useMemo(() => {
        Log.debug('component: GpeButton');

        return (
            <TouchableOpacity {...props} style={[Styles.button, props.style]}>
                <GpeText
                    style={Styles.buttonText}>
                    {props.children}
                </GpeText>
            </TouchableOpacity>
        );
    }, [props]);
});

export { GpeButton as default };