import React from 'react';

import { Store } from '../store';
import Config, { Log } from '../config';
import { Redirect } from '../components/GpeRouter';
import PrivacyWebPage from '../components/PrivacyWebPage';

function PrivacyScreen(props: any) {
    const { state } = React.useContext(Store);

    return React.useMemo(() => {
        Log.debug('screen: PRIVACY POLICY');

        return state.user.auth
            ? <PrivacyWebPage />
            : <Redirect to={Config.routes.firstpage} />
    }, [state.user.auth]);
}

export { PrivacyScreen as default }