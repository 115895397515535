import React, { MutableRefObject } from "react";

import Config from "../../../../config";
import { MenuOptionInterface } from "../../../../interfaces/components";
import { DispatchType } from "../../../../interfaces/store";
import { doLogout } from "../../../../middlewares/UserMiddleware";
import { Store } from "../../../../store";

function MenuOptionsList() {
    const { dispatch } = React.useContext(Store);

    const dispatchRef: MutableRefObject<DispatchType> = React.useRef(dispatch);
    const doLogoutCallback = React.useCallback(() => doLogout(dispatchRef.current), []);

    const menuOptions: Array<MenuOptionInterface> = React.useMemo(() => [
        {
            accessibilityRole: 'link',
            languageKey: 'library.sidebar.introduction.link.text',
            to: Config.routes.introduction,
        },
        /* {
            accessibilityRole: 'button',
            languageKey: 'library.sidebar.library.link.text',
            onPress: () => openLink(`${contextConfig.libraryUrl}${token}`),
        }, */
        {
            accessibilityRole: 'link',
            languageKey: 'library.sidebar.devices.link.text',
            to: Config.routes.devices,
        },
        {
            accessibilityRole: 'link',
            languageKey: 'library.sidebar.contact.link.text',
            to: Config.routes.contact,
        },
        {
            accessibilityRole: 'link',
            languageKey: 'library.sidebar.help.link.text',
            to: Config.routes.help,
        },
        {
            accessibilityRole: 'link',
            languageKey: 'library.sidebar.conditions.link.text',
            to: Config.routes.conditions,
        },
        {
            accessibilityRole: 'link',
            languageKey: 'library.sidebar.privacy.link.text',
            to: Config.routes.privacy,
        },
        {
            accessibilityRole: 'link',
            languageKey: 'library.sidebar.forget.link.text',
            to: Config.routes.forget,
        },
        {
            accessibilityRole: 'button',
            languageKey: 'library.sidebar.logout.button.text',
            testID: 'logout',
            onPress: () => doLogoutCallback(),
        },
    ], [doLogoutCallback]);

    return React.useMemo(() => menuOptions, [menuOptions]);
};

export { MenuOptionsList as default }