import React from 'react';
import { TouchableOpacity, ViewStyle, GestureResponderEvent } from 'react-native';
import GpeText from '../../GpeText';

import Hoverable from '../../Hoverable';

function HeaderButton(props: { icon: string, width: number, style?: ViewStyle, color?: string, onPress?: ((event: GestureResponderEvent) => void) }) {
    return (
        <Hoverable>
            {(hover: boolean) => (
                <TouchableOpacity
                    testID='toggle-sidebar-menu'
                    onPress={props.onPress}
                    style={[
                        {
                            width: props.width,
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: hover ? 'rgba(242, 242, 242, 1)' : 'rgba(242, 242, 242, 0.5)',
                        },
                        props.style,
                    ]}>
                    <GpeText style={{ fontFamily: 'WookIcones', color: props.color, fontSize: 50 }}>{props.icon}</GpeText>
                </TouchableOpacity>
            )}
        </Hoverable>
    );
}

export { HeaderButton as default }