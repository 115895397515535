import { AppServicesInterface } from '../interfaces/services';
import { doGetRequest, doPostRequest } from './HttpClient';

const AppServices = async (props: AppServicesInterface) => {
    const serviceName = Object.keys(props)[0];
    switch (serviceName) {
        case "checkVersion":
            return doGetRequest(
                serviceName,
                props[serviceName]?.urlParams
            );
        case "registerDevice":
            return doPostRequest(
                serviceName,
                props[serviceName]?.urlParams,
                null,
                props[serviceName]?.headers
            );
        case "createMobileSession":
            return doPostRequest(
                serviceName,
                props[serviceName]?.urlParams,
                null,
                props[serviceName]?.headers
            );
        case "getValueByKey":
            return doGetRequest(
                serviceName,
                props[serviceName]?.urlParams
            );
        case "gpeAnalytics":
            return doPostRequest(
                serviceName,
                props[serviceName]?.urlParams,
                props[serviceName]?.body,
                props[serviceName]?.headers
            );
        case "kitabooMobileSession":
            return doPostRequest(
                serviceName,
                props[serviceName]?.urlParams,
                null,
                props[serviceName]?.headers
            );
        default:
            return {};
    }
}

export { AppServices as default };