import React, { MutableRefObject } from 'react';
import {
    View,
    TextInput,
    Platform,
    TouchableWithoutFeedback,
    TouchableOpacity,
} from 'react-native';

import Config, { Log } from '../../../../config';
import { Store } from '../../../../store';
import {
    RegistStyles,
    ContextTheme,
} from '../../../../styles';
import { Link } from '../../../GpeRouter';
import GpeButton from '../../../GpeButton';
import {
    doRegisterBertrandCustomer,
    doValidateBertrandCustomer,
} from '../../../../middlewares/UserMiddleware';
import Icon from '../../../Icon';
import { openLink } from '../../../../middlewares/AppMiddleware';
import GpeScrollView from '../../../GpeScrollView';
import BertrandLogo from './BertrandLogo';
import { getPasswordRules } from '../../../../middlewares/UserMiddleware';
import GpeText from '../../../GpeText';
import ProgressBar from './ProgressBar';
import { DispatchType } from '../../../../interfaces/store';

function RegistForm() {
    const { state, dispatch } = React.useContext(Store);
    const [rulesFetch, setRulesFetched] = React.useState<Array<string> | null>(null);
    const [progress, setProgress] = React.useState(0);

    /** LOCAL STATE */
    const [form, setForm] = React.useState<{
        step: 1 | 2,
        email: string,
        name: string,
        password: string,
        securePassword: boolean,
        passwordConfirm: string,
        securePasswordConfirm: boolean,
        conditions: boolean,
        notValid: { [key: string]: string | undefined },
    }>({
        step: 1,
        email: '',
        name: '',
        password: '',
        securePassword: true,
        passwordConfirm: '',
        securePasswordConfirm: true,
        conditions: false,
        notValid: {},
    });
    const [rulesValidation, setRules] = React.useState<{
        uppercase: boolean,
        lowercase: boolean,
        number: boolean,
        specialChar: boolean,
        size: boolean,
        repeat: boolean,
        equalUsername: boolean,
    }>({
        uppercase: false,
        lowercase: false,
        number: false,
        specialChar: false,
        size: false,
        repeat: false,
        equalUsername: false,
    });

    const dispatchRef: MutableRefObject<DispatchType> = React.useRef(dispatch);

    /* Mapping rules array to object */
    const rulesCheck = React.useMemo(() => {
        var rulesReturn = {
            uppercase: false,
            lowercase: false,
            number: false,
            specialChar: false,
            size: 0,
            repeat: false,
            equalUsername: false,
        }
        if (rulesFetch) {
            rulesFetch.forEach((element: string | any) => {
                if (element === "LOWERCASE_LETTER")
                    rulesReturn.lowercase = true;
                else if (element === "UPPERCASE_LETTER")
                    rulesReturn.uppercase = true;
                else if (element === "ONE_DIGIT")
                    rulesReturn.number = true;
                else if (element === "SPECIAL_CHARACTER")
                    rulesReturn.specialChar = true;
                else if (element === "EQUAL_CONTIGUOUS_CHARACTERS")
                    rulesReturn.repeat = true;
                else if (element === "EQUAL_USERNAME")
                    rulesReturn.equalUsername = true;
                else {
                    let size = element.split(":");
                    if (size[0] === "MIN_LENGTH_CHAR")
                        rulesReturn.size = size[1];
                }
            })
        }
        return rulesReturn;
    }, [rulesFetch]);

    React.useEffect(() => {
        !rulesFetch
            && getPasswordRules(dispatchRef.current, setRulesFetched);
    }, [rulesFetch]);

    return React.useMemo(() => {
        Log.debug('component: RegistForm');

        /** VALIDATE */
        const formValid = () => {
            const notValid: {
                email?: string,
                name?: string,
                password?: string,
                passwordConfirm?: string,
                conditions?: string,
            } = {};

            if (form.step === 1) {
                form.email.match(new RegExp('@', 'g'))?.length !== 1 && (notValid.email = 'e-mail inválido!');
                form.email.lastIndexOf('.') < 3 && (notValid.email = 'e-mail inválido!');
                (form.email.lastIndexOf('@') < 1
                    || form.email.lastIndexOf('.') < form.email.lastIndexOf('@')) && (notValid.email = 'e-mail inválido!');
            }
            else {
                form.name.length < 1 && (notValid.name = 'insira o nome e sobrenome!');
                form.name.split(' ').length < 2 && (notValid.name = 'insira o nome e sobrenome!');

                form.password.length < 1 && (notValid.password = 'insira a password!');

                form.passwordConfirm.length < 1 && (notValid.passwordConfirm = 'confirme a password!');

                form.password !== form.passwordConfirm && (notValid.passwordConfirm = 'password diferente!');

                !checkPasswordRules(form.password) && (notValid.password = 'A password introduzida não cumpre os critérios abaixo indicados. Por favor, verifique.')

                !form.conditions && (notValid.conditions = 'é necessário aceitar as condições!');
            }

            Object.keys(notValid).length && setForm({
                ...form,
                notValid: notValid,
            });

            return !Object.keys(notValid).length;
        }

        const registSubmit = () => {
            if (!state.user.registError
                && formValid()
                && state.app.uid) {
                const nameArray = form.name.split(' ');
                const lastName = nameArray[nameArray.length - 1];
                const firstName = form.name.slice(0, -lastName.length);
                doRegisterBertrandCustomer(
                    dispatchRef.current,
                    state.app.uid,
                    { ...form, fistName: firstName.trim(), lastName: lastName.trim() }
                );
            }
        };

        const handleChange = (type: string, value: number | string | boolean) => {
            state.user.registError && dispatchRef.current({
                type: 'USER-REGIST_ERROR',
                payload: '',
            });

            const notValid: any = form.notValid;
            notValid[type] && (delete notValid[type]);

            setForm({
                ...form,
                [type]: value,
                notValid: notValid,
            });
        };

        const checkPasswordRules = (password: string) => {
            /* REGEX */
            const specialcharReg = /[!@#$%^&*()_+\-=\]{};':"\\|,.<>?]+/;
            const consecutivecharReg = /(.)\1\1/;
            const lowercaseReg = /[a-z]/;
            const uppercaseReg = /[A-Z]/;
            const digitoReg = /\d/
            let valid = false;

            let rulesCurrentValidation = {
                uppercase: !(rulesCheck.uppercase && !uppercaseReg.test(password)),
                lowercase: !(rulesCheck.lowercase && !lowercaseReg.test(password)),
                number: !(rulesCheck.number && !digitoReg.test(password)),
                specialChar: !(rulesCheck.specialChar && !specialcharReg.test(password)),
                size: !(password.length < rulesCheck.size),
                repeat: !(rulesCheck.repeat && consecutivecharReg.test(password)),
                equalUsername: !(rulesCheck.equalUsername && password === form.email.substring(0, form.email.lastIndexOf("@"))),
            }

            valid = Object.values(rulesCurrentValidation).every(Boolean);
            setRules(rulesCurrentValidation);
            updateProgressBar(valid, password);

            return valid;
        };

        const updateProgressBar = (valid: boolean, password: string) => {
            /* Very Strong Criteria */
            const doubleuppercaseReg = /.*[A-Z].*[A-Z].*/;
            const doublelowercaseReg = /.*[a-z].*[a-z].*/;
            const doubledigitoReg = /.*\d.*\d.*/;

            if (valid && doubleuppercaseReg.test(password) && doublelowercaseReg.test(password) && doubledigitoReg.test(password)) setProgress(100);
            else if (valid) setProgress(75);
            else if (password.length) setProgress(25);
            else setProgress(0);
        };

        return (
            <GpeScrollView
                horizontal={false}
                style={{ backgroundColor: ContextTheme.palette.lightGrey }}
                contentContainerStyle={{ padding: 20, alignItems: 'center' }}>
                <View
                    style={RegistStyles.container}>
                    {/** LOGO */}
                    <View
                        style={{ width: '100%' }}>
                        <View
                            style={{
                                flexDirection: 'row',
                                justifyContent: 'center',
                                marginBottom: 20,
                            }}>
                            <BertrandLogo
                                style={{
                                    width: 80,
                                    height: 80,
                                    marginRight: 20,
                                }} />

                            <View
                                style={{ alignItems: 'center', justifyContent: 'center' }}>
                                <GpeText
                                    style={{
                                        fontFamily: ContextTheme.font.regular,
                                        fontSize: 14,
                                        color: ContextTheme.palette.grey
                                    }}>
                                    {'BIBLIO BERTRAND'}</GpeText>
                            </View>
                        </View>
                    </View>

                    {/** STEPS HEADER */}
                    <View
                        style={{ width: '100%', marginBottom: 20 }}>
                        <View
                            style={{ flexDirection: 'row', alignItems: 'center' }}>
                            {form.step === 1
                                ? (
                                    <Link
                                        to={Config.routes.firstpage}>
                                        <Icon
                                            icon=">"
                                            color={ContextTheme.palette.theme}
                                            style={{ transform: [{ rotate: '180deg' }] }} />
                                    </Link>
                                ) : (
                                    <TouchableOpacity
                                        onPress={() => handleChange('step', 1)}>
                                        <Icon
                                            icon=">"
                                            color={ContextTheme.palette.theme}
                                            style={{ transform: [{ rotate: '180deg' }] }} />
                                    </TouchableOpacity>
                                )}
                        </View>

                        <View
                            style={{
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}>
                            <GpeText
                                style={{
                                    fontFamily: ContextTheme.font.semibold,
                                    color: ContextTheme.palette.grey,
                                    fontSize: 22,
                                    lineHeight: undefined,
                                }}>
                                {'NOVO REGISTO'}</GpeText>
                            <View
                                style={{ flexDirection: 'row' }}>
                                <View
                                    style={{
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        height: 30,
                                        width: 30,
                                        marginLeft: 10,
                                        backgroundColor: form.step === 1
                                            ? ContextTheme.palette.theme
                                            : ContextTheme.palette.grey
                                    }}>
                                    <GpeText
                                        style={{
                                            fontFamily: ContextTheme.font.semibold,
                                            fontSize: 22,
                                            color: ContextTheme.palette.white,
                                        }}>
                                        {'1'}</GpeText>
                                </View>
                                <View
                                    style={{
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        height: 30,
                                        width: 30,
                                        marginLeft: 10,
                                        backgroundColor: form.step !== 1
                                            ? ContextTheme.palette.theme
                                            : ContextTheme.palette.grey
                                    }}>
                                    <GpeText
                                        style={{
                                            fontFamily: ContextTheme.font.semibold,
                                            fontSize: 22,
                                            color: ContextTheme.palette.white,
                                        }}>
                                        {'2'}</GpeText>
                                </View>
                            </View>
                        </View>
                    </View>

                    {/** STEP 1 */}
                    {form.step === 1
                        ? (
                            <View
                                style={{ width: '100%' }}>
                                <GpeText
                                    style={[
                                        RegistStyles.text,
                                        {
                                            fontSize: 14,
                                            color: ContextTheme.palette.grey,
                                            textAlign: 'left',
                                            marginBottom: 20,
                                        }
                                    ]}>
                                    {'Registe-se agora em menos de 1 minuto.'}
                                </GpeText>

                                {Object.keys(form.notValid).length
                                    ? <GpeText style={RegistStyles.error}>{form.notValid[Object.keys(form.notValid)[0]]}</GpeText>
                                    : state.user.registError
                                        ? <GpeText style={RegistStyles.error}>{state.user.registError}</GpeText>
                                        : null}

                                <View
                                    style={[
                                        RegistStyles.inputContainer,
                                        form.notValid.email
                                            ? { borderColor: 'red' }
                                            : null,
                                        { marginBottom: 20 }
                                    ]}>
                                    <Icon icon='u' style={{ color: '#ccc', backgroundColor: ContextTheme.palette.white }} />
                                    <TextInput
                                        style={[
                                            RegistStyles.input,
                                            {
                                                fontSize: 14,
                                                color: ContextTheme.palette.grey
                                            }
                                        ]}
                                        onChangeText={value => handleChange('email', value)}
                                        onSubmitEditing={() => {
                                            const valid = formValid();
                                            const success = () => handleChange('step', 2);
                                            valid && doValidateBertrandCustomer(dispatchRef.current, form, success);
                                        }}
                                        value={form.email}
                                        {...(Platform.OS !== "web" ? { autoCompleteType: "email" } : null)}
                                        disableFullscreenUI={true}
                                        autoCapitalize="none"
                                        placeholder="utilizador@email.com" />
                                </View>

                                <View
                                    style={{ marginBottom: 20 }}>
                                    <GpeText
                                        style={[
                                            RegistStyles.text,
                                            {
                                                fontFamily: ContextTheme.font.semibold,
                                                fontSize: 14,
                                                color: ContextTheme.palette.grey,
                                                textAlign: 'left',
                                            }
                                        ]}>
                                        {'Segurança'}
                                    </GpeText>
                                    <GpeText
                                        style={[
                                            RegistStyles.text,
                                            {
                                                fontSize: 14,
                                                color: ContextTheme.palette.grey,
                                                textAlign: 'left',
                                            }
                                        ]}>
                                        {'Garantimos a segurança e confidencialidade dos seus dados.'}
                                    </GpeText>
                                </View>

                                <GpeButton
                                    style={[
                                        RegistStyles.button,
                                        {
                                            width: '100%',
                                            maxWidth: undefined
                                        }
                                    ]}
                                    onPress={() => {
                                        const valid = formValid();
                                        const success = () => handleChange('step', 2);
                                        valid && doValidateBertrandCustomer(dispatchRef.current, form, success);
                                    }}>
                                    <GpeText style={{ fontFamily: ContextTheme.font.semibold, fontSize: 16 }}>AVANÇAR</GpeText>
                                </GpeButton>
                            </View>
                        ) : null}

                    {/** STEP 2 */}
                    {form.step === 2
                        ? (
                            <View
                                style={{ width: '100%' }}>
                                <GpeText
                                    style={[
                                        RegistStyles.text,
                                        {
                                            fontSize: 14,
                                            color: ContextTheme.palette.grey,
                                            textAlign: 'left',
                                            marginBottom: 20,
                                        }
                                    ]}>
                                    {'Defina a sua password de acesso.'}
                                </GpeText>

                                {Object.keys(form.notValid).length
                                    ? <GpeText style={RegistStyles.error}>{form.notValid[Object.keys(form.notValid)[0]]}</GpeText>
                                    : state.user.registError
                                        ? <GpeText style={RegistStyles.error}>{state.user.registError}</GpeText>
                                        : null}

                                <View
                                    style={[
                                        RegistStyles.inputContainer,
                                        form.notValid.name
                                            ? { borderColor: 'red' }
                                            : null,
                                        { marginBottom: 20 }
                                    ]}>
                                    <Icon icon='u' style={{ color: '#ccc', backgroundColor: ContextTheme.palette.white }} />
                                    <TextInput
                                        style={[
                                            RegistStyles.input,
                                            {
                                                fontSize: 14,
                                                color: ContextTheme.palette.grey
                                            }
                                        ]}
                                        onChangeText={value => handleChange('name', value)}
                                        onSubmitEditing={registSubmit}
                                        value={form.name}
                                        {...(Platform.OS !== "web" ? { autoCompleteType: "name" } : null)}
                                        disableFullscreenUI={true}
                                        placeholder="nome e sobrenome" />
                                </View>

                                <View
                                    style={[
                                        RegistStyles.inputContainer,
                                        form.notValid.password
                                            ? { borderColor: 'red' }
                                            : null,
                                        { marginBottom: 20 }
                                    ]}>
                                    <Icon icon='U' style={{ color: '#ccc', backgroundColor: ContextTheme.palette.white }} />
                                    <TextInput
                                        style={[
                                            RegistStyles.input,
                                            {
                                                fontSize: 14,
                                                color: ContextTheme.palette.grey
                                            }
                                        ]}
                                        onChangeText={value => { handleChange('password', value); checkPasswordRules(value) }}
                                        onSubmitEditing={registSubmit}
                                        value={form.password}
                                        {...(Platform.OS !== "web" ? { autoCompleteType: "password" } : null)}
                                        secureTextEntry={form.securePassword}
                                        disableFullscreenUI={true}
                                        autoCapitalize="none"
                                        placeholder="password" />
                                    <TouchableOpacity
                                        onPress={() => handleChange('securePassword', !form.securePassword)}>
                                        <Icon
                                            icon={form.securePassword ? 't' : 'T'}
                                            style={{ color: ContextTheme.palette.registColor, backgroundColor: ContextTheme.palette.white }} />
                                    </TouchableOpacity>
                                </View>

                                <View
                                    style={[
                                        RegistStyles.inputContainer,
                                        form.notValid.passwordConfirm
                                            ? { borderColor: 'red' }
                                            : null,
                                        { marginBottom: 20 }
                                    ]}>
                                    <Icon icon='U' style={{ color: '#ccc', backgroundColor: ContextTheme.palette.white }} />
                                    <TextInput
                                        style={[
                                            RegistStyles.input,
                                            {
                                                fontSize: 14,
                                                color: ContextTheme.palette.grey
                                            }
                                        ]}
                                        onChangeText={value => handleChange('passwordConfirm', value)}
                                        onSubmitEditing={registSubmit}
                                        value={form.passwordConfirm}
                                        secureTextEntry={form.securePasswordConfirm}
                                        disableFullscreenUI={true}
                                        autoCapitalize="none"
                                        placeholder="confirmar password" />
                                    <TouchableOpacity
                                        onPress={() => handleChange('securePasswordConfirm', !form.securePasswordConfirm)}>
                                        <Icon
                                            icon={form.securePasswordConfirm ? 't' : 'T'}
                                            style={{ color: ContextTheme.palette.registColor, backgroundColor: ContextTheme.palette.white }} />
                                    </TouchableOpacity>
                                </View>

                                <View style={{ marginBottom: 10 }}>
                                    <ProgressBar style={{ width: "100%" }} progress={progress} />
                                    <GpeText
                                        style={[
                                            RegistStyles.rulesIntroLabel,
                                            { color: ContextTheme.palette.grey },
                                            { fontSize: 13 }
                                        ]}>
                                        {'A sua password deve:'}
                                    </GpeText>
                                    <View
                                        style={[
                                            {
                                                width: '100%',
                                                justifyContent: 'flex-start',
                                                flexDirection: "column",
                                            }
                                        ]}>
                                        <GpeText style={RegistStyles.desktopRulesRowEnd}>
                                            <GpeText style={[
                                                RegistStyles.label,
                                                { color: rulesValidation.equalUsername ? 'green' : ContextTheme.palette.grey },
                                                { fontSize: 13 }
                                            ]}>
                                                {'\u2022'} Ser diferente do seu username.
                                            </GpeText>
                                        </GpeText>
                                        <GpeText style={RegistStyles.desktopRulesRowStart} >
                                            <GpeText style={[
                                                RegistStyles.label,
                                                { color: rulesValidation.size ? 'green' : ContextTheme.palette.grey },
                                                { fontSize: 13 }
                                            ]}>
                                                {'\u2022'} Conter, no mínimo, {rulesCheck.size} carateres.
                                            </GpeText>
                                        </GpeText>
                                        <GpeText style={RegistStyles.desktopRulesRowStart}>
                                            <GpeText style={[
                                                RegistStyles.label,
                                                { color: rulesValidation.uppercase ? 'green' : ContextTheme.palette.grey },
                                                { fontSize: 13 }
                                            ]}>
                                                {'\u2022'} Conter, pelo menos, uma maiúscula.
                                            </GpeText>
                                        </GpeText>
                                        <GpeText style={RegistStyles.desktopRulesRowStart}>
                                            <GpeText style={[
                                                RegistStyles.label,
                                                { color: rulesValidation.lowercase ? 'green' : ContextTheme.palette.grey },
                                                { fontSize: 13 }
                                            ]}>
                                                {'\u2022'} Conter, pelo menos, uma minúscula.
                                            </GpeText>
                                        </GpeText>
                                        <GpeText style={RegistStyles.desktopRulesRowEnd}>
                                            <GpeText style={[
                                                RegistStyles.label,
                                                { color: rulesValidation.number ? 'green' : ContextTheme.palette.grey },
                                                { fontSize: 13 }
                                            ]}>
                                                {'\u2022'} Conter, pelo menos, um algarismo.
                                            </GpeText>
                                        </GpeText>
                                        <GpeText style={[RegistStyles.desktopRulesRowEnd, { marginBottom: 10 }]}>
                                            <GpeText style={[
                                                RegistStyles.label,
                                                { color: rulesValidation.specialChar ? 'green' : ContextTheme.palette.grey },
                                                { fontSize: 13 }
                                            ]}>
                                                {'\u2022'} Conter, pelo menos, um carater especial.
                                            </GpeText>
                                        </GpeText>
                                    </View>
                                </View>

                                <View style={{ flexDirection: 'row', marginBottom: 20 }}>
                                    <TouchableWithoutFeedback
                                        onPress={() => handleChange('conditions', !form.conditions)}>
                                        <View
                                            style={{ width: '100%', flexDirection: 'row' }}>
                                            <React.Fragment>
                                                <GpeText
                                                    style={{
                                                        fontFamily: 'WookIcones',
                                                        color: ContextTheme.palette.grey,
                                                        fontSize: 15,
                                                        lineHeight: 18,
                                                        height: 20,
                                                        width: 20,
                                                        borderWidth: 1,
                                                        borderColor: form.notValid.conditions ? 'red' : '#ccc',
                                                        borderRadius: 2,
                                                        textAlign: 'center',
                                                        marginRight: 10,
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        backgroundColor: ContextTheme.palette.white,
                                                    }}>{form.conditions ? 'X' : null}</GpeText>
                                                <View style={{ flex: 1 }}>
                                                    <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                                                        <GpeText
                                                            style={[
                                                                RegistStyles.text,
                                                                {
                                                                    fontSize: 14,
                                                                    textAlign: 'left',
                                                                    color: ContextTheme.palette.grey,
                                                                }
                                                            ]}>
                                                            {'Li e aceito as '}
                                                        </GpeText>
                                                        <TouchableOpacity
                                                            onPress={() => openLink(
                                                                Config.contextParams.conditionsUrl,
                                                                '_blank'
                                                            )}>
                                                            <GpeText style={[
                                                                RegistStyles.text,
                                                                {
                                                                    fontSize: 14,
                                                                    fontFamily: ContextTheme.font.semibold,
                                                                    color: ContextTheme.palette.grey,
                                                                    textDecorationLine: 'underline',
                                                                }
                                                            ]}>
                                                                {'condições gerais de venda'}
                                                            </GpeText>
                                                        </TouchableOpacity>
                                                        <GpeText
                                                            style={[
                                                                RegistStyles.text,
                                                                {
                                                                    fontSize: 14,
                                                                    textAlign: 'left',
                                                                    color: ContextTheme.palette.grey,
                                                                }
                                                            ]}>
                                                            {' e a '}
                                                        </GpeText>
                                                        <TouchableOpacity
                                                            onPress={() => openLink(
                                                                Config.contextParams.privacyUrl,
                                                                '_blank'
                                                            )}>
                                                            <GpeText style={[
                                                                RegistStyles.text,
                                                                {
                                                                    fontSize: 14,
                                                                    fontFamily: ContextTheme.font.semibold,
                                                                    color: ContextTheme.palette.grey,
                                                                    textDecorationLine: 'underline',
                                                                }
                                                            ]}>
                                                                {'política de privacidade'}
                                                            </GpeText>
                                                        </TouchableOpacity>
                                                        <GpeText
                                                            style={[
                                                                RegistStyles.text,
                                                                {
                                                                    fontSize: 14,
                                                                    textAlign: 'left',
                                                                    color: ContextTheme.palette.grey,
                                                                }
                                                            ]}>
                                                            {'.'}
                                                        </GpeText>
                                                    </View>
                                                </View>
                                            </React.Fragment>
                                        </View>
                                    </TouchableWithoutFeedback>
                                </View>

                                <GpeButton
                                    style={[RegistStyles.button, { width: '100%', maxWidth: undefined }]} onPress={registSubmit}>
                                    <GpeText style={{ fontFamily: ContextTheme.font.semibold, fontSize: 16 }}>REGISTAR</GpeText>
                                </GpeButton>
                            </View>
                        ) : null}
                </View>
            </GpeScrollView>
        )
    }, [form, state.app.uid, state.user.registError, progress, rulesCheck, rulesValidation]);
}

export { RegistForm as default }