import * as wook from './wook';
import * as bertrand from './bertrand';
import Config from '../../config';

const styles = {
    wook,
    bertrand
};

const theme = styles[Config.contextName as keyof typeof styles];

export { theme as default };