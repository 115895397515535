import React from 'react';

function GpeWebView(
    props: {
        uri: string,
        onError: () => void,
        onLoaded: () => void,
    }
) {
    return React.useMemo(() => {
        return (
            <iframe
                style={{ flex: 1, width: '100%', borderWidth: 0 }}
                title={'WebPage'}
                src={props.uri}
                onError={props.onError}
                onLoad={props.onLoaded}
            />
        );
    }, [props]);
};

export { GpeWebView as default };
