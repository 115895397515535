import React, { MutableRefObject } from 'react';
import { View, TextInput, TouchableOpacity, ViewStyle } from 'react-native';

import Config, { Log } from '../../../config';
import { Store } from '../../../store';
import { LibraryStyles, dimensionWidth_Boolean, dimensionWidth_Object, ContextTheme } from '../../../styles';
import { setSearchDispatch, toggleSearch } from '../../../middlewares/LibraryMiddleware';
import { getTranslation } from '../../../middlewares/AppMiddleware';
import Icon from '../../Icon';
import DimensionsEventTimeout from '../../DimensionsEvent';
import { useHistory, useLocation } from '../../GpeRouter';
import Base64 from '../../../middlewares/commons/Base64';

function SearchBar(props: { showcaseRender?: boolean }) {
    /** STORE */
    const { state } = React.useContext(Store);

    /** MEDIAS */
    const showSearchMedia: {
        xs: 'true' | 'false',
        md: 'true' | 'false',
    } = React.useMemo(() => ({ xs: 'false', md: 'true' }), []);
    const searchMedia: { xs: ViewStyle, md: ViewStyle } = {
        xs: {
            position: 'absolute',
            top: 0,
            right: props.showcaseRender ? 0 : 52,
            zIndex: 1,
            paddingLeft: 0,
        },
        md: {
            marginBottom: 40,
        },
    };
    const buttonSearchMedia: { xs: ViewStyle, md: ViewStyle } = {
        xs: { left: 0 },
        md: { left: 0 },
    };

    /** PROPS */
    const translation = React.useMemo(() => getTranslation(state.app.language), [state.app.language]);
    const timeoutSearch: MutableRefObject<NodeJS.Timeout | undefined> = React.useRef();
    const location = useLocation();
    const history = useHistory();
    const searchString = React.useMemo(() => (
        location.pathname.indexOf(Config.routes.search) > -1
            ? Base64.atob(location.pathname.split(`${Config.routes.search}/`)[1])
            : ''
    ), [location]);

    /** STATE */
    const [searchState, searchDispatch] = React.useReducer((
        _state: {
            show: boolean,
            search: string,
            changed: boolean,
            searchStyle: ViewStyle,
            buttonSearchStyle: ViewStyle,
        },
        _payload: {
            show?: boolean,
            search?: string,
            changed?: boolean,
            searchStyle?: ViewStyle,
            buttonSearchStyle?: ViewStyle,
        }
    ) => ({ ..._state, ..._payload }), {
        show: dimensionWidth_Boolean(showSearchMedia) || Boolean(searchString),
        search: searchString,
        changed: false,
        searchStyle: dimensionWidth_Object(searchMedia),
        buttonSearchStyle: dimensionWidth_Object(buttonSearchMedia),
    });

    /** RESIZE LISTENER */
    DimensionsEventTimeout(() => {
        const _show = dimensionWidth_Boolean(showSearchMedia);
        const _searchStyle = dimensionWidth_Object(searchMedia);
        const _buttonSearchStyle = dimensionWidth_Object(buttonSearchMedia);
        if (_show !== searchState.show
            || _searchStyle !== searchState.searchStyle
            || _buttonSearchStyle !== searchState.buttonSearchStyle) {
            searchDispatch({
                show: _show,
                searchStyle: _searchStyle,
                buttonSearchStyle: _buttonSearchStyle,
            });
        }
    });

    React.useEffect(() => {
        /** MOUNT */
        setSearchDispatch((_bool: boolean) => searchDispatch({
            show: !dimensionWidth_Boolean(showSearchMedia)
                ? _bool
                : dimensionWidth_Boolean(showSearchMedia)
        }));
        /** UNMOUNT */
        return () => setSearchDispatch(undefined);
    });

    /** RENDER */
    return React.useMemo(() => {
        Log.debug('component: SearchBar');

        let _searchInput: TextInput;
        const startSearchEvent = (search: string) => {
            timeoutSearch.current
                && clearTimeout(timeoutSearch.current);

            searchDispatch({
                search: search,
                show: true,
                changed: false,
            });
            history.push(`${Config.routes.search}/${Base64.btoa(search)}`);
        };

        return (
            <View
                style={[
                    searchState.searchStyle,
                    {
                        flexDirection: 'row',
                        height: 50,
                        width: searchState.show ? '100%' : 50,
                        overflow: 'hidden',
                        right: searchState.show ? 0 : searchState.searchStyle.right,
                    },
                ]}>

                <TextInput
                    ref={(ref: TextInput) => { _searchInput = ref }}
                    onSubmitEditing={() => {
                        _searchInput.blur();
                        startSearchEvent(searchState.search);
                    }}
                    style={{
                        fontFamily: ContextTheme.font.regular,
                        marginLeft: searchState.show ? 0 : -50,
                        paddingLeft: searchState.show ? 60 : 0,
                        paddingRight: searchState.show ? 60 : 0,
                        width: '100%',
                        backgroundColor: searchState.show ? 'rgba(242, 242, 242, 1)' : 'rgba(242, 242, 242, 0)',
                    }}
                    onChangeText={(value) => {
                        searchDispatch({
                            search: value,
                            changed: true,
                        });
                        timeoutSearch.current
                            && clearTimeout(timeoutSearch.current);
                        timeoutSearch.current = setTimeout(function () {
                            startSearchEvent(value);
                        }, 1000);
                    }}
                    onBlur={() => {
                        if (!searchState.search) {
                            timeoutSearch.current
                                && clearTimeout(timeoutSearch.current);
                            searchDispatch({
                                show: dimensionWidth_Boolean(showSearchMedia),
                                changed: false,
                            });
                        }
                    }}
                    placeholder={translation('library.bookshelf.search.placeholder')}
                    value={searchState.search}
                    disableFullscreenUI={true} />

                {/** botao pesquisa */}
                <TouchableOpacity
                    onPress={() => {
                        toggleSearch(!searchState.show);
                        if (_searchInput && !searchState.show) {
                            _searchInput.focus();
                        }
                        else if (_searchInput && searchState.show) {
                            _searchInput.blur();
                        }
                    }}
                    style={[
                        LibraryStyles.icon,
                        LibraryStyles.greyBackgroundColor,
                        { position: 'absolute', left: 0, height: '100%' },
                        searchState.show ? searchState.buttonSearchStyle : null,
                    ]}>
                    <Icon icon='s' />
                </TouchableOpacity>

                {/** executa pesquisa */}
                {searchState.show && searchState.search && searchState.changed
                    ? (
                        <TouchableOpacity
                            onPress={() => {
                                _searchInput.blur();
                                startSearchEvent(searchState.search);
                            }}
                            style={[
                                LibraryStyles.icon,
                                LibraryStyles.greyBackgroundColor,
                                {
                                    position: 'absolute',
                                    height: '100%',
                                    right: 0,
                                }
                            ]}>
                            <Icon icon='>' />
                        </TouchableOpacity>
                    ) : searchState.show && searchState.search && !searchState.changed
                        ? (
                            <TouchableOpacity
                                onPress={() => {
                                    _searchInput.blur();
                                    history.push(`${Config.routes.bookshelf}`);
                                }}
                                style={[
                                    LibraryStyles.icon,
                                    LibraryStyles.greyBackgroundColor,
                                    {
                                        position: 'absolute',
                                        height: '100%',
                                        right: 0,
                                    }
                                ]}>
                                <Icon icon='*' />
                            </TouchableOpacity>
                        ) : null}
            </View>
        )
    }, [
        translation,
        searchState,
        history,
        showSearchMedia,
    ]);

}

export { SearchBar as default };