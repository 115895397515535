import React, { MutableRefObject } from 'react';

import { Store } from '../store';
import Config, { Log } from '../config';
import Offer from '../components/Offer';
import { DispatchType } from '../interfaces/store';
import { libraryReset, userReset } from '../middlewares/UserMiddleware';
import LoginScreen from './LoginScreen';
import { Redirect, useLocation } from '../components/GpeRouter';

function OfferScreen() {
    const { state, dispatch } = React.useContext(Store);

    const location = useLocation();

    const dispatchRef: MutableRefObject<DispatchType> = React.useRef(dispatch);

    const [forceLogout, setForceLogout] = React.useState(true);

    const pathToken = React.useMemo(() => (
        location.pathname.indexOf(Config.routes.offer) > -1
            ? location.pathname.split(`${Config.routes.offer}/`)[1]
            : ''
    ), [location]);

    React.useEffect(() => {
        if (forceLogout) {
            userReset(dispatchRef.current);
            libraryReset(dispatchRef.current);
            setForceLogout(false);
        }
    }, [forceLogout]);

    return React.useMemo(() => {
        Log.debug('screen: OFFER');

        return !forceLogout
            ? (pathToken
                ? (state.user.auth
                    ? <Offer />
                    : <LoginScreen />
                ) : <Redirect to={Config.routes.bookshelf} />
            ) : null;
    }, [
        state.user.auth,
        forceLogout,
        pathToken,
    ]);
}

export { OfferScreen as default }