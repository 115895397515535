import { Animated } from "react-native";
import NetInfo from "@react-native-community/netinfo";

import Config from '../../config';
import { LanguageKeys } from "../../interfaces/language";
import BookshelfServices from '../../services/LibraryServices';
import { CategoryInterface, EbookInterface, ProductIterface } from "../../interfaces/middlewares";
import { removeDirectory } from "../FilesystemMiddleware";
import AppServices from "../../services/AppServices";
import {
    AppServicesInterface,
    BookshelfServicesInterface,
    ResponseInterface,
    UserServicesInterface,
} from "../../interfaces/services";
import { DispatchType } from "../../interfaces/store";
import UserServices from "../../services/UserServices";
import { handleError } from "../../services/HttpClient/errorTreatment";
import KitabooReader from "react-native-ebooks-reader";

const onGoingRequests: {
    [key: string]: boolean
} = {};

function startFetching(
    _dispatch: DispatchType,
    _serviceName?: string,
) {
    _serviceName
        && (onGoingRequests[_serviceName] = true);
    if (!_serviceName
        || (_serviceName
            && Object.keys(onGoingRequests).length === 1)) {
        _dispatch({
            type: 'APP',
            payload: {
                fetching: true,
            },
        });
    }
}

function endFetching(
    _dispatch: DispatchType,
    _serviceName?: string,
) {
    _serviceName
        && (delete onGoingRequests[_serviceName]);
    if (!_serviceName
        || (_serviceName
            && !Object.keys(onGoingRequests).length)) {
        _dispatch({
            type: 'APP',
            payload: {
                fetching: false,
                percentage: null,
            },
        });
    }
}

function handleBookshelfData(
    bookshelfData: {
        categories: Array<CategoryInterface>,
        ebooks: Array<EbookInterface>,
        editor: Array<EbookInterface>,
        readerAppId: any,
        version: string,
    },
    _translation: (key: keyof LanguageKeys) => string | undefined,
) {
    const _shelfsObj = categoriesDataObj(bookshelfData.categories, _translation);
    const _audiobooksTitle = _translation('library.audiobooks.bookshelf.title');
    const _ebooksTitle = _translation('library.ebooks.bookshelf.title');
    /** excertos vao para uma montra especifica */
    const _wantToReadList: Array<EbookInterface> = [];
    const _wantToEarList: Array<EbookInterface> = [];

    bookshelfData.ebooks.forEach(ebook => {
        if (!ebook.sample && !ebook.categoryId) {
            /** ebooks */
            _ebooksTitle
                && ebook.formatType !== Config.ebook.format.kitabooAudio
                && _shelfsObj[_ebooksTitle].ebooksList.push(ebook);
            /** audiolivros */
            _audiobooksTitle
                && isAudioBook(ebook.formatType)
                && _shelfsObj[_audiobooksTitle].ebooksList.push(ebook);
        }
        else if (!ebook.sample && ebook.categoryId) {
            _shelfsObj[ebook.categoryId].ebooksList.push(ebook);
        }
        else {
            isAudioBook(ebook.formatType)
                ? _wantToEarList.push(ebook)
                : _wantToReadList.push(ebook);
        }
    });

    const _shelfsArray = [];
    /** ebooks */
    if (_ebooksTitle
        && _shelfsObj[_ebooksTitle].ebooksList.length) {
        _shelfsObj[_ebooksTitle].ebooksList.sort(
            (a: any, b: any) => compareObjField(a, b, 'desc', 'ord',)
        );
        _shelfsObj[_ebooksTitle].bookAmount = _shelfsObj[_ebooksTitle].ebooksList.length;
        _shelfsArray.push(_shelfsObj[_ebooksTitle]);
        delete _shelfsObj[_ebooksTitle];
    }
    /** audiobooks */
    if (_audiobooksTitle
        && _shelfsObj[_audiobooksTitle].ebooksList.length) {
        _shelfsObj[_audiobooksTitle].ebooksList.sort(
            (a: any, b: any) => compareObjField(a, b, 'desc', 'ord',)
        );
        _shelfsObj[_audiobooksTitle].bookAmount = _shelfsObj[_audiobooksTitle].ebooksList.length;
        _shelfsArray.push(_shelfsObj[_audiobooksTitle]);
        delete _shelfsObj[_audiobooksTitle];
    }
    /** user shelfs */
    bookshelfData.categories.forEach(category => {
        _shelfsObj[category.id].ebooksList.sort(
            (a: any, b: any) => compareObjField(a, b, 'desc', 'ord',)
        );
        /** atualizar o "bookAmount", porque pode conter excertos e foram removidos das estantes */
        _shelfsObj[category.id].bookAmount = _shelfsObj[category.id].ebooksList.length;
        _shelfsArray.push(_shelfsObj[category.id]);
        delete _shelfsObj[category.id];
    });

    /** showcases */
    const _showcasesArray = [];
    _wantToReadList
        && _wantToReadList.length
        && (_showcasesArray.push(
            {
                title: _translation('library.showcase.want.to.read.title'),
                ebooksList: _wantToReadList
            }
        ));
    _wantToEarList
        && _wantToEarList.length
        && (_showcasesArray.push(
            {
                title: _translation('library.showcase.want.to.ear.title'),
                ebooksList: _wantToEarList
            }
        ));

    return {
        shelfs: _shelfsArray,
        showcases: _showcasesArray,
    };
}

function categoriesDataObj(
    categories: Array<CategoryInterface>,
    _translation: (key: keyof LanguageKeys) => string | undefined,
) {
    const categoriesObj: { [key: string]: CategoryInterface } = {};
    if (categories && categories.length) {
        categories.sort(
            (a: any, b: any) => compareObjField(a, b, 'desc', 'id',)
        );
        categories.forEach((category, index) => {
            category.ebooksList = [];
            categoriesObj[category.id] = category;
        });
    }

    /** criar estante de ebooks sem estante associada */
    const ebooksTitle = _translation('library.ebooks.bookshelf.title');
    if (ebooksTitle) {
        Object.keys(categoriesObj).unshift(ebooksTitle);
        categoriesObj[ebooksTitle] = createNewShelf(ebooksTitle);
    }

    /** criar estante de audiolivros sem estante associada */
    const audiobooksTitle = _translation('library.audiobooks.bookshelf.title');
    if (audiobooksTitle) {
        Object.keys(categoriesObj).unshift(audiobooksTitle);
        categoriesObj[audiobooksTitle] = createNewShelf(audiobooksTitle);
    }

    return categoriesObj;
}

function createNewShelf(shelf: string | number) {
    return {
        action: "new",
        bookAmount: 0,
        deleted: false,
        id: shelf,
        lastUpdateTime: null,
        modificationTime: null,
        name: String(shelf),
        order: null,
        owner: null,
        type: null,
        ebooksList: [],
    };
}

function coverFadeOut(setCoverState: Function, coverState: any) {
    return Animated.timing(coverState.opacity, {
        toValue: 0,
        duration: 1000,
        useNativeDriver: false,
    }).start(() => setCoverState({
        show: false,
        dragging: false,
        zIndex: 0,
    }));
}

function coverResetPosition(setCoverState: Function, coverState: any) {
    return Animated.spring(coverState.pan, {
        toValue: { x: 0, y: 0 },
        friction: 7,
        useNativeDriver: false,
    }).start((done) => {
        if (done.finished) {
            setCoverState({
                dragging: false,
                zIndex: 0,
            });
        }
    });
}

function handleLanguageData(response: any) {
    let data: { [key: string]: string } = {};
    if (response && response['entry']) {
        response['entry'].forEach((entry: any) => {
            data[entry['reader-language-key']] = entry['reader-language-value'];
        });
    }
    return data;
}

function openPopup(
    _dispatch: DispatchType,
    _component: React.ReactElement | string | undefined
) {
    switch (typeof _component) {
        case "string":
            _dispatch({
                type: 'APP-POPUP',
                payload: { text: _component },
            });
            break;
        default:
            _dispatch({
                type: 'APP-POPUP',
                payload: { component: _component },
            });
            break;
    }
}

function closePopup(_dispatch: DispatchType) {
    _dispatch({
        type: 'APP-POPUP',
        payload: null,
    });
}

function getCurrentTime() {
    //return ts in miliseconds
    return new Date().getTime();
};

function generateRandom(max: number = 1000) {
    return Math.floor(Math.random() * max);
}

function getEpochTime(time = getCurrentTime()) {
    return Math.round(time / 1000.0);
}

function compareObjField(
    a: { [field1: string]: { [field2: string]: string } },
    b: { [field1: string]: { [field2: string]: string } },
    direction: 'desc' | 'asc' | undefined,
    field1: string | number,
    field2?: string | number,
) {
    // Use toUpperCase() to ignore character casing
    let fieldA: string | number = field2 && a && a[field1] && a[field1][field2]
        ? String(a[field1][field2]).toUpperCase()
        : a && a[field1]
            ? String(a[field1]).toUpperCase()
            : '';
    let fieldB: string | number = field2 && b && b[field1] && b[field1][field2]
        ? String(b[field1][field2]).toUpperCase()
        : b && b[field1]
            ? String(b[field1]).toUpperCase()
            : '';

    if (!isNaN(parseFloat(fieldA))
        && !isNaN(parseFloat(fieldB))) {
        fieldA = parseFloat(fieldA);
        fieldB = parseFloat(fieldB);
    }

    let comparison = 0;
    if (fieldA > fieldB) {
        comparison = 1;
    } else if (fieldA < fieldB) {
        comparison = -1;
    }

    const orderDir = comparison === 0
        || direction === 'desc'
        || direction === undefined
        ? 1
        : -1;
    return comparison * orderDir;
}

function getEbookDirectory(appStorageDir: string, folder?: string | number): string {
    return folder
        ? `${appStorageDir}/${Config.appDirs.packages}/${folder}`
        : `${appStorageDir}/${Config.appDirs.packages}`;
}

function getAssetDirectory(appStorageDir: string, folder?: string | number): string {
    return folder
        ? `${appStorageDir}/${Config.appDirs.assets}/${folder}`
        : `${appStorageDir}/${Config.appDirs.assets}`;
}

function getAudioDirectory(appStorageDir: string, folder?: string | number): string {
    return folder
        ? `${appStorageDir}/${Config.appDirs.medias}/${folder}`
        : `${appStorageDir}/${Config.appDirs.medias}`;
}

function showcaseProductToEbookFormat(product: ProductIterface): EbookInterface {
    return {
        action: '',
        addedByEditor: true,
        assignTs: undefined,
        authors: product.autores
            ? product.autores.map(({ nome }) => nome)
            : undefined,
        bookGuid: '',
        categoryId: 0,
        coverUrl: product.galleryURL,
        detailUrl: product.detailURL,
        dictionaryId: undefined,
        expiryDate: undefined,
        format: undefined,
        gpeId: product.artID,
        isbn: product.isbn13,
        itemType: Config.ebook.type.sample,
        formatType: product.formatosVirtuais
            && product.formatosVirtuais.length
            ? String(product.formatosVirtuais[0].idFormato)
            : undefined,
        kitabooCloudId: 0,
        languageCode: '',
        ord: 0,
        originalFormatting: true,
        projectId: undefined,
        publishYear: '',
        publisherId: 0,
        publisherName: product.editor,
        readItemId: 0,
        sample: true, // como vem de uma montra sera sempre sample
        subArtid: product.subArtID,
        title: product.titulo,
        version: 0,
    };
}

function verifyConnection(onlineHandler: Function, offlineHandler?: Function) {
    return NetInfo.fetch().then(state => {
        if (state.type && state.isConnected) {
            return onlineHandler();
        }

        if (offlineHandler) return offlineHandler();
    });
}

/**
 * Converts a number from bytes to MB
 * @method humanFileSize
 * @param  {number}         bytes [description]
 * @return {string}               [description]
 */
function humanFileSize(bytes: number): string {
    var exp = Math.log(bytes) / Math.log(1024) | 0;
    var result = (bytes / Math.pow(1024, exp)).toFixed(2);

    return result + ' ' + (exp === 0 ? 'bytes' : 'KMGTPEZY'[exp - 1] + 'B');
}

function removeFromStorage(
    _dispatch: DispatchType,
    _appStateStorageDir: string,
    _ebook: EbookInterface,
    _endHandler?: () => void,
) {
    if (isAudioBookOnIos(_ebook.formatType)) {
        _ebook.kitabooCloudId
            && KitabooReader.deleteDownloadedHLSBook(String(_ebook.kitabooCloudId));

        _dispatch({
            type: 'LIBRARY-DELETE_DOWNLOADED_VERSIONS',
            payload: _ebook.gpeId,
        });
        _endHandler && _endHandler();

        return;
    }

    const _rootDirectory = isAudioBook(_ebook.formatType)
        ? getAudioDirectory(_appStateStorageDir)
        : getEbookDirectory(_appStateStorageDir);
    const _packageFolder = isAudioBook(_ebook.formatType)
        ? _ebook.isbn
            ? _ebook.isbn.replace(/-/g, '').replace(/ /g, '')
            : `${_ebook.gpeId}`
        : _ebook.gpeId;

    return removeDirectory(`${_rootDirectory}/${_packageFolder}`)
        .then(() => {
            _dispatch({
                type: 'LIBRARY-DELETE_DOWNLOADED_VERSIONS',
                payload: _ebook.gpeId,
            });
            _endHandler && _endHandler();
        })
        .catch((error: any) => openPopup(_dispatch, error));
}

function normalizeString(_toNormalize = '', slugify = false) {
    const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
    const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
    const p = new RegExp(a.split('').join('|'), 'g')

    if (slugify) {
        return _toNormalize.toString().toLowerCase()
            .replace(/\s+/g, '-')
            .replace(p, c => b.charAt(a.indexOf(c)))
            .replace(/&/g, '-and-')
            .replace(/[^\w-]+/g, '')
            .replace(/-+/g, '-')
            .replace(/^-+/, '')
            .replace(/-+$/, '');
    }
    return _toNormalize.toString().toLowerCase()
        .replace(p, c => b.charAt(a.indexOf(c)));
}

const loading = async (
    dispatch: DispatchType,
    serviceName: string,
    callback: Function,
) => {
    if (onGoingRequests[serviceName]) return;

    startFetching(dispatch, serviceName);
    return callback().then((response: any) => {
        endFetching(dispatch, serviceName);

        return response;
    });
}

const services = {
    app: async (
        dispatch: DispatchType,
        props: AppServicesInterface,
        callback = (response: ResponseInterface):
            ResponseInterface
            | Promise<any>
            | void => response,
    ) => {
        const serviceName = Object.keys(props)[0];
        const serviceCallback = async () => { return AppServices(props).then(callback) };
        return loading(dispatch, serviceName, serviceCallback);
    },
    user: async (
        dispatch: DispatchType,
        props: UserServicesInterface,
        callback = (response: ResponseInterface):
            ResponseInterface
            | Promise<any>
            | void => response,
    ) => {
        const serviceName = Object.keys(props)[0];
        const serviceCallback = async () => { return UserServices(props).then(callback) };
        return loading(dispatch, serviceName, serviceCallback);
    },
    library: async (
        dispatch: DispatchType,
        props: BookshelfServicesInterface,
        callback = (response: ResponseInterface):
            ResponseInterface
            | Promise<any>
            | void => response,
    ) => {
        const serviceName = Object.keys(props)[0];
        const serviceCallback = async () => { return BookshelfServices(props).then(callback) };
        return loading(dispatch, serviceName, serviceCallback);
    },
}

function inIframe() {
    try {
        return Config.platform === 'web'
            && window.self !== window.top;
    } catch (e) {
        return true;
    }
}

function forceRefresh(
    _dispatch: DispatchType,
    _appStateUid: string,
    _userStateAuth?: { token: string } | null,
    _userStateAuthToken?: string | null,
): Promise<any> {
    const _reset = async (_userToken: string | null = null) => _dispatch([
        {
            type: 'APP-LANGUAGE',
            payload: null,
        }, {
            type: 'APP-UID_REGISTERED',
            payload: null,
        }, {
            type: 'APP-STORAGE_DIR',
            payload: null,
        }, {
            type: 'USER-AUTH_TOKEN',
            payload: null,
        }, {
            type: 'USER-KITABOO_TOKEN',
            payload: _userToken,
        }, {
            type: 'LIBRARY-BOOKSHELF',
            payload: null,
        }, {
            type: 'LIBRARY-SHOWCASES',
            payload: null,
        }, {
            type: 'LIBRARY-BOOKSHELF_SELECTED',
            payload: null,
        }, {
            type: 'LIBRARY-DOWNLOADED_COVERS',
            payload: {},
        }
    ]);

    if (Config.platform === 'web'
        || !_userStateAuth
        || !_userStateAuthToken) return _reset();

    return services.app(
        _dispatch,
        {
            kitabooMobileSession: {
                urlParams: {
                    "encodedDeviceId": encodeURIComponent(_appStateUid),
                    "platform": Config.kitaboo.readerAppNames[Config.platform],
                    "clientId": Config.context.kitaboo.clientId,
                },
                headers: {
                    "x-token": _userStateAuth.token,
                    "keyClockSessionToken": _userStateAuthToken,
                }
            }
        },
        (response) => {
            if (response.error
                || !response.success.data
                || !response.success.data.responseCode
                || response.success.data.responseCode !== 200) {
                response.success
                    && (!response.success.data
                        || !response.success.data.responseCode
                        || response.success.data.responseCode !== 200)
                    && (response.error = handleError(response.success.data?.responseMsg))
                return openPopup(_dispatch, response.error);
            }
            return _reset(response.success.data.userToken);
        }
    );
}

function imageUrlTreatment(_url: string = '') {
    if (_url) {
        _url = _url?.replace('//q-img', '//img');
        _url = _url?.replace('//p-img', '//img');
        _url = _url?.replace('//qp-img', '//img');
        // FIXME: martelo para remover dimensoes fixas
        _url = _url?.replace('/160x230', '');
    }
    return _url;
}

function getEbookInBookshelf(
    _bookshelf: {
        shelfs: Array<CategoryInterface>,
        showcases: Array<{ title: string | undefined, ebooksList: Array<EbookInterface> }>,
    },
    _ebook: EbookInterface,
    _showcaseWantToReadTitle: string | undefined,
) {
    let _found: boolean = false;
    let _ebookFound: EbookInterface | null = _ebook;
    if (_ebook.sample) {
        if (_showcaseWantToReadTitle) {
            for (const showcase of _bookshelf.showcases) {
                if (showcase.title === _showcaseWantToReadTitle) {
                    for (const ebook of showcase.ebooksList) {
                        if (ebook.gpeId === _ebook.gpeId
                            || (ebook.bookGuid
                                && _ebook.bookGuid
                                && ebook.bookGuid === _ebook.bookGuid)
                            || (ebook.readItemId
                                && _ebook.readItemId
                                && ebook.readItemId === _ebook.readItemId)) {
                            _found = true;
                            _ebookFound = ebook;
                            break;
                        }
                    }
                    break;
                }
            }
        }
    }
    else {
        for (const shelf of _bookshelf.shelfs) {
            for (const ebook of shelf.ebooksList) {
                if (ebook.gpeId === _ebook.gpeId
                    || (ebook.bookGuid
                        && _ebook.bookGuid
                        && ebook.bookGuid === _ebook.bookGuid)
                    || (ebook.readItemId
                        && _ebook.readItemId
                        && ebook.readItemId === _ebook.readItemId)) {
                    _found = true;
                    _ebookFound = ebook;
                    break;
                }
            }
            if (_found) break;
        }
    }
    return _ebookFound;
}

function isAudioBook(_formatType: string | undefined) {
    return _formatType === Config.ebook.format.kitabooAudio
}

function isAudioBookOnIos(_formatType: string | undefined) {
    return isAudioBook(_formatType) && Config.platform === 'ios'
}

function presentFirstOnboard(
    _userStateAuth: { id: number } | null,
    _userStateFirstOnboard: { [key: number]: boolean },
    _userStateSecondOnboard: { [key: number]: boolean },
) {
    if (_userStateAuth
        && !_userStateFirstOnboard[_userStateAuth.id]
        && !_userStateSecondOnboard[_userStateAuth.id]) {

        return true;
    }

    return false;
}

function presentSecondOnboard(
    _userStateAuth: { id: number } | null,
    _userStateFirstOnboard: { [key: number]: boolean },
    _userStateFirstOnboardDate: { [key: number]: number },
    _userStateSecondOnboard: { [key: number]: boolean },
    _libraryStateBookshelf: {
        shelfs: CategoryInterface[];
        showcases: {
            title: string;
            ebooksList: EbookInterface[];
        }[];
    } | null,
) {
    if (_userStateAuth
        && !_userStateSecondOnboard[_userStateAuth.id]) {
        if (!_userStateFirstOnboard[_userStateAuth.id]
            && _libraryStateBookshelf?.shelfs.length) {

            return true;
        }

        if (_userStateFirstOnboard[_userStateAuth.id]) {
            let today = new Date();
            let days = Math.floor(
                (today.getTime() - _userStateFirstOnboardDate[_userStateAuth.id])
                / (24 * 60 * 60 * 1000)
            );
            if (days >= 7) {

                return true;
            }
        }
    }

    return false;
}

export {
    startFetching,
    endFetching,
    loading,
    handleBookshelfData,
    coverFadeOut,
    coverResetPosition,
    handleLanguageData,
    openPopup,
    closePopup,
    getCurrentTime,
    generateRandom,
    getEpochTime,
    compareObjField,
    getEbookDirectory,
    getAssetDirectory,
    getAudioDirectory,
    showcaseProductToEbookFormat,
    verifyConnection,
    humanFileSize,
    removeFromStorage,
    normalizeString,
    services,
    inIframe,
    forceRefresh,
    imageUrlTreatment,
    getEbookInBookshelf,
    isAudioBook,
    isAudioBookOnIos,
    presentFirstOnboard,
    presentSecondOnboard,
};