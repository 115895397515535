import React from 'react';
import { View } from 'react-native';

import Config from '../../../config';
import { EbookInterface } from '../../../interfaces/middlewares';
import { LibraryStyles, ContextTheme } from '../../../styles';
import Icon from '../../Icon';

function TypeIcon(props: { ebook: EbookInterface, coverRendering?: Boolean }) {
    return React.useMemo(() => {
        return (
            <View
                style={[
                    LibraryStyles.icon,
                    LibraryStyles.typeIcon,
                    props.ebook.formatType !== Config.ebook.format.kitabooAudio
                        ? LibraryStyles.orangeBackgroundColor
                        : LibraryStyles.themeBackgroundColor
                ]}>
                <Icon color={ContextTheme.palette.white} icon={
                    props.ebook.formatType !== Config.ebook.format.kitabooAudio
                        ? 'N'
                        : 'S'
                } />
            </View>
        )
    }, [props.ebook.formatType]);
}

export { TypeIcon as default }