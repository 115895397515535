import React, { MutableRefObject } from 'react';

import { Log } from '../config';
import { DispatchType } from '../interfaces/store';
import { loadAnalytics } from '../middlewares/AnalyticsMiddleware';
import { doCheckVersion, downloadAssets, generateUid, getLanguage, getTranslation, loadStorage } from '../middlewares/AppMiddleware';
import { getDefaultStorageDirectory } from '../middlewares/FilesystemMiddleware';
import { Store } from '../store';
import { ContextTheme } from '../styles';
import Context from './ByContext';
import GpeText from './GpeText';

function AppInitializer(props: { children: any }) {
    const { state, dispatch } = React.useContext(Store);

    const dispatchRef: MutableRefObject<DispatchType> = React.useRef(dispatch);

    const translation = React.useMemo(() => getTranslation(state.app.language), [state.app.language]);

    React.useEffect(() => {
        !state.app.storageLoaded
            && loadStorage(dispatchRef.current);

        state.app.storageLoaded
            && !state.app.storageDir
            && (state.app.storageDir === null)
            && getDefaultStorageDirectory()
                .then((storageAbsolutePath: string) => dispatchRef.current({
                    type: 'APP-STORAGE_DIR',
                    payload: storageAbsolutePath,
                }));

        state.app.storageLoaded
            && !state.app.uid
            && dispatchRef.current({
                type: 'APP-UID',
                payload: generateUid(),
            });

        !state.app.language
            && getLanguage(dispatchRef.current);

        !state.app.manager
            && doCheckVersion(dispatchRef.current);

        state.app.uid
            && !state.app.analyticsLoaded
            && loadAnalytics(dispatchRef.current, state.app.uid, state.user.auth);

        state.app.storageDir
            && state.app.assetsVersion !== Context.Assets.version
            && downloadAssets(
                dispatchRef.current,
                state.app.storageDir,
                Context.Assets,
            );
    }, [
        state.app.storageLoaded,
        state.app.storageDir,
        state.app.uid,
        state.app.language,
        state.app.manager,
        state.app.analyticsLoaded,
        state.app.assetsVersion,
        state.user.auth,
    ]);

    return React.useMemo(() => {
        Log.debug('component: AppInitializer: ', Boolean(
            state.app.storageLoaded
            && state.app.storageDir
            && state.app.uid
            && state.app.language
            && state.app.manager
            && !state.app.manager.mustUpdate
            && state.app.analyticsLoaded
        ));

        return (state.app.storageLoaded
            && state.app.storageDir
            && state.app.uid
            && state.app.language
            && state.app.manager
            && !state.app.manager.mustUpdate
            && state.app.analyticsLoaded
            ? props.children
            : state.app.manager
                && state.app.manager.mustUpdate
                ? (
                    <GpeText
                        style={{ fontFamily: ContextTheme.font.regular, textAlign: 'center' }}>
                        {translation('app.must.update.text')}
                    </GpeText>
                ) : null
        )
    }, [
        translation,
        state.app.storageLoaded,
        state.app.storageDir,
        state.app.uid,
        state.app.language,
        state.app.manager,
        state.app.analyticsLoaded,
        props.children
    ]);
}

export { AppInitializer as default };