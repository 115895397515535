import React from 'react';
import { SafeAreaView } from 'react-native';

import { Log } from '../config';
import GpeText from './GpeText';

class ErrorBoundary extends React.Component<any, { hasError: boolean }>  {
    constructor(props: any) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: any) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error: any, errorInfo: any) {
        // You can also log the error to an error reporting service
        Log.debug(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <SafeAreaView
                    style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                    <GpeText
                        style={{ textAlignVertical: 'center' }}>
                        {'Alguma coisa correu mal!'}
                    </GpeText>
                </SafeAreaView>
            );
        }

        return this.props.children;
    }
}

export { ErrorBoundary as default };