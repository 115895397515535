import React, { MutableRefObject } from 'react';
import NetInfo from "@react-native-community/netinfo";

import Config from '../../../../config';
import { Store } from '../../../../store';
import { useHistory } from '../../../GpeRouter';
import HeaderButton from '../../../Header/components/HeaderButton';
import { DispatchType } from '../../../../interfaces/store';
import { forceRefresh, verifyConnection } from '../../../../middlewares/commons';

function RefreshButtonBertrand(props: {
    width: number,
    headerDispatch: Function
}) {
    /** STORE */
    const { state, dispatch } = React.useContext(Store);

    /** PROPS */
    const history = useHistory();
    const dispatchRef: MutableRefObject<DispatchType> = React.useRef(dispatch);
    const mountedRef: MutableRefObject<boolean | undefined> = React.useRef();
    const refreshCallback = React.useCallback(() => {
        props.headerDispatch({
            showSidebar: false,
            showUserMenu: false,
        });
        state.app.uid
            && forceRefresh(
                dispatchRef.current,
                state.app.uid,
                state.user.auth,
                state.user.authToken,
            );
        history.push(Config.routes.bookshelf);
    }, [
        props,
        history,
        state.app.uid,
        state.user.auth,
        state.user.authToken,
    ]);

    /** STATE */
    const [onlineStateOptionState, setOnlineStateOptionState] = React.useState(false);

    React.useEffect(() => {
        mountedRef.current = true;

        // verifica o estado atual da ligacao
        verifyConnection(() => !onlineStateOptionState
            && mountedRef.current
            && setOnlineStateOptionState(true));

        // evento para alteracoes na ligacao
        NetInfo.addEventListener(state => {
            const connectedState = Boolean(state.type && state.isConnected);
            connectedState !== onlineStateOptionState
                && mountedRef.current
                && setOnlineStateOptionState(connectedState);
        });

        return () => {
            mountedRef.current = false;
        }
    }, [onlineStateOptionState]);


    /** RENDER */
    return React.useMemo(() => {
        if (onlineStateOptionState) {
            return (
                <HeaderButton
                    icon='r'
                    onPress={refreshCallback}
                    width={props.width} />
            );
        } else return null;
    }, [
        props,
        refreshCallback,
        onlineStateOptionState
    ]);
}

export { RefreshButtonBertrand as default }