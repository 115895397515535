import React, { MutableRefObject } from 'react';
import { View, TouchableOpacity } from 'react-native';
import NetInfo from "@react-native-community/netinfo";

import Config, { Log } from '../../../../../config';
import { getTranslation } from '../../../../../middlewares/AppMiddleware';
import { Store } from '../../../../../store';
import { LibraryStyles, ContextTheme } from '../../../../../styles';
import Hoverable from '../../../../Hoverable';
import Icon from '../../../../Icon';
import { useHistory } from '../../../../GpeRouter';
import GpeText from '../../../../GpeText';
import { DispatchType } from '../../../../../interfaces/store';
import { forceRefresh, verifyConnection } from '../../../../../middlewares/commons';

function OnlineStateOption(props: { headerDispatch: Function }) {
    const { state, dispatch } = React.useContext(Store);

    const translation = React.useMemo(() => getTranslation(state.app.language), [state.app.language]);
    const dispatchRef: MutableRefObject<DispatchType> = React.useRef(dispatch);
    const mountedRef: MutableRefObject<boolean | undefined> = React.useRef();
    const history = useHistory();
    const _props = React.useMemo(() => props, [props]);
    const refreshCallback = React.useCallback(() => {
        _props.headerDispatch({
            showSidebar: false,
            showUserMenu: false,
        });
        state.app.uid
            && forceRefresh(
                dispatchRef.current,
                state.app.uid,
                state.user.auth,
                state.user.authToken,
            );
        history.push(Config.routes.bookshelf);
    }, [
        _props,
        history,
        state.app.uid,
        state.user.auth,
        state.user.authToken,
    ]);

    /** STATE */
    const [onlineStateOptionState, setOnlineStateOptionState] = React.useState(false);

    React.useEffect(() => {
        mountedRef.current = true;

        // verifica o estado atual da ligacao
        verifyConnection(() => !onlineStateOptionState
            && mountedRef.current
            && setOnlineStateOptionState(true));

        // evento para alteracoes na ligacao
        NetInfo.addEventListener(state => {
            const connectedState = Boolean(state.type && state.isConnected);
            connectedState !== onlineStateOptionState
                && mountedRef.current
                && setOnlineStateOptionState(connectedState);
        });

        return () => {
            mountedRef.current = false;
        }
    }, [onlineStateOptionState]);

    return React.useMemo(() => {
        Log.debug('component: OnlineStateOption');

        const colorState = {
            online: Config.contextName === 'bertrand'
                ? '#7CBB74'
                : ContextTheme.palette.theme,
            offline: '#CC3333',
        };

        return (
            <Hoverable>
                {(hover: boolean) => (
                    <TouchableOpacity
                        onPress={() => onlineStateOptionState && refreshCallback()}>
                        <View
                            style={[
                                LibraryStyles.menuOption,
                                { backgroundColor: hover ? '#d9d9d9' : 'transparent' }
                            ]}>
                            <View
                                style={{
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    borderStyle: 'solid',
                                    borderBottomColor: '#cccccc',
                                    borderBottomWidth: 1,
                                }}>
                                <GpeText
                                    style={[
                                        LibraryStyles.menuOptionText,
                                        {
                                            color: '#cccccc',
                                            borderTopWidth: undefined,
                                        }
                                    ]}>
                                    {onlineStateOptionState
                                        ? translation('library.sidebar.online.state.text.1')
                                        : translation('library.sidebar.offline.state.text.1')}
                                </GpeText>
                                <View
                                    style={{
                                        paddingTop: 22,
                                        paddingBottom: 22,
                                        flexDirection: 'row',
                                    }}>
                                    <GpeText
                                        style={{
                                            fontFamily: ContextTheme.font.regular,
                                            fontSize: 12,
                                            lineHeight: 16,
                                            color: onlineStateOptionState ? colorState.online : colorState.offline,
                                        }}>
                                        {onlineStateOptionState
                                            ? translation('library.sidebar.online.state.text.2')
                                            : translation('library.sidebar.offline.state.text.2')}
                                    </GpeText>
                                    {onlineStateOptionState
                                        &&
                                        <View
                                            style={{
                                                height: 16,
                                                width: 24,
                                                marginLeft: 10,
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}>
                                            <Icon
                                                color={colorState.online}
                                                icon='r' />
                                        </View>}
                                </View>
                            </View>
                        </View>
                    </TouchableOpacity>
                )}
            </Hoverable>
        )
    }, [
        translation,
        onlineStateOptionState,
        refreshCallback
    ]);
}

export { OnlineStateOption as default }