import React, { MutableRefObject } from 'react';
import { View } from 'react-native';

import Header from '../Header';
import { Store } from "../../store";
import { dimensionWidth } from '../../styles';
import DimensionsEventTimeout from '../DimensionsEvent';
import { openPopup, startFetching, endFetching } from '../../middlewares/commons';
import GpeWebView from './components/GpeWebView';
import { DispatchType } from '../../interfaces/store';

function WebPage(props: { uri: string }) {

    const { dispatch } = React.useContext(Store);

    /** MEDIA */
    const heightMedia = React.useMemo(() => ({ xs: 60, md: 80 }), []);

    /** LOCAL STATE */
    const [webPageState, setWebPageState] = React.useReducer((
        _state: {
            height: number,
            mounted: boolean,
        },
        _payload: {
            height?: number,
            mounted?: boolean,
        }
    ) => ({ ..._state, ..._payload }), {
        height: dimensionWidth(heightMedia),
        mounted: false,
    });

    const dispatchRef: MutableRefObject<DispatchType> = React.useRef(dispatch);

    const hideLoading = React.useCallback(() => (
        endFetching(dispatchRef.current, 'WebPageLoading')
    ), []);

    const openErrorPopup = React.useCallback(() => {
        openPopup(dispatchRef.current, `Erro ao carregar a página!`);
        hideLoading();
    }, [hideLoading]);

    React.useEffect(() => {
        !webPageState.mounted
            && (setWebPageState({ mounted: true }));
        !webPageState.mounted
            && (startFetching(dispatchRef.current, 'WebPageLoading'));
    }, [webPageState.mounted])

    /** DIMENSION LISTENER */
    DimensionsEventTimeout(() => {
        const _height = dimensionWidth(heightMedia);
        if (_height !== webPageState.height) {
            setWebPageState({ height: _height });
        }
    });

    return React.useMemo(() => {
        return (
            <View style={{ flex: 1 }}>
                <Header height={webPageState.height} />
                <GpeWebView
                    uri={props.uri}
                    onError={openErrorPopup}
                    onLoaded={hideLoading} />
            </View>
        );
    }, [props, webPageState, openErrorPopup, hideLoading]);
};

export { WebPage as default };
