import React, { MutableRefObject } from 'react';
import { View, TextInput } from 'react-native';

import { Store } from '../../../store';
import { PopupStyles } from '../../../styles';
import GpeButton from '../../GpeButton';
import { getTranslation } from '../../../middlewares/AppMiddleware';
import { doCreateBookshelf } from '../../../middlewares/LibraryMiddleware';
import Config, { Log } from '../../../config';
import ShelfCreateMsgSuccess from './ShelfCreateMsgSuccess';
import GpeText from '../../GpeText';
import { DispatchType } from '../../../interfaces/store';
import { closePopup } from '../../../middlewares/commons';

function ShelfCreateForm() {
    const { state, dispatch } = React.useContext(Store);

    const dispatchRef: MutableRefObject<DispatchType> = React.useRef(dispatch);
    const translation = React.useMemo(() => getTranslation(state.app.language), [state.app.language]);

    const [form, setForm] = React.useState<{
        name: string,
        notValid: { [key: string]: string | undefined },
    }>({
        name: '',
        notValid: {},
    });

    const handleChange = React.useCallback((type: string, value: string) => {
        const notValid: any = form.notValid;
        notValid[type] && (delete notValid[type]);

        setForm({
            ...form,
            [type]: value,
            notValid: notValid,
        });
    }, [form]);

    const formValid = React.useCallback(() => {
        const notValid: { name?: string } = {};

        form.name.length < 1 && (notValid.name = 'insira pelo menos um caracter!');

        Object.keys(notValid).length && setForm({
            ...form,
            notValid: notValid,
        });

        return !Object.keys(notValid).length;
    }, [form]);

    const formSubmit = React.useCallback(() => (
        formValid()
        && state.app.uid
        && state.user.auth
        && state.user.authToken
        && doCreateBookshelf(
            dispatchRef.current,
            state.app.uid,
            state.user.auth,
            state.user.authToken,
            form,
            Config.contextName === 'bertrand'
                ? <ShelfCreateMsgSuccess />
                : null
        )
    ), [
        state.app.uid,
        state.user.auth,
        state.user.authToken,
        form,
        formValid,
    ]);

    return React.useMemo(() => {
        Log.debug('component: ShelfCreateForm');

        return (
            <View
                testID='create-shelf-form'
                style={{
                    width: '100%',
                    maxWidth: 600,
                    margin: 'auto',
                    alignItems: 'center',
                }}
            >
                <GpeText
                    style={[
                        PopupStyles.h1,
                        Config.contextName === 'bertrand'
                            ? {
                                fontSize: 16,
                            } : null
                    ]}>
                    {translation('library.create.bookshelf.form.title')}
                </GpeText>

                <TextInput
                    style={[
                        PopupStyles.input,
                        Config.contextName === 'bertrand'
                            ? {
                                fontSize: 14,
                            } : null
                    ]}
                    onChangeText={value => handleChange('name', value)}
                    value={form.name}
                    placeholder={translation('library.create.bookshelf.form.placeholder')}
                    onSubmitEditing={formSubmit}
                    disableFullscreenUI={true} />

                <GpeButton
                    testID='submit'
                    style={PopupStyles.button}
                    onPress={formSubmit}>
                    {translation('app.form.confirm.button.text')}
                </GpeButton>

                {Config.contextName === 'bertrand'
                    ? null
                    : (
                        <GpeButton
                            style={[
                                PopupStyles.button,
                                { backgroundColor: '#d9d9d9' }
                            ]}
                            onPress={() => closePopup(dispatchRef.current)}>
                            <GpeText style={PopupStyles.blackColor}>{translation('app.form.cancel.button.text')}</GpeText>
                        </GpeButton>
                    )}
            </View>
        )
    }, [
        translation,
        form,
        formSubmit,
        handleChange,
    ]);
}

export { ShelfCreateForm as default }