import React from 'react';
import { View, Image } from 'react-native';

import Config, { Log } from '../../../../config';
import { getAssetImageUri } from '../../../../middlewares/AppMiddleware';
import { Store } from '../../../../store';
import { ContextTheme, WelcomeStyles } from '../../../../styles';
import { Link } from '../../../GpeRouter';
import GpeScrollView from '../../../GpeScrollView';
import GpeText from '../../../GpeText';
import BertrandLogo from './BertrandLogo';

function Welcome() {
    const { state } = React.useContext(Store);

    return React.useMemo(() => {
        Log.debug('component: Welcome');

        return (
            <GpeScrollView
                horizontal={false}
                style={{ backgroundColor: ContextTheme.palette.lightGrey }}
                contentContainerStyle={{ padding: 20, alignItems: 'center' }}>
                <View
                    style={WelcomeStyles.container}>
                    <View
                        style={{ width: '100%', marginBottom: 20 }}>
                        <View
                            style={{
                                flexDirection: 'row',
                                justifyContent: 'center',
                                marginBottom: 20,
                            }}>
                            <BertrandLogo
                                style={{
                                    width: 80,
                                    height: 80,
                                    marginRight: 20,
                                }} />

                            <View
                                style={{ alignItems: 'center', justifyContent: 'center' }}>
                                <GpeText
                                    style={WelcomeStyles.h1}>
                                    {'BEM-VINDO(A) À BIBLIO\nBERTRAND'}</GpeText>
                            </View>
                        </View>

                        <GpeText
                            style={[
                                WelcomeStyles.text,
                                { textAlign: 'justify', marginBottom: 20 }
                            ]}>
                            {'Aqui poderá ler os seus livros digitais (eBooks), ouvir os seus audiolivros e ter acesso a milhares de excertos dos seus autores preferidos. Organize-os nas suas estantes virtuais e leia ou ouça onde quiser, quando quiser.'}
                        </GpeText>
                        <GpeText
                            style={[WelcomeStyles.text, { textAlign: 'justify' }]}>
                            {'Faça o login com os seus dados que utiliza em www.bertrand.pt ou faça um novo registo.'}
                        </GpeText>
                    </View>

                    <View
                        style={{ width: '100%', marginBottom: 20 }}>
                        <Link
                            to={Config.routes.login}
                            style={WelcomeStyles.button}>
                            <GpeText
                                style={[WelcomeStyles.text, { fontFamily: ContextTheme.font.semibold, color: ContextTheme.palette.white }]}>
                                {'LOGIN'}
                            </GpeText>
                        </Link>

                        <Link
                            to={Config.routes.regist}
                            style={WelcomeStyles.button}>
                            <GpeText
                                style={[WelcomeStyles.text, { fontFamily: ContextTheme.font.semibold, color: ContextTheme.palette.white }]}>
                                {'NOVO REGISTO'}
                            </GpeText>
                        </Link>
                    </View>

                    <Image
                        style={{
                            height: 59,
                            width: 50,
                            resizeMode: 'contain',
                            aspectRatio: 1,
                        }}
                        source={{ uri: getAssetImageUri('logo.less', state.app.downloadedAssets) }} />
                </View>
            </GpeScrollView>
        );
    }, [state.app.downloadedAssets]);
}

export { Welcome as default }