import React from "react";
import { TextStyle } from "react-native";

import GpeText from "../../GpeText";
import { LibraryStyles } from "../../../styles";
import Config from "../../../config";

export default function TitleH1(props: {
    title: string | undefined,
    style?: TextStyle,
}) {

    return React.useMemo(() => (
        <GpeText
            style={[
                LibraryStyles.h1,
                Config.contextName === 'bertrand'
                    ? LibraryStyles.themeColor
                    : LibraryStyles.blackColor,
                Config.contextName === 'bertrand'
                    ? { fontSize: 26 }
                    : null,
                {...props.style}
            ]}>
            {props.title}
        </GpeText>
    ), [
        props.title,
        props.style,
    ])
}
