import AsyncStorage from '@react-native-async-storage/async-storage';

import Config, { Log } from '../../config';

const ignoreStoragePlatform: {
    [key: string]: Array<typeof Config.platform>
} = {
    'APP-LANGUAGE': ['web'],
    'LIBRARY-BOOKSHELF': ['web'],
    'LIBRARY-SHOWCASES': ['web'],
};

async function setStorage(_dispatch: { type: string, payload: any }) {
    if (!ignoreStoragePlatform[_dispatch.type]
        || ignoreStoragePlatform[_dispatch.type].indexOf(Config.platform) < 0) {
        try {
            return await AsyncStorage.setItem(_dispatch.type, JSON.stringify(_dispatch.payload));
        } catch (e) {
            Log.debug(`saving error! type: ${_dispatch.type}`);
        }
    }
}

async function getStorage(_key: string) {
    if (!ignoreStoragePlatform[_key]
        || ignoreStoragePlatform[_key].indexOf(Config.platform) < 0) {
        try {
            const _value = await AsyncStorage.getItem(_key);
            return _value ? JSON.parse(String(_value)) : null;
        } catch (e) {
            Log.debug("error reading value!");
        }
    }
}

async function getStorageKeys() {
    try {
        return await AsyncStorage.getAllKeys();
    } catch (e) {
        Log.debug("error reading keys!");
    }
}

async function clearStorage() {
    try {
        return await AsyncStorage.clear();
    } catch (e) {
        Log.debug("error clearing keys!");
    }
}

export { setStorage, getStorage, getStorageKeys, clearStorage }