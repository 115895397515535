import React from "react";
import { Platform } from 'react-native';

let isEnabled = false;
if (Platform.OS === 'web') {
    /**
     * Web browsers emulate mouse events (and hover states) after touch events.
     * This code infers when the currently-in-use modality supports hover
     * (including for multi-modality devices) and considers "hover" to be enabled
     * if a mouse movement occurs more than 1 second after the last touch event.
     * This threshold is long enough to account for longer delays between the
     * browser firing touch and mouse events on low-powered devices.
     */
    const HOVER_THRESHOLD_MS = 1000;
    let lastTouchTimestamp = 0;

    function enableHover() {
        if (isEnabled || Date.now() - lastTouchTimestamp < HOVER_THRESHOLD_MS) {
            return;
        }
        isEnabled = true;
    }

    function disableHover() {
        lastTouchTimestamp = Date.now();
        if (isEnabled) {
            isEnabled = false;
        }
    }

    document.addEventListener("touchstart", disableHover, true);
    document.addEventListener("touchmove", disableHover, true);
    document.addEventListener("mousemove", enableHover, true);
}

function isHoverEnabled(): boolean {
    return isEnabled;
}

function Hoverable(props: { children: Function | React.Component, onHoverIn?: Function, onHoverOut?: Function }) {
    const initialState = {
        isHovered: false,
        showHover: true,
    };

    const [state, setState] = React.useState(initialState);

    const _handleMouseEnter = (e: any) => {
        if (isHoverEnabled() && !state.isHovered) {
            const { onHoverIn } = props;
            if (onHoverIn) onHoverIn();
            setState(state => ({ ...state, isHovered: true }));
        }
    }

    const _handleMouseLeave = (e: any) => {
        if (state.isHovered) {
            const { onHoverOut } = props;
            if (onHoverOut) onHoverOut();
            setState(state => ({ ...state, isHovered: false }));
        }
    }

    const _handleGrant = () => {
        setState(state => ({ ...state, showHover: false }));
    }

    const _handleRelease = () => {
        setState(state => ({ ...state, showHover: true }));
    }

    const { children } = props;

    const child =
        typeof children === "function"
            ? children(state.showHover && state.isHovered)
            : children;

    const events = {
        onMouseEnter: _handleMouseEnter,
        onMouseLeave: _handleMouseLeave,
        // prevent hover showing while responder
        onResponderGrant: _handleGrant,
        onResponderRelease: _handleRelease,
        // if child is Touchable
        onPressIn: _handleGrant,
        onPressOut: _handleRelease,
    };

    return React.cloneElement(React.Children.only(child), events);
}

export { Hoverable as default };
