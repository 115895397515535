import React from 'react';
import { View } from 'react-native';
import Svg, { Path } from 'react-native-svg';

function ModalBackground(props: { color: string }) {
    return (
        <View style={{ position: 'absolute', top: 0, left: 0, right: 0, height: 160 }}>
            <Svg width="100%" height="100%" fill={props.color} x="0px" y="0px" viewBox="0 0 360 160" preserveAspectRatio="none">
                <Path id="pagina" d="M0,120c0,0,53.7,40,120,40s120-40,120-40s53.7-40,120-40V0H0L0,120z" />
            </Svg>
        </View >
    );
}

export { ModalBackground as default }