import React from 'react';

import { Store } from '../store';
import Config, { Log } from '../config';
import { Redirect } from '../components/GpeRouter';
import ConditionsWebPage from '../components/ConditionsWebPage';

function ConditionsScreen(props: any) {
    const { state } = React.useContext(Store);

    return React.useMemo(() => {
        Log.debug('screen: CONDITIONS');

        return state.user.auth
            ? <ConditionsWebPage />
            : <Redirect to={Config.routes.firstpage} />
    }, [state.user.auth]);
}

export { ConditionsScreen as default }