import React from 'react';

import Config, { Log } from '../config';
import { Store } from '../store';
import Context from '../components/ByContext';
import { Redirect } from '../components/GpeRouter';

function RegistScreen() {
    const { state } = React.useContext(Store);

    return React.useMemo(() => {
        Log.debug('screen: REGIST');

        return !state.user.auth
            ? <Context.Regist />
            : <Redirect to={Config.routes.bookshelf} />
    }, [state.user.auth]);
}

export { RegistScreen as default };