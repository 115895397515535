import RNFS from 'react-native-fs';

function getDefaultStorageDirectory(): Promise<string> {
    return Promise.resolve('./');
}

function getStorageFreeSpace(): Promise<number | undefined> {
    return Promise.resolve(undefined);
}

async function createDirectoryIfNotExists(directoryPath: string): Promise<boolean> {
    return Promise.resolve(true);
}

async function directoryExist(directoryPath: string): Promise<boolean> {
    return Promise.resolve(true);
}

async function removeDirectory(directoryPath: string): Promise<boolean> {
    return Promise.resolve(true);
}

function downloadAndSaveFile(
    url: string,
    fileDirectory: string,
    progressHandler?: (result: RNFS.DownloadProgressCallbackResult) => void,
): void {
}

async function downloadFile(
    url: string,
    fileName: string | number,
    fileDirectory: string,
    progressHandler?: (result: RNFS.DownloadProgressCallbackResult) => void,
    endHandler?: (value: any) => void,
): Promise<void> {
    return downloadAndSaveFile(
        url,
        `${fileDirectory}/${fileName}`,
        progressHandler,
    );
}

function cancelEbookDownloadByJobId(jobId: number): void {
}

export {
    getDefaultStorageDirectory,
    getStorageFreeSpace,
    downloadFile,
    cancelEbookDownloadByJobId,
    createDirectoryIfNotExists,
    directoryExist,
    removeDirectory,
}